import React from 'react';
import { get } from 'lodash';
import { PauseTableTitle } from '../../../components/PauseTable/components/PauseTableTitle';
import { PauseTableTitleActions } from '../../../components/PauseTable/components/PauseTableTitleActions';
import { Plus } from '../../../../assets/icons/feather/components';
import { PauseTable, PauseTableContainer } from '../../../components/PauseTable';
import { PauseTableHead, PauseTableHeadCell } from '../../../components/PauseTable/components/PauseTableHead';
import { PauseTableBody, PauseTableBodyCell, PauseTableRow } from '../../../components/PauseTable/components/PauseTableRow';
import { PauseTypography } from '../../../components/PauseTypography';
import { HELP_LINKS } from '../../../../utils/constants';
import { DEFAULT_LEAVE_TYPE_DESCRIPTION_MSG } from '../store';
import { HowtoLink, Type } from '../../../components/HowtoLink';
import { LeaveType } from '../../../api/leave-types';

const { nonDeductibleLeaveTypes } = HELP_LINKS;

export const NonDeductibleLTTable = (props: Props) => {
  const {
    data = [],
    isEditable = false,
    addNewLeaveTypeURL = '',
    editLeaveType = () => null,
  } = props;
  return (
    <div css={(theme) => ({
      background: get(theme, 'palette.pause.white.main'),
    })}
    >
      <PauseTableContainer>
        <PauseTableTitle
          actions={isEditable ? (
            <PauseTableTitleActions actions={[{
              icon: <Plus />,
              text: 'Add new',
              href: addNewLeaveTypeURL,
            }]}
            />
          ) : null}
          title="Non-deductible Leaves"
          info={{
            text: 'Non-Deductible leaves are unlimited in nature and do not result in any subtraction when applied for. You can use the description to provide a suggested limit.',
            buttonText: 'Learn More',
            buttonHref: nonDeductibleLeaveTypes,
          }}
          subTitle={(
            <div css={(t) => ({
              display: 'grid',
              alignItems: 'center',
              gridGap: t.spacing(2),
              gridAutoFlow: 'column',
            })}
            >
              <HowtoLink
                linkText="How do non-deductibles work?"
                link="https://support.getpause.com/en/articles/5513989-how-to-set-up-non-deductible-leave-options"
              />
              {/* TODO show in-app link after video is ready */}
              {false && (
              <HowtoLink
                linkText="Watch video tutorial"
                link="https://support.getpause.com"
                type={Type.Video}
              />
              )}
            </div>
                      )}
        />
        <PauseTable>
          <PauseTableHead>
            <PauseTableRow>
              <PauseTableHeadCell css={{
                width: '25%',
              }}
              >
                Name
              </PauseTableHeadCell>
              <PauseTableHeadCell css={{
                width: '35%',
              }}
              >
                Description
              </PauseTableHeadCell>
              <PauseTableHeadCell css={{
                width: '20%',
              }}
              >
                Requires Approval
              </PauseTableHeadCell>
              <PauseTableHeadCell css={{
                width: '15%',
              }}
              >
                {/* Allowance */}
              </PauseTableHeadCell>
            </PauseTableRow>
          </PauseTableHead>
          {data.length ? (
            <PauseTableBody>
              {(data || []).map((lt) => (
                <PauseTableRow
                  key={lt.id}
                  {...(isEditable && {
                    onClick: () => editLeaveType({ id: lt.id, type: lt.type }),
                  })}
                  css={{
                    td: {
                      verticalAlign: 'baseline',
                    },
                  }}
                >
                  <PauseTableBodyCell>
                    {isEditable ? (
                      <PauseTypography css={{ cursor: 'pointer' }}>
                        {lt.emoji}
                        {' '}
                        {lt.name}
                      </PauseTypography>
                    ) : (
                      <PauseTypography>
                        {lt.emoji}
                        {' '}
                        {lt.name}
                      </PauseTypography>
                    )}
                  </PauseTableBodyCell>
                  <PauseTableBodyCell>

                    <PauseTypography color="pause.primary.medium">
                      {lt.description || DEFAULT_LEAVE_TYPE_DESCRIPTION_MSG}
                    </PauseTypography>
                  </PauseTableBodyCell>
                  <PauseTableBodyCell>

                    <PauseTypography color="pause.primary.medium">
                      {lt.requiresApproval ? 'Yes' : 'No'}
                    </PauseTypography>
                  </PauseTableBodyCell>
                  <PauseTableBodyCell>
                          &nbsp;
                  </PauseTableBodyCell>
                </PauseTableRow>
              ))}

            </PauseTableBody>
          ) : (
            <PauseTableBody>
              <PauseTableBodyCell colspan="4">
                <PauseTypography color="primary.light">
                  No leave types, yet.
                </PauseTypography>
              </PauseTableBodyCell>
            </PauseTableBody>
          )}
        </PauseTable>
      </PauseTableContainer>
    </div>
  );
};

type Props = {
  data: LeaveType[],
  addNewLeaveTypeURL: any,
  editLeaveType: (any?) => any,
  isEditable?: boolean,
}
