import { createMuiTheme } from '@material-ui/core/styles';
import { get } from 'lodash';
import { colors } from './colors';
// Create a theme instance.

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    pauseMobile: 600,
    md: 960,
    pauseTablet: 960,
    lg: 1280,
    xl: 1920,
  },
};

export const PauseTheme = createMuiTheme({
  typography: {
    fontFamily: 'Concourse T4',
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiModal: {
      disableEnforceFocus: true,
    },
  },
  breakpoints,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          color: colors.primary.dark,
        },
      },
    },
    MuiTableCell: {
      body: {
        color: colors.primary.dark,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: colors.primary.main,
      },
      iconButton: {
        color: colors.primary.main,
      },
      dayLabel: {
        color: colors.primary.light,
        fontFamily: 'Concourse C6',
        textTransform: 'lowercase',
        fontWeight: 500,
        lineHeight: 1.22,
        fontSize: '0.875rem',
        fontFeatureSettings: "'tnum' on, 'lnum' on",
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: colors.primary.dark,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1rem',
      },
    },
    MuiChip: {
      root: {
        borderRadius: '0px',
      },
    },
    MuiButton: {
      root: {
        // Only for datepicker
        fontFamily: 'Concourse T6',
        fontWeight: 500,
        lineHeight: 1.21,
        fontSize: '1rem',

        minWidth: 'auto',
        borderRadius: '0px',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
        },
        textTransform: 'none',
        // '&:focus': {
        //   boxShadow: '0 0 0 0.1rem rgba(55,55,55,.2)',
        // },
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiCard: {
      root: {
        boxShadow: 'none',
        borderRadius: '0px',
      },
    },
    MuiTextField: {
      root: {
        '& .MuiOutlinedInput-root': {
          borderRadius: '0px',
        },
        '& .MuiFilledInput-root.Mui-disabled': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiFormControl: {
      root: {
        '& .MuiOutlinedInput-root': {
          borderRadius: '0px',
        },
        '& .MuiFilledInput-root.Mui-disabled': {
          backgroundColor: 'transparent',
        },
        '& .MuiFilledInput-root.Mui-disabled svg': {
          display: 'none',
        },
      },
    },
    MuiMenu: {
      paper: {
        boxShadow: '0px 4px 12px 0px rgba(41,41,102,0.12),0px 0px 1px 0px rgba(41,41,102,0.08)',
      },
    },
    MuiPaper: {
      root: {
        color: 'inherit',
      },
    },
  },
  palette: {
    pause: colors,
  },
  pauseShadows: {
    '-16': '0px -24px 54px 0px rgba(41,41,102,0.12), 0px 0px 4px 0px rgba(41,41,102,0.08), 0px 16px 16px 0px rgba(41,41,102,0.04)',
    '-8': '0px -12px 24px 0px rgba(41,41,102,0.12), 0px 0px 1px 0px rgba(41,41,102,0.08) , 0px 8px 8px 0px rgba(41,41,102,0.04)',
    '-4': '0px -4px 12px 0px rgba(41,41,102,0.12),0px 0px 1px 0px rgba(41,41,102,0.08),0px 4px 4px 0px rgba(41,41,102,0.04)',
    '-2': '0px -1px 2px rgba(41, 41, 102, 0.04), 0px 0px 1px rgba(41, 41, 102, 0.08)',
    2: '0px 1px 2px rgba(41, 41, 102, 0.04), 0px 0px 1px rgba(41, 41, 102, 0.08)',
    4: '0px 4px 12px 0px rgba(41,41,102,0.12),0px 0px 1px 0px rgba(41,41,102,0.08),0px 4px 4px 0px rgba(41,41,102,0.04)',
    8: '0px 12px 24px 0px rgba(41,41,102,0.12), 0px 0px 1px 0px rgba(41,41,102,0.08) , 0px 8px 8px 0px rgba(41,41,102,0.04)',
    16: '0px 24px 54px 0px rgba(41,41,102,0.12), 0px 0px 4px 0px rgba(41,41,102,0.08), 0px 16px 16px 0px rgba(41,41,102,0.04)',
  },
  shadows: [
    '0px 2px 4px 0px rgba(41,41,102,0.16),0px 0px 1px 0px rgba(41,41,102,0.08),0px 1px 2px 0px rgba(41,41,102,0.04)',
    '0px 4px 12px 0px rgba(41,41,102,0.12),0px 0px 1px 0px rgba(41,41,102,0.08),0px 4px 4px 0px rgba(41,41,102,0.04)',
    '0px 12px 24px 0px rgba(41,41,102,0.12), 0px 0px 1px 0px rgba(41,41,102,0.08) , 0px 8px 8px 0px rgba(41,41,102,0.04)',
    '0px 24px 54px 0px rgba(41,41,102,0.12), 0px 0px 4px 0px rgba(41,41,102,0.08), 0px 16px 16px 0px rgba(41,41,102,0.04)',
  ],
  get: (key) => get(PauseTheme, key),
});
