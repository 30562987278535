import React, { useEffect, useMemo, useState } from 'react';
import { get, pick } from 'lodash';
import moment from 'moment';
import { attachNonDeductibleToMe, attachNonDeductibleToPeople, attachTeamToPeople } from '../../../utils/data';
import { getLeaveStateFromLeave } from './utils';
import { useEnhancedRouter } from '../../../router';
import { useNotifier } from '../snackbar/store';
import { useApproverForPerson } from '../../hooks/people';
import { useTeams } from '../../api/teams';
import { useLeaves } from '../../api/leaves';
import { usePeople } from '../../api/people';
import { useLeaveTypes } from '../../api/leave-types';
import { useMe } from '../../api/me';
import { useOrg } from '../../api/org';
import { LeaveState } from './leave-form-state';
import { useIndividualLeave } from './useIndividualLeave';
import { getCycleEndpoints } from '../../../utils/datetime';
import { usePerson } from '../../api/person';
import { noop } from '../../api/_utils';
import { useCompOff } from '../../api/compoff';
import { useAllHands } from '../../api/allhands';
import fromEntries from '../../../utils/fromEntries';

// If there is a presetLeave, the leave id from the route will be ignored
export const useLeaveFormStore = ({ presetLeave = null, presetType = null } = {}) => {
  const { allHands: lockedDates } = useAllHands();
  const {
    loading,
    org,
  } = useOrg();

  const { me } = useMe();

  const { people } = usePeople();

  const {
    createLeave,
    approveLeave,
    declineLeave,
    cancelLeave,
  } = useLeaves();

  const { teams } = useTeams();
  const { leaveTypes } = useLeaveTypes();

  const router = useEnhancedRouter();
  const leaveID: string | null = Array.isArray(router.query.id)
    ? router.query.id.join()
    : router.query.id || null;

  const actualLeaveID: string | null = (presetLeave) ? get(presetLeave, 'id', null) : leaveID;
  const { leave, loading: leaveLoading } = useIndividualLeave({
    id: (!presetLeave || ['LEAVE', 'LEAVE_NOTIFY_ADMIN'].includes(presetType || '')) ? actualLeaveID : null,
  });

  const [leaveState, setLeaveState] = useState<LeaveState>(LeaveState.New);
  const { enqueueNotification } = useNotifier();

  // This takes a leave object and returns the same object with
  // `applicant` and `leaveType` populated from their IDs
  // eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
  const formatLeaveForInitialValue = React.useCallback((leave) => ({
    ...leave,
    applicant: (people || []).find((p) => p.id === get(leave, 'applicant.id')),
    leaveType: (leaveTypes || []).find((l) => l.id === get(leave, 'leaveType.id')),
  }), [people, leaveTypes]);

  useEffect(() => {
    setLeaveState(getLeaveStateFromLeave(leave));
  }, [leave]);

  const onCreateLeave = (payload) => createLeave(payload).then(
    (data) => {
      if (data) {
        const teamOfApplicant = teams.find(
          (team) => team.id === get(data, 'applicant.teamID'),
        );
        const approver = people.find(
          (person) => person.id === get(teamOfApplicant, 'approverID'),
        );
        const applicantIsRequester = get(data, 'applicant.id') === get(data, 'requester.id');
        const message = data.status === 'ACCEPTED'
          ? `${applicantIsRequester ? 'Your' : 'The'} leave is approved!`
          : `Leave application sent${
            approver ? ` to ${approver.name}` : '!'
          }`;
        enqueueNotification(message, {
          variant: 'success',
        });
      }
      return Promise.resolve();
    },
  ).catch(() => Promise.reject());

  const onApproveLeave = (payload) => approveLeave({
    id: get(leave, 'id'),
    ...payload,
    // eslint-disable-next-line consistent-return
  }).then((data) => {
    if (data) {
      enqueueNotification(
        'Leave is approved!',
        { variant: 'success' },
      );
      return Promise.resolve();
    }
  }).catch(() => Promise.reject());

  const onRejectLeave = (payload) => declineLeave({
    id: get(leave, 'id'),
    ...payload,
    // eslint-disable-next-line consistent-return
  }).then((data) => {
    if (data) {
      enqueueNotification(
        'Leave rejected!',
        { variant: 'success' },
      );
      return Promise.resolve();
    }
  }).catch(() => Promise.reject());

  const onDeleteLeave = () => cancelLeave({
    id: get(leave, 'id'),
    // eslint-disable-next-line consistent-return
  }).then((data) => {
    if (data) {
      enqueueNotification(
        'Leave deleted!',
      );
      return Promise.resolve();
    }
  }).catch(noop);

  const currentLeave = React.useMemo(() => (leave ? formatLeaveForInitialValue(leave) : null),
    [formatLeaveForInitialValue, leave]);

  const [selectedPerson, setSelectedPerson] = useState(me);

  // Start - compute correct approver
  const currentApprover = useApproverForPerson(selectedPerson);

  const leaveApproverID = get(currentLeave, 'actioner.id');

  const leaveApprover = (people || []).find((p) => p.id === leaveApproverID);

  const approver = React.useMemo(() => (leaveApprover || currentApprover),
    [leaveApprover, currentApprover]);

  // End - compute correct approver

  const selectablePeople = React.useMemo(() => {
    if (!me || !people || !teams || !leaveTypes) return [];
    const isAdmin = get(me, 'isAdmin');
    // const teamsIApproveFor = get(me, 'roles.approver.teams', []).filter((t) => t.id).map((a) => a.id);
    const peopleIApproveFor = me?.roles?.approver?.applicants || [];
    return isAdmin
      ? (attachNonDeductibleToPeople({
        people: attachTeamToPeople({ people, teams }),
        leaveTypes,
      }))
      : (attachNonDeductibleToPeople({
        people: attachTeamToPeople({ people, teams }),
        leaveTypes,
      }).filter((p) => peopleIApproveFor.includes(p.id)));
  }, [me, people, teams, leaveTypes]);

  const [leaveStartDate, setLeaveStartDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const { person } = usePerson({
    personID: get(selectedPerson, 'id'),
    params: {
      cycleStartDate: moment(getCycleEndpoints({
        cycleStartMonth: get(org, 'cycleStartMonth'),
        today: leaveStartDate,
      }).startDate).format('YYYY-MM-DD'),
    },
    enabled: !currentLeave,
  });
  const leaveTypeEmojiMap = fromEntries((leaveTypes || []).map((l) => [l.id, l.emoji]));
  const allowanceList = useMemo(() => {
    const list = get(person, 'allowance', []);
    return list.map((l) => ({
      ...l,
      emoji: leaveTypeEmojiMap[l.id] || '',
    }));
  }, [person]);
  const selectedDate = router.query?.type;

  const {
    createCompOff,
    cancelCompOff,
    approveCompOff,
    declineCompOff,
  } = useCompOff();
  const onCreateCompOff = (data: {
    numberOfDays: number,
    applicationReason: string,
  }) => createCompOff(data).then((response) => {
    const status = response?.status;
    if (status === 'PENDING') {
      enqueueNotification('Comp-off request has been sent!');
      return null;
    }
    if (status === 'ACCEPTED') {
      enqueueNotification('Comp-off applied!');
      return null;
    }
    return null;
  }).catch(noop);

  const onCancelCompOff = (id: string) => cancelCompOff(id).then(() => {
    enqueueNotification('Comp-off canceled!');
  }).catch(noop);

  const onApproveCompOff = (id: string) => approveCompOff(id).then(() => {
    enqueueNotification('Comp-off approved!');
  }).catch(noop);

  const onDeclineCompOff = (id: string) => declineCompOff(id).then(() => {
    enqueueNotification('Comp-off declined!');
  }).catch(noop);

  // eslint-disable-next-line no-nested-ternary
  const currentCompOff = presetLeave ? presetType !== 'COMP_OFF' ? null : {
    id: (presetLeave as any)?.id || '',
    numberOfDays: ((presetLeave as any)?.quarterDays || 0) * 0.25,
    applicationReason: (presetLeave as any)?.applicationReason || '',
    applicant: (presetLeave as any)?.applicant || me,
    status: get(presetLeave, 'status', ''),
    actioner: get(presetLeave, 'actioner', null),
  } : null;

  const currentAllHands = presetLeave ? presetType !== 'LOCKED_DATE' ? null : {
    team: {
      id: (presetLeave as any)?.team?.id,
      name: (presetLeave as any)?.team?.name || (teams || []).find((t) => t.id === (presetLeave as any)?.team?.id)?.name,
    },
    ...pick<any>(presetLeave, [
      'startDate',
      'endDate',
      'startType',
      'endType',
      'id',
      'reason',
    ]),
  } : null;

  const {
    onCreateAllHands,
    onDeleteAllHands,
  } = useAllHands();

  return useMemo(() => ({
    selectablePeople,
    lockedDates,
    currentLeave,
    onCreateLeave,
    onApproveLeave,
    onRejectLeave,
    onDeleteLeave,
    approver,
    setSelectedPerson,
    loading: loading || leaveLoading,
    teams,
    me: attachNonDeductibleToMe({ me, leaveTypes }),
    selectedPerson,
    disableDeductibleLeaveTypes: get(org, 'disableDeductibleLeaveTypes'),
    leaveState,
    currentApprover,
    allowanceList,
    setLeaveStartDate,
    selectedDate,
    currentCompOff,
    onCreateCompOff,
    onCancelCompOff,
    onApproveCompOff,
    onDeclineCompOff,
    currentAllHands,
    onCreateAllHands,
    onDeleteAllHands,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [me,
    leaveTypes,
    teams,
    people,
    loading,
    leaveLoading,
    approver,
    selectedPerson,
    leaveState,
    currentLeave,
    currentApprover,
    allowanceList,
    setLeaveStartDate,
    selectedDate,
    currentCompOff,
    currentAllHands,
    org]);
};

export const LeaveFormStoreWrapper = ({ children, presetLeave, presetType }: any) => {
  const storeData = useLeaveFormStore({ presetLeave, presetType });
  return children(storeData);
};
