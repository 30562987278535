import React from 'react';
import { Drawer } from '@material-ui/core';
import { get } from 'lodash';
import X from '../../../assets/icons/feather/components/X';
import { PauseTypography } from '../PauseTypography';
import { PauseIconButton } from '../PauseIconButton';
import { PauseLoader } from '../PauseLoader';

export const PauseDrawerContext = React.createContext<any>(null);

export const PauseDrawer = ({
  open = false,
  onClose = () => {},
  anchor = 'right',
  topOffset = 0,
  bottomOffset = 0,
  leftOffset,
  rightOffset,
  children,
  width = '320px',
  zIndex,
  hasChildDrawer = false,
  isChildDrawer = false,
  variant,
  ref,
  loading,
  ...props
}: {
  open?: boolean,
  onClose?: (any?) => any,
  anchor?: 'right' | 'top' | 'left' | 'bottom',
  topOffset?: number,
  bottomOffset?: number,
  leftOffset?: number,
  rightOffset?: number,
  children?: React.ReactNode,
  width?: string,
  zIndex?: number,
  hasChildDrawer?: boolean,
  isChildDrawer?: boolean,
  variant?: 'permanent' | 'persistent' | 'temporary',
  ref?: any,
  loading?: boolean,
  [key: string]: any,
}) => (
  <PauseDrawerContext.Provider value={{ onClose }}>
    <Drawer
      disableScrollLock
      {...props}
      ref={ref}
      anchor={anchor}
      open={open}
      onClose={onClose}
      variant={variant}
      // @ts-ignore
      css={(theme) => ({
        '&.MuiDrawer-root': {
          top: `${Number(topOffset)}px !important`,
          bottom: `${Number(bottomOffset)}px !important`,
          // TODO: the left and right offsets need to be responsive
          ...(leftOffset !== null && { left: `${Number(leftOffset)}px !important` }),
          ...(rightOffset !== null && { right: `${Number(rightOffset)}px !important` }),
          ...(zIndex && { zIndex: `${zIndex} !important` }),
        },
        '.MuiBackdrop-root': {
          backgroundColor: 'transparent',
          top: `${Number(topOffset)}px !important`,
          bottom: `${Number(bottomOffset)}px !important`,
        },
        '.MuiDrawer-paper': {
          width,
          maxWidth: '100vw',
          minWidth: '320px',
          height: 'auto',
          ...(isChildDrawer && { zIndex: -1 }),
          top: Number(topOffset),
          bottom: Number(bottomOffset),
          borderRadius: 0,
          [theme.breakpoints.down(theme.breakpoints.values.pauseMobile)]: {
            minWidth: 'auto',
            maxWidth: '100vw',
            width: '100vw',
          },
          ...(leftOffset !== null && { left: `${Number(leftOffset)}px !important` }),
          ...(rightOffset !== null && { right: `${Number(rightOffset)}px !important` }),
          ...(!hasChildDrawer && (anchor === 'right') && {
            borderTopLeftRadius: theme.spacing(1),
            borderBottomLeftRadius: theme.spacing(1),
          }),
          ...(!hasChildDrawer && (anchor === 'left') && {
            borderTopRightRadius: theme.spacing(1),
            borderBottomRightRadius: theme.spacing(1),
          }),
          ...(!isChildDrawer && { boxShadow: get(theme, 'pauseShadows.16') }),
          ...(isChildDrawer && {
            boxShadow: get(theme, 'pauseShadows.16'),
            borderLeft: `${theme.spacing(0.5)}px solid ${get(theme, 'palette.pause.background.dark')}`,
          }),
          ...(isChildDrawer && (anchor === 'left') && {
            borderTopRightRadius: theme.spacing(1),
            borderBottomRightRadius: theme.spacing(1),
          }),
          ...(isChildDrawer && (anchor === 'right') && {
            borderTopRightRadius: theme.spacing(1),
            borderBottomRightRadius: theme.spacing(1),
          }),
          '@media (min-width: 1366px)': {
            minWidth: '400px',
          },
        },
      })}
    >
      {loading ? <PauseLoader showPageTitle={false} /> : (
        <div
          css={() => ({
            display: 'grid',
            height: '100%',
            gridTemplateRows: 'auto 1fr minmax(max-content, max-content)',
            alignItems: 'stretch',
          })}
        >
          {children}
        </div>
      )}
    </Drawer>
  </PauseDrawerContext.Provider>
);

export const PauseDrawerTitle = ({
  children = <></>,
  render,
  compact = false,
  hideCloseButton = false,
}: {
  children?: React.ReactNode,
  render?: JSX.Element,
  compact?: boolean,
  hideCloseButton?: boolean,
}) => {
  const ctx = React.useContext(PauseDrawerContext);
  return (
    <div
      css={(theme) => ({
        height: '100%',
        display: 'grid',
        gridAutoFlow: 'column',
        gridGap: theme.spacing(3),
        alignItems: 'center',
        justifyContent: 'space-between',
        background: theme.palette.pause.background.medium,
        padding: compact ? `0 ${theme.spacing(2)}px 0 0` : `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(3)}px`,
      })}
    >
      {!render ? (
        <PauseTypography variant="title" color="pause.primary.dark">
          {children}
        </PauseTypography>
      ) : render}
      {!hideCloseButton && (
      <PauseIconButton
        // @ts-ignore
        icon={<X />}
        onClick={ctx.onClose}
        variant="primaryAlt"
        compact
        size="small"
      />
      )}
    </div>
  );
};

export const PauseDrawerContent = ({
  children = <></>,
  compact = false,
  isNotificationDrawer = true,
  noPadding = false,
}: {
  children: React.ReactNode,
  compact?: boolean,
  isNotificationDrawer?: boolean,
  noPadding?: boolean,
}) => (
  <div
    css={(theme) => ({
      overflowX: 'hidden',
      overflowY: 'auto',
      height: '100%',
      padding: compact ? 0 : theme.spacing(3),
      background: theme.get('palette.pause.background.light'),
      ...(!isNotificationDrawer && {
        padding: theme.spacing(3),
      }),
      ...(!compact && noPadding && {
        padding: theme.spacing(0),
      }),
    })}
  >
    {children}
  </div>
);

export const PauseDrawerFooter = ({
  children,
}) => (
  <div css={(theme) => ({
    background: theme.palette.pause.background.medium,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  })}
  >
    <div>
      {children}
    </div>
  </div>
);
