import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { PauseTypography } from '../../../PauseTypography';
import { PauseIconButton } from '../../../PauseIconButton';
import { Info } from '../../../../../assets/icons/feather/components';
import { PauseTooltip } from '../../../PauseTooltip';

export const PauseTableTitle = ({
  title, info, titleHelper, actions, subTitle,
}) => (
  <div css={(theme) => ({
    // minHeight: theme.spacing(9),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gridGap: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  })}
  >
    <div css={(theme) => ({
      display: 'grid',
      gridAutoFlow: 'column',
      alignItems: 'center',
      gridGap: theme.spacing(0.5),
      justifyContent: 'start',
    })}
    >
      <div>
        <PauseTypography
          variant="title"
          css={(t) => ({
            display: 'flex',
            gap: t.spacing(1.5),
            alignItems: 'center',
          })}
        >
          {title}
          {titleHelper ? (
            <>
              {titleHelper}
            </>
          ) : null}
          {info && info.text && (
          <PauseTooltip
            arrow
            interactive
            buttonText={info.buttonText}
            buttonOnClick={info.buttonOnClick}
            title={info.text}
            buttonHref={info.buttonHref}
          >
            <PauseIconButton icon={<Info />} variant="primaryAlt" compact />
          </PauseTooltip>
          )}
        </PauseTypography>
        {subTitle && (
        <div css={(t) => ({
          marginTop: t.spacing(0.5),
        })}
        >
          {subTitle}
        </div>
        )}
      </div>
    </div>
    {actions && actions}
  </div>
);

PauseTableTitle.propTypes = {
  title: PropTypes.string.isRequired,
  titleHelper: PropTypes.node,
  actions: PropTypes.node,
  info: PropTypes.objectOf({
    text: PropTypes.element.isRequired,
    buttonText: PropTypes.string,
    buttonOnClick: PropTypes.func,
    buttonHref: PropTypes.string,
  }),
  subTitle: PropTypes.node,
};

PauseTableTitle.defaultProps = {
  actions: <></>,
  titleHelper: null,
  info: null,
  subTitle: null,
};
