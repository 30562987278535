import React from 'react';
import { PauseAvatar } from '../PauseAvatar';
import { PauseTypography } from '../PauseTypography';
import { ExpressiveColors } from '../PauseTheme/colors';
import { PauseTag } from '../PauseTag';

type Props = {
  fullName: string,
  color?: ExpressiveColors,
  image?: string,
  teamName?: string,
  isAdmin?: boolean,
  hasLoggedIn?: boolean,
  description?: string,
}
export const PausePerson = ({
  fullName, color = 'purple', image = '', teamName = '', isAdmin = false, hasLoggedIn,
  description = '',
}: Props) => (
  <div css={(theme) => ({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: theme.spacing(1),
    alignItems: 'center',
  })}
  >
    <PauseAvatar color={color} size="large" src={image} />
    <div css={(theme) => ({
      display: 'grid',
      gridGap: theme.spacing(0.5),
      justifyContent: 'start',
    })}
    >
      <div css={{
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: 'auto 1fr',
        alignItems: 'center',
      }}
      >
        <PauseTypography dontWrapText color="pause.primary.main" variant="body1">
          {fullName}
        </PauseTypography>
        {isAdmin && (
        <div css={(theme) => ({
          marginLeft: theme.spacing(1),
        })}
        >
          <PauseTag>Admin</PauseTag>
        </div>
        )}
        {hasLoggedIn === false && (
        <div css={(theme) => ({
          marginLeft: theme.spacing(1),
        })}
        >
          <PauseTag textColor="yellow.dark" color="yellow.light">Pending</PauseTag>
        </div>
        )}
      </div>
      {(teamName || description) && <PauseTypography dontWrapText color="pause.primary.medium" variant="body2">{(teamName || description)}</PauseTypography>}
    </div>
  </div>
);
