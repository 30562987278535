import React, { useEffect, useState } from 'react';
import { get, uniq } from 'lodash';
import { useFormikContext } from 'formik';
// import moment from 'moment';
import { PauseInputGeneric } from '../../../components/PauseInputGeneric';
import { PauseFormField, PauseFormSubmitButton } from '../../../components/PauseForm';
import {
  AlignRight,
  Calendar,
  Check,
  Grid as GridIcon,
  Info,
  User,
  X,
} from '../../../../assets/icons/feather/components';
import { PausePerson } from '../../../components/PausePerson';
import { PauseButton } from '../../../components/PauseButton';
import { LeaveDateRangePicker } from '../../../components/PauseDatepicker/picker';
import { PauseTypography } from '../../../components/PauseTypography';
// import { intersectingDates } from '../../../../utils/datetime';
import { PauseFormGroupCard, PauseFormGroupCardsList } from '../../../components/PauseFormGroupCard';
import { LeaveTypeDropdown } from '../../../components/LeaveTypeDropdown';
import { getLeaveActionerString, getLeaveTemporalPositionFromLeave } from '../utils';
import { joinNames } from '../../../../utils/data';
import { Conflict, LeaveCheck, useLeaves } from '../../../api/leaves';
import {
  getLeaveActions, LeaveAction, LeaveState, Viewer,
} from '../leave-form-state';

import { LeaveFormIntermediateState } from './LeaveForm';
import { PauseSubmitButton } from '../../../components/PauseSubmitButton';
import { intersectingLockedDates } from '../../../../utils/datetime';
import { getLeaveInWords } from '../../../../utils/leaves';
import { noop } from '../../../../utils/noop';

type UseDeductionCountLogic = (a: {
  leaveState: LeaveState,
  leave: any
}) => (LeaveCheck & {
  hasApplicantConflict: boolean,
  loading: boolean,
}) | null

const useDeductionCountLogic: UseDeductionCountLogic = ({
  leave = null,
  leaveState,
}) => {
  const formik = useFormikContext<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [deduction, setDeduction] = useState<number | null>(null);
  const [conflicts, setConflicts] = useState<Conflict[]>([]);
  const [hasApplicantConflict, setHasSelfConflict] = useState<boolean>(false);
  const { getDeductionAndConflicts } = useLeaves();

  const checkLeaveForConflictsAndDeduction = ![LeaveState.Deleted, LeaveState.Declined]
    .includes(leave);

  useEffect(() => {
    async function fetchDeductionData() {
      if (checkLeaveForConflictsAndDeduction && formik.values.leaveType) {
        const params = {
          startDate: formik.values.startDate,
          endDate: formik.values.endDate,
          startType: formik.values.startType,
          endType: formik.values.endType,
          leaveTypeID: get(formik.values, 'leaveType.id'),
          applicantID: get(formik.values, 'applicant.id'),
        };

        setLoading(true);
        try {
          if (!leave) {
            const data = await getDeductionAndConflicts(params);
            setDeduction(get(data, 'deduction', 0));
            setConflicts(
              (data?.conflicts || []).filter((c) => c.personID !== get(formik.values, 'applicant.id')),
            );
            setHasSelfConflict(get(data, 'conflicts', []).some((c) => c.personID === get(formik.values, 'applicant.id')));
          } else {
            setDeduction(get(leave, 'deduction', 0));
            setConflicts(
              get(leave, 'conflicts', []),
            );
            setHasSelfConflict(get(leave, 'conflicts', []).some((c) => c.personID === get(formik.values, 'applicant.id')));
          }
        } catch (e) {
          noop();
        }
        setLoading(false);
      }
    }
    fetchDeductionData().catch(noop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    leave,
    formik.values.leaveType,
    formik.values.startDate,
    formik.values.endDate,
    formik.values.startType,
    formik.values.endType,
    formik.values.applicant,
    // formik.values.hasLockedDateError
  ]);

  return [LeaveState.Deleted, LeaveState.Declined].includes(leaveState) ? null : {
    loading,
    deduction,
    conflicts,
    hasApplicantConflict: ![LeaveState.New]
      .includes(leaveState)
      ? false
      : hasApplicantConflict,
  };
};

const useLockedDateCheck = (args: {
  lockedDates: Array<{ creator: { fullName: string }, id: string, startDate: string, endDate: string, startType: string, endType: string, team: { id: string, name: string } }>,
  applicant: { teamID: string } & any
}) => {
  const formik: any = useFormikContext();
  const { lockedDates = [], applicant = null } = args;
  const leaveRange = {
    startDate: formik.values.startDate,
    endDate: formik.values.endDate,
    startType: formik.values.startType,
    endType: formik.values.endType,
  };
  const intersection = intersectingLockedDates(leaveRange)(lockedDates);
  const applicableLockedDates = uniq(intersection.filter((ldObj) => (applicant?.teamIDs || []).includes(ldObj.team.id)).map((ldObj) => ldObj.id));
  return {
    intersectingLockedDates: lockedDates.filter((ld) => applicableLockedDates.includes(ld.id)),
  };
};

const convertLockedDateIntoAMessage = (args: {
  team: {
    name: string
  },
  creator: {
    fullName: string
  },
  startDate: string,
  endDate: string,
  startType: string,
  endType: string
}): string => `${args.creator.fullName} has set an all-hands for the ${args.team.name} team for this period: ${getLeaveInWords(args)}`;

interface InfoTextProps extends React.PropsWithChildren<{}> {
  message: string,
  type: 'warning' | 'error' | 'info' | 'success'
}
const InfoText = ({ message, type = 'warning' }: InfoTextProps) => {
  // eslint-disable-next-line no-nested-ternary
  const colorsFromType = {
    warning: 'pause.orange.dark',
    error: 'pause.error.main',
    success: 'pause.success.main',
    info: 'pause.primary.dark',
  }[type] || 'pause.primary.dark';

  const IconFromType = {
    warning: Info,
    error: X,
    success: Check,
    info: Info,
  }[type] || Info;

  return (
    <div css={(theme) => ({
      display: 'grid',
      gridAutoFlow: 'columns',
      gridTemplateColumns: `${theme.spacing(2)}px auto`,
      gridGap: theme.spacing(2),
    })}
    >
      <div>
        <IconFromType
          css={(theme) => ({
            width: theme.spacing(2.5),
            height: theme.spacing(2.5),
            stroke: get(theme, `palette.${colorsFromType}`),
          })}
        />
      </div>
      <div>
        <PauseTypography color={colorsFromType}>
          {message}
        </PauseTypography>
      </div>
    </div>
  );
};

interface LeaveApplicationFooterProps {
  lockedDates: any[],
  currentLeave: any | null,
  isLoading: boolean,
  formIntermediateState: LeaveFormIntermediateState | null,
  changeFormIntermediateState: (a: LeaveFormIntermediateState | null) => any,
  onClose: () => any,
  disableDeductibleLeaveTypes: boolean,
  viewer: Viewer,
  leaveState: LeaveState,
  me: any,
}
// TODO: remove 'export'. We're doing this just for using this in storybook.
export const LeaveApplicationFooter = ({
  lockedDates,
  currentLeave,
  isLoading,
  formIntermediateState = null,
  changeFormIntermediateState = () => null,
  onClose = () => null,
  disableDeductibleLeaveTypes,
  viewer,
  leaveState,
  me,
}: LeaveApplicationFooterProps) => {
  const conflictsAndDeductionResult = useDeductionCountLogic({
    // isNewLeave: !!currentLeave,
    leave: currentLeave,
    leaveState,
  });

  const leaveCheckLoading = get(conflictsAndDeductionResult, 'loading', false);
  const hasApplicantConflict = get(conflictsAndDeductionResult, 'hasApplicantConflict', null);
  const deduction = get(conflictsAndDeductionResult, 'deduction', null);
  const conflicts = get(conflictsAndDeductionResult, 'conflicts', []);

  const formik = useFormikContext<any>();

  const { applicant } = formik.values;

  const { intersectingLockedDates } = useLockedDateCheck({ lockedDates, applicant });

  const deductionString = React.useMemo<string | null>(() => {
    if (hasApplicantConflict || disableDeductibleLeaveTypes) return null;
    if (deduction === null) return null;
    return `${deduction} day(s) ${currentLeave ? 'are' : 'will be'} deducted.`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasApplicantConflict, disableDeductibleLeaveTypes, deduction]);

  const conflictsString = React.useMemo<string | null>(() => {
    if (hasApplicantConflict) {
      if (get(me, 'id') === get(applicant, 'id')) {
        return 'You cannot apply this leave because you are already on pause during this period.';
      }
      return `You cannot apply this leave because ${applicant.fullName} is already on pause during this period`;
    }
    if (conflicts.length) {
      const names = Array.from(new Set((conflicts as Conflict[]).map((c) => c.personName)));
      return `${joinNames(names)} ${names.length > 1 ? 'are' : 'is'} already on pause during this period.`;
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicant, conflicts, hasApplicantConflict]);

  const disableSubmit = hasApplicantConflict || undefined;

  const leaveTemporalPosition = getLeaveTemporalPositionFromLeave(currentLeave);

  const leaveFormAction = getLeaveActions({
    leaveState,
    viewer,
    leaveTemporalPosition,
  });

  return isLoading ? (<></>) : (
    <div css={(theme) => ({
      display: 'grid',
      gridGap: theme.spacing(2),
    })}
    >
      {!leaveCheckLoading && deductionString && (
      <InfoText message={deductionString} type="success" />
      )}

      {!leaveCheckLoading && conflictsString && (
        <InfoText message={conflictsString} type={hasApplicantConflict ? 'error' : 'warning'} />
      )}

      {!leaveCheckLoading && intersectingLockedDates?.length ? (
        <InfoText
          message={
          intersectingLockedDates?.length === 1 ? convertLockedDateIntoAMessage(intersectingLockedDates[0]) : 'There are multiple all-hands during this leave range.'
        }
          type="warning"
        />
      ) : null}

      <div>
        {leaveFormAction === LeaveAction.Submit && (
        <div css={(theme) => ({
          display: 'grid',
          gridAutoFlow: 'column',
          gridTemplateColumns: '1fr 1fr',
          gridGap: theme.spacing(2),
        })}
        >
          <div>
            <PauseButton
              onClick={onClose}
              variant="outlined"
              color="error"
            >
              Discard
            </PauseButton>
          </div>
          <div>
            <PauseSubmitButton disabled={disableSubmit}>
              <PauseButton>
                Submit
              </PauseButton>
            </PauseSubmitButton>
          </div>
        </div>
        )}

        {leaveFormAction === LeaveAction.ApproveReject && (
        <div css={(theme) => ({
          display: 'grid',
          gridAutoFlow: 'column',
          gridTemplateColumns: '1fr 1fr',
          gridGap: theme.spacing(2),
        })}
        >
          {formIntermediateState === LeaveFormIntermediateState.ConfirmDecline ? (
            <>
              <div>
                <PauseButton onClick={() => changeFormIntermediateState(null)} variant="outlined">Back</PauseButton>
              </div>
              <div>
                <PauseSubmitButton enableForInitialValid>
                  <PauseButton color="error">
                    Decline
                  </PauseButton>
                </PauseSubmitButton>
                {/* <PauseFormSubmitButton color="error">Decline</PauseFormSubmitButton> */}
              </div>
            </>
          ) : (
            <>
              <div>
                <PauseButton onClick={() => changeFormIntermediateState(LeaveFormIntermediateState.ConfirmDecline)} color="error">Decline</PauseButton>
              </div>
              <div>
                <PauseSubmitButton enableForInitialValid>
                  <PauseButton color="success">
                    Approve
                  </PauseButton>
                </PauseSubmitButton>
              </div>
            </>
          )}
        </div>
        )}

        {leaveFormAction === LeaveAction.ApproveDelete && (
        <div css={(theme) => ({
          display: 'grid',
          gridAutoFlow: 'column',
          gridTemplateColumns: '1fr 1fr',
          gridGap: theme.spacing(2),
        })}
        >
          {formIntermediateState === LeaveFormIntermediateState.ConfirmDelete ? (
            <>
              <div>
                <PauseButton onClick={() => changeFormIntermediateState(null)} variant="outlined">Back</PauseButton>
              </div>
              <div>
                <PauseFormSubmitButton color="error">Delete</PauseFormSubmitButton>
              </div>
            </>
          ) : (
            <>
              <div>
                <PauseButton onClick={() => changeFormIntermediateState(LeaveFormIntermediateState.ConfirmDelete)} color="error">Delete</PauseButton>
              </div>
              <div>
                <PauseFormSubmitButton color="success">Approve</PauseFormSubmitButton>
              </div>
            </>
          )}
        </div>
        )}

        {leaveFormAction === LeaveAction.Delete && (
        <div css={(theme) => ({
          display: 'grid',
          gridAutoFlow: 'column',
          gridGap: theme.spacing(2),
        })}
        >
          <div>
            <PauseSubmitButton enableForInitialValid disableIfFormNotValid disabled={disableSubmit}>
              <PauseButton color="error">
                Delete
              </PauseButton>
            </PauseSubmitButton>
          </div>
        </div>
        )}
        {leaveFormAction === LeaveAction.Nothing && (
        <div css={(theme) => ({
          display: 'grid',
          gridAutoFlow: 'column',
          gridGap: theme.spacing(2),
        })}
        >
          <div>
            <PauseButton variant="outlined" onClick={onClose}>Close</PauseButton>
          </div>
        </div>
        )}
      </div>

    </div>
  );
};

export const LeaveRangePicker = ({ isReadOnly }: any) => {
  const { values, setFieldValue } = useFormikContext<any>();

  const updateLeaveDateRange = React.useCallback(({
    startDate,
    endDate,
    startType,
    endType,
  }) => {
    setFieldValue('startDate', startDate);
    setFieldValue('endDate', endDate);
    setFieldValue('startType', startType);
    setFieldValue('endType', endType);
  }, [setFieldValue]);

  return (
    <LeaveDateRangePicker
      /* @ts-ignore */
      isReadOnly={isReadOnly}
      startDate={values.startDate}
      endDate={values.endDate}
      startType={values.startType}
      endType={values.endType}
      onChange={updateLeaveDateRange}
    />
  );
};

interface LeaveFormContentsProps extends React.PropsWithChildren<{}> {
  people: any[],
  approver: { fullName: string },
  setSelectedPerson: (person: any) => any,
  disableDeductibleLeaveTypes: boolean,
  formIntermediateState: LeaveFormIntermediateState | null,
  leaveState: LeaveState,
  me: any,
  currentLeave: any,
  allowanceList: any[],
  setLeaveStartDate: (a: string | moment.Moment) => any
}
export const LeaveFormContents = ({
  people = [],
  approver,
  setSelectedPerson,
  disableDeductibleLeaveTypes,
  formIntermediateState,
  leaveState,
  me,
  currentLeave,
  allowanceList = [],
  setLeaveStartDate = () => null,
}: LeaveFormContentsProps) => {
  const formik = useFormikContext<any>();
  const amIApprover = get(me, 'roles.approver.applicants', []).length > 0;
  const amIAdmin = get(me, 'isAdmin');
  const hideApplicantDropdown = leaveState === LeaveState.New && (!amIApprover && !amIAdmin);

  useEffect(() => {
    setSelectedPerson(formik.values.applicant);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.applicant]);

  useEffect(() => {
    setLeaveStartDate(formik.values.startDate);
  }, [formik.values.startDate, setLeaveStartDate]);

  const approverString = React.useMemo(() => getLeaveActionerString({
    leave: currentLeave,
    currentApprover: approver,
    me,
  }), [me, approver, currentLeave]);

  return (
    <div css={(theme) => ({
      background: theme.get('palette.pause.background.light'),
      minHeight: '100%',
    })}
    >
      {/* {approver && ( */}
      <div css={(theme) => ({
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(0.5),
      })}
      >
        <PauseTypography variant="caption" color="pause.primary.medium">
          {approverString}
        </PauseTypography>
      </div>
      {/* )} */}
      <PauseFormGroupCardsList>
        {(!hideApplicantDropdown) && (
        <PauseFormGroupCard>
          <PauseFormField name="applicant">
            <PauseInputGeneric
              /* @ts-ignore */
              disabled={leaveState !== LeaveState.New}
              startIcon={<User />}
              type="autocomplete"
              placeholder="Select a person"
              labelKey="fullName"
              options={people}
              /* @ts-ignore */
              dropdownItemComponent={<PausePerson />}
            />
          </PauseFormField>
        </PauseFormGroupCard>
        )}
        <PauseFormGroupCard>
          <PauseInputGeneric
            /* @ts-ignore */
            startIcon={<Calendar />}
            customComponent={<LeaveRangePicker isReadOnly={leaveState !== LeaveState.New} />}
          />
        </PauseFormGroupCard>
        <PauseFormGroupCard>
          <PauseFormField name="leaveType">
            <PauseInputGeneric
              /* @ts-ignore */
              disabled={leaveState !== LeaveState.New}
              labelKey="name"
              startIcon={<GridIcon />}
              placeholder="Select type of leave"
              customComponent={(
                <LeaveTypeDropdown
                  disableDeductibleLeaveTypes={disableDeductibleLeaveTypes}
                  disabled={leaveState !== LeaveState.New}
                  leaveTypes={allowanceList}
                  selectedLeaveType={formik.values.leaveType}
                  onChange={(v) => formik.setFieldValue('leaveType', v.target.value)}
                />
              )}
            />
          </PauseFormField>
        </PauseFormGroupCard>
        <PauseFormGroupCard>
          <PauseFormField name="applicationReason">
            <PauseInputGeneric
              /* @ts-ignore */
              type="textarea"
              disabled={leaveState !== LeaveState.New}
              startIcon={<AlignRight />}
              rows={4}
              placeholder={leaveState === LeaveState.New ? 'Add reason for leave (optional)' : 'No reason given'}
            />
          </PauseFormField>
        </PauseFormGroupCard>
        {(formIntermediateState === LeaveFormIntermediateState.ConfirmDecline
                || leaveState === LeaveState.Declined) && (
                <PauseFormGroupCard>
                  <PauseFormField name="rejectionReason">
                    <PauseInputGeneric
                      /* @ts-ignore */
                      autoFocus
                      type="textarea"
                      disabled={formIntermediateState !== LeaveFormIntermediateState.ConfirmDecline}
                      startIcon={<AlignRight />}
                      rows={4}
                      placeholder={formIntermediateState
                      === LeaveFormIntermediateState.ConfirmDecline
                        ? 'Add reason for declining'
                        : 'No reason given for declining'}
                    />
                  </PauseFormField>
                </PauseFormGroupCard>
        )}
      </PauseFormGroupCardsList>
    </div>
  );
};
