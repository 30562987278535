import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { PauseTypography } from '../PauseTypography';

export const PauseMenuItem = ({
  children, selected = false, disableHighlighting = false, compact = false, color = 'pause.primary.dark', ...props
}) => (
  <MenuItem
    css={(theme) => ({
      ...(compact && ({
        paddingLeft: 0,
        paddingRight: 0,
      })),
      width: '100%',
      backgroundColor: theme.get('palette.pause.white.main'),
      '&:hover': {
        backgroundColor: theme.get('palette.pause.background.light'),
      },
      '&:focus': {
        backgroundColor: theme.get('palette.pause.background.medium'),
      },
      '&.Mui-selected': {
        backgroundColor: disableHighlighting ? 'transparent' : theme.get('palette.pause.background.medium'),
        '&:hover': {
          backgroundColor: theme.get('palette.pause.background.dark'),
        },
        '&:focus': {
          backgroundColor: theme.get('palette.pause.background.dark'),
        },
      },
    })}
    disableRipple
    selected={selected}
    {...props}
  >
    <PauseTypography
      css={() => ({
        width: '100%',
      })}
      variant="body1"
      color={color || 'pause.primary.dark'}
    >
      {children}
    </PauseTypography>
  </MenuItem>
);

PauseMenuItem.propTypes = {
  children: PropTypes.node,
  selected: PropTypes.bool,
  disableHighlighting: PropTypes.bool,
  compact: PropTypes.bool,
  color: PropTypes.string,
};

PauseMenuItem.defaultProps = {
  children: null,
  selected: false,
  disableHighlighting: false,
  compact: false,
  color: 'pause.primary.dark',
};
