import { useSnackbar } from 'notistack';
import React from 'react';
import { useSnackbarNotifications } from '../../api/snackbar';
import { SnackbarWithActions } from './SnackbarWithActions';

let displayed: any[] = [];

export const PauseSnackbarStore = () => {
  const { snackbarNotifications, removeSnackbarNotification } = useSnackbarNotifications();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  React.useEffect(() => {
    snackbarNotifications.forEach(({
      key, message, options = {}, dismissed = false,
    }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        // if a notification has been dismissed, remove it from query too
        removeSnackbarNotification(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (_, myKey) => {
          removeSnackbarNotification(myKey);
          removeDisplayed(myKey);
        },
        content: () => (
          <SnackbarWithActions
            id={key}
            message={message}
            onButtonClick={options.onButtonClick}
            hideButton={options.hideButton}
            variant={options.variant || 'info'}
            buttonText={options.buttonText}
          />
        ),
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [snackbarNotifications, closeSnackbar, enqueueSnackbar]);

  return null;
};

export const useNotifier = () => {
  const { enqueueSnackbarNotification, removeSnackbarNotification } = useSnackbarNotifications();
  const defaultOptions = {
    key: new Date().getTime() + Math.random(),
    variant: 'info',
  };

  const withDefaultOptions = (options?: any) => ({
    ...defaultOptions,
    ...options,
  });
  return {
    enqueueNotification: (message: string, options?: any) => enqueueSnackbarNotification({
      message,
      options: withDefaultOptions(options),
    }),
    removeNotification: (key: string | number) => removeSnackbarNotification(key),
  };
};
