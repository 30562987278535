/* eslint-disable no-param-reassign */
import { useEffect } from 'react';
import { RewriteFrames } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/node';
import { get } from 'lodash';
import { useMe } from '../src/api/me';
import { useOrg } from '../src/api/org';

/**
 * this func initializes Sentry with necessary integrations
 * Not entirely sure about the RewriteFrames but it's obviously related to server side stuff.
 * TODO: see if we can remove the conditional before the initializer function.
 * */
export const sentryInit = () => {
  const integrations: any = [];
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    if (
      process.env.NEXT_IS_SERVER === 'true'
            && process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR
    ) {
      // For Node.js, rewrite Error.stack to use relative paths, so that source
      // maps starting with ~/_next map to files in Error.stack with path
      // app:///_next
      integrations.push(
        new RewriteFrames({
          iteratee: (frame: any) => {
            frame.filename = frame.filename.replace(
              process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
              'app:///',
            );
            frame.filename = frame.filename.replace('.next', '_next');
            return frame;
          },
        }),
      );
    }
  }
  if (process.env.NODE_ENV === 'production') {
    integrations.push(new Integrations.BrowserTracing());
  }
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    integrations,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: process.env.NEXT_PUBLIC_COMMIT_SHA,
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  });
};

/** this hook waits for me and org to be present
 * and then adds me and org as tags
 * so that every error in Sentry also tells us
 * who/which org it is. For context + better debugging.
 */
export const useSentry = () => {
  const { org, loading: orgLoading } = useOrg();
  const { me, loading: meLoading } = useMe();

  useEffect(() => {
    if (!orgLoading && !meLoading) {
      Sentry.setTag('meID', get(me, 'id'));
      Sentry.setTag('meName', get(me, 'fullName'));
      Sentry.setTag('meEmail', get(me, 'email'));
      Sentry.setTag('orgID', get(org, 'id'));
      Sentry.setTag('orgName', get(org, 'name'));
    }
  }, [orgLoading, me, org, meLoading]);
};
