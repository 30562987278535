import React from 'react';
import { Grid } from '@material-ui/core';
import { PauseTypography } from '../../../components/PauseTypography';
import { PauseFormGroupCard, PauseFormGroupCardsList } from '../../../components/PauseFormGroupCard';
import { PauseFormField } from '../../../components/PauseForm';
import { PauseInputGeneric } from '../../../components/PauseInputGeneric';
import { AlignRight, Calendar, Users } from '../../../../assets/icons/feather/components';
import { PauseButton } from '../../../components/PauseButton';
import { PauseSubmitButton } from '../../../components/PauseSubmitButton';
import { AllHandsState } from './LeaveForm';
import { LeaveRangePicker } from './LeaveFormContents';
import { usePeople } from '../../../api/people';
import { useTeams } from '../../../api/teams';

const TeamMenuItem = (props) => {
  const { name, countMembers } = props;
  return (
    <div>
      <PauseTypography>{name}</PauseTypography>
      <PauseTypography
        variant="body2"
        color="pause.primary.medium"
      >
        {countMembers}
        {' '}
        member(s)
      </PauseTypography>
    </div>
  );
};

export const AllHandsFooter = (props: {
  state: AllHandsState,
  onClose: () => any,
}) => {
  const {
    state = AllHandsState.Readonly,
    onClose,
  } = props;

  if ([AllHandsState.New].includes(state)) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PauseTypography color="pause.primary.medium">
            Selected team will be encouraged not to book leaves on these dates
          </PauseTypography>
        </Grid>
        <Grid item xs={12}>
          <PauseSubmitButton>
            <PauseButton>Submit</PauseButton>
          </PauseSubmitButton>
        </Grid>
      </Grid>
    );
  }

  if ([AllHandsState.Deletable].includes(state)) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PauseSubmitButton enableForInitialValid>
            <PauseButton
              color="error"
            >
              Delete
            </PauseButton>
          </PauseSubmitButton>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PauseButton
          onClick={onClose}
          variant="outlined"
        >
          Close
        </PauseButton>
      </Grid>
    </Grid>
  );
};

export const AllHandsForm = (props: {
  state: AllHandsState
}) => {
  const { state } = props;
  const { people } = usePeople();
  const { teams: _teams } = useTeams();
  const teams = _teams
    .map((t) => ({
      id: t.id,
      name: t.name,
      countMembers: (people || []).filter((p) => p.teamIDs.includes(t.id)).length,
    }));
  return (
    <div css={(theme) => ({
      background: theme.get('palette.pause.background.light'),
      minHeight: '100%',
    })}
    >
      <PauseFormGroupCardsList>
        <PauseFormGroupCard>
          <PauseFormField name="team">
            <PauseInputGeneric
              // @ts-ignore
              disabled={state !== AllHandsState.New}
              startIcon={<Users />}
              type="autocomplete"
              placeholder="Select a team"
              labelKey="name"
              options={teams}
              /* @ts-ignore */
              dropdownItemComponent={<TeamMenuItem />}
            />
          </PauseFormField>
        </PauseFormGroupCard>
        <PauseFormGroupCard>
          <PauseFormField name="applicationReason">
            <PauseInputGeneric
              // @ts-ignore
              startIcon={<Calendar />}
              customComponent={<LeaveRangePicker isReadOnly={state !== AllHandsState.New} />}
            />
          </PauseFormField>
        </PauseFormGroupCard>
        <PauseFormGroupCard>
          <PauseFormField name="reason">
            <PauseInputGeneric
                    // @ts-ignore
              startIcon={<AlignRight />}
              disabled={state !== AllHandsState.New}
              type="textarea"
              rows={4}
              placeholder={state === AllHandsState.New ? 'Add reason for locking dates (optional)' : 'No reason given'}
            />
          </PauseFormField>
        </PauseFormGroupCard>
      </PauseFormGroupCardsList>
    </div>
  );
};
