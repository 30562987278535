import React from 'react';
import {
  Tooltip, TooltipProps, withStyles,
} from '@material-ui/core';

const StyledPauseTooltip = withStyles((theme) => ({
  tooltip: {
    /* @ts-ignore */
    backgroundColor: theme?.get('palette.pause.primary.main'),
    borderRadius: theme.spacing(1),
    margin: theme.spacing(1, 0),
    fontSize: 'unset',
    /* @ts-ignore */
    boxShadow: theme.pauseShadows['16'],
  },
  arrow: {
    /* @ts-ignore */
    color: theme.get('palette.pause.primary.main'),
  },
}))(Tooltip);

export const PauseSimpleTooltip = (props: TooltipProps) => {
  const {
    children, title, arrow = true, ...rest
  } = props;
  return (
    <StyledPauseTooltip
      arrow={arrow}
      title={title}
      {...rest}
    >
      {children}
    </StyledPauseTooltip>
  );
};
