import { useQuery, useQueryClient } from 'react-query';
import { useEffect } from 'react';
import { get } from 'lodash';
import { useOrg } from './org';
import { useMe } from './me';

export enum AppStateEnum {
  notAuthenticated = 'notAuthenticated',
  justAuthenticated = 'justAuthenticated', // this is not very useful but keeping for now
  createOrg = 'createOrg',
  isFirstLogin = 'isFirstLogin',
  apiIsDown = 'apiIsDown',
  ready = 'ready'
}

export const useAppState = () => {
  const { org, error: orgError } = useOrg();
  const { me, error: meError } = useMe();
  const queryClient = useQueryClient();
  const { data: appState } = useQuery<AppStateEnum>('appState', {
    initialData: AppStateEnum.notAuthenticated,
  });

  useEffect(() => {
    if (orgError) {
      (async function () {
        const code = get(orgError, 'response.status');
        if (!code) { // Could be a network error
          queryClient.setQueryData<any>('appState', () => AppStateEnum.apiIsDown);
        } else if (code === 401) {
          try {
            // Me should be successful here to check for
            // definiteness of the fact that org doesn't exist
            // Reason: org 401 could also mean other things
            // TODO - stop relying on /me once API has fixed this to a 404
            await queryClient.fetchQuery('me');

            // If me is successful, it will most certainly be an empty object (code 204),
            // so we can safely say that an org needs to be created
            queryClient.setQueryData<any>('appState', () => AppStateEnum.createOrg);
          } catch (e) {
            // This occurs when /me fails. Both /orgs and /me failing will most certainly mean
            // that the API is down or inaccessible
            queryClient.setQueryData<any>('appState', () => AppStateEnum.apiIsDown);
          }
        } else {
          // If there is an error and its not a 401
          queryClient.setQueryData<any>('appState', () => AppStateEnum.apiIsDown);
        }
      }());
    }
  }, [queryClient, orgError]);

  useEffect(() => {
    if (meError) {
      queryClient.setQueryData('appState', () => AppStateEnum.apiIsDown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meError]);

  useEffect(() => {
    const meID = get(me, 'id');
    const meHasLoggedIn = get(me, 'hasLoggedIn');
    if (Boolean(org) && meID) {
      if (!meHasLoggedIn) {
        queryClient.setQueryData('appState', () => AppStateEnum.isFirstLogin);
      } else {
        queryClient.setQueryData('appState', () => AppStateEnum.ready);
      }
    }
  }, [queryClient, me, org]);

  return {
    appState,
  };
};
