import { get } from 'lodash';

export const getInitials = (name = '') => name
  .replace(/[^a-zA-Z ]/g, '')
  .split(' ')
  .slice(0, 2)
  .map((word) => word.toUpperCase().charAt(0))
  .join('');

export const getPeopleCountDisplay = (
  people: Array<{
    fullName?: string,
    name?: string
  }>,
) => (people.length > 1
  ? `${people.length} birthdays`
  : (get(people, '0.fullName') || get(people, '0.name')));

export const getLockedCountDisplay = (
  teams: Array<{
    fullName?: string,
    name?: string
  }>,
): string => (teams?.length > 1
  ? `${teams?.length} teams`
  : (get(teams, '0.fullName') || get(teams, '0.name')));

export const toCapitalCase = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const pluralize = (singular: string) => (plural: string) => (number: number): string => (number === 1 ? singular : plural);
