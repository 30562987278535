import { useQuery, useQueryClient, useMutation } from 'react-query';
import { api } from '../hooks/useAxios';
import { LEAVE_TYPES } from '../../utils/constants';
import { expressiveColors } from '../components/PauseTheme/colors';

export type LeaveType = {
  id: string,
  description: string,
  isArchived: boolean,
  name: string,
  requiresApproval: boolean,
  color?: string,
  type: string,
  emoji: string,
}
export type LeaveTypeWithAllowance = LeaveType & {
  allowance: {
    type: string,
    amount: number
  },
  color?: string,
}

type LeaveTypesAPIResponse = {
  annual: LeaveTypeWithAllowance,
  deductible: LeaveTypeWithAllowance[],
  nonDeductible: LeaveType[]
}

const updater = (newInfo) => (oldInfo) => (newInfo.id === oldInfo.id
  ? ({ ...oldInfo, ...newInfo }) : oldInfo);
const updateLeaveType = (newInfo) => (leaveTypesAPIResponse) => {
  const { annual, deductible, nonDeductible } = leaveTypesAPIResponse;
  return {
    annual: updater(newInfo)(annual),
    deductible: deductible.map(updater(newInfo)),
    nonDeductible: nonDeductible.map(updater(newInfo)),
  };
};

const removeLeaveType = (id) => (leaveTypesAPIResponse) => {
  const { annual, deductible, nonDeductible } = leaveTypesAPIResponse;
  return {
    annual,
    deductible: deductible.filter((d) => d.id !== id),
    nonDeductible: nonDeductible.filter((d) => d.id !== id),
  };
};

const addLeaveType = (newLeaveType) => (leaveTypesAPIResponse) => {
  const { DEDUCTIBLE, NON_DEDUCTIBLE } = LEAVE_TYPES;
  const { annual, deductible, nonDeductible } = leaveTypesAPIResponse;
  return {
    annual,
    deductible: deductible.concat(newLeaveType.type === DEDUCTIBLE ? [newLeaveType] : []),
    nonDeductible: nonDeductible
      .concat(newLeaveType.type === NON_DEDUCTIBLE ? [newLeaveType] : []),
  };
};

const addColor = (item, idx) => ({
  ...item,
  color: expressiveColors[idx % expressiveColors.length],
});
const {
  get, post, put, del,
} = api();

export const useLeaveTypes = () => {
  const queryClient = useQueryClient();

  const getLeaveTypes = () => get({
    url: '/leavetypes',
  });

  const createLeaveType = (data) => post({
    url: '/leavetypes',
    data,
  });

  const editLeaveType = (data) => put({
    url: `/leavetypes/${data.id}`,
    data,
  });

  const deleteLeaveType = (id) => del({
    url: `/leavetypes/${id}`,
  });

  const { data: leaveTypes, isFetching } = useQuery('leaveTypes', getLeaveTypes, {
    // the following outrageous hack in `annual as LeaveTypeWithAllowance | LeaveType`
    // is because Typescript does not let us concat arrays of different types. That's precisely what
    // we're trying to do here where we concat Annual, Deductible and Non-deductible leave types
    // into a single array.
    select: (data: LeaveTypesAPIResponse): Array<LeaveTypeWithAllowance | LeaveType> => {
      const { ANNUAL, DEDUCTIBLE, NON_DEDUCTIBLE } = LEAVE_TYPES;
      const annual = {
        ...data.annual,
        type: ANNUAL,
      };
      const deductibles = (data.deductible || []).map((d) => ({ ...d, type: DEDUCTIBLE }));
      const nonDeductibles = (data.nonDeductible || [])
        .map((d) => ({ ...d, type: NON_DEDUCTIBLE }));
      return [annual as LeaveTypeWithAllowance | LeaveType]
        .concat(deductibles, nonDeductibles)
        .map(addColor);
    },
  });

  const { mutateAsync: createLeaveTypeMutation } = useMutation(createLeaveType, {
    onSuccess: (newData, { type }) => {
      queryClient.setQueryData<any>('leaveTypes',
        (oldData: LeaveTypesAPIResponse) => addLeaveType({ ...newData, type })(oldData));
      queryClient.invalidateQueries('people', { refetchInactive: true, refetchActive: true });
      queryClient.invalidateQueries('person');
      queryClient.invalidateQueries('me');
    },
  });

  const { mutateAsync: editLeaveTypeMutation } = useMutation(editLeaveType, {
    onSuccess: (editedLeaveType) => {
      queryClient.setQueryData<any>('leaveTypes', (oldData: LeaveTypesAPIResponse) => updateLeaveType(editedLeaveType)(oldData));
      queryClient.invalidateQueries('people', { refetchInactive: true, refetchActive: true });
      queryClient.invalidateQueries('person');
      queryClient.invalidateQueries('me');
    },
  });

  const { mutateAsync: deleteLeaveTypeMutation } = useMutation(deleteLeaveType, {
    onSuccess: (_, leaveTypeID) => {
      queryClient.setQueryData<any>('leaveTypes',
        (oldData: LeaveTypesAPIResponse) => removeLeaveType(leaveTypeID)(oldData));
      queryClient.invalidateQueries('people', { refetchInactive: true, refetchActive: true });
      queryClient.invalidateQueries('person');
      queryClient.invalidateQueries('me');
    },
  });

  return {
    leaveTypes,
    loading: isFetching,
    createLeaveType: createLeaveTypeMutation,
    editLeaveType: editLeaveTypeMutation,
    deleteLeaveType: deleteLeaveTypeMutation,
  };
};
