import React from 'react';
import { Container } from '@material-ui/core';

// TODO - make responsive
export const PauseContainer = ({ children }: {
  children: React.ReactElement
}) => (
  <Container
    maxWidth="xl"
    css={(theme) => ({
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      height: '100%',
    })}
  >
    {children}
  </Container>
);
