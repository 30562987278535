import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useCallback, useMemo } from 'react';
import { get as _get, omit, debounce } from 'lodash';
// import { diff } from 'deep-object-diff';
import { useAPI } from '../hooks/useAxios';
import { usePeople } from './people';
import { formatMe } from './_person-utils';
import { replaceKeysDeep } from '../../utils/replace-keys';

const formatMeForAPI = (me) =>
  replaceKeysDeep(
    {
      ...omit(me, [
        'name',
        'selected',
        'joiningDate',
        'creditLeavesFrom',
        'applicablePublicHolidayCalendars',
        'isAdmin',
        'isArchived',
        'leavingDate',
        'roles',
      ]),
    },
    {
      teamIDs: 'groupIDs',
      teamID: 'groupID',
    },
  );

export const useMe = ({ enabled } = { enabled: false }) => {
  const queryClient = useQueryClient();
  const { get, put } = useAPI();
  const { mutatePersonInCache: updatePersonInPeopleCache } = usePeople();

  const getMe = () =>
    get({
      url: '/me',
    });

  const editMeAPI = (data) =>
    put({
      url: '/me',
      data: formatMeForAPI(data),
    });

  const { data: me, isLoading, isIdle, error } = useQuery<any>('me', getMe, {
    enabled,
  });

  const { mutateAsync: editMe } = useMutation<any>(editMeAPI, {
    onSuccess: (newMe) => {
      queryClient.setQueryData<any>('me', newMe);
      queryClient.invalidateQueries(['person']);
      updatePersonInPeopleCache(newMe);
    },
  });

  const updatePreferences = useCallback(
    debounce((ids) => {
      // This is the snapshot of the latest value of me in store
      const currentMe = queryClient.getQueryData('me');
      const formattedMe = formatMe(currentMe);

      const newMe = {
        ...formattedMe,
        preferencesObject: {
          ...formattedMe?.preferencesObject,
          groupIDs: ids,
        },
      };

      return editMe(newMe);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, 0),
    [],
  );

  return {
    isAdmin: me?.isAdmin || false,
    me: useMemo(() => (me ? formatMe(me) : null), [me]),
    loading: isLoading,
    isIdle,
    editMe,
    updatePreferences,
    error,
  };
};
