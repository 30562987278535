import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isEqual, min } from 'lodash';
import { Grid } from '@material-ui/core';
import dynamic from 'next/dynamic';
import { PauseDrawerContent, PauseDrawerFooter, PauseDrawerTitle } from '../../../components/PauseDrawer';
import { PHDatesForm } from './PHDatesForm';
import { PauseTextField } from '../../../components/PauseTextField';
import { PauseInteractiveButton } from '../../../components/PauseInteractiveButton';
import { PauseTextButton } from '../../../components/PauseTextButton';
import {
  PauseTab,
  PauseTabPanel, PauseTabs, PauseTabsList, useTabs,
} from '../../../components/PauseTabs';
import { ChevronLeft, ChevronRight, X } from '../../../../assets/icons/feather/components';
import { PauseTypography } from '../../../components/PauseTypography';
import { PHCalendarMembersList } from './PHMembersList';
import { noop } from '../../../../utils/noop';
import { PauseLoader } from '../../../components/PauseLoader';
import {
  PauseModal, PauseModalBody, PauseModalFooter, PauseModalTitle,
} from '../../../components/PauseModal';
import { PauseButton } from '../../../components/PauseButton';
import { PauseDropdown } from '../../../components/PauseDropdown';

const Picker = dynamic(() => import('emoji-picker-react'), { ssr: false });

const filterByYear = (year: number) => (item: any & { date: string }) => Number(moment(item.date).format('YYYY')) === year;

const AddAllMembersBottomSheet = (props: {
  onAddAllMembers: (any) => Promise<any>,
  onCancel: () => any
}) => {
  const {
    onAddAllMembers = () => Promise.resolve(),
    onCancel = () => null,
  } = props;
  return (
    <div css={(t) => ({
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      background: t.get('palette.pause.white.main'),
      padding: t.spacing(2),
      borderTopLeftRadius: t.spacing(2),
      borderTopRightRadius: t.spacing(2),
      zIndex: 5000,
      boxShadow: t.pauseShadows['-4'],
    })}
    >
      <div css={(t) => ({
        display: 'grid',
        gridGap: t.spacing(2),
        alignItems: 'center',
        justifyContent: 'space-between',
        gridAutoFlow: 'column',
        gridTemplateColumns: '1fr auto',
        marginBottom: t.spacing(3),
      })}
      >
        <PauseTypography
          variant="title"
        >
          Add members to this calendar
        </PauseTypography>
        <div>
          <X
            css={() => ({
              width: '24px',
              height: '24px',
              cursor: 'pointer',
            })}
            onClick={onCancel}
          />
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PauseInteractiveButton
            onClick={onAddAllMembers}
          >
            Add all members without a calendar
          </PauseInteractiveButton>
        </Grid>
        <Grid item xs={12}>
          <PauseButton variant="outlined" onClick={onCancel}>Add members manually</PauseButton>
        </Grid>
      </Grid>
    </div>
  );
};

export const PHCalendarEditForm = (props: {
  initTab: string,
  calendarID: string,
  holidays: {
    loading: boolean,
    data: any,
  },
  people: any[],
  teams: any[],
  isAdmin: boolean,
  onEditHoliday: (a: {
    name: string,
    id: string,
    date: moment.Moment | string,
  }) => any,
  onAddHoliday: (a: {
    name: string,
    date: moment.Moment | string,
  }) => any,
  onDeleteHoliday: (a: {
    id: string,
  }) => any,
  onEditCalendarNameEmoji: (args: {
    name: string,
    emoji: string | null,
  }) => Promise<any>,
  onDeleteCalendar: () => Promise<any>,
  onRemoveMemberFromCalendar: (id:string) => Promise<any>,
  onAddMemberToCalendar: (id:string) => Promise<any>,
  onAddMembersInBulk: () => Promise<any>
}) => {
  const {
    initTab = 'holidays',
    people = [],
    holidays = {
      data: [],
      loading: false,
    },
    teams = [],
    isAdmin = false,
    onEditHoliday = () => null,
    onAddHoliday = () => null,
    onDeleteHoliday = () => null,
    onEditCalendarNameEmoji = () => null,
    onDeleteCalendar = () => null,
    onRemoveMemberFromCalendar = () => Promise.resolve(),
    onAddMemberToCalendar = () => Promise.resolve(),
    onAddMembersInBulk = () => Promise.resolve(),
  } = props;
  const [firstTimeOpen, setFirstTimeOpen] = useState<boolean>(initTab === 'members');
  const [year, setYear] = useState<number>(Number(moment().format('YYYY')));
  const orgHasUsersWithoutCalendar = (people || []).some((person) => person.applicablePublicHolidayCalendars?.length === 0);
  const loadingCalendarData = holidays?.loading || false;
  const members: string[] = holidays?.data?.assignedToPeople || [];
  const initialHolidays = (holidays?.data?.holidays || [])
    .filter(filterByYear(year)).sort((a, b) => (a.date < b.date ? -1 : 1));
  const [calendarEmoji, setCalendarEmoji] = useState<string | null>(holidays?.data?.emoji || null);
  const [calendarName, setCalendarName] = useState<string>(holidays?.data?.name || '');
  const initialData = useMemo(() => ({
    publicHolidays: initialHolidays,
  }), [initialHolidays]);
  const maxYear = Number(moment().format('YYYY')) + 1;
  const minYear = Number(min((holidays?.data?.holidays || []).map((h) => moment(h.date).format('YYYY')).concat([moment().format('YYYY')])));
  // the .concat in above functions makes sure that user can always
  // get to the current year even if there are no holidays in it

  const { value: activeTab, onChange: onChangeTab } = useTabs({ initActiveTab: initTab });

  const onRemoveMember = (id:string) => onRemoveMemberFromCalendar(id).catch(noop);
  const onAddMember = (id:string) => onAddMemberToCalendar(id).catch(noop);

  useEffect(() => {
    setCalendarName(holidays?.data?.name || '');
    setCalendarEmoji(holidays?.data?.emoji || null);
  }, [holidays]);

  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState<boolean>(false);

  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState<boolean>(false);
  return (loadingCalendarData || !holidays?.data) ? <PauseLoader /> : (
    <>
      {deleteConfirmationVisible && (
      // @ts-ignore
      <PauseModal
        width="25vw"
        onClose={() => setDeleteConfirmationVisible(false)}
        open={deleteConfirmationVisible}
      >
        <PauseModalTitle
          onClose={() => setDeleteConfirmationVisible(false)}
          title="Delete calendar"
        />
        <PauseModalBody>
          <PauseTypography>
            Are you sure you want to delete this calendar?
            People assigned this calendar will no longer have
            any holidays/calendar assigned to them.
          </PauseTypography>
        </PauseModalBody>
        <PauseModalFooter>
          <Grid spacing={2} container>
            <Grid item xs={6}>
              <PauseButton
                variant="outlined"
                onClick={() => setDeleteConfirmationVisible(false)}
              >
                Cancel
              </PauseButton>
            </Grid>
            <Grid item xs={6}>
              <PauseInteractiveButton
                color="error"
                onClick={() => onDeleteCalendar()}
              >
                Delete
              </PauseInteractiveButton>
            </Grid>
          </Grid>
        </PauseModalFooter>
      </PauseModal>
      )}
      <Formik
        key={`${initialData.publicHolidays.length}-${year}`} // this is required because
        // when you add a holiday, it gets added to the top of the list by default
        // but we also sort the list by date. when data is submitted to api, holidays is refreshed
        // & that sorting order changes at the data-layer but formik does not update the values (so
        // does not change the sorting order). hence, we force it to do that by using the key.
        enableReinitialize
        initialValues={initialData}
        validationSchema={Yup.object({
          publicHolidays: Yup.array().of(Yup.object().shape({
            name: Yup.string().required('Required'), // these constraints take precedence
            date: Yup.mixed().required('Required'), // these constraints take precedence
          })),
        })}
        onSubmit={() => Promise.resolve()}
      >
        {({ values, setFieldValue }) => (
          <>
            {/* @ts-ignore */}
            <PauseDrawerTitle>
              {holidays?.data?.emoji || ''}
              {' '}
              {holidays?.data?.name}
            </PauseDrawerTitle>
            <PauseDrawerContent compact>
              <div
                css={(t) => ({
                  paddingBottom: t.spacing(5),
                })}
              >
                <>
                  {isAdmin && (
                  <div css={(t) => ({
                    margin: t.spacing(2.5, 3),
                    display: 'grid',
                    gridAutoFlow: 'column',
                    gridTemplateColumns: '1fr auto',
                  })}
                  >
                    <>
                      {/* @ts-ignore */}
                      <PauseDropdown
                        id="emoji-picker"
                        isOpen={isEmojiPickerOpen}
                        onClose={() => setIsEmojiPickerOpen(false)}
                        dropdownContent={(
                          <Picker
                            onEmojiClick={(_, emojiData) => {
                              setCalendarEmoji(emojiData.emoji);
                              setIsEmojiPickerOpen(false);
                            }}
                            disableSkinTonePicker
                            disableAutoFocus
                            groupVisibility={{
                              flags: true,
                              smileys_people: false,
                              animals_nature: false,
                              food_drink: false,
                              travel_places: false,
                              activities: false,
                              objects: false,
                              symbols: false,
                              recently_used: false,
                            }}
                            preload
                            native
                          />
                                      )}
                        autoWidth
                        disableScrollLock
                      >
                        <PauseTextField
                          label="Emoji"
                          value={calendarEmoji || ''}
                          onClick={() => isAdmin && setIsEmojiPickerOpen(true)}
                          disabled={!isAdmin}
                        />
                      </PauseDropdown>
                      <PauseTextField
                        label="Name of this calendar"
                        value={calendarName}
                        onChange={(e) => setCalendarName(e?.target?.value || '')}
                        disabled={!isAdmin}
                      />

                      <PauseInteractiveButton
                        onClick={() => onEditCalendarNameEmoji({
                          name: calendarName,
                          emoji: calendarEmoji || null,
                        })}
                        buttonComponent={PauseTextButton}
                        color="primary"
                        size="small"
                        disabled={isEqual({
                          calendarName,
                          calendarEmoji,
                        }, {
                          calendarName: holidays?.data?.name,
                          calendarEmoji: holidays?.data?.emoji,
                        })}
                      >
                        Save
                      </PauseInteractiveButton>
                    </>

                  </div>
                  )}
                  <PauseTabs value={activeTab} onChange={onChangeTab} size="small">
                    <PauseTabsList>
                      <PauseTab label="Holidays" value="holidays" />
                      <PauseTab label="Members" value="members" />
                    </PauseTabsList>
                    <PauseTabPanel value="holidays">
                      <div css={(t) => ({
                        padding: t.spacing(2.5, 3),
                        display: 'grid',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gridAutoFlow: 'column',
                        gridTemplateColumns: '1fr auto',
                      })}
                      >
                        <PauseTypography
                          css={(t) => ({
                            display: 'flex',
                            alignItems: 'center',
                            gap: t.spacing(1),
                          })}
                          variant="subtitle"
                        >
                          {/* @ts-ignore */}
                          <PauseTextButton
                            inline
                            compact
                            disabled={year === minYear}
                            onClick={() => setYear(year - 1)}
                          >
                            <ChevronLeft css={{
                              width: '24px',
                              height: '24px',
                            }}
                            />
                          </PauseTextButton>
                          {' '}
                          <div>{year}</div>
                          {' '}
                          {/* @ts-ignore */}
                          <PauseTextButton
                            inline
                            compact
                            disabled={year === maxYear}
                            onClick={() => setYear(year + 1)}
                          >
                            <ChevronRight css={{
                              width: '24px',
                              height: '24px',
                            }}
                            />
                          </PauseTextButton>
                        </PauseTypography>
                        <PauseTypography
                          variant="caption"
                          color="pause.primary.medium"
                        >
                          {initialHolidays.length}
                          {' '}
                          holiday
                          {initialHolidays.length !== 1 ? 's' : ''}
                        </PauseTypography>
                      </div>
                      <PHDatesForm
                        values={values}
                        setFieldValue={setFieldValue}
                        setLocallySavedValues={() => null}
                        locallySavedValues={initialData}
                        onEdit={onEditHoliday}
                        onDelete={onDeleteHoliday}
                        onAdd={(data) => onAddHoliday(data)}
                        autoSave
                        year={year}
                        isEditable={isAdmin}
                      />
                      {!initialHolidays.length && (
                      <div css={(t) => ({
                        padding: t.spacing(2.5, 4),
                      })}
                      >
                        {isAdmin && (
                        <PauseTypography
                          variant="body1"
                          color="pause.primary.medium"
                        >
                          Holidays for
                          {' '}
                          {year.toString()}
                          {' '}
                          will be auto-filled before the beginning of next year.
                          You can still add your own holidays for
                          {' '}
                          {year.toString()}
                          {' '}
                          if you want to.
                        </PauseTypography>
                        )}
                        {!isAdmin && (
                        <PauseTypography
                          variant="body1"
                          color="pause.primary.medium"
                        >
                          No holidays yet.
                        </PauseTypography>
                        )}
                      </div>
                      )}
                    </PauseTabPanel>
                    <PauseTabPanel value="members">
                      <div css={(t) => ({
                        padding: t.spacing(2.5, 0),
                      })}
                      >
                        <PHCalendarMembersList
                          {...{
                            people,
                            teams,
                            isAdmin,
                            onAddMember,
                            members,
                            onRemoveMember,
                          }}
                        />
                      </div>
                    </PauseTabPanel>
                  </PauseTabs>

                </>
              </div>
            </PauseDrawerContent>
            {isAdmin && (
            <PauseDrawerFooter>
              <PauseInteractiveButton
                color="error"
                variant="outlined"
                onClick={() => setDeleteConfirmationVisible(true)}
              >
                Delete Calendar
              </PauseInteractiveButton>
            </PauseDrawerFooter>
            )}
          </>
        )}
      </Formik>
      {firstTimeOpen && orgHasUsersWithoutCalendar && (
      <AddAllMembersBottomSheet
        onAddAllMembers={async () => {
          try {
            await onAddMembersInBulk();
            setFirstTimeOpen(false);
          } catch (e) {
            noop();
          }
        }}
        onCancel={() => setFirstTimeOpen(false)}
      />
      )}
    </>
  );
};
