import React from 'react';
import { get } from 'lodash';
import { PauseAutocomplete } from '../PauseAutocomplete';
import { PauseTypography } from '../PauseTypography';
import { LEAVE_TYPES } from '../../../utils/constants';

const { ANNUAL, NON_DEDUCTIBLE } = LEAVE_TYPES;

const LeaveTypeRender = ({
  name,
  allowanceRemaining = null,
  emoji,
}: {
    name: string,
    allowanceRemaining?: number | null,
    emoji: string,
}) => (
  <div css={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }}
  >
    <div>
      {emoji}
      {' '}
      {name}
    </div>
    {allowanceRemaining !== null && (
    <div css={(theme) => ({
      color: get(theme, 'palette.pause.primary.medium'),
      whiteSpace: 'nowrap',
    })}
    >
      {`${allowanceRemaining} left`}
    </div>
    )}
  </div>
);

const GroupRenderer = ({ group, children }: {
  group: string,
  children: React.ReactNode
}) => (
  <div>
    <div css={(theme) => ({
      padding: theme.spacing(1, 1.5),
      background: theme.palette.pause.background.light,
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
    })}
    >
      <PauseTypography color="primary.light" variant="captionSmallUppercase">{group}</PauseTypography>
    </div>
    <div>
      {children}
    </div>
  </div>
);

type LeaveType = {
  id: string,
  type: string,
  name: string,
  allowanceRemaining: number,
  emoji: string,
}

export const LeaveTypeDropdown = ({
  leaveTypes,
  onChange = () => null,
  selectedLeaveType = null,
  disabled = false,
  disableDeductibleLeaveTypes = false,
}: {
    leaveTypes: Array<LeaveType>,
    onChange?: (any?) => any,
    selectedLeaveType?: LeaveType | null,
    disabled?: boolean,
    disableDeductibleLeaveTypes?: boolean

}) => {
  // eslint-disable-next-line no-prototype-builtins
  const groupBy = (l) => ((l.hasOwnProperty('allowanceRemaining') ? 'Deductible' : 'Non-deductible'));
  const filteredLeaveTypes = disableDeductibleLeaveTypes ? (leaveTypes || []).filter((lt) => lt.type === NON_DEDUCTIBLE) : leaveTypes;

  return (
    <PauseAutocomplete
      // @ts-ignore
      disabled={disabled}
      options={filteredLeaveTypes || []}
      onChange={onChange}
      value={selectedLeaveType}
      groupBy={disableDeductibleLeaveTypes ? null : groupBy}
      labelKey="name"
      placeholder="Select leave type"
      renderGroup={GroupRenderer}
      id={selectedLeaveType ? selectedLeaveType.id : null}
      // @ts-ignore
      dropdownItemComponent={<LeaveTypeRender />}
      getOptionDisabled={(option) => option.type !== ANNUAL && option.allowanceRemaining < 0.5}
    />
  );
};
