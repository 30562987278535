import { useMutation, useQueryClient, useQuery } from 'react-query';
import { get as _get, unionBy } from 'lodash';
import { useMemo } from 'react';
import { useAPI } from '../hooks/useAxios';
// import { ApprovalNotificationsQueryData, RequestNotificationsQueryData } from './notifications';
import { useAutoInfiniteQuery } from './_utils';
import { replaceKeysDeep } from '../../utils/replace-keys';

export type Conflict = {
  leaveEndDate: string,
  leaveEndType: string,
  leaveStartDate: string,
  leaveStartType: string,
  leaveStatus: string,
  leaveTypeName: string,
  personID: string,
  personName: string,
  teamID: string,
  teamName: string
}

export type LeaveCheck = {
  deduction: number | null,
  conflicts: Conflict[]
}

const transformLeavesForBackwardsCompatability = (leave) => ({
  ...leave,
  applicantID: _get(leave, 'applicant.id'),
  requesterID: _get(leave, 'requester.id'),
  leaveTypeID: _get(leave, 'leaveType.id'),
  actionerID: _get(leave, 'actioner.id'),
});

// eslint-disable-next-line max-len
// const updateApprovalNotificationLeave = (updatedLeave) => (oldData: ApprovalNotificationsQueryData) => ({
//   ...oldData,
//   pages: oldData.pages.map((page) => ({
//     ...page,
//     approvals: page.approvals.map((approval) => ({
//       ...approval,
//       leave: approval?.leave?.id === updatedLeave.id ? updatedLeave : approval?.leave,
//     })),
//   })),
// });
//
// // eslint-disable-next-line max-len
// const updateRequestNotificationLeave = (updatedLeave) => (oldData: RequestNotificationsQueryData) => ({
//   ...oldData,
//   pages: oldData.pages.map((page) => ({
//     ...page,
//     requests: page.requests.map((request) => ({
//       ...request,
//       leave: request?.leave?.id === updatedLeave.id ? updatedLeave : request?.leave,
//     })),
//   })),
// });

type UseLeaves = (a?: {
  enabled?: boolean,
  params?: any,
  refetchOnWindowFocus?: boolean | 'always' | undefined
}) => {
  leaves: any[],
  loading: boolean,
  isComplete: boolean,
  createLeave: (a: any) => any,
  approveLeave: (a: any) => any,
  declineLeave: (a: any) => any,
  cancelLeave: (a: any) => any,
  getDeductionAndConflicts: (a: any) => Promise<LeaveCheck>,
  getLeaveByID: (a: { id: string }) => any
}
export const useLeaves: UseLeaves = ({
  enabled,
  params,
  refetchOnWindowFocus,
} = {
  enabled: false,
  params: {},
  refetchOnWindowFocus: undefined,
}) => {
  const {
    get, post, put,
  } = useAPI();

  const queryClient = useQueryClient();

  const me = queryClient.getQueryData<any>('me');

  // eslint-disable-next-line no-shadow
  const getLeavesAPI = ({ pageParam = 0, limit, queryParams }): Promise<any> => get({
    url: '/leaves',
    params: {
      offset: pageParam,
      limit,
      ...queryParams,
    },
  }, (d) => d);

  const getLeaveByIDAPI = ({ id }) => get({
    url: `/leaves/${id}`,
  });

  const {
    isLoading, data: leavesData,
    isComplete,
  } = useAutoInfiniteQuery({
    queryKey: ['leaves', params],
    queryFn: getLeavesAPI,
    queryParams: params,
    apiResponseKey: 'leaves',
    enabled,
    refetchOnWindowFocus,
    onSuccess: (data) => {
      const leaves = _get(data, 'pages', []).reduce((accLeaves, currPage: any) => accLeaves.concat(currPage.leaves), []);
      const oldData: any[] = queryClient.getQueryData('leavesStore') || [];
      const mergedLeaves = unionBy(leaves, oldData, 'id');
      queryClient.setQueryData('leavesStore', mergedLeaves);
    },
  });

  const createLeave = (data) => post({
    url: '/leaves',
    data,
  });

  const editLeave = (data) => put({
    url: `/leaves/${data.id}`,
    data,
  });

  const approveLeave = (data) => editLeave({
    ...data,
    status: 'ACCEPTED',
  });

  const declineLeave = (data) => editLeave({
    ...data,
    status: 'REJECTED',
  });

  const cancelLeave = (data) => editLeave({
    ...data,
    status: 'CANCELED',
  });

  const { mutateAsync: createLeaveMutation } = useMutation(createLeave, {
    onSuccess: (newLeave): any => {
      queryClient.refetchQueries('people');
      if (newLeave?.applicant?.id === me?.id) {
        queryClient.invalidateQueries('me');
      }
      queryClient.invalidateQueries(['person', newLeave?.applicant?.id], { refetchInactive: true, refetchActive: true });
      queryClient.refetchQueries('requestNotifications');
      queryClient.setQueryData('leavesStore', (oldData: any[] = []) => oldData.concat(newLeave));
      queryClient.setQueryData(['leave', newLeave.id], () => newLeave);
      return newLeave;
    },
  });

  const { mutateAsync: approveLeaveMutation } = useMutation(approveLeave, {
    onSuccess: (approvedLeave) => {
      // update(approvedLeave);
      queryClient.refetchQueries('people');
      if (approvedLeave?.applicant?.id === me?.id) {
        queryClient.invalidateQueries('me');
      }
      queryClient.invalidateQueries(['person', approvedLeave?.applicant?.id], { refetchInactive: true, refetchActive: true });
      queryClient.refetchQueries('approvalNotifications');
      queryClient.refetchQueries('requestNotifications');
      queryClient.setQueryData('leavesStore', (oldData: any[] = []) => (oldData || []).map((d) => (d.id === approvedLeave.id ? approvedLeave : d)));
      queryClient.setQueryData(['leave', approvedLeave.id], () => approvedLeave);
      return approvedLeave;
    },
  });

  const { mutateAsync: declineLeaveMutation } = useMutation(declineLeave, {
    onSuccess: (declinedLeave) => {
      // remove(declinedLeave);
      queryClient.refetchQueries('people');
      if (declinedLeave?.applicant?.id === me?.id) {
        queryClient.invalidateQueries('me');
      }
      queryClient.invalidateQueries(['person', declinedLeave?.applicant?.id], { refetchInactive: true, refetchActive: true });
      queryClient.refetchQueries('approvalNotifications');
      queryClient.refetchQueries('requestNotifications');
      queryClient.setQueryData('leavesStore', (oldData: any[] = []) => (oldData || []).filter((d) => d.id !== declinedLeave.id));
      queryClient.setQueryData(['leave', declinedLeave.id], () => declinedLeave);
      return declinedLeave;
    },
  });

  const { mutateAsync: cancelLeaveMutation } = useMutation(cancelLeave, {
    onSuccess: (cancelledLeave) => {
      // remove(cancelledLeave);
      queryClient.refetchQueries('people');
      if (cancelledLeave?.applicant?.id === me?.id) {
        queryClient.invalidateQueries('me');
      }
      queryClient.invalidateQueries(['person', cancelledLeave?.applicant?.id], { refetchInactive: true, refetchActive: true });
      queryClient.refetchQueries('approvalNotifications');
      queryClient.refetchQueries('requestNotifications');
      queryClient.setQueryData('leavesStore', (oldData: any[] = []) => (oldData || []).filter((d) => d.id !== cancelledLeave.id));
      queryClient.setQueryData(['leave', cancelledLeave.id], () => cancelledLeave);
      return cancelledLeave;
    },
  });

  // eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
  const getDeductionAndConflictsAPI = (params): Promise<LeaveCheck> => get({
    url: '/leaves/check',
    params,
  }, (d) => replaceKeysDeep(d, {
    group: 'team',
    groupIDs: 'teamIDs',
    groupID: 'teamID',
    groups: 'teams',
  }));
  const { mutateAsync: getDeductionAndConflicts } = useMutation(getDeductionAndConflictsAPI);

  const { mutateAsync: getLeaveByID } = useMutation(getLeaveByIDAPI);

  const leaves = useMemo(() => (leavesData || [])
    .map(transformLeavesForBackwardsCompatability),
  [leavesData]);

  return {
    leaves,
    loading: isLoading,
    isComplete,
    createLeave: createLeaveMutation,
    approveLeave: approveLeaveMutation,
    declineLeave: declineLeaveMutation,
    cancelLeave: cancelLeaveMutation,
    getDeductionAndConflicts,
    getLeaveByID,
  };
};

export const useAllLeaves = () => {
  const { data: leavesData, isLoading } = useQuery('leavesStore', () => [], { initialData: [] });
  const leaves = useMemo(() => (leavesData || [])
    .map(transformLeavesForBackwardsCompatability),
  [leavesData]);
  return { leaves, loading: isLoading };
};
