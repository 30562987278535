import { get } from 'lodash';
import { LEAVE_TYPES } from '../../utils/constants';
import { replaceKeysDeep } from '../../utils/replace-keys';

type AllowanceObject = {
  annual?: { [key: string]: any } | null,
  deductible?: { [key: string]: any }[],
  nonDeductible?: { [key: string]: any }[],
}
const mergeAndFormatPersonAllowance = ({
  annual = null,
  deductible = [],
  nonDeductible = [],
}: AllowanceObject = {}) => {
  const { ANNUAL, DEDUCTIBLE, NON_DEDUCTIBLE } = LEAVE_TYPES;
  const combinedDeductAndNonDeduct = deductible.map((lt) => ({ ...lt, type: DEDUCTIBLE }))
    .concat(nonDeductible.map((lt) => ({ ...lt, type: NON_DEDUCTIBLE })));
  return annual ? [{ ...annual, type: ANNUAL } as any]
    .concat(combinedDeductAndNonDeduct) : combinedDeductAndNonDeduct;
};

export const formatPerson = (person) => (person ? replaceKeysDeep(({
  ...person,
  name: get(person, 'fullName'),
  selected: true,
  allowance: Array.isArray(get(person, 'allowance'))
    ? get(person, 'allowance')
    : mergeAndFormatPersonAllowance(get(person, 'allowance')),
}), {
  groupIDs: 'teamIDs',
}) : null);

export const formatMe = (person) => (person ? formatPerson(person) : null);
