import React from 'react';
import { CircularProgress } from '@material-ui/core';
import {
  useBaseStyles, useShapeBasedStyles, useVariantBasedStyles, useSizeBasedStyles,
} from '../PauseButton/utils';
import { PauseBadge } from '../PauseBadge';

type Props = {
  color?: string,
  icon?: React.ReactElement,
  variant?: string,
  disabled?: boolean,
  isLoading?: boolean,
  isActive?: boolean,
  isAttachedToLeft?: boolean,
  isAttachedToRight?: boolean,
  size?: 'xsmall' | 'small' | 'medium' | 'large',
  withBadge?: boolean,
  [key: string]: any

}

export const PauseIconButton = React.forwardRef(({
  color = 'primary',
  icon = <></>,
  variant = 'contained',
  disabled,
  isLoading,
  isActive,
  isAttachedToLeft = false,
  isAttachedToRight = false,
  size = 'medium',
  withBadge,
  ...otherProps
}: Props, ref: React.ForwardedRef<any>) => {
  const baseStyles = useBaseStyles();
  const variantBasedStyles = useVariantBasedStyles({ variant, color, isActive });
  const shapeBasedStyles = useShapeBasedStyles({
    isAttachedToLeft,
    isAttachedToRight,
    buttonType: 'IconButton',
    size,
  });
  const sizeBasedStyles = useSizeBasedStyles({ size });

  const buttonStyles = () => ({
    ...baseStyles.defaultStyles,
    ...variantBasedStyles,
    ...shapeBasedStyles,
    ...sizeBasedStyles,
  });

  return (
    <button
      ref={ref}
      type="button"
      disabled={isLoading || disabled}
      css={buttonStyles}
      {...otherProps}
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {isLoading ? (
        <CircularProgress
          size="1em"
          color="primary"
        />
      ) : (withBadge ? <PauseBadge>{icon}</PauseBadge> : icon)}
    </button>
  );
});
