import React from 'react';
import { CircularProgress, Backdrop, makeStyles } from '@material-ui/core';
import { get } from 'lodash';
import { Routes, useEnhancedRouter } from '../../../router';
import { PauseLoader } from '../../components/PauseLoader';
import { useMe } from '../../api/me';
import { useAppState, AppStateEnum } from '../../api/app-state';
import { useChat } from '../../hooks/useChat';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.text.secondary,
    backgroundColor: 'rgba(255,255,255, 0.55)',
  },
  progressWrapper: {
  },
}));

export const PageSpinner = () => {
  const classes = useStyles();
  return (
    <>
      <Backdrop className={classes.backdrop} open>
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      </Backdrop>
    </>
  );
};

const BLOCKED_ROUTES = [
  Routes.Settings,
  Routes.Invite,
  Routes.BulkInvite,
];

export const PageAuthCheck = ({ children }: {
  children: React.ReactNode,
}) => {
  const router = useEnhancedRouter();
  const [routerResolved, setRouterResolved] = React.useState<boolean>(false);
  const { me } = useMe();

  const isMeAdmin: boolean = get(me, 'isAdmin') || false;

  const { appState } = useAppState();
  React.useEffect(() => {
    if ([AppStateEnum.notAuthenticated,
      AppStateEnum.justAuthenticated,
    ].includes(appState as AppStateEnum)) return;
    (async function () {
      if (appState === AppStateEnum.createOrg) {
        if (!window.location.pathname.includes(Routes.Onboarding)) {
          await router.push({
            pathname: Routes.Onboarding,
            query: router.query,
          });
          setRouterResolved(true);
        } else {
          setRouterResolved(true);
        }
      } else if (appState === AppStateEnum.isFirstLogin) {
        if (![Routes.FinishSignup].includes(router.pathname as Routes)) {
          await router.push({
            pathname: Routes.FinishSignup,
            query: router.query,
          });
          setRouterResolved(true);
        } else {
          setRouterResolved(true);
        }
      } else if (appState === AppStateEnum.ready) {
        if ([Routes.FinishSignup].includes(router.pathname as Routes) || router.pathname.includes(Routes.Onboarding)) {
          await router.push({
            pathname: Routes.Home,
            query: router.query,
          });
        } else if (!isMeAdmin
            && BLOCKED_ROUTES.some((a) => router.pathname.includes(a))
        ) {
          await router.push({
            pathname: Routes.Home,
            query: router.query,
          });
        }
        setRouterResolved(true);
      }
    }());
  }, [appState, isMeAdmin, router.pathname]);

  useChat();

  return (
    <>
      {(!routerResolved) ? (
        <PauseLoader fullPage />
      ) : <>{children}</>}
    </>
  );
};
