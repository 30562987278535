import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, withStyles } from '@material-ui/core';
import { PauseButton } from '../PauseButton';

const StyledPauseTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.get('palette.pause.primary.main'),
    padding: 0,
    borderRadius: theme.spacing(2),
  },
  arrow: {
    color: theme.get('palette.pause.primary.main'),
  },
}))(Tooltip);

export const PauseTooltip = ({
  title,
  arrow,
  children,
  buttonText,
  buttonOnClick,
  buttonHref,
  ...props
}) => (
  <StyledPauseTooltip
    {...props}
    title={(
      <div css={(theme) => ({
        backgroundColor: theme.get('palette.pause.primary.main'),
        color: theme.get('palette.pause.background.light'),
        display: 'grid',
        gridGap: theme.spacing(2),
        padding: theme.spacing(3),
        justifyContent: 'start',
        borderRadius: theme.spacing(2),
      })}
      >
        {title}
        {buttonText && (
        <div css={{
          justifySelf: 'start',
        }}
        >
          <PauseButton variant="primaryDark" onClick={buttonOnClick} href={buttonHref}>
            {buttonText}
          </PauseButton>
        </div>
        )}
      </div>
    )}
    arrow={arrow}
  >
    <div>
      {children}
    </div>
  </StyledPauseTooltip>
);

PauseTooltip.propTypes = {
  title: PropTypes.oneOf([PropTypes.node, PropTypes.string]).isRequired,
  arrow: PropTypes.bool,
  buttonText: PropTypes.element,
  buttonOnClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  buttonHref: PropTypes.string,
};

PauseTooltip.defaultProps = {
  arrow: false,
  buttonText: null,
  buttonOnClick: null,
  buttonHref: null,
};
