import React, { useCallback, useRef } from 'react';
import { isEqual } from 'lodash';
import { PauseTextField } from '../PauseTextField';
import { PauseFormField2 } from './index';

interface Props {
  showErrorMessage?: boolean, name: string, disabled?: boolean,
  fieldProps?: {[x: string]: any},
  fast?: boolean,
}

export const PauseFormTextField = React.memo(({
  showErrorMessage, name, disabled,
  fieldProps,
  fast,
}: Props) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { current: memoizedFieldProps } = useRef(fieldProps);
  const memoizedRender = useCallback(({ field, state }) => (
    <PauseTextField
      {...field}
      {...memoizedFieldProps}
      {...state}
    />
  ), [memoizedFieldProps]);
  return (
    <PauseFormField2
      name={name}
      fast={fast}
      disabled={disabled}
      showErrorMessage={showErrorMessage}
      render={memoizedRender}
    />
  );
}, (
  newProps,
  oldProps,
) => isEqual(newProps, oldProps) && isEqual(newProps.fieldProps, oldProps.fieldProps));
