import React from 'react';
import PropTypes from 'prop-types';
import { Radio, FormControlLabel } from '@material-ui/core';
import { PauseTypography } from '../PauseTypography';

const PlainRadio = ({ checked = false, onChange = () => null, ...props }: {
  checked?: boolean,
  onChange?: () => any,
  [key: string]: any
}) => (
  <Radio
    css={(theme) => ({
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.Mui-checked': {
        '& .MuiSvgIcon-root': {
          fill: theme.get('palette.pause.primary.main'),
        },
      },
      '& .MuiSvgIcon-root path': {
        width: theme.spacing(2.25),
      },
      '& .MuiSvgIcon-root': {

        fill: theme.get('palette.pause.primary.medium'),
      },
    })}
    disableRipple
    checked={checked}
    onChange={onChange}
    {...props}
  />
);

PlainRadio.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.node,
};

PlainRadio.defaultProps = {
  children: null,
  checked: false,
  onChange: null,
};

export const PauseRadio = ({
  checked, onChange, label, description, ...props
}: Props) => {
  if (!label) {
    return (
      <PlainRadio
        checked={checked}
        onChange={onChange}
        {...props}
      />
    );
  }
  return (
    <PauseTypography color={checked ? 'pause.primary.dark' : 'pause.primary.medium'}>
      <FormControlLabel
        css={(theme) => ({
          color: 'inherit',
          display: 'grid',
          alignItems: 'center',
          gridAutoFlow: 'column',
          justifyContent: 'start',
          gridGap: theme.spacing(1.5),
          marginLeft: 0,
          marginRight: 0,
        })}
        control={<PlainRadio checked={checked} onChange={onChange} {...props} />}
        label={label}
      />
      {description && (
      <PauseTypography
        variant="body2"
        color="pause.primary.medium"
        css={(theme) => ({
          marginTop: theme.spacing(1),
          marginLeft: theme.spacing(4.5),
        })}
      >
        {description}
      </PauseTypography>
      )}
    </PauseTypography>
  );
};
type Props = {
  label?: React.ReactNode,
  checked: boolean,
  onChange?: () => any,
  children?: React.ReactNode,
  description?: string,
  [key: string]: any,
}
