import React from 'react';
import { css, Global } from '@emotion/core';
import { Input } from '@material-ui/core';
import { useQuery } from 'react-query';
import { PauseContainer } from '../../components/PauseContainer';
import { PauseMainNavigation } from '../../composites/PauseMainNavigation';
import { Journey } from './Journey';
import {
  LEAVE_APPLICATION_JOURNEY_KEY,
  LEAVE_APPLICATION_JOURNEY_NEW_APPLICATION_KEY,
  LEAVE_POLICY_JOURNEY_CREATE_LEAVE_TYPE_KEY,
  LEAVE_POLICY_JOURNEY_KEY,
  NOTIFICATIONS_JOURNEY_KEY,
  NOTIFICATIONS_JOURNEY_VIEW_KEY,
} from '../../../router';
import { PauseJourneyDrawer } from '../../composites/PauseJourneyDrawer';
import { LeaveFormPage } from '../leave-form';
import { LeaveTypeForm } from '../leave-policy';
import { PauseNotifications } from '../notifications';
// import { PauseNotifications } from '../notifications';

export const HEADER_HEIGHT = 13.25;
export const PAGE_VERTICAL_PADDING = 4.75;

interface LayoutProps {
  children: any,
  centered?: boolean,
  showAuthToken?: boolean,
  fullPage?: boolean,
  preserveQueryParams?: string[]
}

export const Layout = ({
  children,
  centered = false,
  showAuthToken = true,
  fullPage = false,
  preserveQueryParams = [],
}: LayoutProps) => {
  const { data: token } = useQuery('token');
  return (
    <div css={(theme) => ({
      backgroundColor: theme.get('palette.pause.background.light'),
    })}
    >
      {fullPage ? (
        <Global styles={css`
        body {
          overflow: scroll;
        }
      `}
        />
      ) : (
        <Global styles={css`
        body {
          overflow: visible;
        }
      `}
        />
      )}
      {/* top navigation section */}
      <PauseMainNavigation topOffset={0} />

      {/* page content */}
      <div
        css={(theme) => ({
          position: 'relative',
          marginTop: theme.spacing(HEADER_HEIGHT),
          ...(fullPage && ({
            height: `calc(100vh - ${theme.spacing(HEADER_HEIGHT)}px - 0px)`,
          })),
        })}
      >
        {/* @ts-ignore */}
        <PauseContainer fullPage>
          <div css={(theme) => (
            {
              width: '100%',
              // marginTop: theme.spacing(20),
              paddingTop: theme.spacing(PAGE_VERTICAL_PADDING),
              ...(fullPage && ({
                height: '100%',
                paddingBottom: theme.spacing(0),
                // overflow: 'scroll',
              })),
              ...(!fullPage && ({
                paddingBottom: theme.spacing(PAGE_VERTICAL_PADDING),
              })),
              ...(centered && {
                margin: '0 auto',
                width: '75%',
                [theme.breakpoints.down(theme.breakpoints.values.pauseTablet)]: {
                  width: '100%',
                },
                [theme.breakpoints.down(theme.breakpoints.values.pauseMobile)]: {
                  width: '100%',
                },
              }),
            }
          )}
          >
            {children}
            {process.env.NEXT_PUBLIC_PAUSE_ENV !== 'production' && showAuthToken && (
              <div style={{
                maxWidth: '500px', margin: 'auto', marginTop: '1000px', marginBottom: '100px',
              }}
              >
                {/* @ts-ignore */}
                <Input css={{ width: '100%' }} variant="outlined" multiline value={token} rows={8} />
                {' '}
                <br />
              </div>
            )}
          </div>
          {/* @ts-ignore */}
          <Journey
            journey={LEAVE_APPLICATION_JOURNEY_KEY}
            stop={LEAVE_APPLICATION_JOURNEY_NEW_APPLICATION_KEY}
          >
            <PauseJourneyDrawer
              preserveQueryParams={preserveQueryParams}
            >
              {/* @ts-ignore */}
              <LeaveFormPage />
            </PauseJourneyDrawer>
          </Journey>

          {/* @ts-ignore */}
          <Journey
            journey={LEAVE_POLICY_JOURNEY_KEY}
            stop={LEAVE_POLICY_JOURNEY_CREATE_LEAVE_TYPE_KEY}
          >
            <PauseJourneyDrawer
              preserveQueryParams={preserveQueryParams}
            >
              {/* @ts-ignore */}
              <LeaveTypeForm />
            </PauseJourneyDrawer>
          </Journey>

        </PauseContainer>

        {/* @ts-ignore */}
        <Journey
          journey={NOTIFICATIONS_JOURNEY_KEY}
          stop={NOTIFICATIONS_JOURNEY_VIEW_KEY}
        >
          <PauseJourneyDrawer
            preserveQueryParams={preserveQueryParams}
          >
            <PauseNotifications />
          </PauseJourneyDrawer>
        </Journey>
      </div>
    </div>
  );
};

export default Layout;
