import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import {
  PauseDrawer,
} from '../../components/PauseDrawer';
import { PUBLIC_HOLIDAYS_EDIT_KEY, PUBLIC_HOLIDAYS_JOURNEY_KEY, useEnhancedRouter } from '../../../router';
import { PHNewCalendarForms } from './components/PHNewCalendarForms';
import {
  useCountries,
  useHolidaysForRegion,
  usePublicHolidayCalendarsMutation,
  usePublicHolidaysByCalendarID, usePublicHolidaysByCalendarIDMutations,
} from '../../api/public-holidays';
import { PHCalendarEditForm } from './components/PHCalendarEditForm';
import {
  PauseModal, PauseModalBody, PauseModalFooter, PauseModalTitle,
} from '../../components/PauseModal';
import { PauseButton } from '../../components/PauseButton';
import { PauseTypography } from '../../components/PauseTypography';
import { noop } from '../../api/_utils';
import { useNotifier } from '../snackbar/store';
import { usePeople } from '../../api/people';
import { useMe } from '../../api/me';
import { useTeams } from '../../api/teams';
import { ifArrayThenString } from '../../../utils/query-to-string';

type AllHoliday = {
  uuid: string,
  name: string,
  date: string
}
type SelectedHoliday = {
  name: string,
  date: string,
  id?: string | null
}
const copyHolidaysForNextYear = (currentYear: string) => (allHolidays: AllHoliday[]) => (selectedHolidays: SelectedHoliday[]): SelectedHoliday[] => {
  const allHolidaysExcludingCurrentYear = allHolidays.filter((a) => moment(a.date).format('YYYY') !== currentYear);
  const selectedHolidaysWithoutIDForNextYear = selectedHolidays.reduce((acc: SelectedHoliday[], curr) => (curr.id ? acc : acc.concat({
    ...curr,
    date: moment(curr.date).add(1, 'year').format('YYYY-MM-DD'),
  })), []);
  const selectedHolidaysWithIDForNextYear = selectedHolidays.reduce((acc: SelectedHoliday[], curr) => {
    if (curr.id) {
      const matchingHolidayFromNextYear = allHolidaysExcludingCurrentYear.find((d) => d.uuid === curr.id);
      return !matchingHolidayFromNextYear ? acc : acc.concat({
        ...curr,
        date: matchingHolidayFromNextYear.date,
      });
    } return acc;
  }, []);
  return selectedHolidays.concat(selectedHolidaysWithoutIDForNextYear, selectedHolidaysWithIDForNextYear);
};

export enum PublicHolidayCalendarState {
  New = 'new',
  Existing = 'Existing'
}

export enum NewCalendarStep {
  SetCalendarType,
  SetDates,
  SetMembers,
  Finish, // this is where the api call is made to commit local changes to backend
  Done
}

type CommonData = {
  teams: any[],
  people: any[],
  isAdmin: boolean,
}

type CurrentCalendarData = {
  holidaysForCurrentCalendar: {
    data: any,
    loading: boolean
  },
  calendarID: string | null,
}

type NewCalendarData = {
  countries : {
    data: any[],
    loading: boolean,
  },
  holidaysForSelectedRegion: {
    data: any[],
    loading: boolean,
  },
}
type NewCalendarMutations = {
  onChangeRegion: (string) => any
}

type CalendarMutations = {
  onCreateNewCalendar: (args: {
    name: string,
    region: string | null,
    holidays: Array<{
      id: string | null,
      name: string,
      date: string,
    }>,
    isCustom: boolean,
    emoji?: string | null,
  }) => Promise<any>,
  onEditCalendar: (any) => Promise<any>,
  onDeleteCalendar: (id: string) => Promise<any>,
  onEditCalendarNameEmoji: (a: {
    id: string,
    name: string,
    emoji: string | null
  }) => Promise<any>,
}

type CalendarState = {
  state: PublicHolidayCalendarState
}

type CurrentCalendarMutations = {
  onEditHoliday: (any) => Promise<any>,
  onAddHoliday: (any) => Promise<any>,
  onDeleteHoliday: ({ id: string }) => Promise<any>,
  onRemoveMemberFromCalendar: (p: {
    calendarID: string,
    personID: string,
  }) => Promise<any>,
  onAddMemberToCalendar: (p: {
    calendarID: string,
    personID: string,
  }) => Promise<any>,
  onAddMembersInBulk: () => Promise<any>,
}

const PublicHolidaysEditWrapper = ({ children }: {
  children: (childProps: CommonData
          & CalendarState
          & CalendarMutations
          & CurrentCalendarData
          & CurrentCalendarMutations
          & NewCalendarData
          & NewCalendarMutations) => JSX.Element
}) => {
  const router = useEnhancedRouter();
  const calendarID = ifArrayThenString(router?.query?.id) || null;
  const state: PublicHolidayCalendarState = calendarID
    ? PublicHolidayCalendarState.Existing : PublicHolidayCalendarState.New;

  const { enqueueNotification } = useNotifier();

  const {
    onEditHoliday,
    onDeleteHoliday,
    onAddHoliday,
    onRemoveMemberFromCalendar,
    onAddMemberToCalendar,
    onAddMembersInBulk,
  } = usePublicHolidaysByCalendarIDMutations({
    calendarID: calendarID || '',
  });

  const {
    onCreatePHCalendar,
    onEditPHCalendar,
    onDeletePHCalendar,
    onEditCalendarNameEmoji,
  } = usePublicHolidayCalendarsMutation();

  const onEditCalendar = (data) => onEditPHCalendar(data)
    .catch(noop);

  const onDeleteCalendar = (data) => onDeletePHCalendar(data)
    .then(() => router.endJourney())
    .catch(noop);

  const [selectedRegion, setSelectedRegion] = useState('');
  const countries = useCountries({ enabled: true });
  const holidaysForSelectedRegion = useHolidaysForRegion({
    enabled: true,
    regionID: selectedRegion,
  });

  const { people } = usePeople();
  const holidaysForCurrentCalendar = usePublicHolidaysByCalendarID({
    calendarID: calendarID || '',
    enabled: !!calendarID,
  });
  const { me } = useMe();
  const { teams } = useTeams();

  const onCreateNewCalendar: CalendarMutations['onCreateNewCalendar'] = (data) => onCreatePHCalendar({
    name: data.name,
    regionCode: data.region || null,
    holidays: copyHolidaysForNextYear(moment().format('YYYY'))(holidaysForSelectedRegion.data)(data.holidays).map((h) => ({
      holidayID: h.id || null,
      name: h.name,
      date: h.date,
    })),
    assignToUsers: [],
    emoji: data?.emoji || null,
  })
    .then((r) => {
      enqueueNotification('New public holiday calendar created!');
      const { id } = r || {};
      if (!id) return null;
      router.push(router.getStopHref(PUBLIC_HOLIDAYS_JOURNEY_KEY, PUBLIC_HOLIDAYS_EDIT_KEY, {
        id,
        type: 'members',
      }));
      return null;
    })
    .catch(noop);

  return children({
    state,
    onCreateNewCalendar,
    onEditCalendar,
    calendarID,
    onEditHoliday,
    onAddHoliday,
    onDeleteHoliday,
    onEditCalendarNameEmoji,
    onDeleteCalendar,
    onRemoveMemberFromCalendar,
    onAddMemberToCalendar,
    onAddMembersInBulk: () => onAddMembersInBulk({ calendarID: calendarID || '' }).catch(noop),
    countries,
    holidaysForSelectedRegion: {
      ...holidaysForSelectedRegion,
      data: (holidaysForSelectedRegion.data || []).filter((d) => moment(d.date).format('YYYY') === moment().format('YYYY')),
    },
    onChangeRegion: (regionID) => setSelectedRegion(regionID),
    people: people || [],
    holidaysForCurrentCalendar,
    isAdmin: me?.isAdmin,
    teams: teams || [],
  });
};

export const PublicHolidaysJourney2 = ({
  onClose,
  open,
}: any) => {
  const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false);
  const onDrawerClose = () => {
    setConfirmModalVisible(true);
  };
  const router = useEnhancedRouter();
  const view = ifArrayThenString(router?.query?.type) || 'holidays';
  return (
    <PublicHolidaysEditWrapper>
      {({
        state,
        onCreateNewCalendar,
        calendarID,
        onEditHoliday,
        onDeleteHoliday,
        onAddHoliday,
        onEditCalendarNameEmoji,
        onDeleteCalendar,
        onRemoveMemberFromCalendar,
        onAddMemberToCalendar,
        onAddMembersInBulk,
        countries,
        holidaysForSelectedRegion,
        onChangeRegion,
        people,
        holidaysForCurrentCalendar,
        isAdmin,
        teams,
      }) => (
        <>
          <PauseModal width="25vw" open={confirmModalVisible} onClose={() => setConfirmModalVisible(false)}>
            <PauseModalTitle title="Discard calendar" onClose={() => setConfirmModalVisible(false)} />
            <PauseModalBody>
              <PauseTypography>
                Are you sure you want to discard this calendar?
                Any settings you’ve made will be lost.
              </PauseTypography>
            </PauseModalBody>
            <PauseModalFooter>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <PauseButton
                    onClick={() => setConfirmModalVisible(false)}
                    variant="outlined"
                  >
                    Cancel
                  </PauseButton>
                </Grid>
                <Grid item xs={6}>
                  <PauseButton
                    onClick={() => {
                      setConfirmModalVisible(false);
                      onClose();
                    }}
                    color="error"
                  >
                    Discard
                  </PauseButton>
                </Grid>
              </Grid>
            </PauseModalFooter>
          </PauseModal>
          {/* @ts-ignore */}
          <PauseDrawer
            open={open}
            onClose={state === PublicHolidayCalendarState.New ? onDrawerClose : onClose}
          >
            {state === PublicHolidayCalendarState.New && (
            <>
              <PHNewCalendarForms
                {...{
                  countries,
                  holidaysForSelectedRegion,
                  onCreateNewCalendar,
                  onChangeRegion,
                }}
              />
            </>
            )}
            {state === PublicHolidayCalendarState.Existing
              && calendarID && (
              <PHCalendarEditForm
                {...{
                  people,
                  isAdmin,
                  holidays: holidaysForCurrentCalendar,
                  teams,
                  initTab: view,
                  onEditHoliday: (data) => onEditHoliday({
                    ...data,
                    calendarID,
                  }),
                  calendarID,
                  onAddHoliday: (data) => onAddHoliday({
                    ...data,
                    calendarID,
                  }).catch(noop),
                  onDeleteHoliday,
                  onEditCalendarNameEmoji: (data) => onEditCalendarNameEmoji({
                    ...data,
                    id: calendarID,
                  }),
                  onDeleteCalendar: () => onDeleteCalendar(calendarID).catch(noop),
                  onRemoveMemberFromCalendar: (id) => onRemoveMemberFromCalendar({
                    personID: id,
                    calendarID,
                  }).catch(noop),
                  onAddMemberToCalendar: (id) => onAddMemberToCalendar({
                    personID: id,
                    calendarID,
                  }).catch(noop),
                  onAddMembersInBulk,
                }}
              />
            )}

          </PauseDrawer>
        </>

      )}
    </PublicHolidaysEditWrapper>

  );
};
