import React from 'react';
import { get } from 'lodash';
import {
  Notification, NotificationAvatar, NotificationContent, NotificationAvatarIcon, NotificationMeta,
} from './NotificationComponents';
import { PauseTypography } from '../PauseTypography';
import { LEAVE_STATUSES } from '../../../utils/constants';
import { Calendar, X } from '../../../assets/icons/feather/components';
import { getLeaveInWords } from '../../../utils/leaves';
import { toCapitalCase } from '../../../utils/strings';

type Props = {
  notifData: {
    id: string,
    detail: any,
    isRead: boolean,
  },
  onNotificationClick: (any?) => any,
  [key: string]: any
}

export const InfoCard = ({
  notifData,
  onNotificationClick = () => {},
  ...otherProps
}: Props) => {
  const notifType = get(notifData, 'type') || 'LEAVE';
  const notifDetails = get(notifData, 'details');
  const {
    APPROVED, REJECTED, PENDING, CANCELLED,
  } = LEAVE_STATUSES;
  const status = get(notifData, 'details.status');
  const declineReason = get(notifData, 'details.rejectionReason');
  const msg = React.useMemo(() => {
    if (notifType === 'LEAVE_NOTIFY_ADMIN') {
      if (status === APPROVED) {
        const isSelfApproved = get(notifData, 'details.actioner.id') === get(notifData, 'details.applicant.id');
        const approverName = get(notifData, 'details.actioner.fullName');
        if (isSelfApproved) return 'Leave approved by you';
        return approverName ? `Leave approved by ${approverName}` : 'Leave auto-approved';
      }
      if (status === REJECTED) {
        return `Leave declined by ${get(notifData, 'details.actioner.fullName')}`;
      }
      if (status === CANCELLED) {
        return 'Leave deleted';
      }
      return 'Pending leave';
    }
    if (notifType === 'LOCKED_DATE') {
      return 'All-hands';
    }
  }, [notifType, notifData]);
  const icon = status === CANCELLED ? X : Calendar;
  // eslint-disable-next-line no-nested-ternary
  const color = [REJECTED, CANCELLED].includes(status)
    ? 'peach'
    : status === PENDING
      ? 'primary'
      : 'green';
  return (
  // @ts-ignore
    <Notification
      isUnread={!get(notifData, 'isRead', false)}
      {...otherProps}
      onClick={() => onNotificationClick({ id: notifData?.id })}
    >
      <NotificationAvatar>
        <NotificationAvatarIcon icon={icon} color={color} />
      </NotificationAvatar>
      <NotificationContent>
        {msg && (
        <div>
          <PauseTypography css={(theme) => ({
            color: theme.palette.pause.primary.dark,
          })}
          >
            {msg}
          </PauseTypography>
        </div>
        )}
        {notifType === 'LEAVE_NOTIFY_ADMIN' && (
        <NotificationMeta>
          <div>
            <PauseTypography variant="caption">Date: </PauseTypography>
            <PauseTypography variant="body2">
              {getLeaveInWords(get(notifData, 'details'))}
            </PauseTypography>
          </div>
          <div>
            <PauseTypography variant="caption">Status: </PauseTypography>
            <PauseTypography variant="body2">
              {toCapitalCase(get(notifData, 'details.status'))}
            </PauseTypography>
          </div>
          {declineReason && (
          <div>
            <PauseTypography variant="caption">Reason for declining: </PauseTypography>
            <PauseTypography variant="body2">
              {declineReason}
            </PauseTypography>
          </div>
          )}
        </NotificationMeta>
        )}
        {notifType === 'LOCKED_DATE' && (
        <NotificationMeta>
          <div>
            <PauseTypography variant="caption">Days: </PauseTypography>
            <PauseTypography variant="body2">
              {getLeaveInWords(notifDetails)}
            </PauseTypography>
          </div>
        </NotificationMeta>
        )}
      </NotificationContent>
    </Notification>
  );
};
