import { useMutation, useQuery, useQueryClient } from 'react-query';
import { get as _get, set } from 'lodash';
import { pipe } from 'lodash/fp';
import { useMemo } from 'react';
import { QueryNames } from '../types/QueryNames';
import { AppStateEnum, useAppState } from './app-state';
import { api } from '../hooks/useAxios';
import { useMe } from './me';

const defaultChecklistAPIResponse: ChecklistAPIResponse = {
  leavePolicy: {
    customisedLeavePolicy: true,
    addedPublicHolidayCalendar: false,
  },
  buildOrg: {
    invitedPeople: false,
    createdMultipleGroups: false,
  },
  connectToSlack: {
    botIntegrated: false,
    summaryNotificationsSet: false,
  },
  customiseSlackPreferences: {
    personalIntegrated: false,
    setupPersonalNotifications: false,
  },
  setupGoogleCalendar: {
    syncSelfLeaves: false,
    autoDeclineMeetingsOnLeave: false,
    autoDeclineMeetingsOnPublicHoliday: false,
    subscribeToTeamLeaveCalendars: false,
  },
  completeYourProfile: {
    addPersonalDetails: false,
    addPhoto: false,
    viewLeavesBalance: false,
  },
};

export type ChecklistItem = {
  groupId: ChecklistGroup,
  path: string,
  status: ChecklistItemStatus,
  label: string,
  href: string,
  adminOnly?: boolean,
}
export enum ChecklistGroup {
  LeavePolicy = 'leave-policy',
  Org = 'org',
  Slack = 'slack',
  GoogleCal = 'google-cal',
  Profile = 'profile'
}
export const groupNameDictionary = {
  [ChecklistGroup.LeavePolicy]: '🌴 Define your leave policy',
  [ChecklistGroup.Org]: '🎳 Build your organisation',
  [ChecklistGroup.Slack]: '🤝 Connect to Slack',
  [ChecklistGroup.GoogleCal]: '🗓 Setup Google calendar',
  [ChecklistGroup.Profile]: '👤 Complete your profile',
};

export enum ChecklistItemStatus {
  Done,
  Pending
}
const initChecklistData: ChecklistItem[] = [
  {
    groupId: ChecklistGroup.LeavePolicy,
    path: 'leavePolicy.customisedLeavePolicy',
    status: ChecklistItemStatus.Pending,
    href: '/leave-policy',
    label: 'Create leave policy',
    adminOnly: true,
  },
  {
    groupId: ChecklistGroup.LeavePolicy,
    path: 'leavePolicy.addedPublicHolidayCalendar',
    status: ChecklistItemStatus.Pending,
    href: '/leave-policy',
    label: 'Add public holidays',
    adminOnly: true,
  },
  {
    groupId: ChecklistGroup.Org,
    path: 'buildOrg.invitedPeople',
    status: ChecklistItemStatus.Pending,
    href: '/teams?journey=invite&stop=begin',
    label: 'Invite members',
    adminOnly: true,
  },
  {
    groupId: ChecklistGroup.Org,
    path: 'buildOrg.createdMultipleGroups',
    status: ChecklistItemStatus.Pending,
    href: '/teams',
    label: 'View/create teams and team approvers',
    adminOnly: true,
  },
  {
    groupId: ChecklistGroup.Slack,
    path: 'connectToSlack.botIntegrated',
    status: ChecklistItemStatus.Pending,
    href: '/settings/integrations',
    label: 'Integrate with Slack',
    adminOnly: true,
  },
  {
    groupId: ChecklistGroup.Slack,
    path: 'connectToSlack.summaryNotificationsSet',
    status: ChecklistItemStatus.Pending,
    href: '/settings/integrations',
    label: 'Set Slack notifications for your organization',
    adminOnly: true,
  },
  {
    groupId: ChecklistGroup.Slack,
    path: 'customiseSlackPreferences.personalIntegrated',
    status: ChecklistItemStatus.Pending,
    href: '/profile/me/preferences/slack',
    label: 'Auto-update slack status with 🌴 when on leave',
  },
  {
    groupId: ChecklistGroup.Slack,
    path: 'customiseSlackPreferences.setupPersonalNotifications',
    status: ChecklistItemStatus.Pending,
    href: '/profile/me/preferences/slack',
    label: 'Customise your personal Slack notifications',
  },
  {
    groupId: ChecklistGroup.GoogleCal,
    path: 'setupGoogleCalendar.syncSelfLeaves',
    status: ChecklistItemStatus.Pending,
    href: '/profile/me/preferences',
    label: 'View your own leaves on Google Calendar',
  },
  {
    groupId: ChecklistGroup.GoogleCal,
    path: 'setupGoogleCalendar.autoDeclineMeetingsOnLeave',
    status: ChecklistItemStatus.Pending,
    href: '/profile/me/preferences',
    label: 'Auto-decline meetings when on leave',
  },
  {
    groupId: ChecklistGroup.GoogleCal,
    path: 'setupGoogleCalendar.subscribeToTeamLeaveCalendars',
    status: ChecklistItemStatus.Pending,
    href: '/profile/me/preferences',
    label: 'View teammates\' leaves on Google Calendar',
  },
  {
    groupId: ChecklistGroup.Profile,
    path: 'completeYourProfile.addPersonalDetails',
    status: ChecklistItemStatus.Pending,
    href: '/profile/me?journey=profile&stop=edit',
    label: 'Add personal details',
  },
  {
    groupId: ChecklistGroup.Profile,
    path: 'completeYourProfile.addPhoto',
    status: ChecklistItemStatus.Pending,
    href: '/profile/me',
    label: 'Add profile photo',
  }, {
    groupId: ChecklistGroup.Profile,
    path: 'completeYourProfile.viewLeavesBalance',
    status: ChecklistItemStatus.Pending,
    href: '/profile/me',
    label: 'View your leaves balance',
  },
];
const { get, post } = api();

export const getGroupName = (groupId: string): string => groupNameDictionary[groupId] || '';

const getChecklistAPI = (): Promise<ChecklistAPIResponse> => get({
  url: '/checklist',
});

const postToChecklistAPI = (data): Promise<ChecklistAPIResponse> => post({
  url: '/checklist',
  data,
});

const filterByUserType = (isAdmin: boolean) => (checklistData: ChecklistItem[]) => checklistData
  .filter((d) => (isAdmin ? true : !d.adminOnly));

const transformIntoChecklistData = (base: ChecklistItem[]) => (apiResponse: ChecklistAPIResponse): ChecklistItem[] => base
  .map((item) => ({
    ...item,
    status: _get(apiResponse, item.path, false) ? ChecklistItemStatus.Done : ChecklistItemStatus.Pending,
  }));

export const useQuickStartGuide = () => {
  const queryClient = useQueryClient();
  const { appState } = useAppState();
  const { me } = useMe();

  const { data, isFetching } = useQuery<ChecklistAPIResponse>(QueryNames.ChecklistData, getChecklistAPI, {
    placeholderData: defaultChecklistAPIResponse,
    enabled: !!([AppStateEnum.ready, AppStateEnum.isFirstLogin]).includes(appState as AppStateEnum),
  });

  const checklist = useMemo<ChecklistItem[]>(() => pipe([
    transformIntoChecklistData(initChecklistData),
    filterByUserType(me?.isAdmin || false),
  ])(data), [
    data, me,
  ]);

  const { mutateAsync: markAsDone } = useMutation(
    ({ path, value }: {path: string, value: boolean}) => postToChecklistAPI(set({}, path, value)),
    {
      onSuccess: (response) => {
        queryClient.setQueryData(QueryNames.ChecklistData, () => response);
      },
    },
  );

  return {
    checklist,
    loading: isFetching,
    markAsDone,
  };
};

/* new structure for checklist */

type ChecklistAPIResponse = {
  leavePolicy: {
    customisedLeavePolicy: boolean,
    addedPublicHolidayCalendar: boolean
  },
  buildOrg: {
    invitedPeople: boolean,
    createdMultipleGroups: boolean
  },
  connectToSlack: {
    botIntegrated: boolean,
    summaryNotificationsSet: boolean
  },
  customiseSlackPreferences: {
    personalIntegrated: boolean,
    setupPersonalNotifications: boolean
  },
  setupGoogleCalendar: {
    syncSelfLeaves: boolean,
    autoDeclineMeetingsOnLeave: boolean,
    autoDeclineMeetingsOnPublicHoliday: boolean,
    subscribeToTeamLeaveCalendars: boolean
  },
  completeYourProfile: {
    addPersonalDetails: boolean,
    addPhoto: boolean,
    viewLeavesBalance: boolean
  }
}
