import React from 'react';
import {
  Formik, Form as FormikForm, useField, useFormikContext,
} from 'formik';
import { debounce, isEqual } from 'lodash';
import { usePrevious } from '../../hooks/usePrevious';
import { PauseButton } from '../PauseButton';

export const PauseFormField = (props) => {
  const {
    children, ...otherProps
  } = props;
  const [field, meta, helpers] = useField(otherProps);
  const { error, touched } = meta;
  return React.cloneElement(children, {
    ...field,
    checked: field.value,
    error: touched && error,
    ...helpers,
  });
};

export const AutoSave = ({ debounceMs = 250 }) => {
  const formik = useFormikContext();
  const prevValues = usePrevious(formik.values);
  const debouncedSubmit = React.useCallback(debounce(formik.submitForm, debounceMs),
    [formik.submitForm, debounceMs]);

  React.useEffect(() => {
    if (prevValues && !isEqual(prevValues, formik.values)) {
      debouncedSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSubmit, formik.values]);

  return <></>;
};

// const FormValuesWatcher = () => {
//   const formik = useFormikContext();
//   React.useEffect(() => {
//     console.log(formik.values);
//   }, [formik.values]);
//   return <></>;
// };

type PauseFormProps = {
  initialValues: any,
  validationSchema: any,
  children: React.ReactNode,
  onSubmit: (any) => any,
  enableReinitialize?: boolean,
  initialTouched?: any,
  autoSave?: boolean,
  debouceMs?: number,
  render?: any,
  [key: string]: any,
}

export const PauseForm = ({
  enableReinitialize,
  children,
  onSubmit,
  initialValues,
  validationSchema,
  initialTouched,
  render,
  autoSave = false,
  debounceMs = 250,
  ...otherProps
}: PauseFormProps) => (
  <Formik
    enableReinitialize={enableReinitialize}
    onSubmit={onSubmit}
    // @ts-ignore
    initialValues={initialValues}
    validationSchema={validationSchema}
    initialTouched={initialTouched}
    render={render}
    {...otherProps}
  >
    <FormikForm>
      {children}
      {/* {debug && <FormValuesWatcher />} */}
      {autoSave && <AutoSave {...(debounceMs && { debounceMs })} />}
    </FormikForm>
  </Formik>
);

// TODO: Copied this part without editing much. Will refine later if required.
// TODO - remove this entirely
/**
 * @deprecated
 */
export const PauseFormSubmitButton = ({
  size = 'medium', children, disabled = false, ...otherProps
}) => {
  const { isSubmitting, submitForm } = useFormikContext();
  return (
    <PauseButton
      isLoading={isSubmitting}
      size={size}
      disabled={isSubmitting || disabled}
      onClick={() => submitForm()}
      type="submit"
      {...otherProps}
    >
      {children}
    </PauseButton>
  );
};
