import { useTheme } from 'emotion-theming';

const FOCUS_BORDER_WIDTH = 0.275;

// const FOCUS_SHADOW

export const useBaseStyles = ({ inline } = { inline: false }) => ({
  defaultStyles: {
    transition: 'all 0.2s',
    textTransform: 'unset',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
    width: !inline ? '100%' : 'auto',
    justifyContent: 'center',
  },
  focusedStyles: {
    zIndex: 2,
  },
});

export const defaultDisabledStyles = {
  cursor: 'not-allowed',
  opacity: 0.3,
};

export const useSizeBasedStyles = ({ size }) => {
  const theme = useTheme<any>();

  const sizeBasedPadding = {
    xsmall: 0.5,
    small: 0.5,
    medium: 1,
    large: 2,
  }[size];

  const sizeBasedFontSize = {
    xsmall: 2,
    small: 3,
    medium: 3,
    large: 3,
  }[size];

  return {
    paddingTop: theme.spacing(sizeBasedPadding),
    paddingBottom: theme.spacing(sizeBasedPadding),
    paddingLeft: theme.spacing(sizeBasedPadding),
    paddingRight: theme.spacing(sizeBasedPadding),
    fontSize: theme.spacing(sizeBasedFontSize),
  };
};
export const useVariantBasedStyles = ({ variant, color, isActive }) => {
  const theme = useTheme<any>();
  const baseStyles = useBaseStyles();
  const outlinedVariantDefaultBoxShadow = `0 0 0 ${theme.spacing(0.25)}px ${theme.get(`palette.pause.${color}.main`)} inset`;
  const primaryAltOutlinedVariantDefaultBoxShadow = `0 0 0 ${theme.spacing(0.25)}px ${theme.get('palette.pause.background.dark')} inset`;

  return {
    contained: {
      backgroundColor: theme.get(`palette.pause.${color}.main`),
      color: theme.get('palette.pause.white.main'),
      '&:hover:not(:disabled)': {
        backgroundColor: theme.get(`palette.pause.${color}.shades.darker`),
      },
      '&:focus': {
        ...baseStyles.focusedStyles,
        boxShadow: `0 0 0 ${theme.spacing(FOCUS_BORDER_WIDTH)}px ${theme.get(`palette.pause.${color}.shades.lighter`)}`,
      },
      '&:disabled': {
        ...defaultDisabledStyles,
        // backgroundColor: theme.get('palette.action.disabledBackground'),
        // color: theme.get('palette.action.disabled'),
      },
    },
    ghost: {
      color: theme.get(`palette.pause.${color}.main`),
      backgroundColor: 'transparent',
      '&:hover:not(:disabled)': {
        color: theme.get(`palette.pause.${color}.shades.darker`),
      },
      '&:focus': {
        ...baseStyles.focusedStyles,
        boxShadow: `0 0 0 ${theme.spacing(FOCUS_BORDER_WIDTH)}px ${theme.get(`palette.pause.${color}.shades.lighter`)}`,
      },
      '&:disabled': {
        ...defaultDisabledStyles,
        // backgroundColor: theme.get('palette.action.disabledBackground'),
        // color: theme.get('palette.action.disabled'),
      },
    },
    ghostDark: {
      color: theme.get(`palette.pause.${color}.dark`),
      backgroundColor: 'transparent',
      '&:hover:not(:disabled)': {
        color: theme.get(`palette.pause.${color}.shades.darker`),
      },
      '&:focus': {
        ...baseStyles.focusedStyles,
        boxShadow: `0 0 0 ${theme.spacing(FOCUS_BORDER_WIDTH)}px ${theme.get(`palette.pause.${color}.shades.lighter`)}`,
      },
      '&:disabled': {
        ...defaultDisabledStyles,
        // backgroundColor: theme.get('palette.action.disabledBackground'),
        // color: theme.get('palette.action.disabled'),
      },
    },
    outlined: {
      color: theme.get(`palette.pause.${color}.main`),
      backgroundColor: theme.get('palette.pause.white.main'),
      boxShadow: outlinedVariantDefaultBoxShadow,
      '&:hover:not(:disabled)': {
        backgroundColor: theme.get(`palette.pause.${color}.shades.lightest`),
      },
      '&:focus': {
        ...baseStyles.focusedStyles,
        boxShadow: `${outlinedVariantDefaultBoxShadow}, 0 0 0 ${theme.spacing(FOCUS_BORDER_WIDTH)}px ${theme.get(`palette.pause.${color}.shades.lighter`)}`,
      },
      '&:disabled': {
        ...defaultDisabledStyles,
      },
    },
    primaryAlt: {
      color: isActive ? theme.get('palette.pause.primary.main') : theme.get('palette.pause.primary.medium'),
      backgroundColor: isActive ? theme.get('palette.pause.background.dark') : 'transparent',
      '&:hover:not(:disabled)': {
        zIndex: 2,
        backgroundColor: theme.get('palette.pause.background.medium'),
      },
      '&:focus': {
        ...baseStyles.focusedStyles,
        boxShadow: `inset 0 0 0 ${theme.spacing(FOCUS_BORDER_WIDTH)}px ${theme.get('palette.pause.primary.light')}`,
      },
      '&:disabled': {
        ...defaultDisabledStyles,
      },
    },
    primaryDark: {
      color: theme.get('palette.pause.white.main'),
      backgroundColor: theme.get('palette.pause.primary.dark'),
      '&:hover:not(:disabled)': {
        zIndex: 2,
        backgroundColor: theme.get('palette.pause.primary.dark'),
      },
      '&:focus': {
        ...baseStyles.focusedStyles,
        boxShadow: `inset 0 0 0 ${theme.spacing(FOCUS_BORDER_WIDTH)}px ${theme.get('palette.pause.primary.light')}`,
      },
      '&:disabled': {
        ...defaultDisabledStyles,
      },
    },
    primaryAltOutlined: {
      color: isActive ? theme.get('palette.pause.primary.main') : theme.get('palette.pause.primary.light'),
      backgroundColor: isActive ? theme.get('palette.pause.background.dark') : theme.get('palette.pause.background.light'),
      boxShadow: primaryAltOutlinedVariantDefaultBoxShadow,
      '&:hover:not(:disabled)': {
        zIndex: 2,
        backgroundColor: theme.get('palette.pause.background.medium'),
        boxShadow: `0 0 0 ${theme.spacing(0.25)}px ${theme.get('palette.pause.primary.light')} inset`,
      },
      '&:focus': {
        ...baseStyles.focusedStyles,
        boxShadow: `inset 0 0 0 ${theme.spacing(FOCUS_BORDER_WIDTH)}px ${theme.get('palette.pause.primary.light')}`,
      },
      '&:disabled': {
        ...defaultDisabledStyles,
      },
    },
  }[variant];
};

type Props = {
  isAttachedToLeft: boolean,
  isAttachedToRight: boolean,
  size?: 'xsmall' | 'small' | 'medium' | 'large',
  buttonType?: 'Button' | 'IconButton'
}
export const useShapeBasedStyles = ({
  isAttachedToLeft, isAttachedToRight, size = 'medium', buttonType = 'Button',
}: Props) => {
  const theme = useTheme<any>();
  const buttonTypeBasedBorderRadius = {
    Button: 1.5,
    IconButton: {
      xsmall: 0.5,
      small: 0.5,
      medium: 1,
      large: 2,
    }[size],
  }[buttonType];

  return {
    borderTopLeftRadius: theme.spacing(buttonTypeBasedBorderRadius),
    borderTopRightRadius: theme.spacing(buttonTypeBasedBorderRadius),
    borderBottomLeftRadius: theme.spacing(buttonTypeBasedBorderRadius),
    borderBottomRightRadius: theme.spacing(buttonTypeBasedBorderRadius),
    ...(isAttachedToLeft && {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      marginLeft: -theme.spacing(FOCUS_BORDER_WIDTH),
    }),
    ...(isAttachedToRight && {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    }),
  };
};
