import React, { useEffect, useState } from 'react';
import { useEnhancedRouter } from '../../router';

const doWithDelay = (action) => setTimeout(() => action(), 0);

export const useJourneyDrawer = (args: {
  preserveQueryParams: string[]
}) => {
  const { preserveQueryParams = [] } = args;
  const router = useEnhancedRouter();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const closeDrawer = () => {
    setIsOpen(false);
    doWithDelay(() => router.endJourney({
      preserveQueryParams,
    }));
  };

  useEffect(() => {
    setIsOpen(true);
  }, []);

  return {
    isOpen,
    closeDrawer,
  };
};

export const PauseJourneyDrawer = ({ children, preserveQueryParams }: { children: React.ReactElement,
preserveQueryParams?: string[] }) => {
  const { isOpen, closeDrawer } = useJourneyDrawer({
    preserveQueryParams: preserveQueryParams || [],
  });
  return React.cloneElement(children, { open: isOpen, onClose: closeDrawer });
};
