import { get, pick, uniqBy } from 'lodash';
import { LEAVE_TYPES } from './constants';

const { NON_DEDUCTIBLE } = LEAVE_TYPES;

export const attachTeamToPeople = ({ people = [], teams = [] }: any = {}) => (people || [])
  .map((person: any) => {
    const team: any = (teams || []).find((t) => get(t, 'id') === get(person, 'teamID'));
    return ({
      ...person,
      team,
      teamName: get(team, 'name'),
    });
  });

const formatLeaveTypeForPeople = (lt) => pick(lt, ['id', 'name', 'description', 'type']);
const filterByNonDeductible = (lt) => lt.type === NON_DEDUCTIBLE;
const formatNonDeductibleLeaveTypesForPeople = (lt) => lt
  .filter(filterByNonDeductible)
  .map(formatLeaveTypeForPeople);

export const attachNonDeductibleToPeople = ({
  people,
  leaveTypes = [],
}: any = {}) => people.map((p) => ({
  ...p,
  allowance: uniqBy([]
    .concat(p.allowance, formatNonDeductibleLeaveTypesForPeople(leaveTypes || [])), 'id'),
}));

/* @ts-ignore */
export const attachNonDeductibleToMe = ({ me, leaveTypes = [] }: any = {}) => ({
  ...me,
  allowance: uniqBy([].concat(get(me, 'allowance', []), formatNonDeductibleLeaveTypesForPeople(leaveTypes || [])), 'id'),
});

export const stripPeople = (people = []) => (people || []).map(
  (person) => pick(person, [
    'id',
    'teamID',
    'teamName',
    'fullName',
    'selected',
    'color',
    'image',
    'allowance',
    'allowance2',
    'birthDate',
    'remainingAllowance',
  ]),
);

export const removeAllowanceFromPeople = (people: any[] = []) => (people || []).map(
  (person: any) => ({
    ...person,
    allowance: [],
    remainingAllowance: null,
    hideAllowance: true,
  }),
);

export const joinNames = (names: string[] = []): string => {
  if (names.length < 2) return names.join('');
  const lastTwoNames = names.slice(names.length - 2);
  const allOtherNames = names.slice(0, names.length - 2);
  return `${allOtherNames.join(', ')}${allOtherNames.length > 0 ? ', ' : ''}${lastTwoNames.join(' and ')}`;
};
