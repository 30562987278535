import React from 'react';
import { SubscriptionStatus } from '../../types/billing';
import { useNotifier } from '../snackbar/store';
import { useEnhancedRouter } from '../../../router';
import { useMe } from '../../api/me';
import { useOrg } from '../../api/org';

export const TrialExpiryNotification = () => {
  const { me } = useMe();
  const { org } = useOrg();
  const { enqueueNotification } = useNotifier();
  const status = org?.subscription?.status;
  const hasMe = me?.id;
  const router = useEnhancedRouter();
  React.useEffect(() => {
    if (!me?.isAdmin) return;
    if (status !== SubscriptionStatus.TrialExpired) return;
    enqueueNotification('Your free trial has expired. Please add your payment details to continue using Pause.', {
      onButtonClick: () => router.push('/settings/billing'),
      buttonText: 'Pay now',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMe, status]);
  return <></>;
};
