import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useMemo } from 'react';
import { useAPI } from '../hooks/useAxios';
import { AppStateEnum, useAppState } from './app-state';

type Team = {
  id: string,
  name: string,
  approverID: string
  workingDays: string[],
  isDefault?: boolean,
  isArchived: boolean,
}
type UseTeams = {
  teams: Team[],
  archivedTeams: Team[],
  loading: boolean,
  deleteTeam: (teamID: string) => any,
  editTeam: (data: any) => any,
  createTeam: (data: any) => any,
}
export const useTeams = (): UseTeams => {
  const {
    get, del, put, post,
  } = useAPI();

  const { appState } = useAppState();
  const queryClient = useQueryClient();
  const getTeams = () => get({ url: '/groups', params: { includeArchived: true } }, (d) => d.groups);
  const editTeam = (data) => put({ url: `/groups/${data.id}`, data });
  const createTeam = (data) => post({ url: '/groups', data });
  const deleteTeam = (teamID) => del({
    url: `/groups/${teamID}`,
  });

  const { data: teamsData, isLoading, isFetching } = useQuery<Team[]>('teams', getTeams, {
    enabled: [AppStateEnum.ready, AppStateEnum.isFirstLogin].includes(appState as AppStateEnum),
    placeholderData: [],
  });

  const { mutateAsync: deleteTeamMutation } = useMutation(deleteTeam, {
    onSuccess: (_, teamID) => {
      queryClient.setQueryData<any>('teams', (oldData: any[]) => oldData.filter((t) => t.id !== teamID));
      queryClient.invalidateQueries('people');
    },
  });

  const { mutateAsync: editTeamMutation } = useMutation(editTeam, {
    onSuccess: (newData) => queryClient.setQueryData<any>('teams', (oldData: any[]) => oldData
      .map((d) => (d.id === newData.id ? newData : d))),
  });

  const { mutateAsync: createTeamMutation } = useMutation(createTeam, {
    onSuccess: (newData) => queryClient.setQueryData<any>('teams', (oldData: any[]) => [newData].concat(oldData)),
  });

  const isArchived = (t) => t.isArchived;

  const teams = useMemo(() => teamsData, [teamsData]);

  const teamsWithoutArchived = useMemo(() => {
    if (isLoading) return [];
    return (teams || []).filter((x) => !isArchived(x));
  }, [isLoading, teams]);

  const archivedTeams = useMemo(() => {
    if (isLoading) return [];
    return (teams || []).filter(isArchived);
  }, [teams, isLoading]);

  return {
    teams: teamsWithoutArchived || [],
    archivedTeams: archivedTeams || [],
    loading: isLoading || isFetching,
    deleteTeam: deleteTeamMutation,
    editTeam: editTeamMutation,
    createTeam: createTeamMutation,
  };
};
