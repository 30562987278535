import { useEffect } from 'react';
import { useMe } from '../api/me';
import { useOrg } from '../api/org';
import { AppStateEnum, useAppState } from '../api/app-state';
import { useOnboardingData } from '../api/onboarding2';

declare global {
  interface Window {
    $crisp: any,
    CRISP_WEBSITE_ID: string,
    CRISP_TOKEN_ID: string,
  }
}

export const useChat = () => {
  const { me } = useMe();
  const { org } = useOrg();
  const { appState } = useAppState();
  const { email } = useOnboardingData({
    enabled: [AppStateEnum.createOrg].includes(appState as AppStateEnum),
  });
  // const enabled = [AppStateEnum.createOrg, AppStateEnum.ready, AppStateEnum.isFirstLogin].includes(appState as AppStateEnum);

  useEffect(() => {
    window.$crisp = [];
    window.$crisp.push(['safe', ['production', 'preview'].includes(process.env.NODE_ENV)]); // check https://docs.crisp.chat/guides/chatbox-sdks/web-sdk/dollar-crisp/#disable-warnings-amp-errors
    window.CRISP_WEBSITE_ID = 'a263b482-dfea-479f-878f-d4ce6a64b23f';
    // @ts-ignore
    (function () { const d = document; const s = d.createElement('script'); s.src = 'https://client.crisp.chat/l.js'; s.async = 1; d.getElementsByTagName('head')[0].appendChild(s); }());
  }, []);

  useEffect(() => {
    if (!me && !email) return;
    window.CRISP_TOKEN_ID = me?.id || email;
    window.$crisp.push(['do', 'session:reset']);
    if ((me?.email || email)) {
      window.$crisp.push(['set', 'user:email', [me?.email || email]]);
      window.$crisp.push(['set', 'user:company', [org?.name || '']]);
      window.$crisp.push(['set', 'session:data', [[
        ['org_id', org?.id || ''],
        ['org_name', org?.name || ''],
        ['user_id', me?.id || ''],
      ]]]);
    }
  }, [me, email]);
};
