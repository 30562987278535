import React from 'react';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar';
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker';
import { PauseTypography } from '../PauseTypography';
import { ChevronLeft, ChevronRight } from '../../../assets/icons/feather/components';
import { PauseTextField } from '../PauseTextField';

const CustomToolbarComponent = (props: ToolbarComponentProps) => {
  const { date, setOpenView, isLandscape } = props;
  return (
    <PickerToolbar
      isLandscape={isLandscape}
      css={(t) => ({
        color: t.get('palette.pause.white.main'),
        flexDirection: 'column',
        gap: t.spacing(1),
        alignItems: 'flex-start',
      })}
    >
      <PauseTypography
        variant="number"
        onClick={() => setOpenView('year')}
      >
        <span css={({
          cursor: 'pointer',
        })}
        >
          {moment(date).format('YYYY')}
          {' '}
          <span>▾</span>
        </span>
      </PauseTypography>
      <PauseTypography variant="title">
        {moment(date).format('ddd, MMM Do')}
      </PauseTypography>
    </PickerToolbar>
  );
};

type Props = {
  value: moment.Moment,
  onChange?: (any?) => any,
  setFieldValue?: (any?) => any,
  disabled: boolean,
  label?: string,
  showYear?: boolean,
  minDate: moment.Moment,
  maxDate: moment.Moment,
  [key: string]: any
}

export const PauseDatePicker = React.forwardRef<Props, any>(({
  value, onChange, setFieldValue, disabled, label = 'Date',
  showYear = false, minDate, maxDate, ...props
}, ref) => (
  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
    <DatePicker
      ref={ref}
      minDate={minDate}
      maxDate={maxDate}
      renderDay={(day: any, selectedDate: any, dayInCurrentMonth) => {
        const dayOutOfRange = day > maxDate || day < minDate;
        return (
          <div
            role="presentation"
            css={(theme) => ({
              width: '36px',
              height: '36px',
              margin: '0 2px',
              paddingTop: '10px',
              display: 'grid',
              justifyContent: 'center',
              cursor: (dayInCurrentMonth && !dayOutOfRange) ? 'pointer' : 'default',
              borderRadius: theme.spacing(0.5),
              backgroundColor: selectedDate.isSame(day)
                ? theme.get('palette.pause.primary.light') : 'transparent',
              ...(!selectedDate.isSame(day) && {
                '&:hover': (dayInCurrentMonth && !dayOutOfRange) ? {
                  backgroundColor: theme.get('palette.pause.background.medium'),
                } : {},
              }),
            })}
          >
            {dayInCurrentMonth && (
            <PauseTypography variant="number" color={dayOutOfRange ? 'pause.primary.light' : 'pause.primary.main'}>
              {day.date()}
            </PauseTypography>
            )}
          </div>
        // <>{dayComponent}</>
        );
      }}
      disableToolbar={!showYear}
      leftArrowButtonProps={{
        disableRipple: true,
      }}
      rightArrowButtonProps={{
        disableRipple: true,
      }}
      leftArrowIcon={<ChevronLeft />}
      rightArrowIcon={<ChevronRight />}
      // @ts-ignore
      TextFieldComponent={({ variant, ...props }) => <PauseTextField label={label} {...props} />}
      css={{
        '.MuiPickersCalendar-week': {
          background: 'black',
        },
        // '.MuiInputBase-root': {
        //   transition: 'all 0.2s ease-in-out',
        //   '&::before': { display: 'none' },
        //   '&::after': { display: 'none' },
        //   padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
        //   borderRadius: theme.spacing(1),
        //   color: theme.palette.pause.primary.main,
        //   cursor: 'pointer',
        //   border: '2px solid transparent',
        //   ...(!disabled && {
        //     '&:hover': {
        //       background: theme.palette.pause.background.dark,
        //     },
        //   }),
        //   '&.Mui-focused': {
        //     background: theme.palette.pause.background.dark,
        //     borderColor: theme.palette.pause.primary.medium,
        //   },
        // },
        // '.MuiInputBase-input': {
        //   cursor: 'pointer',
        // },
        // '.MuiInputBase-input:focus': {
        //   cursor: 'pointer',
        // },
      }}
      {...props}
      disabled={disabled}
      value={value}
      onChange={(newDate) => {
        // eslint-disable-next-line no-unused-expressions
        if (setFieldValue) setFieldValue(props.name, newDate);
        // eslint-disable-next-line no-unused-expressions
        else onChange({ current: { newDate } });
      }}
      variant="dialog"
      {...{
        ToolbarComponent: !showYear ? undefined : CustomToolbarComponent,
      }}
    />
  </MuiPickersUtilsProvider>
));
