import axios from 'axios';

import { mainQueryClient } from '../api/query-client';

const Axios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});
const { CancelToken } = axios;
// let cancel;

Axios.interceptors.request.use((request) => {
  const token = mainQueryClient.getQueryData('token');
  request.headers.common.Authorization = `Bearer ${token}`;
  return {
    ...(!token ? {
      cancelToken: new CancelToken((cancel) => cancel('Cancel invalid request')),
    } : request),
  };
}, (e) => Promise.reject(e));

Axios.interceptors.response.use((response) => response,
  (error) => {
    throw error;
  });

type Get = (a: {url: string, params?: any}, b?: Function) => Promise<any>
const get: Get = ({ url, params = null }, extractor = (a) => a) => Axios({
  method: 'get',
  url,
  params,
}).then((res) => extractor(res.data));

type Post = (a: {url: string, data?: any, headers?: any }, b?: Function) => Promise<any>
const post: Post = ({ url, data, headers }, extractor = (a) => a) => Axios({
  method: 'post',
  url,
  data,
  headers,
}).then((res) => extractor(res.data));

type Put = (a: {url: string, data?: any}, b?: Function) => Promise<any>
const put: Put = ({ url, data }, extractor = (a) => a) => Axios({
  method: 'put',
  url,
  data,
}).then((res) => extractor(res.data));

type Del = ({ url: string }) => Promise<any>
const del: Del = ({ url }) => Axios({
  method: 'delete',
  url,
}).then((res) => res.data || true);

export const useAPI = () => ({
  get,
  post,
  put,
  del,
});

export const api = () => ({
  get,
  post,
  put,
  del,
});
