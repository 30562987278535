import React, { useMemo } from 'react';
import { isEqual } from 'lodash';
import { PauseDatePicker } from '../PauseDateTimePickers';
import { PauseFormField2 } from './index';

type Props = {
  showErrorMessage?: boolean,
  name: string,
  disabled?: boolean,
  fast?: boolean,
  fieldProps: {
    [key: string]: any
  }
}

export const PauseFormDatepicker = React.memo<Props>(({
  showErrorMessage, name, disabled,
  fieldProps,
  fast,
}) => {
  const memoizedFieldProps = useMemo(() => fieldProps, [fieldProps]);
  return (
    <PauseFormField2
      name={name}
      fast={fast}
      disabled={disabled}
      fieldProps={memoizedFieldProps}
      showErrorMessage={showErrorMessage}
      render={({ field, state, helpers: { setValue } }) => {
        const { onChange, ...otherFieldValues } = field;
        return (
          <PauseDatePicker
            {...otherFieldValues}
            {...state}
            {...memoizedFieldProps}
            setFieldValue={(_, fieldValue) => setValue(fieldValue)}
          />
        );
      }}
    />
  );
}, (
  newProps,
  oldProps,
) => isEqual(newProps, oldProps) && isEqual(newProps.fieldProps, oldProps.fieldProps));
