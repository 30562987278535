import { CheckpointOnboardingData, OnboardingStep } from '../src/types/onboarding';
import fromEntries from './fromEntries';

type Just<T> = T
type Nothing = null
type Maybe<T> = Just<T> | Nothing

type OrgInfo = {
  orgName: Maybe<string>,
  personName: Maybe<string>,
  personEmail: Maybe<string>,
  hasSlack: Maybe<boolean>,
}

export type LeaveCycleInfo = {
  timezone: Maybe<string>,
  cycleStartMonth: Maybe<string>
}

// eslint-disable-next-line no-shadow
export enum LeaveDispersalType {
  PerCycle = 'PER_CYCLE',
  PerMonth = 'PER_MONTH'
}
export type LeaveType = {
  id: string,
  name: string,
  description?: string,
  allowance: {
    amount: number,
    type: LeaveDispersalType,
  },
  type: 'annual' | 'deductible',
  carryForwardAmount?: Maybe<number>,
  requiresApproval: boolean,
}
export type LeaveTypesInfo = LeaveType[]
type AllowanceManual = { leaveID: string, allowance: number }
type AllowanceProrata = { leaveID: string, startDate: string }
export type CheckpointPerson = {
  id: string,
  name: string,
  email: string,
  allowances: Maybe<AllowanceProrata[] | AllowanceManual[]>
}

export type SlackSettings = {
  channelID: Maybe<string>,
  dailySummaries: boolean,
  publicHolidays: boolean,
  birthdays: boolean,
  workAnniversaries: boolean,
}

export type CheckpointData = {
  orgInfo: Maybe<OrgInfo>,
  leaveCycleInfo: Maybe<LeaveCycleInfo>,
  leaveTypesInfo: Maybe<LeaveTypesInfo>,
  people: Maybe<CheckpointPerson[]>,
  slackSettings: Maybe<SlackSettings>,
  hasIntegratedSlack: boolean,
}

const onboardingStepOrder = {
  [OnboardingStep.OrgExistsCheck]: 0,
  [OnboardingStep.OrgInfo]: 1,
  [OnboardingStep.LeaveCycleStart]: 2,
  [OnboardingStep.LeaveTypesInfo]: 3,
  [OnboardingStep.Done]: 4,
};

const prefix = '/start';

export const onboardingRoutes = {
  [OnboardingStep.OrgExistsCheck]: `${prefix}/org-exists`,
  [OnboardingStep.OrgInfo]: `${prefix}/org-info`,
  [OnboardingStep.LeaveCycleStart]: `${prefix}/leave-cycle`,
  [OnboardingStep.LeaveTypesInfo]: `${prefix}/leave-policy`,
  [OnboardingStep.Done]: '/home',
};

// eslint-disable-next-line max-len
export const getStepFromCheckpointData = (checkpointOnboardingData: CheckpointOnboardingData): OnboardingStep => checkpointOnboardingData.step;

// eslint-disable-next-line max-len
export const isAllowedToViewThisScreen = (checkpointOnboardingData: CheckpointOnboardingData) => (onboardingStep: OnboardingStep): boolean => {
  if (onboardingStepOrder[checkpointOnboardingData.step]
          >= onboardingStepOrder[onboardingStep]) return true;
  return false;
};

export const getRouteFromOnboardingStep = (onboardingStep: OnboardingStep): string => `${onboardingRoutes[onboardingStep]}` || '';

export const getOnboardingStepFromRoute = (route: string): OnboardingStep => {
  const reverse = fromEntries(Object
    .entries(onboardingRoutes)
    .map(([k, v]) => [v, k as OnboardingStep]));
  return reverse[route] || OnboardingStep.OrgExistsCheck;
};

export const initialCheckpointData: CheckpointData = {
  orgInfo: null,
  leaveCycleInfo: null,
  leaveTypesInfo: null,
  people: null,
  slackSettings: null,
  hasIntegratedSlack: false,
};
