import React from 'react';
import {
  InputBase, FormControl, FormHelperText, FormLabel,
} from '@material-ui/core';
import { useTheme } from 'emotion-theming';
import { omit } from 'lodash';
import { PauseTypography } from '../PauseTypography';
import { PauseDatePicker } from '../PauseDateTimePickers';
import { PauseAutocomplete } from '../PauseAutocomplete';

const getComponentFromType = (type) => {
  const comboNames = ['dropdown', 'select', 'autocomplete'];
  if (type === 'textarea') return InputBase;
  if (comboNames.includes(type)) return PauseAutocomplete;
  if (type === 'date') return PauseDatePicker;
  return InputBase;
};

export const PauseInputGeneric = React.forwardRef((props, ref) => {
  const {
    type = 'text',
    label,
    error,
    required,
    id,
    startIcon,
    customComponent = null,
    disabled,
  } = props;

  const omitPropsFromInput = ['type', 'error', 'startIcon', 'endIcon', 'label'];

  const theme = useTheme();

  const inputBaseCSS = ({
    transition: 'all 0.2s ease-in-out',
    '&.MuiInputBase-root': {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
      borderRadius: theme.spacing(1),
      color: theme.palette.pause.primary.main,
      cursor: !disabled ? 'pointer' : 'not-allowed',
      border: '2px solid transparent',
      ...(!disabled && {
        '&:hover': {
          background: theme.palette.pause.background.dark,
        },
      }),
      '&.Mui-focused': {
        background: theme.palette.pause.background.dark,

        borderColor: theme.palette.pause.primary.medium,
      },
      textarea: {
        padding: '6px 0 7px', // Material applies this padding for <input /> but not for <textarea /> so we force it
      },
    },
    '.MuiInputBase-input': {
      cursor: !disabled ? 'pointer' : 'not-allowed',
    },
    '.MuiInputBase-input:focus': {
      cursor: !disabled ? 'pointer' : 'not-allowed',
    },
  });

  const labelCSS = ({
    display: 'block',
    ...(startIcon && { gridRow: 1, gridColumn: 2 }),
    marginLeft: theme.spacing(1.5),
    '&.Mui-focused': {
      color: theme.palette.pause.primary.main,
    },
    '.Mui-error': {
      color: theme.palette.pause.error.main,
    },
    '.MuiFormLabel-asterisk': {
      display: 'none',
    },
  });

  const helperTextCSS = ({
    ...(startIcon && { gridRow: 3, gridColumn: 2 }),
    display: 'block',
    marginLeft: theme.spacing(1.5),
    '.Mui-error': {
      color: theme.palette.pause.error.main,
    },
  });

  const startIconCSS = ({
    display: 'flex',
    marginTop: theme.spacing(1.25),
    justifyContent: 'flex-start',
    // padding: `0 ${theme.spacing(2)}px 0 0`,
    height: '100%',
    color: theme.get('palette.pause.primary.medium'),
    svg: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  });

  const formWrapperCSS = ({
    display: 'grid',
    rowGap: theme.spacing(0.5),
    columnGap: theme.spacing(1.5),
    ...(startIcon && { gridTemplateColumns: 'auto 1fr', gridTemplateRows: 'auto auto auto' }),
    ...(!startIcon && { gridTemplateColumns: 'auto', gridTemplateRows: 'auto' }),
  });

  const Component = customComponent || getComponentFromType(type);

  return (
    <FormControl
      disabled={disabled}
      css={formWrapperCSS}
      fullWidth
      {...omit(props, ['id'])}
    >
      <FormLabel htmlFor={id} css={labelCSS} {...props}>
        {label}
        {' '}
        {label && required && '*'}
      </FormLabel>
      <div css={() => ({
        ...(startIcon && { gridRow: 2, gridColumn: 2 }),
      })}
      >
        {!customComponent && (
        <Component
          ref={ref}
          css={inputBaseCSS}
          {...omit(props, omitPropsFromInput)}
          {...(type === 'textarea' && {
            multiline: true,
          })}
          {...(type === 'number' && {
            type: 'number',
          })}
          disabled={disabled}
          fullWidth
        />
        )}
        {customComponent && Component}
      </div>
      <FormHelperText {...props} css={helperTextCSS}>
        <PauseTypography variant="body2">
          {error}
        </PauseTypography>
      </FormHelperText>
      {startIcon && <div css={{ ...startIconCSS, gridRow: 2, gridColumn: 1 }}>{startIcon}</div>}
    </FormControl>
  );
});
