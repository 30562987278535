export type Maybe<T> = T | null

export enum AllowanceCreditType {
  PerMonth = 'PER_MONTH',
  PerCycle = 'PER_CYCLE'
}
export type LeaveType = {
  name: string,
  allowance: {
    type: AllowanceCreditType | null,
    amount: number,
    isNonDeductible: boolean,
  }
}

export enum OnboardingStep {
  OrgExistsCheck = 'OrgExistsCheck',
  OrgInfo = 'OrgInfo',
  LeaveCycleStart = 'LeaveCycleStart',
  LeaveTypesInfo = 'LeaveTypesInfo',
  Done = 'Done'
}
export type CheckpointOnboardingData = {
  step: OnboardingStep
  org: Maybe<{
    orgCreatorName: Maybe<string>,
    orgCreatorEmail: Maybe<string>,
    orgName: Maybe<string>,
    leaveCycleStartMonth: Maybe<string>,
    timezone: Maybe<string>,
  }>,
  leaveTypes: Maybe<Array<LeaveType>>,
}

export type CheckpointDataAPIResponseFormatted = {
  userEmail: string,
  data: CheckpointOnboardingData,
  domainDetails: {
    nameOfEarliestOrgWithThisDomain: string | null
  }
}
