import React, { useCallback } from 'react';
import { InputBase, InputAdornment } from '@material-ui/core';

const PauseInputAdornment = ({ children }) => (
  <InputAdornment css={(theme) => ({
    width: theme.spacing(5),
    textAlign: 'center',
    height: '100%',
    maxHeight: 'unset',
    color: theme.get('palette.pause.primary.medium'),
    background: theme.get('palette.pause.background.dark'),
    svg: {
      marginLeft: theme.spacing(1),
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  })}
  >
    {children}
  </InputAdornment>
);

export const PauseInput = React.forwardRef(({
  leftIcon, rightIcon, value, disabled, onChange = () => {}, placeholder, ...otherProps
}, ref) => {
  const onChangeCallback = useCallback((e) => onChange(e), [onChange]);
  return (
    <InputBase
      css={(theme) => ({
        display: 'grid',
        gridAutoFlow: 'column',
        overflow: 'hidden',
        borderRadius: theme.spacing(1.25),
        borderWidth: theme.spacing(0.25),
        ...(leftIcon && !rightIcon && {
          gridTemplateColumns: 'auto 1fr',
        }),
        ...(!leftIcon && rightIcon && {
          gridTemplateColumns: '1fr auto',
        }),
        ...(leftIcon && rightIcon && {
          gridTemplateColumns: 'auto 1fr auto',
        }),
        borderStyle: 'solid',
        background: theme.get('palette.pause.background.medium'),
        borderColor: theme.get('palette.pause.background.dark'),
        color: theme.get('palette.pause.primary.dark'),
        input: {
          padding: theme.spacing(1.5),
          width: `calc(100% - (2 * ${theme.spacing(1.5)}px))`,
        },
        'input::-webkit-input-placeholder': { /* Chrome/Opera/Safari */
          color: theme.get('palette.pause.primary.medium'),
          opacity: 1,
        },
        'input::-moz-placeholder': { /* Firefox 19+ */
          color: theme.get('palette.pause.primary.medium'),
          opacity: 1,
        },
        'input:-ms-input-placeholder': { /* IE 10+ */
          color: theme.get('palette.pause.primary.medium'),
          opacity: 1,
        },
        'input:-moz-placeholder': { /* Firefox 18- */
          color: theme.get('palette.pause.primary.medium'),
          opacity: 1,
        },
        ...(!disabled && {
          '&:hover': {
            borderColor: theme.get('palette.pause.primary.light'),
          },
        }),

        '&.Mui-focused': {
          borderColor: theme.get('palette.pause.primary.main'),
        },
        transition: 'all 0.1s',
      })}
      startAdornment={leftIcon && <PauseInputAdornment>{leftIcon}</PauseInputAdornment>}
      endAdornment={rightIcon && <PauseInputAdornment>{rightIcon}</PauseInputAdornment>}
      variant="outlined"
      value={value}
      disabled={disabled}
      onChange={onChangeCallback}
      placeholder={placeholder}
      ref={ref}
      {...otherProps}
    />
  );
});

export const PauseInputDisplay = React.forwardRef(({
  leftIcon, rightIcon, value, disabled, onChange = () => {}, placeholder, ...otherProps
}, ref) => {
  const onChangeCallback = useCallback((e) => onChange(e), [onChange]);
  return (
    <InputBase
      css={(theme) => ({
        fontFamily: 'Concourse T6',
        fontWeight: 500,
        lineHeight: 1.22,
        fontSize: '3rem',
        display: 'grid',
        gridAutoFlow: 'column',
        overflow: 'hidden',
        borderRadius: theme.spacing(1.25),
        borderWidth: theme.spacing(0.25),
        ...(leftIcon && !rightIcon && {
          gridTemplateColumns: 'auto 1fr',
        }),
        ...(!leftIcon && rightIcon && {
          gridTemplateColumns: '1fr auto',
        }),
        ...(leftIcon && rightIcon && {
          gridTemplateColumns: 'auto 1fr auto',
        }),
        borderStyle: 'solid',
        background: theme.get('palette.pause.background.medium'),
        borderColor: theme.get('palette.pause.background.dark'),
        color: theme.get('palette.pause.primary.dark'),
        input: {
          padding: theme.spacing(0.5, 1.5),
          width: `calc(100% - (2 * ${theme.spacing(1.5)}px))`,
        },
        'input::-webkit-input-placeholder': { /* Chrome/Opera/Safari */
          color: theme.get('palette.pause.primary.medium'),
          opacity: 1,
        },
        'input::-moz-placeholder': { /* Firefox 19+ */
          color: theme.get('palette.pause.primary.medium'),
          opacity: 1,
        },
        'input:-ms-input-placeholder': { /* IE 10+ */
          color: theme.get('palette.pause.primary.medium'),
          opacity: 1,
        },
        'input:-moz-placeholder': { /* Firefox 18- */
          color: theme.get('palette.pause.primary.medium'),
          opacity: 1,
        },
        ...(!disabled && {
          '&:hover': {
            borderColor: theme.get('palette.pause.primary.light'),
          },
        }),

        '&.Mui-focused': {
          borderColor: theme.get('palette.pause.primary.main'),
        },
        transition: 'all 0.1s',
      })}
      startAdornment={leftIcon && <PauseInputAdornment>{leftIcon}</PauseInputAdornment>}
      endAdornment={rightIcon && <PauseInputAdornment>{rightIcon}</PauseInputAdornment>}
      variant="outlined"
      value={value}
      disabled={disabled}
      onChange={onChangeCallback}
      placeholder={placeholder}
      ref={ref}
      {...otherProps}
    />
  );
});
