import React from 'react';
// import { darken } from 'polished';
import { CircularProgress } from '@material-ui/core';
import { PauseTypography } from '../PauseTypography';
import { defaultDisabledStyles, useBaseStyles } from '../PauseButton/utils';

const sizeBasedTypeVariant = (size) => ({
  large: 'title',
  medium: 'button',
  small: 'number',
}[size]);

type Props = {
  children: React.ReactNode,
  inline?: boolean,
  absolute?: boolean,
  underlinedOnHover?: boolean,
  startIcon?: JSX.Element,
  isLoading?: boolean,
  color?: 'primary' | 'inherit' | 'secondary',
  size?: string | number,
  compact?: boolean,
  [key: string]: any
}

export const PauseTextButton = ({
  children,
  inline = false,

  absolute = false, underlinedOnHover = false,
  startIcon = <></>,
  isLoading = false,
  color = 'primary',
  size = 'medium',
  compact = false,
  ...props
}: Props) => {
  const baseStyles = useBaseStyles({ inline });
  return (
    <button
      // @ts-ignore
      css={(theme) => ({
        ...baseStyles.defaultStyles,
        justifyContent: 'flex-start',
        padding: !compact ? `${theme.spacing(1)}px ${theme.spacing(1)}px` : 0,
        backgroundColor: 'transparent',
        color: theme.get(`palette.pause.${color}.main`),
        ...(absolute && ({
          position: 'absolute', top: 0, right: 0, left: 0, bottom: 0,
        })),
        ...(underlinedOnHover && {
          '&:hover *': {
            textDecoration: 'underline',
          },
        }),
        '&:hover': {
          color: theme.get(`palette.pause.${color}.dark`),
        },
        // '&:focus': {
        //   color: darken(0.09, theme.get(`palette.pause.${color}.medium`) || theme.get(`palette.pause.${color}.main`)),
        // },
        '&:disabled': {
          ...defaultDisabledStyles,
        },
      })}
      {...props}
    >
      <PauseTypography
        variant={sizeBasedTypeVariant(size)}
      >
        <div css={(theme) => ({
          display: 'grid',
          gridGap: theme.spacing(0.5),
          alignItems: 'center',
          gridAutoFlow: 'column',
        })}
        >
          {isLoading ? (
            <CircularProgress
              size="1em"
              color={color}
            />
          ) : startIcon}
          {children}
        </div>
      </PauseTypography>
    </button>
  );
};
