import React from 'react';
import { NotificationsStoreWrapper } from './store';
import { NotificationDrawer } from './components/NotificationDrawers';

export const PauseNotifications = ({
  open = false,
  onClose = () => null,
}) => (
  <NotificationsStoreWrapper>
    {({
      notifications,
      getNotificationDetails,
      loadMore,
      acceptLeave,
      acceptCompOff,
      markAsRead,
      notificationContext,
      notificationContexts,
      setNotificationType,
      hasMore,
      unReadCounts,
      isApprover,
      isAdmin,
      done,
    }) => (
      <NotificationDrawer
        drawerLoading={!done}
        isApprover={isApprover}
        unReadCounts={unReadCounts}
        open={open}
        onClose={onClose}
        notifications={notifications}
        notificationContext={notificationContext}
        notificationContexts={notificationContexts}
        getNotificationDetails={getNotificationDetails}
        loadMore={loadMore}
        hasMore={hasMore}
        acceptLeave={acceptLeave}
        markAsRead={markAsRead}
        setNotificationType={setNotificationType}
        isAdmin={isAdmin}
        acceptCompOff={acceptCompOff}
      />
    )}
  </NotificationsStoreWrapper>
);
