import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { PauseDrawer, PauseDrawerContent, PauseDrawerTitle } from '../../../components/PauseDrawer';
import { ActionCard } from '../../../components/Notification/ActionCard';
import { RequestCard } from '../../../components/Notification/RequestCard';
import { InfoCard } from '../../../components/Notification/InfoCard';
import { NOTIFICATION_CONTEXTS } from '../../../components/Notification/utils';
import { PauseInteractiveButton } from '../../../components/PauseInteractiveButton';
import {
  PauseTab, PauseTabs, PauseTabsList, useTabs,
} from '../../../components/PauseTabs';
import { PauseTypography } from '../../../components/PauseTypography';
import { LeaveForm, Tabs } from '../../leave-form/components/LeaveForm';
import { LeaveFormStoreWrapper } from '../../leave-form/store';

const { APPROVALS, REQUESTS, OTHERS } = NOTIFICATION_CONTEXTS;

const Props = {
  open: PropTypes.bool.isRequired,
  notifications: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool,
  isApprover: PropTypes.bool,
  notificationContext: PropTypes.string.isRequired,
  notificationContexts: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  getNotificationDetails: PropTypes.func.isRequired,
  acceptLeave: PropTypes.func,
  onUpdateApprovalNotification: PropTypes.func,
  markAsRead: PropTypes.func,
  setNotificationType: PropTypes.func.isRequired,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  drawerLoading: PropTypes.bool,
  unReadCounts: PropTypes.shape({
    approvals: PropTypes.number,
    requests: PropTypes.number,
    others: PropTypes.number,
  }),
  acceptCompOff: PropTypes.func,
};

const defaultProps = {
  isAdmin: false,
  isApprover: false,
  acceptLeave: () => null,
  onUpdateApprovalNotification: () => null,
  markAsRead: () => null,
  loadMore: () => null,
  hasMore: false,
  drawerLoading: false,
  unReadCounts: {
    approvals: 0,
    requests: 0,
    others: 0,
  },
};

export const NotificationDrawer = ({
  open,
  notifications,
  isApprover,
  isAdmin,
  notificationContext,
  onClose,
  getNotificationDetails,
  acceptLeave,
  markAsRead,
  loadMore,
  hasMore,
  setNotificationType,
  unReadCounts,
  drawerLoading,
  acceptCompOff,
  ...rest
}) => {
  const topOffset = 104;
  const [detailsDrawerOpen, setDetailsDrawerOpen] = React.useState(false);
  const [detailsDrawerData, setDetailsDrawerData] = React.useState<any>({});
  // const [notifDetailState, setNotifDetailState] = React.useState(null);
  const { value, onChange: onChangeTab } = useTabs({ initActiveTab: notificationContext });

  const handleDetailsDrawerClose = () => {
    if (!detailsDrawerOpen) return;
    setDetailsDrawerOpen(false);
    setTimeout(() => setDetailsDrawerData({}), 0);
  };
  const handleNotificationClick = async ({ id }) => {
    setDetailsDrawerData({});
    const details = await getNotificationDetails({ id });
    if (!get(details, 'isRead', true)) {
      markAsRead({ notificationID: id, context: notificationContext });
    }
    // if (notificationContext === OTHERS) return;
    setDetailsDrawerData(details);
    setDetailsDrawerOpen(true);
  };

  const handleNotificationsDrawerClose = () => {
    setDetailsDrawerOpen(false);
    setDetailsDrawerData({});
    setTimeout(() => {
      onClose();
    }, 100);
  };

  const handleTabChange = (newTab) => {
    setNotificationType({ type: newTab });
    onChangeTab(newTab);
  };

  React.useEffect(() => {
    if (!open) {
      setDetailsDrawerOpen(false);
    }
  }, [open]);

  React.useEffect(() => {
    setDetailsDrawerOpen(false);
    setDetailsDrawerData({});
  }, [notificationContext]);

  return (
    // @ts-ignore
    <PauseDrawer
      width="400px"
      {...rest}
      hasChildDrawer={detailsDrawerOpen}
      onClose={handleNotificationsDrawerClose}
      open={open}
      topOffset={topOffset}
      anchor="right"
      loading={drawerLoading}
      ModalProps={{ disablePortal: true }}

    >
      {/* @ts-ignore */}
      <PauseDrawerTitle
        compact
        hideCloseButton
        render={(
          <div css={{
            alignSelf: 'flex-end',
          }}
          >
            <PauseTabs size="small" value={value} onChange={handleTabChange}>
              <PauseTabsList>
                {(isAdmin || isApprover) && <PauseTab value={APPROVALS} label="For Approval" count={unReadCounts.approvals} />}
                <PauseTab value={REQUESTS} label="My Requests" count={unReadCounts.requests} />
                <PauseTab value={OTHERS} label="Others" count={unReadCounts.others} />
              </PauseTabsList>
            </PauseTabs>
          </div>

      )}
      />

      <PauseDrawerContent noPadding>
        {
          notifications.length ? (
            <div>
              {
                notifications
                  .map((notif) => (
                    <div key={notif.id}>
                      {notificationContext === APPROVALS
                  && (
                  <ActionCard
                    onApproveLeave={acceptLeave}
                    onApproveCompOff={acceptCompOff}
                    onNotificationClick={handleNotificationClick}
                    selected={notif.id === detailsDrawerData?.id}
                    notifData={notif}
                  />
                  )}

                      {notificationContext === REQUESTS
                  && (
                  <RequestCard
                    notifData={notif}
                    onNotificationClick={handleNotificationClick}
                    selected={notif.id === detailsDrawerData?.id}
                  />
                  )}

                      {notificationContext === OTHERS
                  && (
                  <InfoCard
                    selected={notif.id === detailsDrawerData.id}
                    onNotificationClick={handleNotificationClick}
                    notifData={notif}
                  />
                  )}

                    </div>
                  ))

              }
              {hasMore && (
              <div css={(theme) => ({
                padding: theme.spacing(2),
                textAlign: 'center',
              })}
              >
                <PauseInteractiveButton variant="ghost" size="small" onClick={loadMore}>
                  Load More
                </PauseInteractiveButton>
              </div>
              )}
            </div>
          ) : (
            <PauseTypography
              css={(theme) => ({
                padding: theme.spacing(2, 3),
              })}
              variant="header"
              color="pause.background.dark"
            >
              Everything quiet here so far...
            </PauseTypography>
          )
        }
      </PauseDrawerContent>

      {get(detailsDrawerData, 'details') && (
      <LeaveFormStoreWrapper
        presetLeave={get(detailsDrawerData, 'details')}
        presetType={get(detailsDrawerData, 'type')}
      >
        {({
          me,
          selectablePeople,
          lockedDates, loading, currentLeave,
          onCreateLeave,
          onApproveLeave,
          onRejectLeave,
          onDeleteLeave,
          approver,
          selectedPerson,
          setSelectedPerson,
          leaveState,
          currentApprover,
          currentCompOff,
          onApproveCompOff,
          onDeclineCompOff,
          onCancelCompOff,
          currentAllHands,
          onCreateAllHands,
          onDeleteAllHands,
        }) => (
          <LeaveForm
            staticTab={(currentLeave && Tabs.Leave) || (currentCompOff && Tabs.CompOff) || (currentAllHands && Tabs.AllHands) || null}
            open={detailsDrawerOpen}
            onClose={handleDetailsDrawerClose}
            drawerProps={{
              topOffset,
              rightOffset: 400,
              anchor: 'right',
              zIndex: 999,
              variant: 'persistent',
              isChildDrawer: true,
            }}
            drawerTitle={currentLeave ? 'Leave request' : currentCompOff ? 'Comp-off request' : currentAllHands ? 'All-hands' : 'Loading...'}
            me={me}
            selectablePeople={selectablePeople}
            lockedDates={lockedDates}
            loading={loading}
            currentLeave={currentLeave}
            onCreateLeave={onCreateLeave}
                        // This is the local leave approving fn
            onApproveLeave={(leave) => onApproveLeave(leave)}
                        // This is the local leave rejecting fn
            onRejectLeave={onRejectLeave}
            onDeleteLeave={onDeleteLeave}
            approver={approver}
            selectedPerson={selectedPerson}
            setSelectedPerson={setSelectedPerson}
            leaveState={leaveState}
            currentApprover={currentApprover}
            currentCompOff={currentCompOff}
            onCreateCompOff={() => Promise.resolve(null)}
            onApproveCompOff={onApproveCompOff}
            onDeclineCompOff={onDeclineCompOff}
            onCancelCompOff={onCancelCompOff}
            currentAllHands={currentAllHands}
            onCreateAllHands={onCreateAllHands}
            onDeleteAllHands={onDeleteAllHands}
          />
        )}
      </LeaveFormStoreWrapper>
      )}
    </PauseDrawer>
  );
};

NotificationDrawer.propTypes = Props;
NotificationDrawer.defaultProps = defaultProps;
