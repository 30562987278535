import React from 'react';
import {
  useEnhancedRouter,
} from '../../../router';
import { useMounted } from '../../hooks/useMounted';

export const Journey = ({
  journey, stop, children,
  preRouteCheck,
  fallbackJourney,
  fallbackStop,
}: {
  journey: string,
  stop: string,
  children: React.ReactNode,
  preRouteCheck?: (any?) => any,
  fallbackJourney?: string,
  fallbackStop?: string
}) => {
  const router = useEnhancedRouter();
  const { current: isMounted } = useMounted();
  if (!stop && router.isJourneyActive(journey)) {
    return (
      <>
        {children}
      </>
    );
  }
  if (router.isStopActive(journey, stop)) {
    // For if there is a pre route check
    if (preRouteCheck && !preRouteCheck() && isMounted) {
      router.navigateToStop({
        journey: fallbackJourney,
        stop: fallbackStop,
        options: { shallow: true },
      });
      return null;
    }
    return (
      <>
        {children}
      </>
    );
  }

  return null;
};
