import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { PauseTypography } from '../../../components/PauseTypography';
import { PauseFormGroupCard, PauseFormGroupCardsList } from '../../../components/PauseFormGroupCard';
import { PauseFormField } from '../../../components/PauseForm';
import { PauseInputGeneric } from '../../../components/PauseInputGeneric';
import { AlignRight, Calendar } from '../../../../assets/icons/feather/components';
import { PauseButton } from '../../../components/PauseButton';
import { PauseSubmitButton } from '../../../components/PauseSubmitButton';
import { CompOffFormState } from './LeaveForm';
// import { PausePerson } from '../../../components/PausePerson';

export const CompOffFooter = (props: {
  state: CompOffFormState,
  onClose: () => any,
  onChangeCompOffState: (any) => any
}) => {
  const {
    state = CompOffFormState.Readonly,
    onClose,
    onChangeCompOffState = () => null,
  } = props;

  if ([CompOffFormState.ApproveOrDecline].includes(state)) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <PauseButton
            color="error"
            onClick={() => onChangeCompOffState(CompOffFormState.WaitingForDecline)}
          >
            Decline
          </PauseButton>
        </Grid>
        <Grid item xs={6}>
          <PauseSubmitButton enableForInitialValid>
            <PauseButton color="success">
              Approve
            </PauseButton>
          </PauseSubmitButton>
        </Grid>
      </Grid>
    );
  }

  if ([CompOffFormState.WaitingForDecline].includes(state)) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <PauseButton
            variant="outlined"
            onClick={() => onChangeCompOffState(CompOffFormState.ApproveOrDecline)}
          >
            Back
          </PauseButton>
        </Grid>
        <Grid item xs={6}>
          <PauseSubmitButton enableForInitialValid>
            <PauseButton color="error">Decline</PauseButton>
          </PauseSubmitButton>
        </Grid>
      </Grid>
    );
  }

  if ([CompOffFormState.New].includes(state)) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <PauseButton
            variant="outlined"
            onClick={onClose}
            color="error"
          >
            Discard
          </PauseButton>
        </Grid>
        <Grid item xs={6}>
          <PauseSubmitButton>
            <PauseButton>Apply</PauseButton>
          </PauseSubmitButton>
        </Grid>
      </Grid>
    );
  }

  if ([CompOffFormState.Deletable].includes(state)) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PauseSubmitButton enableForInitialValid>
            <PauseButton
              color="error"
            >
              Delete
            </PauseButton>
          </PauseSubmitButton>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PauseButton
          onClick={onClose}
          variant="outlined"
        >
          Close
        </PauseButton>
      </Grid>
    </Grid>
  );
};

export const CompOffForm = (props: {
  me: any,
  approver: any,
  state: CompOffFormState,
  compOffState: string,
  actioner: any,
}) => {
  const {
    me,
    approver,
    state = CompOffFormState.Readonly,
    compOffState,
    actioner,
  } = props;
  const approverString = useMemo(() => {
    if (state === CompOffFormState.New) {
      if (me?.id === approver?.id) {
        return 'Auto-approved';
      }
      return `Approver: ${approver?.fullName}`;
    }
    return compOffState !== 'CANCELED' ? `Approver: ${approver?.fullName}` : `Canceled by ${actioner?.fullName}`;
  }, [state, me, approver]);
  // const isMeApprover = (me?.roles?.approver?.teams || []).length;
  // const isMeAdmin = me?.isAdmin;
  return (
    <div css={(theme) => ({
      background: theme.get('palette.pause.background.light'),
      minHeight: '100%',
    })}
    >
      <div css={(theme) => ({
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(0.5),
      })}
      >
        <PauseTypography variant="caption" color="pause.primary.medium">
          {approverString}
        </PauseTypography>
      </div>
      <PauseFormGroupCardsList>
        {/* {(![CompOffFormState.New].includes(state)) && ( */}
        {/* <PauseFormGroupCard> */}
        {/*  <PauseFormField name="applicant"> */}
        {/*    <PauseInputGeneric */}
        {/*      disabled */}
        {/*      // disabled={![CompOffFormState.New].includes(state) || (!isMeApprover || !isMeAdmin)} */}
        {/*      startIcon={<User />} */}
        {/*      type="autocomplete" */}
        {/*      placeholder="Select a person" */}
        {/*      labelKey="fullName" */}
        {/*      options={[]} */}
        {/*      dropdownItemComponent={<PausePerson />} */}
        {/*    /> */}
        {/*  </PauseFormField> */}
        {/* </PauseFormGroupCard> */}
        {/* )} */}
        <PauseFormGroupCard>
          <PauseFormField name="numberOfDays">
            <PauseInputGeneric
            // @ts-ignore
              disabled={[CompOffFormState.Readonly,
                CompOffFormState.ApproveOrDecline,
                CompOffFormState.WaitingForDecline,
                CompOffFormState.Deletable].includes(state)}
              startIcon={<Calendar />}
              type="number"
              placeholder="Select number of days"
              inputProps={{
                min: 0,
                step: 0.25,
              }}
            />
          </PauseFormField>
        </PauseFormGroupCard>
        <PauseFormGroupCard>
          <PauseFormField name="applicationReason">
            <PauseInputGeneric
            // @ts-ignore
              startIcon={<AlignRight />}
              disabled={[CompOffFormState.Readonly,
                CompOffFormState.ApproveOrDecline,
                CompOffFormState.WaitingForDecline,
                CompOffFormState.Deletable].includes(state)}
              type="textarea"
              rows={4}
              placeholder="Add reason and dates for comp-off"
            />
          </PauseFormField>
        </PauseFormGroupCard>
      </PauseFormGroupCardsList>
    </div>
  );
};
