import React from 'react';
import Link from 'next/link';
import { PauseTextButton } from '../../../PauseTextButton';

export const PauseTableTitleActions = ({ actions }: {
  actions: {
    icon: JSX.Element,
    text: string,
    href?: string | {
      pathname: string
      query: {
        journey: string,
        stop: string
      }
    },
    onClick?: (any?) => any,
  }[]
}) => (
  <div css={(theme) => ({
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'end',
    gridGap: theme.spacing(2),
    gridAutoFlow: 'column',
  })}
  >
    {actions.map(({
      icon, href, text, onClick,
    }) => {
      const commonButtonProps: {
        size: string,
        startIcon: JSX.Element,
        color: 'primary' | 'inherit' | 'secondary'
      } = {
        size: 'medium',
        startIcon: icon,
        color: 'primary',
      };
      return (href ? (
        <Link key={text} href={href || ''} scroll={false}>
          <PauseTextButton {...commonButtonProps}>
            {text}
          </PauseTextButton>
        </Link>
      )
        : (
          <PauseTextButton
            key={text}
            onClick={onClick}
            {...commonButtonProps}
          >
            {text}
          </PauseTextButton>
        ));
    })}
  </div>
);
