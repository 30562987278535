import React, { useContext, useState } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { TabPanel, TabContext } from '@material-ui/lab';
import { get } from 'lodash';
import { PauseTypography } from '../PauseTypography';

export const useTabs = ({ initActiveTab }) => {
  const [activeTab, setActiveTab] = useState(initActiveTab);
  const changeTab = (newTab) => {
    setActiveTab(newTab);
  };
  React.useEffect(() => {
    setActiveTab(initActiveTab);
  }, [initActiveTab]);
  return { value: activeTab, onChange: changeTab };
};

const PauseTabsContext = React.createContext();

export const PauseTabs = ({
  value, onChange, children, size = 'default',
}) => (
  <PauseTabsContext.Provider value={{ value, size, onChange }}>
    <TabContext value={value}>
      {children}
    </TabContext>
  </PauseTabsContext.Provider>
);

export const PauseTabsList = (props) => {
  const { value, onChange } = useContext(PauseTabsContext);
  const css = (theme) => ({
    '.MuiTabs-indicator': {
      height: theme.spacing(0.5),
      background: get(theme, 'palette.pause.primary.main'),
    },
  });
  return <Tabs css={css} {...props} value={value} onChange={(_, v) => onChange(v)} />;
};

export const PauseTab = ({
  label, count = null, ...rest
}) => {
  const { size } = useContext(PauseTabsContext);
  const hasCount = ![undefined, null].includes(count);
  const normalizedCount = count > 99 ? '99+' : `${count}`;
  const variants = {
    small: 'body2',
    default: 'title',
    medium: 'body1',
  };
  const CountComponent = normalizedCount > 0 ? (
    <PauseTypography
      variant="captionSmall"
      css={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...(size === 'small' && {
          width: theme.spacing(2.5),
          height: theme.spacing(2.5),
          marginLeft: theme.spacing(0.5),
        }),
        ...(size === 'default' && {
          width: theme.spacing(3.5),
          height: theme.spacing(3.5),
          marginLeft: theme.spacing(1),
        }),
        borderRadius: theme.spacing(0.5),
        background: get(theme, 'palette.pause.primary.main'),
        color: get(theme, 'palette.pause.background.light'),
        textAlign: 'center',

      })}
    >
      {normalizedCount}
    </PauseTypography>
  ) : <></>;
  const LabelComponent = (
    <div
      css={(theme) => ({
        display: 'grid',
        gridAutoFlow: 'column',
        gridGap: theme.spacing(0.5),
        justifyContent: 'start',
        alignItems: 'center',
      })}
    >
      <PauseTypography variant={variants[size] || variants.default} css={{ fontWeight: 'bold' }}>
        {label}
      </PauseTypography>
      {hasCount && CountComponent}
    </div>
  );
  return (
    <Tab
      css={(theme) => ({
        textTransform: 'unset',
        '&.MuiTab-root': {
          minWidth: 'auto !important',
          paddingRight: theme.spacing(3),
          paddingLeft: theme.spacing(3),
          paddingBottom: theme.spacing(2),
          paddingTop: theme.spacing(2),
          color: get(theme, 'palette.pause.primary.light'),
          transition: 'all 0.2s ease-in-out',
          '&.Mui-selected': {
            color: get(theme, 'palette.pause.primary.main'),
          },
          '&:hover': {
            color: get(theme, 'palette.pause.primary.main'),
          },
        },
        '&.MuiTab-textColorInherit': {
          opacity: 1,
        },
      })}
      disableFocusRipple
      disableRipple
      label={LabelComponent}
      {...rest}
    />
  );
};

export const PauseTabPanel = ({ value, children }) => (
  <TabPanel value={value} css={() => ({ padding: 0 })}>
    {children}
  </TabPanel>
);
