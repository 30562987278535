import React, { useMemo } from 'react';
import { Typography, useTheme } from '@material-ui/core';

export const DEFAULT_TYPOGRAPHY_VARIANT = 'body1';

export const ALLOWED_TYPOGRAPHY_VARIANTS = [
  'display',
  'header',
  'title',
  'subtitle',
  'button',
  'caption',
  'number',
  'body1',
  'body2',
  'captionSmall',
  'captionSmallUppercase',
];

const onMobile = '@media (max-width:599.95px)';

export const variants = {
  display: {
    fontFamily: 'Concourse T6',
    fontWeight: 500,
    lineHeight: 1.22,
    fontSize: '3rem',
    [onMobile]: {

    },
  },
  header: {
    fontFamily: 'Concourse T6',
    fontWeight: 500,
    lineHeight: 1.11,
    fontSize: '2.25rem',
    [onMobile]: {
      lineHeight: 1.21,
      fontSize: '1.5rem',
    },
  },
  title: {
    fontFamily: 'Concourse T6',
    fontWeight: 500,
    lineHeight: 1.25,
    fontSize: '1.5rem',
    [onMobile]: {
      lineHeight: 1.21,
      fontSize: '1.25rem',
    },
  },
  subtitle: {
    fontFamily: 'Concourse C6',
    fontWeight: 500,
    lineHeight: 0.88,
    fontSize: '1.125rem',
    fontFeatureSettings: "'tnum' on, 'lnum' on",
    // letterSpacing: '0.03rem',
    fontVariant: 'all-small-caps',
    textTransform: 'lowercase',
    [onMobile]: {
      lineHeight: 1.28,
      fontSize: '0.875rem',
    },
  },
  button: {
    fontFamily: 'Concourse T6',
    fontWeight: 500,
    lineHeight: 1.21,
    fontSize: '1rem',
    [onMobile]: {
      lineHeight: 1.25,
      fontSize: '1rem',
      textTransform: 'uppercase',
      fontVariant: 'all-small-caps',
    },
  },
  caption: {
    fontFamily: 'Concourse C6',
    fontWeight: 500,
    lineHeight: 1.14,
    fontSize: '0.875rem',
    fontVariant: 'all-small-caps',
    textTransform: 'lowercase',
    [onMobile]: {
      lineHeight: 1,
      fontSize: '0.875rem',
      fontVariant: 'all-small-caps',
    },
  },
  number: {
    fontFamily: 'Concourse C6',
    textTransform: 'lowercase',
    fontWeight: 500,
    lineHeight: 1.22,
    fontSize: '0.875rem',
    fontFeatureSettings: "'tnum' on, 'lnum' on",
    [onMobile]: {

    },
  },
  body1: {
    fontFamily: 'Concourse T4',
    fontWeight: 400,
    lineHeight: 1.4,
    fontSize: '1rem',
    [onMobile]: {
      lineHeight: 1.5,
      fontSize: '1rem',
    },
  },
  body2: {
    fontFamily: 'Concourse T4',
    fontWeight: 400,
    lineHeight: 1.28,
    fontSize: '0.875rem',
    [onMobile]: {
      lineHeight: 1.5,
      fontSize: '0.875rem',
    },
  },
  // TODO - Remove variant and all usages
  captionSmall: {
    fontFamily: 'Concourse C6',
    textTransform: 'lowercase',
    fontWeight: 500,
    lineHeight: 1.22,
    fontSize: '0.75rem',
    [onMobile]: {

    },
  },
  // TODO - Remove variant and all usages
  captionSmallUppercase: {
    fontFamily: 'Concourse C6',
    textTransform: 'uppercase',
    fontWeight: 500,
    lineHeight: 1.22,
    fontSize: '0.75rem',
    [onMobile]: {

    },
  },
};

export const getTypographyVariant = (variant) => variants[variant];

// We are entirely bypassing variants from Material Typography and overriding
// styles with emotion. This isn't ideal but we'll wait for Mui v5 for
// custom variants support for typography.
// TODO - only allow some colors that make sense
interface Props extends React.PropsWithChildren<any> {
  children: any,
  color?: string,
  variant?: string,
  dontWrapText?: boolean,
  component?: any,
  dontBreakWord?: boolean
}
export const PauseTypography = React.forwardRef<any, Props>(({
  children,
  color = 'primary',
  variant = 'body1',
  component = 'div',
  dontWrapText = false,
  dontBreakWord = false,
  ...props
}: Props, ref) => {
  const theme = useTheme();
  const typographyVariant = getTypographyVariant(variant);
  const computedVariant = typographyVariant
          || getTypographyVariant(DEFAULT_TYPOGRAPHY_VARIANT);

  /* @ts-ignore */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const computedColor = useMemo(() => (['inherit', 'currentColor'].includes(color) ? color : theme.get(`palette.${color}`)), [color]);
  return (
    <Typography
      ref={ref}
      component={component}
      variant="body1"
      css={{
        ...computedVariant,
        color: computedColor,
        ...(dontWrapText ? {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '100%',
        } : {
          hyphens: dontBreakWord ? 'none' : 'auto',
          wordWrap: 'break-word',
          whiteSpace: 'normal !important',
          maxWidth: '100%',
        }),
      }}
      {...props}
    >
      {children}
    </Typography>
  );
});
PauseTypography.defaultProps = {
  color: 'primary',
  variant: 'body1',
  dontWrapText: false,
};
