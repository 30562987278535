/* eslint-disable react/require-default-props */
import React, { MouseEventHandler, useCallback, useMemo } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useBaseStyles, useShapeBasedStyles, useVariantBasedStyles } from './utils';
import { PauseTypography } from '../PauseTypography';

interface Props {
  color?: string,
  startIcon?: JSX.Element,
  variant?: 'contained' | 'outlined',
  isAttachedToLeft?: boolean,
  isAttachedToRight?: boolean,
  children: any,
  disabled?: boolean,
  isLoading?: boolean,
  size?: string,
  href?: string | undefined,
  onClick?: MouseEventHandler<HTMLButtonElement>,
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>,
  [key: string]: any
}

const ButtonComponent: React.FC<Props> = React.memo(({
  color = 'primary',
  startIcon = <></>,
  variant = 'contained',
  isAttachedToLeft = false,
  isAttachedToRight = false,
  children = <></>,
  disabled = false,
  isLoading = false,
  size = 'default',
  onClick = () => null,
  onMouseEnter = () => null,
  ...otherProps
}: Props) => {
  const sizeBasedMinHeight = {
    default: 5,
    small: 4,
  }[size];
  // @ts-ignore
  const variantBasedStyles = useVariantBasedStyles({ variant, color });
  const shapeBasedStyles = useShapeBasedStyles({ isAttachedToLeft, isAttachedToRight });
  const baseStyles = useBaseStyles();
  const buttonStyles = (theme) => ({
    ...baseStyles.defaultStyles,
    minHeight: theme.spacing(sizeBasedMinHeight || 5),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    ...variantBasedStyles,
    ...shapeBasedStyles,
  });
  return (
    <button
      type="button"
      disabled={isLoading || disabled}
      css={buttonStyles}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      {...otherProps}
    >

      <PauseTypography variant="button">
        <div css={(theme) => ({
          display: 'grid',
          gridGap: theme.spacing(1),
          alignItems: 'center',
          gridAutoFlow: 'column',
        })}
        >
          {isLoading ? (
            <CircularProgress
              size="1em"
              // @ts-ignore
              color="currentColor"
            />
          ) : startIcon}
          {children}
        </div>
      </PauseTypography>
    </button>
  );
});

export const PauseButton: React.FC<Props> = React.memo(({
  href, onClick, onMouseEnter, ...props
}: Props) => {
  const memoizedProps: Props = useMemo(() => props, [props]);
  const memoizedOnClick = useCallback((e) => onClick && onClick(e), [onClick]);
  const memoizedOnMouseEnter = useCallback((e) => onMouseEnter && onMouseEnter(e), [onMouseEnter]);
  return (
    /* if there is a href, we assume that the developer intends to place an external link
      * and open it in a new tab/window */
    !memoizedProps.disabled && href ? (
      <a href={href} rel="noreferrer" target="_blank">
        <ButtonComponent
          onMouseEnter={memoizedOnMouseEnter}
          onClick={memoizedOnClick}
          {...memoizedProps}
        />
      </a>
    ) : (
      <ButtonComponent
        onMouseEnter={memoizedOnMouseEnter}
        onClick={memoizedOnClick}
        {...memoizedProps}
      />
    )
  );
});
