export default function fromEntries(entries: Array<[string, any]>) {
  if (Object.fromEntries) return Object.fromEntries(entries);

  const obj = {};
  let i = 0;
  let k;
  let v;
  while (i < entries.length) {
    [k, v] = entries[i];
    obj[k] = v;
    i += 1;
  }
  return obj;
}
