/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { get } from 'lodash';
import Link from 'next/link';
import { PauseMainNav, PauseMainNavItemsGroup } from '../components/PauseMainNav';
import { PauseAvatar } from '../components/PauseAvatar';
import { PauseIconButton } from '../components/PauseIconButton';
import {
  Calendar, Users, Mail, Settings, Book, Info, MessageCircle,
} from '../../assets/icons/feather/components';
import { NOTIFICATIONS_JOURNEY_KEY, NOTIFICATIONS_JOURNEY_VIEW_KEY, useEnhancedRouter } from '../../router';
import { useNotificationCount } from '../integrated-components/notifications/store';
import { usePeople } from '../api/people';
import { useMe } from '../api/me';
import { useOrg } from '../api/org';
import { PauseSimpleTooltip } from '../components/PauseSimpleTooltip';
import Tree from '../../assets/icons/pause-custom/components/Tree';
import { PauseTypography } from '../components/PauseTypography';
import { PauseDropdown } from '../components/PauseDropdown';

const HelpDropdownItem = (props: {
  icon: any,
  title: string,
  description: string,
  onClick?: () => any,
}) => {
  const {
    icon: Icon,
    title,
    description,
    onClick,
  } = props;
  return (
    <div
      css={(t) => ({
        cursor: (onClick !== undefined) ? 'pointer' : 'inherit',
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: '32px auto',
        gridGap: t.spacing(2),
        alignItems: 'center',
        padding: t.spacing(2),
        borderBottom: `1px solid ${get(t, 'palette.pause.background.dark')}`,
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          background: `${get(t, 'palette.pause.background.medium')}`,
        },
      })}
      onClick={onClick}
    >
      <div>
        <Icon css={(t) => ({
          width: t.spacing(4),
          height: t.spacing(4),
          stroke: get(t, 'palette.pause.primary.medium'),
        })}
        />
      </div>
      <div>
        <PauseTypography>{title}</PauseTypography>
        <PauseTypography
          variant="body2"
          color="pause.primary.medium"
        >
          {description}
        </PauseTypography>
      </div>
    </div>
  );
};
const HelpDropdownContent = (props: {
  onHide: () => any,
}) => {
  const {
    onHide = () => null,
  } = props;
  return (
    <div>
      <HelpDropdownItem
        icon={Book}
        title="Read support docs"
        description="Find detailed guides to Pause features"
        onClick={() => {
          onHide();
          window.open('https://support.getpause.com/');
        }}
      />
      <HelpDropdownItem
        icon={MessageCircle}
        title="Chat with us"
        description="Get answers in minutes (8am-8pm IST)"
        onClick={() => {
          onHide();
          return window?.$crisp && window?.$crisp.push(['do', 'chat:open']);
        }}
      />
    </div>
  );
};

const activeTabRouteMap = {
  home: '/',
  checklist: '/home',
  leavePolicy: '/leave-policy',
  members: '/members',
  teams: '/teams',
  invite: '/invite',
  notifications: '/',
  profile: '/profile/me',
  settings: '/settings',
  // archived: '/archived-people',
  archived: '/archived-members',
};
export const PauseMainNavigation = ({
  topOffset = 0,
}: {
  topOffset: number;
}) => {
  const { me: person, loading: meLoading } = useMe();
  const { orgPaymentPending } = useOrg();
  const router = useEnhancedRouter();
  const { people, loading: loadingPeople } = usePeople();
  const { pathname } = router;
  const activeTab = router.isStopActive(
    NOTIFICATIONS_JOURNEY_KEY, NOTIFICATIONS_JOURNEY_VIEW_KEY,
  ) ? 'notifications' : pathname;
  const isAdmin = React.useMemo(() => get(person, 'isAdmin'), [person]);
  const { count } = useNotificationCount();
  // const count = {};
  const hasUnreadNotification = React.useMemo(() => (
    get(count, 'approvals') > 0
    || get(count, 'requests') > 0
    || get(count, 'others') > 0
  ), [count]);
  const [isHelpDropdownOpen, setIsHelpDropdownOpen] = useState<boolean>(false);

  const hideNotificationsLink = orgPaymentPending;

  return (
    <div css={{
      position: 'fixed',
      left: 0,
      right: 0,
      top: `${topOffset}px`,
      zIndex: 1200,
    }}
    >
      <PauseMainNav>
        <PauseMainNavItemsGroup>
          <Link passHref href={activeTabRouteMap.checklist}>
            <PauseSimpleTooltip enterDelay={100} title="Quick start" aria-label="Quick start">
              <a
                css={(theme) => ({
                  display: 'flex',
                  padding: theme.spacing(1.5),
                  borderRadius: theme.spacing(2),
                  alignItems: 'center',
                  justifyContent: 'center',
                  ...(activeTab === activeTabRouteMap.checklist && { background: get(theme, 'palette.pause.background.dark') }),
                })}
              >
                <img
                  css={(theme) => ({
                    width: '30px',
                    height: '30px',
                    borderRadius: theme.spacing(1),
                    border: `2px solid ${activeTab === activeTabRouteMap.checklist ? get(theme, 'palette.pause.primary.light') : 'transparent'}`,
                  })}
                  src="/images/pause_logo.png"
                />
              </a>
            </PauseSimpleTooltip>
          </Link>
          <Link passHref href={activeTabRouteMap.home}>
            <PauseSimpleTooltip enterDelay={100} title="Calendar" aria-label="Calendar">
              <a>
                <PauseIconButton
                  // @ts-ignore
                  isActive={!isHelpDropdownOpen && (activeTab === '/')}
                  size="large"
                  variant="primaryAlt"
                  icon={<Calendar />}
                />
              </a>
            </PauseSimpleTooltip>
          </Link>
          <Link passHref href={activeTabRouteMap.members}>
            <PauseSimpleTooltip enterDelay={100} title="Members/Teams" arial-label="Members and Teams">
              <a>
                <PauseIconButton
                  // @ts-ignore
                  isActive={!isHelpDropdownOpen && [activeTabRouteMap.members, activeTabRouteMap.archived, activeTabRouteMap.teams].includes(activeTab)}
                  size="large"
                  variant="primaryAlt"
                  icon={<Users />}
                />
              </a>
            </PauseSimpleTooltip>
          </Link>
          <Link passHref href={activeTabRouteMap.leavePolicy}>
            <PauseSimpleTooltip enterDelay={100} title="Leave Policy" aria-label="Leave Policy">
              <a>
                <PauseIconButton
                  // @ts-ignore
                  isActive={!isHelpDropdownOpen && (activeTab === activeTabRouteMap.leavePolicy)}
                  size="large"
                  variant="primaryAlt"
                  icon={<Tree />}
                />
              </a>
            </PauseSimpleTooltip>
          </Link>
        </PauseMainNavItemsGroup>
        <PauseMainNavItemsGroup>
          {/* @ts-ignore */}
          <PauseDropdown
            id="help"
            isOpen={isHelpDropdownOpen}
            onClose={() => setIsHelpDropdownOpen(false)}
            dropdownContent={<HelpDropdownContent onHide={() => setIsHelpDropdownOpen(false)} />}
            autoWidth
            disableScrollLock
          >
            <PauseIconButton
              // @ts-ignore
              variant="primaryAlt"
              size="large"
              icon={<Info />}
              onClick={() => {
                router.endJourney();
                setIsHelpDropdownOpen(true);
              }}
              isActive={isHelpDropdownOpen}
            />
          </PauseDropdown>
          {!hideNotificationsLink && (
            <Link
              passHref
              href={router.getStopHref(
                NOTIFICATIONS_JOURNEY_KEY, NOTIFICATIONS_JOURNEY_VIEW_KEY,
              )}
              scroll={false}
            >
              <PauseSimpleTooltip enterDelay={100} title="Notifications" aria-label="Notifications">
                <a>
                  <PauseIconButton
                    // @ts-ignore
                    isActive={!isHelpDropdownOpen && (activeTab === 'notifications')}
                    // onClick={toggleNotifications}
                    size="large"
                    variant="primaryAlt"
                    withBadge={hasUnreadNotification}
                    icon={<Mail />}
                  />
                </a>
              </PauseSimpleTooltip>
            </Link>
          )}
          {isAdmin && (
            <Link passHref href={activeTabRouteMap.settings}>
              <PauseSimpleTooltip enterDelay={100} title="Settings" aria-label="Settings">
                <a>
                  <PauseIconButton
                    // @ts-ignore
                    isActive={!isHelpDropdownOpen && activeTab.includes('/settings')}
                    size="large"
                    variant="primaryAlt"
                    icon={<Settings />}
                  />
                </a>
              </PauseSimpleTooltip>
            </Link>
          )}
          <Link passHref href={activeTabRouteMap.profile} scroll={false}>
            <PauseSimpleTooltip enterDelay={100} title="Profile" aria-label="Profile">
              <a css={() => ({
                textDecoration: 'none',
                cursor: 'pointer',
              })}
              >
                <PauseAvatar
                  size="xlarge"
                  color={get((people || []).find((p) => p.id === person?.id), 'color')}
                  {...(!meLoading && !loadingPeople && ({
                    src: person?.image,
                  }))}
                />
              </a>
            </PauseSimpleTooltip>
          </Link>
        </PauseMainNavItemsGroup>
      </PauseMainNav>
    </div>
  );
};
