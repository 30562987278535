import React, { ReactNode } from 'react';
import { TableHead, TableCell } from '@material-ui/core';
import { PauseTypography } from '../../../PauseTypography';

interface PauseTableHeadProps {
  children: ReactNode
  [x:string]: any
}

export const PauseTableHead = ({ children, ...props }: PauseTableHeadProps) => (
  <TableHead
    css={(theme) => ({
      background: theme.get('palette.pause.background.medium'),
      border: 'none',
    })}
    {...props}
  >
    {children}
  </TableHead>
);

interface PauseTableHeadCellProps {
  children: ReactNode
  width?: string,
  custom?: boolean,
  [x:string]: any
}

export const PauseTableHeadCell = ({
  children, width = 'auto', custom = false, ...props
}: PauseTableHeadCellProps) => (
  <TableCell
    css={(theme) => ({
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      border: 'none',
      width,
    })}
    {...props}
  >
    {custom ? <>{children}</> : (
      <PauseTypography variant="caption" color="pause.primary.medium">
        {children}
      </PauseTypography>
    )}
  </TableCell>
);
