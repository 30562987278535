import React from 'react';
import { PauseTextButton } from '../PauseTextButton';

export const PauseSnackbar = ({
  children,
  // TODO - implement variant if and when necessary
  variant = 'default', // type: enum :: ['default', 'error']
  hideButton = false, buttonText = 'Done', onButtonClick = () => {},
}) => (
  <div css={(theme) => ({
    backgroundColor: theme.get('palette.pause.primary.dark'),
    borderRadius: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    gridGap: theme.spacing(5),
    minWidth: '12.5rem',
    boxShadow: theme.pauseShadows['8'],
  })}
  >
    <div css={(theme) => ({
      color: theme.get('palette.pause.background.light'),
    })}
    >
      {children}
    </div>
    {!hideButton && (
    <PauseTextButton
      color="secondary"
      compact
      onClick={onButtonClick}
    >
      {buttonText}
    </PauseTextButton>
    )}

  </div>
);
