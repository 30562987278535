import React from 'react';
import { get } from 'lodash';
import { Book, Video } from '../../../assets/icons/feather/components';
import { PauseTypography } from '../PauseTypography';

export enum Type {
  Text= 'text',
  Video='video'
}

const IconComponent = {
  [Type.Text]: Book,
  [Type.Video]: Video,
};

export const HowtoLink = (props: {
  type?: Type,
  linkText: string,
  link: string,
  typographyProps?: (any?) => any,
}) => {
  const {
    type = Type.Text,
    linkText = 'Support docs',
    link = 'https://support.getpause.com',
    typographyProps = () => null,
  } = props;
  const LinkIcon = get(IconComponent, type, Book);
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      <PauseTypography
        css={(t) => ({
          ...typographyProps(t),
          display: 'inline-flex',
          alignItems: 'center',
          gridGap: t.spacing(0.5),
          cursor: 'pointer',
          '&:hover': {
            color: get(t, 'palette.pause.primary.dark'),
          },
        })}
        variant="body2"
        color="pause.primary.main"
      >
        <LinkIcon css={(t) => ({
          width: t.spacing(2),
          height: t.spacing(2),
        })}
        />
        {linkText}
      </PauseTypography>
    </a>
  );
};
