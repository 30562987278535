import { lighten, darken } from 'polished';

const baseColors = {
  primary: {
    dark: '#332966',
    main: '#4A36B3',
    medium: '#857DB3',
    light: '#C4BAF5',
  },
  primaryLight: {
    dark: '#857DB3',
    medium: '#857DB3',
    main: '#C4BAF5',
    light: '#C4BAF5',
  },
  background: {
    dark: '#EDEBFF',
    medium: '#F7F5FF',
    light: '#FBFAFF',
  },
  secondary: {
    main: '#FF8C19',
    dark: '#FF8C19',
    light: '#FF8C19',
    medium: '#FF8C19',
  },
  success: {
    main: '#68D682',
  },
  error: {
    main: '#C93830',
  },
  white: {
    main: '#FFFFFF',
  },
  cyan: {
    dark: '#59B3B3',
    light: '#E1F5F5',
  },
  pink: {
    dark: '#CC5299',
    light: '#F5E1F5',
  },
  peach: {
    dark: '#E65C5C',
    light: '#FFE0E0',
  },
  orange: {
    dark: '#FF8C1A',
    light: '#FFF0E0',
  },
  blue: {
    dark: '#45BBE6',
    light: '#E0F6FE',
  },
  green: {
    dark: '#52CC52',
    light: '#E1F5E1',
  },
  purple: {
    dark: '#9973E6',
    light: '#F1EBFF',
  },
  yellow: {
    dark: '#F5B400',
    light: '#FFF4D6',
  },
  grey: {
    low: '#BCC4DA',
  },
};

export const colors = {
  ...baseColors,
  secondary: {
    ...baseColors.secondary,
    shades: {
      lightest: lighten(0.42, baseColors.secondary.main),
      lighter: lighten(0.25, baseColors.secondary.main),
      darker: darken(0.05, baseColors.secondary.main),
    },
  },
  primary: {
    ...baseColors.primary,
    shades: {
      lightest: lighten(0.52, baseColors.primary.main),
      lighter: lighten(0.25, baseColors.primary.main),
      darker: darken(0.05, baseColors.primary.main),
    },
  },
  error: {
    ...baseColors.error,
    shades: {
      lightest: lighten(0.5, baseColors.error.main),
      lighter: lighten(0.25, baseColors.error.main),
      darker: darken(0.05, baseColors.error.main),
    },
  },
  success: {
    ...baseColors.success,
    shades: {
      lightest: lighten(0.5, baseColors.success.main),
      lighter: lighten(0.25, baseColors.success.main),
      darker: darken(0.05, baseColors.success.main),
    },
  },
};

export const expressiveColors = ['cyan', 'pink', 'peach', 'orange', 'blue', 'green', 'purple', 'yellow'] as const;
export type ExpressiveColors = (typeof expressiveColors)[number]
