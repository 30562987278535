import React, { useEffect, useRef, useState } from 'react';
import { Avatar } from '@material-ui/core';
import { ExpressiveColors } from '../PauseTheme/colors';
import { User } from '../../../assets/icons/feather/components';

export const PauseAvatar = ({
  src, size = 'small', color, responsive = false,
}: {
    src?: string,
    size: 'large' | 'small' | 'xlarge' | number,
    color: ExpressiveColors,
    responsive?: boolean
}) => {
  const ref = useRef<any>(null);
  const [width, setWidth] = useState('auto');

  useEffect(() => {
    if (ref && ref.current) {
      setWidth(ref.current.clientHeight);
    }
  }, [ref]);

  const sizeIncludesKeyedList = (['small', 'large', 'xlarge'] as Array<number | string>).includes(size);

  const computedSize = sizeIncludesKeyedList ? {
    small: 3,
    large: 5,
    xlarge: 6.875,
  }[size] : size;

  const computedBorderRadius = sizeIncludesKeyedList ? {
    small: 0.75,
    large: 1.25,
    xlarge: 2,
  }[size] : 0;

  const resizedImage = (originalImage?: string) => {
    if (!originalImage) return '';
    const sizeBasedParams = sizeIncludesKeyedList ? 'c_thumb,g_center,w_100,h_100' : 'c_thumb,g_center,w_200,h_200';
    return originalImage.replace('/upload/', `/upload/q_auto,${sizeBasedParams}/`);
  };

  return (
    <Avatar
      ref={ref}
      css={(theme) => ({
        zIndex: 2,
        width: responsive ? width : theme.spacing(computedSize),
        height: responsive ? '100%' : theme.spacing(computedSize),
        borderRadius: theme.spacing(computedBorderRadius),
        padding: 0,
        backgroundColor: theme.get(`palette.pause.${color}.light`),
      })}
      src={resizedImage(src)}
    >
      <div css={(theme) => ({
        fontSize: theme.spacing(computedSize as number / 2),
        color: theme.get(`palette.pause.${color}.dark`),
        paddingTop: theme.spacing(0.5),
      })}
      >
        <User />
      </div>
    </Avatar>
  );
};
