import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useMemo } from 'react';
import { useAPI } from '../hooks/useAxios';
import { SubscriptionStatus } from '../types/billing';

export const useOrg = ({ enabled } = { enabled: false }) => {
  const {
    get, put, post,
  } = useAPI();
  const queryClient = useQueryClient();

  const getOrg = () => get({
    url: '/orgs',
  });

  const createOrgAPI = (data) => post({ url: '/orgs', data });

  const editOrgAPI = (data) => put({ url: '/orgs', data });

  const {
    data: org, isLoading, isIdle, error,
  } = useQuery<any>('org', getOrg, {
    enabled,
  });

  const { mutateAsync: editOrg } = useMutation<any>(editOrgAPI, {
    onSuccess: (newData) => {
      queryClient.setQueryData<any>('org', (oldData: any[]) => ({
        ...oldData,
        ...newData,
      }));
    },
  });

  const { mutateAsync: createOrg } = useMutation<any>(createOrgAPI, {
    onSuccess: (newData) => {
      queryClient.setQueryData<any>('org', (oldData: any[]) => ({
        ...oldData,
        ...newData,
      }));
    },
  });

  return {
    org: useMemo(() => (org), [org]),
    loading: isLoading,
    isIdle,
    editOrg,
    createOrg,
    error,
    orgPaymentPending: [
      SubscriptionStatus.Overdue,
      SubscriptionStatus.TrialExpired,
    ].includes(org?.subscription?.status),
  };
};
