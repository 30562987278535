import React from 'react';
import moment from 'moment';
import { DAY_TYPES } from '../../../utils/constants';

export const NOTIFICATION_CONTEXTS = {
  APPROVALS: 'APPROVALS',
  REQUESTS: 'REQUESTS',
  OTHERS: 'OTHERS',
};

const {
  afternoon, lunchtime,
} = DAY_TYPES;

export const ComputeDateRangeString = ({
  startDate,
  endDate,
  startType,
  endType,
}: {
    startDate: moment.MomentInput,
    endDate: moment.MomentInput,
    startType: string,
    endType: string
}) => {
  /**
   * possible combinations and corresponding render string:
   * { startDate, endDate (not eq startDate), startType (start of day), endType (end of day) } = 2 Jan - 3 Jan
   * { startDate, endDate (not eq startDate), startType (start of second half), endType (end of day) } = 2 Jan second half - 3 Jan
   * { startDate, endDate (not eq startDate), startType (start of day), endType (end of first half) } = 2 Jan - 3 Jan first half
   * { startDate, endDate (eq startDate), startType (start of day), endType (end of day) } = 2 Jan
   * { startDate, endDate (eq startDate), startType (start of day), endType (end of first half) } = 2 Jan first half
   * { startDate, endDate (eq startDate), startType (start of second half), endType (end of day) } = 2 Jan second half
   */
  const format = 'DD MMM';
  const startDateString = moment(startDate).format(format);
  const startTypeString = React.useMemo(() => {
    if (startDate !== endDate) {
      return startType === afternoon ? 'second half' : '';
    }
    if (startDate === endDate) {
      return startType === afternoon ? 'second half' : '';
    }
  }, [startDate, endDate, startType]);
  const endDateString = startDate === endDate ? '' : moment(endDate).format(format);
  const endTypeString = React.useMemo(() => {
    if (startDate !== endDate) {
      return endType === lunchtime ? 'first half' : '';
    }
    if (startDate === endDate) {
      return endType === lunchtime ? 'first half' : '';
    }
  }, [startDate, endDate, endType]);

  return (
    <>
      {startDateString}
      {' '}
      {startTypeString}
      {' '}
      {endDateString && (<>&mdash;</>)}
      {' '}
      {endDateString}
      {' '}
      {endTypeString}
    </>
  );
};

export const NOTIFICATION_DETAIL_STATES = {
  APPROVALS_APPROVED: 'APPROVALS_APPROVED',
  APPROVALS_REJECTED: 'APPROVALS_REJECTED',
  APPROVALS_PENDING: 'APPROVALS_PENDING',
  APPROVALS_AWAITING_REJECT_REASON: 'APPROVALS_AWAITING_REJECT_REASON',
  REQUESTS_PENDING: 'REQUESTS_PENDING',
  REQUESTS_APPROVED: 'REQUESTS_APPROVED',
  REQUESTS_REJECTED: 'REQUESTS_REJECTED',
  OTHERS: 'OTHERS',
  REQUESTS_CANCELLED: 'REQUESTS_CANCELLED',
  APPROVALS_CANCELLED: 'APPROVALS_CANCELLED',
};
