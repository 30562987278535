type TriggerDownload = ({
  fileName,
  fileContents,
}: {
  fileName: string,
  fileContents: string
}) => any
export const triggerDownload: TriggerDownload = ({
  fileName,
  fileContents,
}) => {
  const url = window.URL.createObjectURL(new Blob([fileContents]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
