import React from 'react';
import PropTypes from 'prop-types';
import { PauseTypography } from '../PauseTypography';
import { PauseDivider } from '../PauseDivider';

const CARD_SIDES_PADDING = 3;

export const PauseFormGroupCardItem = ({ children }) => (
  <div css={(theme) => ({
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    '&:first-child': {
      paddingTop: theme.spacing(0),
    },
    '&:last-child': {
      paddingBottom: theme.spacing(0),
    },
  })}
  >
    {children}
  </div>
);

PauseFormGroupCardItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export const PauseFormGroupCardDivider = () => (
  <div css={(theme) => ({
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    width: `calc(100% + ${theme.spacing(CARD_SIDES_PADDING)}px)`,
    // marginLeft: `calc(-${theme.spacing(CARD_SIDES_PADDING)}px)`,
  })}
  >
    <PauseDivider />
  </div>
);

export const PauseFormGroupCard = ({ title, children }) => (
  <div css={(theme) => ({
    display: 'grid',
    background: theme.get('palette.pause.white.main'),
    gridGap: theme.spacing(3),
    boxShadow: theme.pauseShadows['2'],
    padding: theme.spacing(CARD_SIDES_PADDING),
  })}
  >
    {title && (
    <PauseTypography
      css={{
        textTransform: 'lowercase',
        fontVariant: 'small-caps',
      }}
      variant="subtitle"
      color="pause.primary.medium"
    >
      {title}
    </PauseTypography>
    )}
    <div>
      {children}
    </div>
  </div>
);

const ChildrenTypes = [
  PropTypes.objectOf(PauseDivider),
  PropTypes.objectOf(PauseFormGroupCardItem),
];

PauseFormGroupCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([...ChildrenTypes])),
    ...ChildrenTypes,
  ]).isRequired,
};

PauseFormGroupCard.defaultProps = {
  title: null,
};

export const PauseFormGroupCardsList = ({ children }) => (
  <div css={(theme) => ({
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'grid',
    gridGap: theme.spacing(1),
  })}
  >
    {children}
  </div>
);

PauseFormGroupCardsList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PauseFormGroupCard),
    PropTypes.objectOf(PauseFormGroupCard),
  ]).isRequired,
};
