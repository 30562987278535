import moment from 'moment';
import { DAY_TYPES } from './constants';

export const defaultDateStringFormat = 'YYYY-MM-DD';
export const stringifyDate = (d) => moment(d).format('YYYY-MM-DD');

export const dateExistsBetween = ({ startDate, endDate }) => (listOfDatesToCheckIn = []) => {
  if (!startDate || !endDate) return false;
  if (!listOfDatesToCheckIn || !listOfDatesToCheckIn.length) return false;
  return listOfDatesToCheckIn.reduce((accumulatedFinalValue, curr) => accumulatedFinalValue || moment(curr).isBetween(startDate, endDate, undefined, '[]'), false);
};

export const intersectingDates = ({ startDate, endDate }) => (listOfDates = []) => {
  if (!startDate || !endDate || !listOfDates || !listOfDates.length) return [];
  return listOfDates.reduce((acc, current) => (moment(current).isBetween(startDate, endDate, undefined, '[]') ? [].concat(acc, [current]) : acc), []);
};

export const getStartEndDateFor = (mid = undefined) =>
  /** takes: (mid?: Date|String|Moment)
      returns: [DateString, DateString]

      DateString is date in the default date string format defined in this file
   */
  // eslint-disable-next-line implicit-arrow-linebreak
  [moment(mid).subtract(1, 'month').format(defaultDateStringFormat),
    moment(mid).add(1, 'month').format(defaultDateStringFormat)];

// converts xx:xx:xx into a human-friendly time
export const getFriendlyTime = (time) => moment(time, 'HH:mm:ss').format('h:mm A');

/** takes some inputs and tells whether the leave is feasible for the startType and endType
 * if it returns true, the leave is feasible. if not, not feasible.
 * @param startDate - moment | string
 * @param endDate - moment | string
 * @param startType - string / enum
 * @param endType - string / enum
 * @returns {boolean}
 */
export const isValidLeaveRange = ({
  startDate, endDate, startType, endType,
}) => {
  const { afternoon, lunchtime } = DAY_TYPES;
  if (stringifyDate(startDate) > stringifyDate(endDate)) { return false; }
  if (
    (stringifyDate(startDate) === stringifyDate(endDate))
          && (startType === afternoon)
          && (endType === lunchtime)
  ) return false;
  return true;
};

export const smartDateRange = ({ startDate, endDate }) => {
  if (!endDate) {
    return startDate.format('MMM YYYY');
  }
  if (startDate.format('MM-YYYY') === endDate.format('MM-YYYY')) {
    return startDate.format('MMM YYYY');
  }
  if (startDate.format('YYYY') === endDate.format('YYYY')) {
    return `${startDate.format('MMM')} - ${endDate.format('MMM YYYY')}`;
  }
  return `${startDate.format('MMM YYYY')} - ${endDate.format('MMM YYYY')}`;
};

type GetCycleEndPoints = (a: {
  cycleStartMonth: string,
  today?: moment.Moment | string
}) => { startDate: moment.Moment, endDate: moment.Moment }
export const getCycleEndpoints: GetCycleEndPoints = ({
  cycleStartMonth = 'January',
  today = moment(),
}) => {
  const cycleStartMonthAsNumber = moment(cycleStartMonth, 'MMMM').month();
  const currentMonthAsNumber = moment(today).month();
  const todaysYear = moment(today).year();
  if (currentMonthAsNumber >= cycleStartMonthAsNumber) {
    return ({
      startDate: moment(cycleStartMonth, 'MMMM').set({ year: todaysYear }).startOf('month'),
      endDate: moment(cycleStartMonth, 'MMMM').set({ year: todaysYear }).add({ months: 11 }).endOf('month'),
    });
  }
  return ({
    startDate: moment(cycleStartMonth, 'MMMM').set({ year: todaysYear - 1 }),
    endDate: moment(cycleStartMonth, 'MMMM').set({ year: todaysYear - 1 }).add({ months: 11 })
      .endOf('month'),
  });
};

type isCurrentCycle = (a: {
  today: moment.Moment | string,
  cycleStartMonth: string,
}) => boolean
export const isCurrentCycle: isCurrentCycle = ({
  today,
  cycleStartMonth,
}) => {
  const currentCycleEndpoints = getCycleEndpoints({ cycleStartMonth });
  const isAfterCurrentCycleStartDate = moment(today).isSameOrAfter(currentCycleEndpoints.startDate);
  const isBeforeCurrentCycleEndDate = moment(today).isSameOrBefore(currentCycleEndpoints.endDate);
  return isAfterCurrentCycleStartDate && isBeforeCurrentCycleEndDate;
};
export const isNextCycle = (a: {
  date: moment.Moment | string,
  cycleStartMonth: string,
}): boolean => {
  const { cycleStartMonth, date } = a;
  const currentCycleEndpoints = getCycleEndpoints({ cycleStartMonth });
  const isAfterNextCycleStartDate = moment(date).isSameOrAfter(moment(currentCycleEndpoints.startDate).add(1, 'year'));
  const isBeforeNextCycleEndDate = moment(date).isSameOrBefore(moment(currentCycleEndpoints.endDate).add(1, 'year'));
  return isAfterNextCycleStartDate && isBeforeNextCycleEndDate;
};

const convertDateRangeIntoArrayOfHalfDays = (range: {
  startDate: string;
  endDate: string;
  startType: string;
  endType: string;
}): string[] => {
  let start = range.startType === DAY_TYPES.afternoon
    ? moment(`${range.startDate}`).startOf('day').add(12, 'hours')
    : moment(range.startDate).startOf('day');
  const end = range.endType === DAY_TYPES.lunchtime
    ? moment(`${range.endDate}`).startOf('day').add(12, 'hours')
    : moment(range.endDate).endOf('day');
  const res: Array<string> = [];
  while (start.isBefore(end)) {
    res.push(start.format('YYYY-MM-DD HH:MM:SS'));
    start = start.add(12, 'hours');
  }
  return res;
};

export const intersectingLockedDates = (leaveRange: {
  startDate: string,
  endDate: string,
  startType: string,
  endType: string
}) => (lockedDates: {
  id: string,
  startDate: string,
  startType: string,
  endDate: string,
  endType: string,
  team: {
    id: string,
    name: string
  }
}[]): {
  id: string,
  team: {
    id: string,
    name: string
  },
  date: string,
}[] => {
  /**
   * flatten locked dates, convert into array of half-days
   * convert leave range into array of half-days
   * filter flattened locked dates by those half-dates that are also part of leave range
   * return filtered list
   * */
  const flattenedLockedDatesAsHalfDays: { id: string, team: {
    id: string,
      name: string,
    },
  date: string }[] = lockedDates.reduce((acc, curr) => {
    const intoHalfDays = convertDateRangeIntoArrayOfHalfDays(curr);
    return (acc as any).concat(intoHalfDays.map((date) => ({
      id: curr.id,
      team: {
        ...curr.team,
      },
      date,
    })));
  }, []);
  const leaveRangeAsHalfDayArray = convertDateRangeIntoArrayOfHalfDays(leaveRange);
  return flattenedLockedDatesAsHalfDays.filter((obj) => leaveRangeAsHalfDayArray.includes(obj.date));
};
