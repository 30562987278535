import React from 'react';
import { get } from 'lodash';
import { PauseTableTitle } from '../../../components/PauseTable/components/PauseTableTitle';
import { PauseTable, PauseTableContainer } from '../../../components/PauseTable';
import { PauseTableHead, PauseTableHeadCell } from '../../../components/PauseTable/components/PauseTableHead';
import { PauseTableBody, PauseTableBodyCell, PauseTableRow } from '../../../components/PauseTable/components/PauseTableRow';
import { PauseTypography } from '../../../components/PauseTypography';
import {
  PUBLIC_HOLIDAYS_EDIT_KEY,
  PUBLIC_HOLIDAYS_JOURNEY_KEY,
  useEnhancedRouter,
} from '../../../../router';
import { PauseTableTitleActions } from '../../../components/PauseTable/components/PauseTableTitleActions';
import { Info, Plus } from '../../../../assets/icons/feather/components';
import { PauseSimpleTooltip } from '../../../components/PauseSimpleTooltip';
import { pluralize } from '../../../../utils/strings';
import { HowtoLink, Type } from '../../../components/HowtoLink';

export const PublicHolidaysTable = ({
  isEditable = false,
  people = [],
  calendars = {
    loading: false,
    data: [],
  },
}: Props) => {
  const router = useEnhancedRouter();
  const peopleWithoutCalendars = people.filter((p) => !p.applicablePublicHolidayCalendars?.length);
  const loading = calendars?.loading || false;
  const phCalendars = calendars?.data || [];
  return (
    <div css={(theme) => ({
      background: get(theme, 'palette.pause.white.main'),
    })}
    >
      <PauseTableContainer>
        <PauseTableTitle
          title="Public Holidays"
          {...peopleWithoutCalendars.length && {
            titleHelper: (
              <PauseTypography
                color="pause.error.main"
                variant="body2"
                css={(t) => ({
                  display: 'inline-grid',
                  gridAutoFlow: 'column',
                  gridTemplateColumns: '1fr auto',
                  gridGap: t.spacing(0.5),
                })}
              >
                {peopleWithoutCalendars.length}
                {' '}
                {pluralize('person')('people')(peopleWithoutCalendars.length || 0)}
                {' '}
                {pluralize('has')('have')(peopleWithoutCalendars.length || 0)}
                {' '}
                no holidays assigned
                {/* @ts-ignore */}
                <PauseSimpleTooltip
                  enterDelay={100}
                  arrow
                  title="Please open an existing calendar or create a new one to assign holidays to these members."
                >
                  <span>
                    <Info />
                  </span>
                </PauseSimpleTooltip>
              </PauseTypography>
            ),
          }}
          {...{
            subTitle: (
              <div css={(t) => ({
                display: 'flex',
                gap: t.spacing(2),
              })}
              >
                <HowtoLink
                  linkText="How to setup PH Calendar"
                  link="https://support.getpause.com/en/article/how-to-set-up-a-public-holiday-calendar-7s414k/"
                />
                {/* TODO show this in-app link once video is ready */}
                {false && (
                <HowtoLink
                  linkText="Watch video tutorial"
                  link="https://support.getpause.com"
                  type={Type.Video}
                />
                )}
              </div>
            ),
          }}
          {...isEditable && {
            actions: (
              <PauseTableTitleActions actions={[{
                icon: <Plus />,
                text: 'Add new',
                href: router
                  .getStopHref(PUBLIC_HOLIDAYS_JOURNEY_KEY, PUBLIC_HOLIDAYS_EDIT_KEY),
              }]}
              />
            ),
          }}
        />
        <PauseTable>
          <PauseTableHead>
            <PauseTableRow>
              <PauseTableHeadCell css={{ width: '63%' }}>
                Calendar
              </PauseTableHeadCell>
              <PauseTableHeadCell css={{ width: '20%' }}>
                No. of holidays
              </PauseTableHeadCell>
              <PauseTableHeadCell css={{ width: '15%' }}>
                Assigned to
              </PauseTableHeadCell>
            </PauseTableRow>
          </PauseTableHead>
          <PauseTableBody>
            {loading && (
            <PauseTableRow>
              <PauseTableBodyCell>Loading...</PauseTableBodyCell>
            </PauseTableRow>
            )}
            {!loading && !phCalendars.length && (
            <PauseTableRow>
              <PauseTableBodyCell>
                <PauseTypography color="pause.primary.medium">
                  No public holiday calendars yet.
                </PauseTypography>
              </PauseTableBodyCell>
            </PauseTableRow>
            )}
            {!loading && phCalendars.map((calendar) => (
              <PauseTableRow
                key={calendar.id}
                onClick={() => router.push(router.getStopHref(
                  PUBLIC_HOLIDAYS_JOURNEY_KEY, PUBLIC_HOLIDAYS_EDIT_KEY,
                  { id: calendar.id },
                ), undefined, { scroll: false })}
              >
                <PauseTableBodyCell>
                  <PauseTypography>
                    {calendar?.emoji || ''}
                    {' '}
                    {calendar.name}
                  </PauseTypography>
                </PauseTableBodyCell>
                <PauseTableBodyCell css={{ width: '25%' }}>
                  {calendar?.numberOfHolidaysInCurrentYear || 0}
                  {' '}
                  {pluralize('day')('days')(calendar?.numberOfHolidaysInCurrentYear || 0)}
                </PauseTableBodyCell>
                <PauseTableBodyCell css={{ width: '25%' }}>
                  {calendar?.assignedToPeople?.length || 0}
                  {' '}
                  {pluralize('member')('members')(calendar?.assignedToPeople?.length || 0)}
                </PauseTableBodyCell>
              </PauseTableRow>
            ))}
          </PauseTableBody>
        </PauseTable>
      </PauseTableContainer>
    </div>
  );
};

type Props = {
  isEditable?: boolean,
  calendars: {
    loading: boolean,
    data: any[]
  },
  people: any[]
}
