import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { PauseTableTitle } from '../../../components/PauseTable/components/PauseTableTitle';
import { PauseTableTitleActions } from '../../../components/PauseTable/components/PauseTableTitleActions';
import { Plus } from '../../../../assets/icons/feather/components';
import { PauseTable, PauseTableContainer } from '../../../components/PauseTable';
import { PauseTableHead, PauseTableHeadCell } from '../../../components/PauseTable/components/PauseTableHead';
import { PauseTableBody, PauseTableBodyCell, PauseTableRow } from '../../../components/PauseTable/components/PauseTableRow';
import { PauseTypography } from '../../../components/PauseTypography';
import { PauseToggle } from '../../../components/PauseToggle';
import { LEAVE_TYPES, HELP_LINKS } from '../../../../utils/constants';
import { PauseTag } from '../../../components/PauseTag';
import { PauseConfirmModal } from '../../../components/PauseConfirmModal';
import { DEFAULT_LEAVE_TYPE_DESCRIPTION_MSG } from '../store';
import { HowtoLink, Type } from '../../../components/HowtoLink';
import { LeaveTypeWithAllowance } from '../../../api/leave-types';

const { deductibleLeaveTypes } = HELP_LINKS;

const { ANNUAL } = LEAVE_TYPES;

export const DeductibleLTTable = (props: Props) => {
  const {
    data = [],
    disabled = false,
    onToggleDisable = () => null,
    isEditable = false,
    addNewLeaveTypeURL = '',
    editLeaveType = () => null,
  } = props;
  const [confirmDisableToggleModalOpen, setConfirmDisableToggleModalOpen] = useState(false);
  const [togglingDisabledTo, setTogglingDisabledTo] = useState(!disabled);

  useEffect(() => {
    setTogglingDisabledTo(!disabled);
  }, [disabled]);
  return (
    <div>
      <PauseConfirmModal
        open={confirmDisableToggleModalOpen}
        onCancel={() => setConfirmDisableToggleModalOpen(false)}
        onConfirm={() => onToggleDisable(togglingDisabledTo)
          .then(() => setConfirmDisableToggleModalOpen(false))}
        title={`Are you sure you want to ${togglingDisabledTo ? 'disable' : 'enable'} deductible leaves?`}
        description={`This action will ${togglingDisabledTo ? 'disable' : 'enable'} all deductible leaves for all users!`}
      />
      <div css={(theme) => ({
        background: get(theme, 'palette.pause.white.main'),
      })}
      >
        <PauseTableContainer>
          <PauseTableTitle
            {...isEditable && {
              actions: !disabled && (
                <PauseTableTitleActions actions={[{
                  icon: <Plus />,
                  text: 'Add new',
                  href: addNewLeaveTypeURL,
                }]}
                />
              ),
            }}
            titleHelper={isEditable ? (
              <div>
                <PauseToggle
                  name="Deductible leave types toggle"
                  checked={!disabled}
                  onChange={() => {
                    setConfirmDisableToggleModalOpen(true);
                  }}
                />
              </div>
            ) : null}
            title="Deductible Leaves"
            info={{
              text: 'Deductible leaves have a fixed allowance that employees can use.',
              buttonText: 'Learn More',
              buttonHref: deductibleLeaveTypes,
            }}
            subTitle={(
              <div css={(t) => ({
                display: 'grid',
                alignItems: 'center',
                gridGap: t.spacing(2),
                gridAutoFlow: 'column',
              })}
              >
                <HowtoLink
                  linkText="How do deductibles work?"
                  link="https://support.getpause.com/en/article/how-to-set-up-deductible-leave-types-x0a2kf/"
                />
                {/* TODO show in-app link after video is ready */}
                {false && (
                <HowtoLink
                  linkText="Watch video tutorial"
                  link="https://support.getpause.com"
                  type={Type.Video}
                />
                )}
              </div>
)}
          />
          {!disabled && (
          <PauseTable>
            <PauseTableHead>
              <PauseTableRow>
                <PauseTableHeadCell css={{
                  width: '25%',
                }}
                >
                  Name
                </PauseTableHeadCell>
                <PauseTableHeadCell css={{
                  width: '35%',
                }}
                >
                  Description
                </PauseTableHeadCell>
                <PauseTableHeadCell css={{
                  width: '20%',
                }}
                >
                  Requires Approval
                </PauseTableHeadCell>
                <PauseTableHeadCell css={{
                  width: '15%',
                  textAlign: 'right',
                }}
                >
                  Allowance
                </PauseTableHeadCell>
              </PauseTableRow>
            </PauseTableHead>
            {data.length ? (
              <PauseTableBody>
                {(data || []).map((lt) => (
                  <PauseTableRow
                    key={lt.id}
                    {...(isEditable && {
                      onClick: () => editLeaveType({ id: lt.id, type: lt.type }),
                    })}
                  >
                    <PauseTableBodyCell>
                      <div css={(theme) => ({
                        display: 'grid',
                        alignItems: 'center',
                        justifyContent: 'start',
                        gridAutoFlow: 'column',
                        gridGap: theme.spacing(0.5),
                      })}
                      >
                        {isEditable ? (
                          <PauseTypography css={{ cursor: 'pointer' }}>
                            {lt.emoji}
                            {' '}
                            {lt.name}
                          </PauseTypography>
                        ) : (
                          <PauseTypography>
                            {lt.emoji}
                            {' '}
                            {lt.name}
                          </PauseTypography>
                        )}
                        {lt.type === ANNUAL && (
                        <PauseTag>
                          Default
                        </PauseTag>
                        )}
                      </div>
                    </PauseTableBodyCell>
                    <PauseTableBodyCell>
                      <PauseTypography color="pause.primary.medium">
                        {lt.description || DEFAULT_LEAVE_TYPE_DESCRIPTION_MSG}
                      </PauseTypography>
                    </PauseTableBodyCell>
                    <PauseTableBodyCell>
                      <PauseTypography color="pause.primary.medium">
                        {lt.requiresApproval ? 'Yes' : 'No'}
                      </PauseTypography>
                    </PauseTableBodyCell>
                    <PauseTableBodyCell css={{
                      textAlign: 'right',
                    }}
                    >
                      <PauseTypography variant="body1" color="pause.primary.dark">
                        {`${get(lt, 'allowance.amount', '-')} days / ${get(lt, 'allowance.type') === 'PER_MONTH' ? 'month' : 'year'}`}
                      </PauseTypography>
                    </PauseTableBodyCell>
                  </PauseTableRow>
                ))}

              </PauseTableBody>
            ) : (
              <PauseTableBody>
                <PauseTableBodyCell colspan="4">
                  <PauseTypography color="primary.light">
                    No leave types, yet.
                  </PauseTypography>
                </PauseTableBodyCell>
              </PauseTableBody>
            )}
          </PauseTable>
          )}
        </PauseTableContainer>
      </div>
    </div>
  );
};
type Props = {
  data: Array<LeaveTypeWithAllowance>,
  addNewLeaveTypeURL: any,
  editLeaveType: (any?) => any,
  isEditable?: boolean,
  disabled?: boolean,
  onToggleDisable?: (any?) => any
}
