import React, { ReactNode } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { PauseTypography } from '../PauseTypography';

interface PlainCheckboxProps {
  checked?: boolean, onChange?: ((a: any, b: any) => void | undefined), disabled?: boolean,
  indeterminate?: boolean,
  [x: string]: any
}

const PlainCheckbox = ({
  checked, onChange, disabled, indeterminate, ...props
}: PlainCheckboxProps) => (
  <Checkbox
    css={(theme) => ({
      padding: 0,
      ...(disabled && {
        cursor: 'not-allowed',
      }),
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.Mui-checked': {
        '& .MuiSvgIcon-root': {
          fill: disabled ? theme.get('palette.pause.primary.medium') : theme.get('palette.pause.primary.main'),
        },
      },
      '& .MuiSvgIcon-root path': {
        width: theme.spacing(2.25),
      },
      '& .MuiSvgIcon-root': {
        fill: disabled ? theme.get('palette.pause.primary.light') : theme.get('palette.pause.primary.medium'),
      },
      '&.MuiCheckbox-indeterminate': {
        '& .MuiSvgIcon-root': {
          fill: disabled ? theme.get('palette.pause.primary.light') : theme.get('palette.pause.primary.medium'),
        },
      },
    })}
    disableRipple
    checked={checked}
    onChange={onChange}
    indeterminate={indeterminate}
    {...props}
  />
);
interface PauseCheckboxProps {
  checked?: boolean,
  onChange?: (a: any, b: any) => void,
  disabled?: boolean,
  label?: string|ReactNode,
  inverted?: boolean,
  disabledComponent?: ReactNode|undefined,
  description?: string,
  [x: string]: any
}

export const PauseCheckbox = ({
  checked = false, onChange, label = null, disabled = false, inverted = false,
  description = '',
  disabledComponent, ...props
}: PauseCheckboxProps) => {
  if (!label) {
    return (
      <PlainCheckbox
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        {...props}
      />
    );
  }
  return (
    <>
      <PauseTypography color={checked ? 'pause.primary.dark' : 'pause.primary.medium'}>
        <FormControlLabel
          disabled={disabled}
          css={(theme) => ({
            color: 'inherit',
            display: 'grid',
            alignItems: 'center',
            gridAutoFlow: 'column',
            justifyContent: inverted ? 'space-between' : 'start',
            gridGap: theme.spacing(1.5),
            direction: inverted ? 'rtl' : 'ltr',
            marginLeft: 0,
            marginRight: 0,
            '& .MuiFormControlLabel-label': {
              direction: 'ltr',
            },
            '& .MuiFormControlLabel-label.Mui-disabled': {
              color: theme.get('palette.pause.primary.medium'),
              cursor: 'not-allowed',
            },
          })}
        // @ts-ignore
          control={(disabled && disabledComponent) ? disabledComponent
            : (
              <PlainCheckbox
                checked={checked}
                onChange={!disabled ? onChange : () => null}
                {...props}
              />
            )}
          label={label}
        />
      </PauseTypography>
      {description && (
      <PauseTypography
        variant="body2"
        color="pause.primary.medium"
        css={(theme) => ({
          marginTop: theme.spacing(1),
          marginLeft: theme.spacing(4.5),
        })}
        onClick={onChange}
      >
        {description}
      </PauseTypography>
      )}
    </>
  );
};
