import React from 'react';
import { get } from 'lodash';
import {
  Notification, NotificationAvatar, NotificationContent, NotificationAvatarIcon, NotificationMeta,
} from './NotificationComponents';
import { Calendar, X } from '../../../assets/icons/feather/components';
import { PauseTypography } from '../PauseTypography';
import { LEAVE_STATUSES } from '../../../utils/constants';
import { getLeaveInWords } from '../../../utils/leaves';

type NotifDataType = {
    id: string,
    leave: {
      startDate: string,
      endDate: string,
      approver: {
        fullName: string,
        id: string
      },
      status: string,
      startType: string,
      endType: string,
      rejectionReason: string,
    },
    createdAt: string
}

export const RequestCard = ({
  notifData,
  onNotificationClick = () => null,
  ...otherProps
}: {
    notifData: NotifDataType,
    onNotificationClick: (any?) => any,
    [key: string]: any
}) => {
  const notifType = get(notifData, 'type') || 'LEAVE';
  const notifDetails = get(notifData, 'details') || get(notifData, 'leave');
  const {
    APPROVED, REJECTED, PENDING, CANCELLED,
  } = LEAVE_STATUSES;
  const status = get(notifData, 'details.status');
  const msg = React.useMemo(() => {
    if (notifType === 'LEAVE') {
      if (status === APPROVED) {
        const isSelfApproved = get(notifData, 'details.actioner.id') === get(notifData, 'details.applicant.id');
        const approverName = get(notifData, 'details.actioner.fullName');
        if (isSelfApproved) return 'Leave approved by you';
        return approverName ? `Leave approved by ${approverName}` : 'Leave auto-approved';
      }
      if (status === REJECTED) {
        return `Leave declined by ${get(notifData, 'details.actioner.fullName')}`;
      }
      if (status === CANCELLED) {
        return 'Leave deleted';
      }
      return 'Pending leave';
    }
    if (notifType === 'COMP_OFF') {
      if (status === APPROVED) {
        return 'Comp-off approved';
      }
      if (status === CANCELLED) {
        return `Comp-off canceled by ${get(notifData, 'details.actioner.fullName')}`;
      }
      return 'Pending approval for comp-off';
    }
    if (notifType === 'LOCKED_DATE') {
      return 'All-hands';
    }
  }, [notifType, notifData]);
  const icon = status === CANCELLED ? X : Calendar;
  // eslint-disable-next-line no-nested-ternary
  const color = [REJECTED, CANCELLED].includes(status)
    ? 'peach'
    : status === PENDING
      ? 'primary'
      : 'green';
  const declineReason = status === REJECTED && (notifType === 'LEAVE') && get(notifData, 'details.rejectionReason');
  return (
    <Notification isUnread={!get(notifData, 'isRead', false)} {...otherProps} onClick={() => onNotificationClick({ id: notifData.id })}>
      <NotificationAvatar>
        <NotificationAvatarIcon icon={icon} color={color} />
      </NotificationAvatar>
      <NotificationContent>
        {msg && (
        <div>
          <PauseTypography css={(theme) => ({
            color: theme.palette.pause.primary.dark,
          })}
          >
            {msg}
          </PauseTypography>
        </div>
        )}

        {notifType === 'LEAVE' && (
        <NotificationMeta>
          <div>
            <PauseTypography variant="caption">Date: </PauseTypography>
            <PauseTypography variant="body2">
              {getLeaveInWords(get(notifData, 'details'))}
            </PauseTypography>
          </div>
          {declineReason && (
          <div>
            <PauseTypography variant="caption">Reason for declining: </PauseTypography>
            <PauseTypography variant="body2">
              {declineReason}
            </PauseTypography>
          </div>
          )}
        </NotificationMeta>
        )}
        {notifType === 'COMP_OFF' && (
        <NotificationMeta>
          <div>
            <PauseTypography variant="caption">Days: </PauseTypography>
            <PauseTypography variant="body2">
              {(get(notifData, 'details.quarterDays', 0) * 0.25) || '-'}
            </PauseTypography>
          </div>
        </NotificationMeta>
        )}
        {notifType === 'LOCKED_DATE' && (
        <NotificationMeta>
          <div>
            <PauseTypography variant="caption">Days: </PauseTypography>
            <PauseTypography variant="body2">
              {getLeaveInWords(notifDetails)}
            </PauseTypography>
          </div>
        </NotificationMeta>
        )}
      </NotificationContent>
    </Notification>
  );
};
