import React from 'react';
import { PauseTypography } from '../PauseTypography';
import { Check } from '../../../assets/icons/feather/components';

export const PauseStatefulChip = ({ children, isActive, onClick = () => {} }) => (
  <div
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    css={(theme) => ({
      transition: 'all 0.2s ease-in-out',
      display: 'inline-grid',
      cursor: 'pointer',
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      borderRadius: theme.spacing(0.5),
      gridAutoFlow: 'column',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: isActive
        ? theme.get('palette.pause.primary.light')
        : theme.get('palette.pause.background.dark'),
      backgroundColor: isActive
        ? theme.get('palette.pause.background.dark')
        : theme.get('palette.pause.background.medium'),
      color: isActive
        ? theme.get('palette.pause.primary.main')
        : theme.get('palette.pause.primary.medium'),
      '&:hover': {
        color: theme.get('palette.pause.primary.main'),
        borderColor: theme.get('palette.pause.primary.light'),
      },
      '&:focus': {
        borderColor: theme.get('palette.pause.primary.light'),
      },
    })}
  >
    <PauseTypography variant="body2">
      <div css={(theme) => ({
        display: 'grid',
        gridAutoFlow: 'column',
        gridGap: theme.spacing(1),
        alignItems: 'center',
      })}
      >
        {children}
        {isActive && <Check />}
      </div>
    </PauseTypography>
  </div>
);

export const PauseStatefulChipGroup = ({ children }) => (
  <div css={(theme) => ({
    display: 'inline-flex',
    '> :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
    '> div': {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
    flexWrap: 'wrap',
  })}
  >
    {children}
  </div>
);

export const PauseStatefulChipRadioGroup = ({ options = [], value, onChange }) => {
  const changeValue = (newValue) => {
    onChange(newValue);
  };
  return (
    <PauseStatefulChipGroup>
      {options.map((option) => (
        <PauseStatefulChip
          key={option.value}
          onClick={() => changeValue(option.value)}
          isActive={option.value === value}
        >
          {option.label}
        </PauseStatefulChip>
      ))}
    </PauseStatefulChipGroup>
  );
};
