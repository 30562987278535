import React from 'react';
import { LeaveFormStoreWrapper } from './store';
import { LeaveForm, Tabs } from './components/LeaveForm';

export const LeaveFormPage = ({
  open,
  onClose = () => null,
}: {
  open: boolean,
  onClose: () => any
}) => (
  <LeaveFormStoreWrapper>
    {({
      me,
      selectablePeople,
      lockedDates,
      loading,
      currentLeave,
      currentApprover,
      onCreateLeave,
      onApproveLeave,
      onRejectLeave,
      onDeleteLeave,
      approver,
      setSelectedPerson,
      disableDeductibleLeaveTypes,
      allowanceList,
      setLeaveStartDate,
      selectedDate,
      onCreateCompOff,
      onApproveCompOff,
      onCancelCompOff,
      onDeclineCompOff,
      currentCompOff,
      currentAllHands,
      onCreateAllHands,
      onDeleteAllHands,
    }) => (
      <LeaveForm
        // eslint-disable-next-line no-nested-ternary
        staticTab={(currentLeave && Tabs.Leave) || (currentCompOff && Tabs.CompOff)}
        open={open}
        onClose={onClose}
        me={me}
        selectablePeople={selectablePeople}
        lockedDates={lockedDates}
        loading={loading}
        currentLeave={currentLeave}
        onCreateLeave={onCreateLeave}
        onApproveLeave={onApproveLeave}
        onRejectLeave={onRejectLeave}
        onDeleteLeave={onDeleteLeave}
        approver={approver}
        setSelectedPerson={setSelectedPerson}
        disableDeductibleLeaveTypes={disableDeductibleLeaveTypes}
        currentApprover={currentApprover}
        allowanceList={allowanceList}
        setLeaveStartDate={setLeaveStartDate}
        selectedDate={selectedDate}
        onCreateCompOff={onCreateCompOff}
        onApproveCompOff={onApproveCompOff}
        onCancelCompOff={onCancelCompOff}
        onDeclineCompOff={onDeclineCompOff}
        currentCompOff={currentCompOff}
        {...((currentCompOff || currentLeave) && {
          drawerTitle: 'Leave',
        })}
        currentAllHands={currentAllHands}
        onCreateAllHands={onCreateAllHands}
        onDeleteAllHands={onDeleteAllHands}
      />

    )}
  </LeaveFormStoreWrapper>
);
