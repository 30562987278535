import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { get } from 'lodash';
import { Grid } from '@material-ui/core';
import dynamic from 'next/dynamic';
import { PauseForm } from '../../../components/PauseForm';
import {
  PauseDrawer, PauseDrawerContent, PauseDrawerFooter, PauseDrawerTitle,
} from '../../../components/PauseDrawer';
import {
  PauseFormGroupCard,
  PauseFormGroupCardsList,
  PauseFormGroupCardItem,
} from '../../../components/PauseFormGroupCard';
import { PauseSubmitButton } from '../../../components/PauseSubmitButton';
import { PauseButton } from '../../../components/PauseButton';
import { PauseTextField } from '../../../components/PauseTextField';
import { PauseCheckbox } from '../../../components/PauseCheckbox';
import { PauseRadio } from '../../../components/PauseRadio';
import { LEAVE_TYPES } from '../../../../utils/constants';
import { PauseTypography } from '../../../components/PauseTypography';
import { PauseFormTextField } from '../../../components/PauseFormField2/PauseFormTextField';
import { PauseFormField2 } from '../../../components/PauseFormField2';
import { PauseFormDatepicker } from '../../../components/PauseFormField2/PauseFormDatepicker';
import { AllowanceCreditType } from '../../../types/onboarding';
import { LeaveType, LeaveTypeWithAllowance } from '../../../api/leave-types';
import { PauseDropdown } from '../../../components/PauseDropdown';

const Picker = dynamic(() => import('emoji-picker-react'), { ssr: false });

const { ANNUAL, DEDUCTIBLE, NON_DEDUCTIBLE } = LEAVE_TYPES;

const defaultLeaveTypeEmoji = '🌴';

const { PER_CYCLE, PER_MONTH } = {
  PER_CYCLE: 'PER_CYCLE',
  PER_MONTH: 'PER_MONTH',
};

export const validateLeaveCredit = ({ amount, type }) => {
  if (type === null) return null;
  if (type === AllowanceCreditType.PerCycle && amount % 1 !== 0) {
    return { amount: 'Yearly credit has to be a whole number (1, 2, ...)' };
  }
  if (type === AllowanceCreditType.PerMonth && amount % 0.25 !== 0) {
    return { amount: 'Monthly credit can only be multiples of 0.25' };
  }
  if (type === AllowanceCreditType.PerCycle && amount > 420) {
    return { amount: 'Cannot be greater than 420' };
  }
  if (type === AllowanceCreditType.PerMonth && amount > 31) {
    return { amount: 'Cannot be greater than 31' };
  }
  return null;
};

export const LeaveCreditRadioGroup = (props: {
  name: string;
  id: string;
  value: any;
  onChange: (any?) => any;
  error: string;
}) => {
  const {
    name, id, value, onChange, error,
  } = props;
  const updateAllowanceAmt = (amount) => onChange({
    target: {
      value: {
        ...value,
        amount: amount && amount >= 0 ? Number(amount) : null,
      },
      name,
      id,
    },
  });

  const updateAllowanceType = (type) => onChange({
    target: {
      value: {
        ...value,
        amount: null,
        type,
      },
      name,
      id,
    },
  });

  return (
    <>
      <Grid
        spacing={2}
        container
        css={(theme) => ({
          padding: theme.spacing(0),
          alignItems: 'center',
          minHeight: theme.spacing(9),
        })}
      >
        <Grid item xs={8}>
          <PauseRadio
            label="Credit yearly"
            checked={get(value, 'type') === PER_CYCLE}
            onChange={() => updateAllowanceType(PER_CYCLE)}
          />
        </Grid>
        <Grid item xs={4}>
          {get(value, 'type') === PER_CYCLE && (
            <PauseTextField
              key={get(value, 'type')}
              disabled={get(value, 'type') !== PER_CYCLE}
              label="Every year"
              {...(get(value, 'type') === PER_CYCLE ? { value: value.amount } : { value: null })}
              onChange={(e) => updateAllowanceAmt(get(e, 'target.value'))}
              type="number"
              inputProps={{
                min: 0,
                step: 1,
              }}
            />
          )}
        </Grid>
      </Grid>
      <Grid
        spacing={2}
        container
        css={(theme) => ({
          padding: theme.spacing(0),
          alignItems: 'center',
          minHeight: theme.spacing(9),
        })}
      >
        <Grid item xs={8}>
          <PauseRadio
            checked={get(value, 'type') === PER_MONTH}
            label="Credit monthly"
            onChange={() => updateAllowanceType(PER_MONTH)}
          />
        </Grid>
        <Grid item xs={4}>
          {get(value, 'type') === PER_MONTH && (
            <PauseTextField
              key={get(value, 'type')}
              disabled={get(value, 'type') !== PER_MONTH}
              label="Every month"
              value={get(value, 'type') === PER_MONTH ? value.amount : null}
              onChange={(e) => updateAllowanceAmt(get(e, 'target.value'))}
              type="number"
              inputProps={{
                min: 0,
                step: 0.25,
              }}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        {get(error, 'amount') && (
          <Grid xs={12}>
            <PauseTypography
              css={(theme) => ({ marginTop: theme.spacing(2), textAlign: 'right' })}
              variant="body2"
              color="pause.error.main"
            >
              {get(error, 'amount')}
            </PauseTypography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const CarryForward = (props: { field: any; state: any; helpers: any }) => {
  const { field, state, helpers } = props;
  const [isSet, setIsSet] = React.useState((field || {}).value > 0);
  const handleToggle = (checked) => {
    if (checked) {
      helpers.setValue(1);
    } else {
      helpers.setValue(0);
    }
    setIsSet(checked);
  };
  return (
    <Grid
      container
      spacing={2}
      css={() => ({
        alignItems: 'center',
      })}
    >
      <Grid item xs={8}>
        <PauseCheckbox checked={isSet} onChange={(_, checked) => handleToggle(checked)} label="Carry forward" />
      </Grid>
      {isSet && (
        <Grid item xs={4}>
          <PauseTextField
            type="number"
            label="Days"
            inputProps={{
              min: 0,
              step: 1,
            }}
            {...field}
            {...state}
          />
        </Grid>
      )}
    </Grid>
  );
};

export const LeavePolicyForm = ({
  open,
  onClose,
  type,
  loading = false,
  isEditing = false,
  currentLeaveType = null,
  onCreateLeaveType = () => null,
  onEditLeaveType = () => null,
  onDeleteLeaveType = () => null,
}: Props) => {
  const defaultInitialValue = React.useMemo(() => {
    if (!currentLeaveType) {
      return {
        name: '',
        description: '',
        ...(type !== NON_DEDUCTIBLE && {
          allowance: {
            amount: 10,
            type: 'PER_CYCLE',
          },
        }),
        type,
        requiresApproval: true,
      };
    }
    return currentLeaveType;
  }, [type, currentLeaveType]);
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState<boolean>(false);
  const [leaveTypeEmoji, setLeaveTypeEmoji] = useState<string>(defaultLeaveTypeEmoji);
  useEffect(() => setLeaveTypeEmoji(currentLeaveType?.emoji || defaultLeaveTypeEmoji), [currentLeaveType]);
  return (
    // @ts-ignore
    <PauseForm
      enableReinitialize
      initialValues={defaultInitialValue}
      validationSchema={Yup.object({
        name: Yup.string().required(),
        description: Yup.string(),
        allowance: Yup.object({
          amount: Yup.number().nullable().required('Credit amount is required'),
          type: Yup.string().required('Required'),
        }).default(undefined),
        ...(type === ANNUAL && {
          carryForward: Yup.number().min(0).required('Required'),
        }),
        notifyAdmin: Yup.boolean(),
      })}
      onSubmit={(values) => {
        if (isEditing) {
          return onEditLeaveType({ ...values, emoji: leaveTypeEmoji });
        }
        return onCreateLeaveType({ ...values, emoji: leaveTypeEmoji });
      }}
      initialTouched={{
        allowance: true,
      }}
    >
      <PauseDrawer
        css={{
          maxWidth: '200px',
        }}
        onClose={onClose}
        open={open}
      >
        <PauseDrawerTitle>
          {isEditing && 'Edit leave type'}
          {!isEditing && type === DEDUCTIBLE && 'Create deductible leave type'}
          {!isEditing && type === NON_DEDUCTIBLE && 'Create non-deductible leave type'}
        </PauseDrawerTitle>
        <PauseDrawerContent compact>
          <div
            css={(theme) => ({
              background: theme.get('palette.pause.background.light'),
              minHeight: '100%',
            })}
          >
            {!loading && (
              <PauseFormGroupCardsList>
                <PauseFormGroupCard title="Details">
                  <PauseFormGroupCardItem>
                    {/* <Field as={PauseTextField} name="name" label="Name" /> */}
                    <div
                      css={(t) => ({
                        display: 'grid',
                        gridAutoFlow: 'column',
                        gridTemplateColumns: '65px auto',
                        gap: t.spacing(2),
                      })}
                    >
                      <PauseDropdown
                        id="emoji-picker"
                        isOpen={isEmojiPickerOpen}
                        onClose={() => setIsEmojiPickerOpen(false)}
                        dropdownContent={(
                          <Picker
                            // @ts-ignore
                            onEmojiClick={(_, emojiData) => {
                              setLeaveTypeEmoji(emojiData.emoji);
                              setIsEmojiPickerOpen(false);
                            }}
                            disableSkinTonePicker
                            disableAutoFocus
                            groupVisibility={{
                              flags: true,
                              smileys_people: true,
                              animals_nature: true,
                              food_drink: true,
                              travel_places: true,
                              activities: true,
                              objects: true,
                              symbols: true,
                              recently_used: false,
                            }}
                            preload
                            native
                          />
                        )}
                        autoWidth
                        disableScrollLock
                      >
                        <PauseTextField
                          label="Emoji"
                          value={leaveTypeEmoji || ''}
                          onChange={(e) => {
                            if (e?.target?.value === '') {
                              setLeaveTypeEmoji(defaultLeaveTypeEmoji);
                            }
                          }}
                          onClick={() => setIsEmojiPickerOpen(true)}
                        />
                      </PauseDropdown>
                      <PauseFormTextField
                        name="name"
                        fieldProps={{
                          label: 'Name',
                        }}
                      />
                    </div>
                  </PauseFormGroupCardItem>
                  <PauseFormGroupCardItem>
                    <PauseFormTextField
                      name="description"
                      fieldProps={{
                        label: 'Description',
                        rows: 4,
                        multiline: true,
                      }}
                    />
                  </PauseFormGroupCardItem>
                  {type !== NON_DEDUCTIBLE && (
                    <PauseFormGroupCardItem>
                      <PauseFormDatepicker
                        name="policyEffectiveSinceDate"
                        fieldProps={{
                          label: 'Leave type added on',
                          format: 'DD MMM YYYY',
                        }}
                        disabled
                      />
                      <PauseTypography
                        variant="body2"
                        color="pause.primary.light"
                        css={(theme) => ({
                          padding: theme.spacing(1, 1),
                          // marginTop: theme.spacing(2),
                        })}
                      >
                        Allowance for this leave is credited to employees from this date onwards.
                      </PauseTypography>
                    </PauseFormGroupCardItem>
                  )}
                </PauseFormGroupCard>

                {type !== NON_DEDUCTIBLE && (
                  <PauseFormGroupCard title="Number of leaves">
                    <PauseFormGroupCardItem>
                      <PauseFormField2
                        name="allowance"
                        validate={validateLeaveCredit}
                        render={({ field, state }) => <LeaveCreditRadioGroup {...field} {...state} />}
                      />
                    </PauseFormGroupCardItem>
                  </PauseFormGroupCard>
                )}
                <PauseFormGroupCard title="Other Settings">
                  <PauseFormGroupCardItem>
                    <PauseFormField2
                      name="requiresApproval"
                      render={({ field, state }) => (
                        <PauseCheckbox {...field} {...state} checked={field.value} label="Needs approval" />
                      )}
                    />
                  </PauseFormGroupCardItem>
                  {type === ANNUAL && (
                    <PauseFormGroupCardItem>
                      <PauseFormField2
                        name="carryForward"
                        render={({ field, state, helpers }) => (
                          <CarryForward field={field} state={state} helpers={helpers} />
                        )}
                      />
                    </PauseFormGroupCardItem>
                  )}
                  <PauseFormGroupCardItem>
                    <PauseFormField2
                      name="notifyAdmin"
                      render={({ field, state }) => (
                        <PauseCheckbox {...field} {...state} checked={field.value} label="Notify admin" />
                      )}
                    />
                  </PauseFormGroupCardItem>
                </PauseFormGroupCard>
              </PauseFormGroupCardsList>
            )}
            {loading && 'Loading...'}
          </div>
        </PauseDrawerContent>
        <PauseDrawerFooter>
          <Grid container spacing={2}>
            {isEditing && type !== ANNUAL && (
              <Grid item xs={6}>
                <PauseButton onClick={() => onDeleteLeaveType(currentLeaveType?.id)} color="error">
                  Delete
                </PauseButton>
              </Grid>
            )}
            <Grid
              item
              xs={
                // eslint-disable-next-line no-nested-ternary
                isEditing && type === ANNUAL ? 12 : isEditing ? 6 : 12
              }
            >
              <PauseSubmitButton enableForInitialValid>
                <PauseButton>{isEditing ? 'Save' : 'Add'}</PauseButton>
              </PauseSubmitButton>
            </Grid>
          </Grid>
        </PauseDrawerFooter>
      </PauseDrawer>
    </PauseForm>
  );
};

type Props = {
  open: boolean;
  onClose: (any?) => any;
  type: string;
  loading: boolean;
  isEditing: boolean;
  currentLeaveType: LeaveTypeWithAllowance | LeaveType | null;
  onCreateLeaveType: (any?) => any;
  onEditLeaveType: (any?) => any;
  onDeleteLeaveType: (any?) => any;
};
