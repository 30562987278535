import React from 'react';
import {
  Modal, Backdrop, Fade,
} from '@material-ui/core';
import { get } from 'lodash';
import { X } from '../../../assets/icons/feather/components';
import { PauseTypography } from '../PauseTypography';
import { PauseIconButton } from '../PauseIconButton';

type Props = {
  children?: React.ReactNode,
  open?: boolean,
  onClose?: (any?) => any,
  width?: string,
  [key: string]: any,
}
export const PauseModal = ({
  children = <></>,
  open = false,
  onClose = () => null,
  width = '35vw',
  ...otherProps
}: Props) => (
  <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={open}
    onClose={onClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
    {...otherProps}
    disableAutoFocus
    css={() => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    })}
  >
    <Fade in={open}>
      <div
        css={(theme) => ({
          minWidth: '25vw',
          maxWidth: '100vw',
          width,
          background: get(theme, 'palette.pause.white.main'),
          borderRadius: theme.spacing(1.5),
          overflow: 'hidden',
          '&:focus': {
            outline: 'none !important',
          },
        })}
      >
        {children}
      </div>
    </Fade>
  </Modal>
);

type TitleProps = {
  title: string,
  description?: string,
  onClose?: () => any
}
export const PauseModalTitle = ({
  title,
  description,
  onClose,
}: TitleProps) => (
  <div css={(theme) => ({
    padding: theme.spacing(2, 2, 2, 2),
    background: get(theme, 'palette.pause.background.light'),
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: theme.spacing(2),
    alignItems: 'start',
    justifyContent: 'space-between',
  })}
  >
    <div>
      <PauseTypography variant="title" color="pause.primary.dark">
        {title}
      </PauseTypography>
      {description && (
      <PauseTypography
        color="pause.primary.medium"
        css={(theme) => ({
          marginTop: theme.spacing(1),
        })}
      >
        {description}
      </PauseTypography>
      )}
    </div>
    <PauseIconButton
      // @ts-ignore
      onClick={onClose || (() => null)}
      icon={(
        <X css={(theme) => ({
          color: get(theme, 'palette.pause.primary.light'),
        })}
        />
      )}
      compact
      size="small"
      variant="ghostDark"
      color="primary.light"
    />
  </div>
);

type BodyProps = {
  children?: React.ReactNode,
  compact?: boolean,
  height?: string,
}
export const PauseModalBody = ({
  children = <></>,
  compact = false,
  height = 'auto',
}: BodyProps) => (
  <div
    css={(theme) => ({
      background: get(theme, 'palette.pause.white.main'),
      padding: compact ? 0 : theme.spacing(2),
      maxHeight: '90vh',
      height,
      overflowY: 'auto',
    })}
    id="pause-modal"
  >
    {children}
  </div>
);

type FooterProps = {
  children?: React.ReactNode,
}

export const PauseModalFooter = ({ children = <></> }: FooterProps) => (
  <div css={(theme) => ({
    padding: theme.spacing(2),
  })}
  >
    {children}
  </div>
);
