import React from 'react';
import { get } from 'lodash';
import { Grid } from '@material-ui/core';
import { PauseAvatar } from '../PauseAvatar';
import { PauseButton } from '../PauseButton';
import { PauseTypography } from '../PauseTypography';
import {
  Notification, NotificationActions, NotificationAvatar, NotificationContent, NotificationMeta,
} from './NotificationComponents';
import { LEAVE_STATUSES } from '../../../utils/constants';
import { getLeaveInWords } from '../../../utils/leaves';
import { noop } from '../../api/_utils';
import { expressiveColors } from '../PauseTheme/colors';

type NotifDataType = {
  leave: {
    startDate: string,
    endDate: string,
    leaveType: string,
    applicant: {
      fullName: string,
      avatar: string,
      id: string
    }
    status: string
  },
  id: string,
}

export const ActionCard = ({
  notifData,
  onApproveLeave = () => null,
  onApproveCompOff = () => null,
  onNotificationClick = () => null,
  ...otherProps
}: {
    notifData: NotifDataType,
    onApproveLeave: (any?) => any,
    onApproveCompOff: (any?) => any,
    onNotificationClick: (any?) => any,
    [key: string]: any

  }) => {
  const notifType = get(notifData, 'type', 'LEAVE'); // LEAVE | COMP_OFF
  const data = get(notifData, 'details');
  const leaveStatus = get(data, 'status');
  const {
    APPROVED, PENDING, REJECTED, CANCELLED,
  } = LEAVE_STATUSES;
  const [isApproving, setIsApproving] = React.useState(false);

  const handleApprove = async (e) => {
    e.stopPropagation();
    setIsApproving(true);
    try {
      if (notifType === 'LEAVE') {
        await onApproveLeave({ leaveID: get(data, 'id'), notificationID: get(notifData, 'id') });
      } else {
        await onApproveCompOff(get(data, 'id'));
      }
    } catch (err) {
      noop();
    }
    setIsApproving(false);
  };

  const handleNotifClick = ({ isRejection = false } = {}) => {
    onNotificationClick({ isRejection, id: get(notifData, 'id') });
  };

  const handleReject = (e) => {
    e.stopPropagation();
    handleNotifClick({ isRejection: true });
  };

  return (
    <Notification {...otherProps} onClick={() => handleNotifClick()}>
      <NotificationAvatar>
        <PauseAvatar
          color={expressiveColors[0]}
          src={get(data, 'applicant.image')}
          // @ts-ignore
          size="large"
        />
      </NotificationAvatar>
      <NotificationContent>
        <PauseTypography css={(theme) => ({
          color: theme.palette.pause.primary.dark,
          marginBottom: theme.spacing(1),
          lineHeight: 1.4,
        })}
        >
          <>
            {notifType === 'LEAVE' ? 'Leave' : 'Comp-off'}
            {' '}
            request from
            {' '}
            {get(data, 'applicant.fullName')}
          </>
        </PauseTypography>
        <NotificationMeta>
          {notifType === 'LEAVE' && (
          <>
            <div>
              <PauseTypography variant="caption">Date: </PauseTypography>
              <PauseTypography variant="body2">
                {getLeaveInWords(data)}
              </PauseTypography>
            </div>
            <div>
              <PauseTypography variant="caption">Type: </PauseTypography>
              <PauseTypography variant="body2">
                {get(data, 'leaveType.name')}
              </PauseTypography>
            </div>
            { leaveStatus !== PENDING && (
            <div>
              <PauseTypography variant="caption">Status: </PauseTypography>
              <PauseTypography variant="body2">
                {/* eslint-disable-next-line no-nested-ternary */}
                {leaveStatus === APPROVED ? 'Approved' : leaveStatus === CANCELLED ? 'Deleted' : 'Declined'}
              </PauseTypography>
            </div>
            )}

            { leaveStatus === REJECTED && (
            <div>
              <PauseTypography variant="caption">Reason for declining: </PauseTypography>
              <PauseTypography variant="body2">
                {get(data, 'rejectionReason') || 'No reason given.'}
              </PauseTypography>
            </div>
            )}
          </>
          )}

          {notifType === 'COMP_OFF' && (
          <>
            <div>
              <PauseTypography variant="caption">Days: </PauseTypography>
              <PauseTypography variant="body2">
                {get(notifData, 'details.quarterDays') * 0.25}
              </PauseTypography>
            </div>
            <div>
              <PauseTypography variant="caption">Reason: </PauseTypography>
              <PauseTypography variant="body2">
                {get(notifData, 'details.applicationReason')}
              </PauseTypography>
            </div>
            {get(notifData, 'details.status') !== 'PENDING' && (
            <div>
              <PauseTypography variant="caption">Status: </PauseTypography>
              <PauseTypography variant="body2">
                {get(notifData, 'details.status') === 'CANCELED' ? 'Canceled' : 'Approved'}
              </PauseTypography>
            </div>
            )}
          </>
          )}

        </NotificationMeta>
        <NotificationActions>
          <Grid item container spacing={2}>
            {
              leaveStatus === PENDING && (
              <>
                <Grid item xs={6}>
                  <PauseButton onClick={handleReject} size="small" variant="outlined">
                    Decline
                  </PauseButton>
                </Grid>
                <Grid item xs={6}>
                  <PauseButton disabled={isApproving} isLoading={isApproving} onClick={handleApprove} size="small">
                    Approve
                  </PauseButton>
                </Grid>
              </>
              )
            }
          </Grid>
        </NotificationActions>
      </NotificationContent>
    </Notification>
  );
};
