export enum InvoiceType {
  FreeTrial = 'FREE_TRIAL',
  TrialExpired = 'TRIAL_EXPIRED',
  TrialExtended = 'EXTENDED_TRIAL',
  Paid = 'PAID_PERIOD'
}
export enum InvoiceStatus {
  Open = 'OPEN',
  Paid = 'PAID',
  Canceled = 'CANCELED',
}
export enum InvoicePricing {
  Indian = 'INDIAN',
  Others = 'OTHERS'
}
export type InvoiceDetails = {
  periodStart?: string,
  periodEnd?: string,
  id?: string,
  numberOfSeats?: number,
  totalAmount?: number,
  pricing?: InvoicePricing,
  downloadURL?: string,
  status?: InvoiceStatus,
  today?: string,
  paymentIntent?: {
    clientSecret: string | null,
  }
}

export type Invoice = {
  type: InvoiceType
  details: InvoiceDetails
}
export enum SubscriptionStatus {
  Trial = 'TRIAL',
  TrialExpired = 'TRIAL_EXPIRED',
  TrialExtended = 'EXTENDED_TRIAL',
  Active = 'ACTIVE',
  Overdue = 'OVERDUE'
}
