import React from 'react';
import { get, pick } from 'lodash';
import { NOTIFICATION_CONTEXTS } from '../../components/Notification/utils';
import { useNotifier } from '../snackbar/store';
import { useLeaves, useAllLeaves } from '../../api/leaves';
import { usePeople } from '../../api/people';
import { useMe } from '../../api/me';
import { useNotifications } from '../../api/notifications';
import { noop } from '../../api/_utils';
import { useCompOff } from '../../api/compoff';

const findLeaveByID_ = (leaves:any[] = []) => (leaveID: string) => leaves.find((l) => l.id === leaveID) || {};

const addApproverToLeave = (args: { people: any[] }) => (leave: any) => {
  const { people = [] } = args;
  if (!leave) return null;
  const approverID = get(leave, 'actioner.id');
  return ({
    ...leave,
    approver: approverID ? {
      id: approverID,
      fullName: get((people || []).find((p) => approverID === p.id), 'fullName'),
    } : null,
  });
};

const addApplicantMetaToLeave = (args: { people: any[] }) => (leave:any) => {
  const { people = [] } = args;
  if (!leave) return null;
  const applicantID = get(leave, 'applicant.id');
  return ({
    ...leave,
    applicant: applicantID ? {
      id: applicantID,
      ...pick(((people || []).find((p) => applicantID === p.id) || {}), ['fullName', 'image']),
    } : null,
  });
};

const countOfPendingLeaves = (data: any[] = []) => (data.filter((d) => get(d, 'status') === 'PENDING') || []).length || 0;

export const useNotificationsStore = () => {
  const { APPROVALS, REQUESTS, OTHERS } = NOTIFICATION_CONTEXTS;
  const { enqueueNotification } = useNotifier();
  const { me, loading: meLoading } = useMe();

  const { people } = usePeople();

  const {
    approvalNotifications,
    requestNotifications,
    otherNotifications,
    hasMoreApprovalNotifications,
    hasMoreRequestNotifications,
    hasMoreOtherNotifications,
    fetchMoreNotifications,
    loading: loadingNotifications,
    totalUnreadOtherNotifications,
    totalUnreadRequestNotifications,
    markNotificationAsRead,
  } = useNotifications();

  const {
    approveLeave,
  } = useLeaves();

  const { approveCompOff: acceptCompOff } = useCompOff();

  const { leaves } = useAllLeaves();
  const notifContexts = Object.values(NOTIFICATION_CONTEXTS);
  type NotifContexts = (typeof notifContexts)[number]
  const [notificationContext, setNotificationContext] = React.useState<NotifContexts>(APPROVALS);

  // eslint-disable-next-line consistent-return
  const loadMore = () => {
    switch (notificationContext) {
      case APPROVALS:
        return fetchMoreNotifications('approvals');
      case REQUESTS:
        return fetchMoreNotifications('requests');
      case OTHERS:
        return fetchMoreNotifications('others');
      default:
        break;
    }
  };
  const notifications = React.useMemo(() => {
    switch (notificationContext) {
      case APPROVALS:
        return approvalNotifications;
      case REQUESTS:
        return requestNotifications;
      case OTHERS:
        return otherNotifications;
      default:
        return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    approvalNotifications,
    otherNotifications,
    requestNotifications,
  ]);
  const notificationLeaves = React.useMemo(() => (notifications || []).map((n) => get(n, 'leave')), [notifications]);

  const getNotificationDetails = ({ id }) => {
    const notification = notifications.find((n) => n.id === id) || null;
    if (!notification) return {};
    return ({
      ...notification,
      leave: addApplicantMetaToLeave({ people })(
        addApproverToLeave({ people })(notification.leave),
      ),
    });
  };

  const findLeaveByID = React.useMemo(
    () => findLeaveByID_(notificationLeaves || []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notificationLeaves, leaves],
  );

  const acceptLeave = ({ leaveID }) => approveLeave(findLeaveByID(leaveID)).then((r) => {
    if (!r.error) {
      enqueueNotification('Leave approved!', {
        variant: 'success',
      });
    }
  }).catch(noop);

  const markAsRead = ({ notificationID, context = null }) => markNotificationAsRead({ id: notificationID, context });

  const setNotificationType = ({ type }) => {
    switch (type) {
      case APPROVALS: {
        setNotificationContext(APPROVALS);
        break;
      }
      case REQUESTS: {
        setNotificationContext(REQUESTS);
        break;
      }
      case OTHERS: {
        setNotificationContext(OTHERS);
        break;
      }
      default:
        break;
    }
  };

  const isApprover = React.useMemo(() => get(me, 'roles.approver.teams', []).length > 0, [me]);
  const isAdmin = React.useMemo(() => get(me, 'isAdmin', false), [me]);

  // resets the context once 'me' loads
  React.useEffect(() => {
    if (!me) return;
    if (isAdmin || isApprover) return;
    setNotificationType({ type: REQUESTS });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, isApprover, isAdmin]);

  const unReadCounts = React.useMemo(() => ({
    approvals: countOfPendingLeaves((approvalNotifications || []).map((n) => get(n, 'details'))) || 0,
    requests: totalUnreadRequestNotifications,
    others: totalUnreadOtherNotifications,
  }), [totalUnreadOtherNotifications, approvalNotifications, totalUnreadRequestNotifications]);

  const hasMore = React.useMemo(() => {
    switch (notificationContext) {
      case APPROVALS:
        return hasMoreApprovalNotifications;
      case REQUESTS:
        return hasMoreRequestNotifications;
      case OTHERS:
        return hasMoreOtherNotifications;
      default:
        return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    notificationContext,
    hasMoreOtherNotifications,
    hasMoreRequestNotifications,
    hasMoreApprovalNotifications,
  ]);

  return {
    done: !meLoading,
    unReadCounts,
    notifications: (notifications || []).map((notif) => ({
      ...notif,
      leave: addApplicantMetaToLeave({ people })(
        addApproverToLeave({ people })(notif.leave),
      ),
    })),
    loading: loadingNotifications,
    loadMore,
    hasMore,
    getNotificationDetails,
    acceptLeave,
    markAsRead,
    notificationContexts: NOTIFICATION_CONTEXTS,
    setNotificationType,
    notificationContext,
    isApprover,
    isAdmin,
    acceptCompOff,
  };
};

export const useNotificationCount = () => {
  const {
    approvalNotifications,
    totalUnreadOtherNotifications,
    totalUnreadRequestNotifications,
  } = useNotifications();

  const unReadCounts = React.useMemo(() => ({
    approvals: countOfPendingLeaves((approvalNotifications || []).map((n) => get(n, 'details'))) || 0,
    requests: totalUnreadRequestNotifications,
    others: totalUnreadOtherNotifications,
  }), [totalUnreadOtherNotifications, approvalNotifications, totalUnreadRequestNotifications]);

  return { count: unReadCounts };
};

export const NotificationsStoreWrapper = ({ children }) => {
  const data = useNotificationsStore();
  return children(data);
};
