import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { get as _get } from 'lodash';
import { useAPI } from '../hooks/useAxios';
import { replaceKeysDeep } from '../../utils/replace-keys';

type ApprovalNotification = {
  id: string,
  leave: { id: string, [key: string]: any},
  updatedAt: string,
}
type RequestNotification = {
  id: string,
  leave: { id: string, [key: string]: any},
  updatedAt: string,
  isRead: boolean,
}
type Page = {
  nextOffset: number,
  prevOffset: number
}

type ApprovalNotificationsPage = Page & { approvals: ApprovalNotification[] }
type RequestNotificationsPage = Page & { requests: RequestNotification[], totalUnread: number }
type OtherNotificationsPage = Page & { others: RequestNotification[], totalUnread: number }

export type ApprovalNotificationsQueryData = {
  pages: ApprovalNotificationsPage[],
  pageParams: any[]
}

export type RequestNotificationsQueryData = {
  pages: RequestNotificationsPage[],
  pageParams: any[]
}

export type OtherNotificationsQueryData = {
  pages: OtherNotificationsPage[],
  pageParams: any[]
}

const NOTIFICATION_TYPES = {
  APPROVALS: 'approvals',
  REQUESTS: 'requests',
  OTHERS: 'others',
};

const { APPROVALS, REQUESTS, OTHERS } = NOTIFICATION_TYPES;

const NOTIFICATION_URLS = {
  approvals: '/notifications/approvals',
  requests: '/notifications/requests',
  others: '/notifications/others',
};

const defaultPageParams = ({ limit = 5 }) => ({
  offset: 0,
  limit,
});

export const useNotifications = ({ enabled = true, limit = 10 } = {}) => {
  const { get, put } = useAPI();

  const getNotifications = (type) => (pageParam) => get({
    url: NOTIFICATION_URLS[type],
    params: pageParam,
  }, (d) => replaceKeysDeep(d, {
    group: 'team',
  }));

  const getApprovalNotifications = getNotifications(APPROVALS);
  const {
    data: approvalNotificationsQueryData,
    isLoading: loadingApprovalNotifications,
    isFetching: fetchingApprovalNotifications,
    fetchNextPage: fetchMoreApprovalNotifications,
    hasNextPage: hasMoreApprovalNotifications,
  } = useInfiniteQuery('approvalNotifications', ({ pageParam = defaultPageParams({ limit }) }) => getApprovalNotifications(pageParam), {
    enabled,
    refetchOnWindowFocus: 'always',
    getNextPageParam: (newPage) => {
      const next = _get(newPage, 'nextOffset', 0);
      const prev = _get(newPage, 'previousOffset', 0);
      if (next < prev + limit) return undefined;
      return {
        offset: next,
        limit,
      };
    },
  });

  const getRequestNotifications = getNotifications(REQUESTS);
  const {
    data: requestNotificationsQueryData,
    isLoading: loadingRequestNotifications,
    isFetching: fetchingRequestNotifications,
    fetchNextPage: fetchMoreRequestNotifications,
    hasNextPage: hasMoreRequestNotifications,
  } = useInfiniteQuery('requestNotifications', ({ pageParam = defaultPageParams({ limit }) }) => getRequestNotifications(pageParam), {
    enabled,
    refetchOnWindowFocus: 'always',
    getNextPageParam: (newPage) => {
      const next = _get(newPage, 'nextOffset', 0);
      const prev = _get(newPage, 'previousOffset', 0);
      if (next < prev + limit) return undefined;
      return {
        offset: next,
        limit,
      };
    },
  });

  const getOtherNotifications = getNotifications(OTHERS);
  const {
    data: otherNotificationsQueryData,
    isLoading: loadingOtherNotifications,
    isFetching: fetchingOtherNotifications,
    fetchNextPage: fetchMoreOtherNotifications,
    hasNextPage: hasMoreOtherNotifications,
  } = useInfiniteQuery('otherNotifications', ({ pageParam = defaultPageParams({ limit }) }) => getOtherNotifications(pageParam), {
    enabled,
    refetchOnWindowFocus: 'always',
    getNextPageParam: (newPage) => {
      const next = _get(newPage, 'nextOffset', 0);
      const prev = _get(newPage, 'previousOffset', 0);
      if (next < prev + limit) return undefined;
      return {
        offset: next,
        limit,
      };
    },
  });

  // question: why so many "as any"s?
  // answer: https://github.com/microsoft/TypeScript/issues/36390
  const approvalNotifications = (_get(approvalNotificationsQueryData, 'pages', []) as any).reduce((all, page) => all.concat(page[APPROVALS]), []);
  const requestNotifications = (_get(requestNotificationsQueryData, 'pages', []) as any).reduce((all, page) => all.concat(page[REQUESTS]), []);
  const otherNotifications = (_get(otherNotificationsQueryData, 'pages', []) as any).reduce((all, page) => all.concat(page[OTHERS]), []);

  const countTotalUnread = (queryData) => {
    const pages = _get(queryData, 'pages', []);
    const lastPage = pages[pages.length - 1];
    return _get(lastPage, 'totalUnread', 0);
  };

  const loading = loadingApprovalNotifications
          || loadingRequestNotifications
          || loadingOtherNotifications;

  const fetching = fetchingApprovalNotifications
          || fetchingRequestNotifications
          || fetchingOtherNotifications;

  const fetchMoreNotifications = (type) => {
    switch (type) {
      case APPROVALS:
        return fetchMoreApprovalNotifications();
      case REQUESTS:
        return fetchMoreRequestNotifications();
      case OTHERS:
        return fetchMoreOtherNotifications();
      default:
        break;
    }
    return undefined;
  };

  const markAsReadAPI = ({ id, context }) => put({
    url: context === 'OTHERS' ? `/notifications/others/${id}` : `/notifications/${id}`,
    data: {
      isRead: true,
    },
  });
  const queryClient = useQueryClient();
  const { mutateAsync: markAsRead } = useMutation(markAsReadAPI, {
    onSuccess: (_, { id }) => {
      queryClient.setQueryData<any>('requestNotifications', (oldData: RequestNotificationsQueryData) => ({
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          totalUnread: page.totalUnread - 1,
          requests: page.requests.map((request) => ({
            ...request,
            isRead: request.id === id ? true : request.isRead,
          })),
        })),
      }));
      queryClient.setQueryData<any>('otherNotifications', (oldData: OtherNotificationsQueryData) => ({
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          totalUnread: page.totalUnread - 1,
          others: page.others.map((request) => ({
            ...request,
            isRead: request.id === id ? true : request.isRead,
          })),
        })),
      }));
    },
    // TODO: Remove duplication here
  });

  const replaceGroupToTeam = (d) => replaceKeysDeep(d, {
    group: 'team',
    groups: 'teams',
    groupID: 'teamID',
    groupIDs: 'teamIDs',
  });

  return {
    approvalNotifications: replaceGroupToTeam(approvalNotifications),
    requestNotifications: replaceGroupToTeam(requestNotifications),
    otherNotifications: replaceGroupToTeam(otherNotifications),
    loadingApprovalNotifications,
    loadingRequestNotifications,
    loadingOtherNotifications,
    fetchingApprovalNotifications,
    fetchingRequestNotifications,
    fetchingOtherNotifications,
    loading,
    fetching,
    fetchMoreNotifications,
    hasMoreApprovalNotifications,
    hasMoreRequestNotifications,
    hasMoreOtherNotifications,
    totalUnreadRequestNotifications: countTotalUnread(requestNotificationsQueryData),
    totalUnreadOtherNotifications: countTotalUnread(otherNotificationsQueryData),
    markNotificationAsRead: markAsRead,
  };
};
