import React from 'react';
import { Badge } from '@material-ui/core';

export const PauseBadge = ({
  children, variant = 'dot', invisible = false, ...props
}: {
    children: React.ReactNode,
    variant?: 'dot' | 'standard',
    invisible?: boolean,
    [key: string]: any
}) => (
  <Badge
    {...props}
    variant={variant}
    invisible={invisible}
    css={(theme) => ({
      '& .MuiBadge-badge': {
        top: '1px',
        right: '1px',
        backgroundColor: theme.get('palette.pause.error.main'),
      },
    })}
  >
    {children}
  </Badge>
);
