import React, { useEffect, useMemo } from 'react';
import { get } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import Link from 'next/link';
import { useEnhancedRouter } from '../../../router';
import { PauseLoader } from '../../components/PauseLoader';
import { useMe } from '../../api/me';
import { useOrg } from '../../api/org';
import Layout from './Layout';
import { PauseTypography } from '../../components/PauseTypography';
import { PauseButton } from '../../components/PauseButton';
import { AppStateEnum, useAppState } from '../../api/app-state';

const PaymentPendingPage = ({ isAdmin = false, logout = () => null }: {
  isAdmin: boolean,
  logout: any
}) => (
  <Layout>
    <div css={(theme) => ({
      margin: 'auto',
      marginTop: theme.spacing(10),
      textAlign: 'center',
      width: theme.spacing(48),
    })}
    >
      <PauseTypography variant="header" css={(theme) => ({ marginBottom: theme.spacing(2) })}>
        Payment pending
        {' '}
        <span role="img" aria-label="emoji">
          🫠
        </span>
      </PauseTypography>

      {isAdmin
        && (
          <div>
            <PauseTypography
              css={(theme) => ({
                marginBottom: theme.spacing(2),
              })}
              variant="title"
              color="pause.primary.medium"
            >
              Uh oh! It seems like payment is pending on your Pause account. Please complete the payment to continue enjoying Pause.
            </PauseTypography>
            <Link href="/settings/billing">
              <PauseButton>
                Go to billing
              </PauseButton>
            </Link>
          </div>
        )}
      {!isAdmin
        && (
          <div>
            <PauseTypography
              css={(theme) => ({
                marginBottom: theme.spacing(2),
              })}
              variant="title"
              color="pause.primary.medium"
            >
              Uh oh! It seems like payment is pending on your Pause account. Please contact your admin and complete the payment to continue enjoying Pause.
            </PauseTypography>
          </div>
        )}
      <PauseTypography variant="caption" component="div" css={(theme) => ({ cursor: 'pointer', marginTop: theme.spacing(2) })} onClick={() => logout({ returnTo: window.location.origin })}>
        Logout
      </PauseTypography>
    </div>
  </Layout>
);

export const PauseSubscriptionCheck = ({ children }: {
  children: React.ReactNode,
}) => {
  const router = useEnhancedRouter();
  const [routerResolved, setRouterResolved] = React.useState<boolean>(false);
  const { me } = useMe();
  const { logout } = useAuth0();
  const { appState } = useAppState();
  const { orgPaymentPending } = useOrg();

  const isMeAdmin: boolean = get(me, 'isAdmin') || false;

  useEffect(() => {
    // resolve router if appState is ready
    if (appState !== AppStateEnum.notAuthenticated) {
      setRouterResolved(true);
    }
  }, [appState]);

  const showPaymentPending = useMemo(() => {
    const isBillingPage = router.asPath.includes('/settings/billing');
    if ([AppStateEnum.ready].includes(appState as AppStateEnum)) {
      return orgPaymentPending && !isBillingPage;
    }
    return false;
  }, [appState, orgPaymentPending, router.asPath]);

  return (
    <>
      {(!routerResolved) ? (
        <PauseLoader fullPage />
      ) : (showPaymentPending) ? <PaymentPendingPage logout={logout} isAdmin={isMeAdmin} /> : <>{children}</>}
    </>
  );
};
