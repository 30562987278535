import React, { ReactNode } from 'react';
import { TableCell, TableRow, TableBody } from '@material-ui/core';
import { get } from 'lodash';

interface PauseTableRowProps {
  children: ReactNode
  flex?: boolean,
  height?: string
  [x:string]: any
}
export const PauseTableRow = ({
  children, flex = false, height = 'auto', ...otherProps
}: PauseTableRowProps) => (
  <TableRow
    css={(theme) => ({
      ...(flex && {
        display: 'flex',
        alignItems: 'center',
      }),
      ...(otherProps?.onClick && { cursor: 'pointer' }),
      height,
      '&:not(:last-child)': {
        borderColor: theme.get('palette.pause.background.dark'),
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
      },
      '&:hover': {
        ...(otherProps?.onClick && { backgroundColor: get(theme, 'palette.pause.background.light') }),
      },
    })}
    {...otherProps}
  >
    {children}
  </TableRow>
);

interface PauseTableBodyProps {
  children: ReactNode
  [x:string]: any
}

export const PauseTableBody = ({ children, ...otherProps }: PauseTableBodyProps) => (
  <TableBody {...otherProps}>
    {children}
  </TableBody>
);

interface PauseTableBodyCellProps {
  children: ReactNode
  width?: string
  [x:string]: any
}

export const PauseTableBodyCell = ({ children, width = 'auto', ...props }: PauseTableBodyCellProps) => (
  <TableCell
    {...props}
    css={(theme) => ({
      width,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      border: 'none',
    })}
  >
    {children}
  </TableCell>
);
