import React from 'react';
import { PauseContainer } from '../PauseContainer';

export const PauseMainNav = ({ children }) => (
  <div css={(theme) => ({
    backgroundColor: theme.get('palette.pause.background.light'),
    borderBottomWidth: theme.spacing(0.25),
    borderBottomColor: theme.get('palette.pause.background.dark'),
    borderBottomStyle: 'solid',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  })}
  >
    <PauseContainer>
      <div css={() => ({
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        alignItems: 'center',
        justifyContent: 'space-between',
      })}
      >
        {children}
      </div>
    </PauseContainer>
  </div>
);

export const PauseMainNavItemsGroup = ({ children }) => (
  <div css={(theme) => ({
    display: 'grid',
    gridGap: theme.spacing(5),
    alignItems: 'center',
    justifyContent: 'start',
    gridAutoFlow: 'column',
  })}
  >
    {children}
  </div>
);
