import React from 'react';
import { get } from 'lodash';
import { css, Global } from '@emotion/core';
import { useTheme } from 'emotion-theming';

export const Core = () => {
  const theme = useTheme();
  return (
    <>
      <Global styles={css`
        body {
          color: ${get(theme, 'palette.pause.primary.dark')};
        },
        .MuiAutocomplete-popper {
          li.MuiAutocomplete-option {
            padding: 0 !important
          },
        },
      `}
      />
    </>
  );
};
