import React from 'react';
import { TextField } from '@material-ui/core';

interface Props {
  label: string,
  hasTags?: boolean,
  [x:string]: any
}

export const PauseTextField = React.memo(React.forwardRef<Props, any>(({
  label,
  hasTags, ...props
}, ref) => (
  <TextField
    label={label}
    ref={ref}
    variant="filled"
    color="primary"
    css={(theme) => ({
      width: '100%',
      '& .MuiFilledInput-root': {
        ...(hasTags ? {
          paddingTop: '24px',
        } : null),
        borderRadius: theme.spacing(0.5),
        color: theme.get('palette.pause.primary.dark'),
        alignItems: 'flex-start',
      },
      '& .MuiFilledInput-root:not(.Mui-disabled)': {
        backgroundColor: theme.get('palette.pause.background.light'),
        '&:hover': {
          backgroundColor: theme.get('palette.pause.background.medium'),
        },
      },
      '& .MuiFilledInput-root.Mui-disabled': {
        backgroundColor: theme.get('palette.pause.background.light'),
        color: theme.get('palette.pause.primary.medium'),
        cursor: 'not-allowed !important',
      },

      '& .MuiFilledInput-underline:after, & .MuiFilledInput-underline:before': {
        content: 'none',
      },
      '& .MuiFormLabel-root:not(.Mui-error)': {
        color: theme.get('palette.pause.primary.medium'),
      },
      '& .MuiFormLabel-root.Mui-Error': {
        color: theme.get('palette.pause.error.main'),
      },
      '& .MuiFormLabel-root.Mui-focused:not(.Mui-Error)': {
        color: theme.get('palette.pause.primary.main'),
      },
    })}
    {...props}
  />
)));
