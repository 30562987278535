import { SnackbarOrigin, SnackbarProvider, VariantType } from 'notistack';
import React from 'react';
import { PauseSnackbarStore } from './store';

export const DEFAULT_SNACKBAR_OPTIONS = {
  autoHideDuration: 10000,
  variant: 'info' as VariantType,
  anchorOrigin: {
    horizontal: 'center',
    vertical: 'bottom',
  } as SnackbarOrigin,
  maxSnack: 3,
  // content: (key, message) => (
  //   <SnackbarWithActions id={key} message={message} />
  // ),
};

export const PauseSnackbarProvider = ({ children }) => (
  <SnackbarProvider
    {...DEFAULT_SNACKBAR_OPTIONS}
  >
    <PauseSnackbarStore />
    {children}
  </SnackbarProvider>
);
