export enum LeaveAction {
  Submit = 'Submit',
  ApproveReject = 'ApproveReject',
  ApproveDelete = 'ApproveDelete', // admins looking at their own pending leaves will have this state
  Delete = 'Delete',
  Nothing = 'Nothing'
}

export enum LeaveState {
  New = 'New',
  Pending = 'Pending',
  Approved = 'Approved',
  Declined = 'Declined',
  Deleted = 'Deleted'
}

export enum Viewer {
  Admin = 'Admin',
  Approver = 'Approver',
  SelfAdmin = 'SelfAdmin',
  Self = 'Self',
  Others = 'Others'
}
export enum LeaveTemporalPosition {
  Past = 'Past',
  Future = 'Future'
}

export const getLeaveActions = ({
  leaveState,
  viewer,
  leaveTemporalPosition,
}: {
  leaveState: LeaveState,
  viewer: Viewer,
  leaveTemporalPosition: LeaveTemporalPosition
}): LeaveAction => {
  switch (leaveState) {
    case LeaveState.Deleted:
      return LeaveAction.Nothing;
    case LeaveState.Declined:
      return LeaveAction.Nothing;
    case LeaveState.New:
      return LeaveAction.Submit;
    case LeaveState.Pending:
      switch (viewer) {
        case Viewer.SelfAdmin:
          return LeaveAction.ApproveDelete;
        case Viewer.Admin:
          return LeaveAction.ApproveReject;
        case Viewer.Approver:
          return LeaveAction.ApproveReject;
        case Viewer.Self:
          return LeaveAction.Delete;
        default:
          return LeaveAction.Nothing;
      }
    case LeaveState.Approved:
      switch (viewer) {
        case Viewer.Admin:
          return LeaveAction.Delete;
        case Viewer.SelfAdmin:
          return LeaveAction.Delete;
        case Viewer.Approver:
          return LeaveAction.Delete;
        case Viewer.Others:
          return LeaveAction.Nothing;
        case Viewer.Self:
          switch (leaveTemporalPosition) {
            case LeaveTemporalPosition.Future:
              return LeaveAction.Delete;
            default:
              return LeaveAction.Nothing;
          }
        default:
          return LeaveAction.Nothing;
      }
    default:
      return LeaveAction.Nothing;
  }
};
