declare global {
  interface Window {
    plausible: any;
  }
}

export enum AnalyticsEvent {
  SignupCreatedAccount = 'Signup_CreatedAccount',
  SignupAddedOrgInfo = 'Signup_AddedOrgInfo',
  SignupSetLeaveCycle = 'Signup_SetLeaveCycle',
  SignupSetLeaveTypes = 'Signup_SetLeaveTypes',
  SignupFinishedOnboarding = 'Signup_FinishedOnboarding',
}

// const domain = process.env.NODE_ENV === 'production' ? 'getpause.com' : 'test-getpause';
export const postEvent = async (eventData: { name: string; props?: any }) => {
  const { name, props = null } = eventData;
  try {
    if (!name) throw new Error('Event needs a `name` property');
    if (!window.plausible) throw new Error('No plausible instance found on window');
    window.plausible(name, { props });
    return true;
  } catch (e) {
    console.log('Plausible error:', e);
    return false;
  }
};

export const trackSignupEvent = (ref: string) =>
  postEvent({
    name: 'Signup',
    props: {
      Referral: ref,
    },
  });
