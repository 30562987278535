import React, { createRef, useEffect } from 'react';
import { Fade, Popover } from '@material-ui/core';

type Props = {
  children: React.ReactNode,
  id: string,
  isOpen: boolean,
  onClose: (any?) => any,
  autoWidth: boolean,
  dropdownContent: React.ReactNode,
  keepMounted?: boolean,
  disableScrollLock?: boolean
}

export const PauseDropdown = ({
  children,
  id,
  isOpen,
  onClose = () => {},
  autoWidth = false,
  dropdownContent,
  keepMounted,
  disableScrollLock,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [popoverWidth, setPopoverWidth] = React.useState<string | number | null>(null);
  const anchorRef = createRef<any>();

  useEffect(() => {
    if (anchorRef && !anchorEl) {
      setAnchorEl(anchorRef.current);
    }
  }, [anchorRef]);

  useEffect(() => {
    if (anchorRef) {
      setPopoverWidth(anchorRef.current.clientWidth);
    }
  }, [anchorRef]);

  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <div css={{ zIndex: isOpen ? 1500 : 0, position: 'relative' }} ref={anchorRef} aria-describedby={id} aria-haspopup="true" id={id}>
        {children}
      </div>
      <Popover
        id={id}
        {...disableScrollLock && {
          container: anchorEl?.parentNode,
        }}
        anchorEl={anchorEl}
        open={anchorEl && isOpen}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 0,
        }}
        keepMounted={keepMounted}
        transformOrigin={{
          vertical: -4,
          horizontal: 0,
        }}
        css={(theme) => ({
          '.MuiPopover-paper': {
            width: popoverWidth && !autoWidth ? `${popoverWidth}px` : 'auto',
            boxShadow: theme.pauseShadows['16'],
            borderRadius: theme.spacing(1.5),
            background: theme.get('palette.pause.white.main'),
          },
        })}
      >
        {dropdownContent}
      </Popover>

    </div>
  );
};
