import React, { useMemo, useState } from 'react';
import { omit } from 'lodash';
import { PauseTypography } from '../../../components/PauseTypography';
import { PausePerson } from '../../../components/PausePerson';
import {
  Search,
} from '../../../../assets/icons/feather/components';
import { PauseTextButton } from '../../../components/PauseTextButton';
import { PauseInteractiveButton } from '../../../components/PauseInteractiveButton';
import { PauseInput } from '../../../components/PauseInput';
import fromEntries from '../../../../utils/fromEntries';

const PadContent = ({ children }: any) => (
  <div css={(t) => ({
    padding: t.spacing(0, 2.5),
  })}
  >
    {children}
  </div>
);

const MembersList = (props: {
  onItemActionClick: (any) => any
  members: any[],
  heading: string,
  itemActionText: string,
  isItemActionVisible: boolean,
}) => {
  const {
    members = [],
    heading = '',
    itemActionText = '',
    isItemActionVisible = false,
    onItemActionClick = () => null,
  } = props;
  return (
    <div css={(t) => ({
      display: 'grid',
      gridGap: t.spacing(3),
    })}
    >
      <div css={(t) => ({
        padding: t.spacing(1, 2.5),
        background: t.get('palette.pause.background.medium'),
        color: t.get('palette.pause.primary.light'),
      })}
      >
        <PauseTypography
          variant="caption"
        >
          {heading}
        </PauseTypography>
      </div>
      <div>
        <PadContent>
          {members.map((person) => (
            <div
              key={person.id}
              css={(t) => ({
                display: 'grid',
                gridGap: t.spacing(2),
                alignItems: 'center',
                justifyContent: 'space-between',
                gridAutoFlow: 'column',
                gridTemplateColumns: '1fr auto',
                marginBottom: t.spacing(2),
              })}
            >
              <PausePerson {...person} />
              {isItemActionVisible && (
              <div>
                <PauseInteractiveButton
                  onClick={() => onItemActionClick(person.id)}
                  buttonComponent={PauseTextButton}
                  compact
                  inline
                >
                  {itemActionText}
                </PauseInteractiveButton>
              </div>
              )}
            </div>
          ))}
        </PadContent>
      </div>
    </div>
  );
};

export const PHCalendarMembersList = (props: {
  members: string[],
  onRemoveMember: (personID: string) => any,
  onAddMember: (personID: string) => any,
  isAdmin: boolean,
  teams: any[],
  people: any[]
}) => {
  const {
    members = [],
    onRemoveMember = () => null,
    onAddMember = () => null,
    isAdmin = false,
    teams = [],
    people: peopleRaw = [],
  } = props;
  const teamsDict = fromEntries((teams || []).map((t) => [t.id, t.name]));
  const [searchText, setSearchText] = useState<string>('');
  const people = useMemo(() => {
    if (!searchText || !searchText.trim()?.length) return peopleRaw;
    return (peopleRaw || []).filter((p) => {
      const searchTextNormalized = searchText.toLowerCase();
      const name = p.fullName?.toLowerCase();
      const email = p.email.toLowerCase();
      return (name.indexOf(searchTextNormalized) || email.indexOf(searchTextNormalized)) > -1;
    });
  }, [peopleRaw, searchText]);
  const filteredMembers: {
    partOfCurrentCalendar: any[],
    partOfSomeOtherCalendar: any[],
    notPartOfAnyCalendar: any[]
  } = (people || []).reduce((acc, currPerson) => {
    const person: any = {
      label: currPerson.fullName,
      value: currPerson.id,
      teamName: teamsDict[currPerson.teamID],
      ...omit(currPerson, ['hasLoggedIn', 'allowance']),
    };
    if (members.includes(person.id)) {
      return {
        ...acc,
        partOfCurrentCalendar: (acc.partOfCurrentCalendar || []).concat(person),
      };
    }
    if (person.applicablePublicHolidayCalendars?.length > 0) {
      return {
        ...acc,
        partOfSomeOtherCalendar: (acc.partOfSomeOtherCalendar || []).concat(person),
      };
    }
    return {
      ...acc,
      notPartOfAnyCalendar: (acc.notPartOfAnyCalendar || []).concat(person),
    };
  }, {
    partOfCurrentCalendar: [],
    partOfSomeOtherCalendar: [],
    notPartOfAnyCalendar: [],
  });

  return (
    <div css={(t) => ({
      display: 'grid',
      gridGap: t.spacing(3),
      gridAutoFlow: 'row',
    })}
    >
      <PadContent>
        <PauseInput
        // @ts-ignore
          placeholder="Search for members"
          leftIcon={<Search />}
          value={searchText}
          onChange={(e) => setSearchText(e?.target?.value || '')}
        />
      </PadContent>
      <div css={(t) => ({
        display: 'grid',
        gridGap: t.spacing(3),
      })}
      >
        {filteredMembers.partOfCurrentCalendar.length ? (
          <MembersList
            onItemActionClick={onRemoveMember}
            members={filteredMembers.partOfCurrentCalendar}
            heading="Members with this calendar"
            itemActionText="Remove"
            isItemActionVisible={isAdmin}
          />
        ) : null}

        {filteredMembers.notPartOfAnyCalendar.length && isAdmin ? (
          <MembersList
            onItemActionClick={onAddMember}
            members={filteredMembers.notPartOfAnyCalendar}
            heading="Members without a calendar"
            itemActionText="Add"
            isItemActionVisible={isAdmin}
          />
        ) : null}

        {filteredMembers.partOfSomeOtherCalendar.length && isAdmin ? (
          <MembersList
            onItemActionClick={onAddMember}
            members={filteredMembers.partOfSomeOtherCalendar}
            heading="Members with a different calendar"
            itemActionText="Change to this calendar"
            isItemActionVisible={isAdmin}
          />
        ) : null}

      </div>
    </div>
  );
};
