import moment from 'moment';
import { isEqual, get } from 'lodash';
import { DAY_FORMAT } from '../src/components/PauseCalendar/PauseFullCalendar/constants';

const singleDayScenarios = [
  {
    values: {
      startType: 'START_OF_DAY',
      endType: 'END_OF_DAY',
    },
    label: 'Full day',
  },
  {
    values: {
      startType: 'START_OF_DAY',
      endType: 'END_OF_FIRST_HALF',
    },
    label: '1st half',
  },
  {
    values: {
      startType: 'START_OF_SECOND_HALF',
      endType: 'END_OF_DAY',
    },
    label: '2nd half',
  },
];

const multiDayStartTypeScenarios = [
  {
    values: {
      startType: 'START_OF_DAY',
    },
    label: 'Full day',
  },
  {
    values: {
      startType: 'START_OF_SECOND_HALF',
    },
    label: '2nd half',
  },
];

const multiDayEndTypeScenarios = [
  {
    values: {
      endType: 'END_OF_DAY',
    },
    label: 'Full day',
  },
  {
    values: {
      endType: 'END_OF_FIRST_HALF',
    },
    label: '1st half',
  },
];
export const getLeaveInWords = ({
  startDate, startType, endDate, endType,
}: {
  startDate: string | moment.Moment,
  startType: string,
  endDate: string | moment.Moment,
  endType: string
}): string => {
  const startDateObj = moment(startDate, DAY_FORMAT);
  const endDateObj = moment(endDate, DAY_FORMAT);

  if (startDateObj.isSame(endDateObj)) {
    const dayTypes = {
      startType, endType,
    };
    const dayTypeString = get(singleDayScenarios.find(
      (s) => isEqual(s.values, dayTypes),
    ), 'label');
    return `${startDateObj.format('Do MMM')}, ${dayTypeString}`;
  }

  const startTypeString = get(multiDayStartTypeScenarios.find(
    (s) => isEqual(s.values, { startType }),
  ), 'label');
  const endTypeString = get(multiDayEndTypeScenarios.find(
    (s) => isEqual(s.values, { endType }),
  ), 'label');
  return `${startDateObj.format('Do MMM')}, ${startTypeString} → ${endDateObj.format('Do MMM')}, ${endTypeString}`;
};
