import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { PauseDateHandlerContents, PauseStartOrEndDateHandler } from './components';
import { DAY_FORMAT } from '../PauseCalendar/PauseFullCalendar/constants';
import { PauseTextButton } from '../PauseTextButton';
import { ArrowRight, Plus } from '../../../assets/icons/feather/components';
import { PauseDatepicker } from './index';
import { PauseDivider } from '../PauseDivider';
import { PauseDropdown } from '../PauseDropdown';
import { PauseTypography } from '../PauseTypography';
import { PauseButton } from '../PauseButton';

type LeaveDateRangePickerProps = {
  onChange: (any?) => any,
  startDate: moment.Moment,
  endDate: moment.Moment,
  startType: string,
  endType: string,
  isReadOnly: boolean
}

export const LeaveDateRangePicker = React.memo<LeaveDateRangePickerProps>(({
  onChange = () => {},
  startDate = moment(),
  endDate = moment(),
  startType = 'START_OF_DAY',
  endType = 'END_OF_DAY',
  isReadOnly,
}) => {
  const leaveDateObject = {
    startDate,
    endDate,
    startType,
    endType,
  };

  const isSameDay = moment(startDate, DAY_FORMAT).isSame(moment(endDate, DAY_FORMAT));
  const [mode, setMode] = useState(isSameDay ? 'single' : 'range');

  useEffect(() => {
    if (!isSameDay) {
      setMode('range');
    } else setMode('single');
  }, [isSameDay]);

  // const [dateType, setDateType] = useState('start');Pause
  const [currentDate, setCurrentDate] = useState(moment());

  const [activeDateType, setActiveDateType] = useState('');

  const [isBeingEdited, setIsBeingEdited] = useState(false);

  useEffect(() => {
    if (mode === 'range' && isBeingEdited && moment(startDate, DAY_FORMAT)
      .isSame(moment(endDate, DAY_FORMAT))) {
      onChange({
        ...leaveDateObject,
        endDate: moment(startDate, DAY_FORMAT).add(1, 'day').format(DAY_FORMAT),
      });
    }
  }, [isBeingEdited, startDate, endDate, leaveDateObject, onChange, mode]);

  useEffect(() => {
    setCurrentDate(moment(startDate));
  }, [startDate]);

  const startDateOptions = [
    {
      actualValue: {
        startType: 'START_OF_DAY',
        endType: 'END_OF_DAY',
      },
      value: 'FULL_DAY',
      label: 'Full day',
    },
    {
      actualValue: {
        startType: 'START_OF_DAY',
        endType: 'END_OF_FIRST_HALF',
      },
      value: 'FIRST_HALF',
      label: '1st half',
    },
    {
      actualValue: {
        startType: 'START_OF_SECOND_HALF',
        endType: 'END_OF_DAY',
      },
      value: 'SECOND_HALF',
      label: '2nd half',
    },
  ];

  const endDateOptions = [
    {
      actualValue: {
        endType: 'END_OF_DAY',
      },
      value: 'FULL_DAY',
      label: 'Full day',
    },
    {
      actualValue: {
        endType: 'END_OF_FIRST_HALF',
      },
      value: 'FIRST_HALF',
      label: '1st half',
    },
  ];

  return (!isBeingEdited ? (
    <div
      css={(theme) => ({
        cursor: isReadOnly ? 'default' : 'pointer',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        display: 'grid',
        gridAutoFlow: 'column',
        alignItems: 'center',
        gridGap: theme.spacing(3),
        justifyContent: 'start',
      })}
      {...(!isReadOnly && {
        onClick: () => setIsBeingEdited(true),
      })}
    >
      <div>
        <PauseDateHandlerContents label={moment(endDate, DAY_FORMAT).isSame(moment(startDate, DAY_FORMAT)) ? 'On' : 'From'}>
          <PauseTypography
            css={(theme) => ({
              marginTop: theme.spacing(0.5),
            })}
            color="pause.primary.dark"
          >
            {moment(startDate).format('MMM DD, YYYY')}
            <PauseTypography variant="body2" color="pause.primary.medium">
              {get(startDateOptions.find(
                (o) => (mode === 'single' ? o.actualValue.startType === startType
                              && o.actualValue.endType === endType
                  : o.actualValue.startType === startType),
              ), 'label')}
            </PauseTypography>
          </PauseTypography>
        </PauseDateHandlerContents>
      </div>
      {mode === 'range'
      && (
      <>
        <div css={(theme) => ({
          color: theme.get('palette.pause.primary.medium'),
          fontSize: theme.spacing(3),
        })}
        >
          <ArrowRight />
        </div>
        <div>
          <PauseDateHandlerContents label="To">
            <PauseTypography
              css={(theme) => ({
                marginTop: theme.spacing(0.5),
              })}
              color="pause.primary.dark"
            >
              {moment(endDate).format('MMM DD, YYYY')}
              <PauseTypography variant="body2" color="pause.primary.medium">
                {get(endDateOptions.find(
                  (o) => o.actualValue.endType === endType,
                ), 'label')}
              </PauseTypography>
            </PauseTypography>
          </PauseDateHandlerContents>
        </div>
      </>
      )}
    </div>
  ) : (
    <div css={{
      width: '100%',
    }}
    >
      <div css={(theme) => ({
        display: 'grid',
        // gridTemplateRows: '1fr auto',
        gridGap: theme.spacing(1),
        marginBottom: theme.spacing(2),
      })}
      >
        <PauseDropdown
          autoWidth
          id="start-date"
          isOpen={activeDateType === 'start'}
          onClose={() => {
            setActiveDateType('');
          }}
          dropdownContent={(
            <div css={(theme) => ({
              padding: theme.spacing(1),
            })}
            >
              <PauseDatepicker
                nonWorkingDays={[]}
                dateToShow={currentDate}
                startDate={startDate}
                endDate={endDate}
                startType={startType}
                endType={endType}
                dateTypeToChange="start"
                mode={mode}
                onChange={(values) => {
                  // if (values.dateType === 'end') {
                  //   setDateType('end');
                  // }
                  onChange({ ...leaveDateObject, ...values });
                }}
              />
            </div>
          )}
        >
          <PauseStartOrEndDateHandler
            // id="start"
            label={mode === 'range' ? 'From' : 'On'}
            onClick={() => {
              setActiveDateType('start');
              // setDateType('start');
              setCurrentDate(moment(startDate, DAY_FORMAT));
            }}
            // selected={dateType === 'start'}
            date={startDate}
            dayTypeOptions={mode === 'single' ? startDateOptions : [startDateOptions[0], startDateOptions[2]]}
            dayTypeValue={startDateOptions.find(
              (o) => (mode === 'single' ? o.actualValue.startType === startType
                  && o.actualValue.endType === endType
                : o.actualValue.startType === startType),
            ) || {
              actualValue: {
                startType: 'START_OF_DAY',
                endType: 'END_OF_DAY',
              },
              value: 'FULL_DAY',
              label: 'Full day',
            }}
            dayTypeOnChange={(v) => {
              const value = startDateOptions.find((o) => o.value === v);
              onChange({
                ...leaveDateObject,
                startType: get(value, 'actualValue.startType'),
                ...(mode === 'single' && {
                  endType: get(value, 'actualValue.endType'),
                }),
              });
            }}
          />
        </PauseDropdown>
        <PauseDivider />
        <div>
          {mode === 'range' ? (
            <PauseDropdown
              autoWidth
              id="end-date"
              isOpen={activeDateType === 'end'}
              onClose={() => {
                setActiveDateType('');
              }}
              dropdownContent={(
                <div css={(theme) => ({
                  padding: theme.spacing(1),
                })}
                >
                  <PauseDatepicker
                    nonWorkingDays={[]}
                    dateToShow={currentDate}
                    startDate={startDate}
                    endDate={endDate}
                    startType={startType}
                    endType={endType}
                    dateTypeToChange="end"
                    mode={mode}
                    onChange={(values) => {
                      // if (values.dateType === 'end') {
                      //   setDateType('end');
                      // }
                      onChange({ ...leaveDateObject, ...values });
                    }}
                  />
                </div>
              )}
            >
              <PauseStartOrEndDateHandler
                // id="end"
                label="To"
                onClick={() => {
                  setActiveDateType('end');
                  // setDateType('end');
                  setCurrentDate(moment(endDate, DAY_FORMAT));
                }}
                // selected={dateType === 'end'}
                date={endDate}
                dayTypeOptions={endDateOptions}
                dayTypeValue={endDateOptions.find(
                  (o) => o.actualValue.endType === endType,
                ) || {
                  actualValue: {
                    endType: 'END_OF_DAY',
                  },
                  value: 'FULL_DAY',
                  label: 'Full day',
                }}
                dayTypeOnChange={(v) => {
                  const value = endDateOptions.find((o) => o.value === v);
                  onChange({
                    ...leaveDateObject,
                    endType: get(value, 'actualValue.endType'),
                  });
                }}
                onClickCloseButton={() => {
                  setMode('single');
                  onChange({
                    ...leaveDateObject,
                    endDate: startDate,
                    // startType: 'START_OF_DAY',
                    endType: 'END_OF_DAY',
                  });
                  // setDateType('start');
                }}
                showCloseButton
              />
            </PauseDropdown>
          ) : (
            <PauseTypography
              css={(theme) => ({
                display: 'grid',
                alignItems: 'center',
                justifyContent: 'start',
                justifyItems: 'start',
                // flexDirection: 'row',
                gridAutoFlow: 'column',
                marginTop: -theme.spacing(0.5),
              })}
              variant="button"
              color="pause.primary.light"
            >
              <PauseTextButton
                onClick={() => {
                  onChange({
                    ...leaveDateObject,
                    endDate: moment(startDate, DAY_FORMAT).clone().add(1, 'days').format(DAY_FORMAT),
                    endType: 'END_OF_DAY',
                  });
                  setActiveDateType('end');
                  // setDateType('end');
                  setMode('range');
                }}
                size="medium"
                startIcon={<Plus />}
                color="primary"
              >
                Add End Date
              </PauseTextButton>
              (for multi-day leaves)
            </PauseTypography>
          )}
        </div>
      </div>

      <div css={() => ({
        float: 'left',
      })}
      >
        <PauseButton size="small" onClick={() => setIsBeingEdited(false)}>Done</PauseButton>
      </div>
    </div>
  ));
});
