import { useQuery, useQueryClient } from 'react-query';

const add = (data) => (oldData) => ([] as any).concat(oldData, data);
const remove = (notificationKey) => (oldData) => oldData
  .filter((n) => n.key !== notificationKey);
export const useSnackbarNotifications = () => {
  const { data } = useQuery<any[]>('snackbarNotifications', { initialData: [] });

  const queryClient = useQueryClient();

  const enqueueSnackbarNotification = (notification) => {
    const key = notification.options && notification.options.key;
    queryClient.setQueryData<any>('snackbarNotifications', add({
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    }));
  };

  const removeSnackbarNotification = (key) => {
    queryClient.setQueryData<any>('snackbarNotifications', remove(key));
  };

  return {
    snackbarNotifications: data || [],
    enqueueSnackbarNotification,
    removeSnackbarNotification,
  };
};
