import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { InputBase } from '@material-ui/core';
import { get } from 'lodash';
import { PauseAutocompleteSelectedListItem } from './PauseAutocompleteSelectedListItem';
import { ChevronDown } from '../../../assets/icons/feather/components';

type Props = {
  autoComplete?: boolean,
  clearOnEscape?: boolean,
  options?: any[],
  multiple?: boolean,
  dropdownItemComponent?: React.ReactElement,
  placeholder?: string,
  children?: React.ReactElement,
  labelKey?: string,
  onChange: (any?) => any,
  value: any,
  showDropdownIcon?: boolean,
  inputComponent?: React.ReactElement,
  id: string | number | null
  name?: string,
  disableCloseOnSelect?: boolean,
  disabled?: boolean,
  [key: string]: any
}

export const PauseAutocomplete = React.memo(({
  autoComplete = true,
  clearOnEscape = true,
  options = [],
  multiple = false,
  dropdownItemComponent = <></>,
  placeholder = 'Select',
  children = <></>,
  labelKey = 'label',
  onChange = () => ({}),
  value,
  showDropdownIcon = false,
  inputComponent,
  id,
  name = undefined,
  disableCloseOnSelect = undefined,
  disabled = false,
  ...otherProps
}: Props) => {
  if (!labelKey) { throw new Error('No labelKey provided for Autocomplete'); }

  const inputCSS = (theme) => ({
    transition: 'all 0.2s ease-in-out',
    '&.MuiInputBase-root': {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
      borderRadius: theme.spacing(1),
      color: get(theme, 'palette.pause.primary.main'),
      cursor: !disabled ? 'pointer' : 'not-allowed',
      border: '2px solid transparent',
      ...(!disabled && {
        '&:hover': {
          background: get(theme, 'palette.pause.background.dark'),
        },
      }),
      '&.Mui-focused': {
        background: get(theme, 'palette.pause.background.dark'),
        borderColor: get(theme, 'palette.pause.primary.medium'),
      },
    },
    '.MuiInputBase-input': {
      cursor: !disabled ? 'pointer' : 'not-allowed',
    },
    '.MuiInputBase-input:focus': {
      cursor: !disabled ? 'pointer' : 'not-allowed',
    },
  });

  const autoCompleteContainerCSS = (theme) => ({
    '.MuiAutocomplete-popperDisablePortal': {
      borderRadius: theme.spacing(2),
      // padding: 0,
    },
    '.MuiAutocomplete-paper': {
      borderRadius: theme.spacing(2),
      // padding: 0,
    },
    '.MuiAutocomplete-listbox': {
      // padding: 0,
    },
    '.MuiAutocomplete-option': {
      ...(dropdownItemComponent && {
        padding: 0,
      }),
      '&:hover': {
        background: get(theme, 'palette.pause.background.medium'),
      },
      '&[data-focus=true]': {
        background: get(theme, 'palette.pause.background.medium'),
      },
      '&[aria-selected=true]': {
        background: get(theme, 'palette.pause.background.dark'),
      },
    },
  });

  return (
    <div css={autoCompleteContainerCSS}>
      <Autocomplete
        disablePortal
        openOnFocus
        multiple={multiple}
        disableCloseOnSelect={typeof disableCloseOnSelect === 'undefined' ? multiple : disableCloseOnSelect}
        autoComplete={autoComplete}
        clearOnEscape={clearOnEscape}
        options={options}
        getOptionLabel={(option) => (labelKey ? option[labelKey] : (option || ''))}
        renderInput={(params) => React.cloneElement(
          inputComponent
                    || (
                    <InputBase
                      endAdornment={showDropdownIcon && !disabled && <ChevronDown />}
                      css={inputCSS}
                      fullWidth
                    />
                    ),
          {
            ...params,
            ...(inputComponent && { ...params.inputProps }),
            ref: params.InputProps.ref,
            placeholder,
          },
        )}
        getOptionSelected={(option, thisValue) => option.id === thisValue.id}
        {...(dropdownItemComponent && {
          renderOption: (option, { selected }) => (
            <PauseAutocompleteSelectedListItem
              // @ts-ignore
              styles={(theme: any) => ({
                borderRadius: 0,
                paddingLeft: theme.spacing(1.5),
                paddingRight: theme.spacing(1.5),
              })}
              selected={selected}
            >
              {React.cloneElement(dropdownItemComponent, { ...option, selected })}
            </PauseAutocompleteSelectedListItem>
          ),
        })}
        onChange={(_, v) => onChange({
          target: {
            autoComplete: true,
            value: v,
            name,
            id,
          },
        })}
        value={value || (multiple ? [] : null)}
        disabled={disabled}
        {...otherProps}
      />
    </div>
  );
});
