import React from 'react';

export const PauseDivider = () => (
  <div css={(theme) => ({
    height: '1px',
    width: '100%',
    backgroundColor: theme.get('palette.pause.background.dark'),
  })}
  />
);
