import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { PauseButton } from '../PauseButton';
import { PauseIconButton } from '../PauseIconButton';
import { PauseTextButton } from '../PauseTextButton';

export const PauseSubmitButton = ({
  disableIfFormNotValid,
  children, enableForInitialValid,
  disabled,
}) => {
  const {
    isSubmitting, isValid, dirty, submitForm,
  } = useFormikContext();

  return React.cloneElement(children, {
    onClick: (e) => {
      e.preventDefault();
      return submitForm();
    },
    isLoading: isSubmitting,
    disabled: disabled || (!(enableForInitialValid && isValid)
            && disableIfFormNotValid && (!isValid || !dirty)),
  });
};

PauseSubmitButton.propTypes = {
  disableIfFormNotValid: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.objectOf(PauseButton),
    PropTypes.objectOf(PauseIconButton),
    PropTypes.objectOf(PauseTextButton),
  ]),
  enableForInitialValid: PropTypes.bool,
  disabled: PropTypes.bool,
};

PauseSubmitButton.defaultProps = {
  children: null,
  disableIfFormNotValid: true,
  enableForInitialValid: false,
  disabled: false,
};
