import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient, useQuery } from 'react-query';
import { AppStateEnum } from './app-state';

export const useAuth = () => {
  const queryClient = useQueryClient();
  const { data: token } = useQuery('token', () => Promise.resolve());
  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
    user,
  } = useAuth0();
  useEffect(() => {
    if (isLoading || token) return;
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: { returnTo: window.location.href },
        redirect_uri: window.location.origin,
      });
      return;
    }
    (async function () {
      const tokenFromAuth0 = await getAccessTokenSilently({
        audience: process.env.NEXT_PUBLIC_AUDIENCE,
        scope: process.env.NEXT_PUBLIC_AUTH0_SCOPE,
      });
      queryClient.setQueryData('token', () => tokenFromAuth0);
      queryClient.setQueryData('appState', () => AppStateEnum.justAuthenticated);
    }());
  }, [isLoading,
    isAuthenticated,
    user,
    token,
    loginWithRedirect,
    getAccessTokenSilently,
    queryClient]);

  return {
    isLoading,
    isAuthenticated,
    user,
    logout: () => {
      window.$crisp.push(['do', 'session:reset']); // removes any ID associated with $crisp for this user
      logout({ returnTo: window.location.origin });
    },
  };
};
