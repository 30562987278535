import React, { useEffect, useState } from 'react';
import {
  Formik,
} from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import {
  PauseDrawerContent, PauseDrawerFooter, PauseDrawerTitle,
} from '../../../components/PauseDrawer';
import { PauseTypography } from '../../../components/PauseTypography';
import { PauseSubmitButton } from '../../../components/PauseSubmitButton';
import { PauseButton } from '../../../components/PauseButton';
import { PHDatesForm } from './PHDatesForm';
import { noop } from '../../../../utils/noop';

type CommonProps = {
  initialData: Array<{
    date: string | moment.Moment,
    isNew?: boolean,
    isBeingEdited?: boolean,
    name: string,
    id: string | null,
  }>,
  calendarName: string,
  onBack: () => any,
}

type NewCalendarFormProps = CommonProps & {
  onSubmit?: (a: any[]) => any
}

type ExistingCalendarFormProps = CommonProps & {
  autoSave?: boolean,
  onEdit?: (a: {
    id: string,
    name: string,
    date: string | moment.Moment
  }) => Promise<any>,
  onDelete?: (a: {id: string}) => Promise<any>,
  onAdd?: (a: {
    name: string,
    date: string | moment.Moment
  }) => Promise<any>,
}

export const NewPublicHolidayDatesForm = (props: NewCalendarFormProps | ExistingCalendarFormProps) => {
  const {
    initialData = [],
    calendarName = '',
    onBack = () => null,
    onSubmit = () => null,
  } = (props as NewCalendarFormProps);
  const {
    autoSave = false,
    onEdit = () => null,
    onDelete = () => null,
    onAdd = () => null,
  } = (props as ExistingCalendarFormProps);
  const [locallySavedValues, setLocallySavedValues] = useState({
    publicHolidays: initialData,
  });
  useEffect(() => {
    setLocallySavedValues({ publicHolidays: initialData });
  }, [initialData]);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        publicHolidays: initialData,
        // publicHolidays: sortBy(initialData, (ph) => moment(ph.date).unix()),
      }}
      validationSchema={Yup.object({
        publicHolidays: Yup.array().of(Yup.object().shape({
          name: Yup.string().required('Required'), // these constraints take precedence
          date: Yup.mixed().required('Required'), // these constraints take precedence
        })),
      })}
      onSubmit={(values) => {
        if (autoSave) return Promise.resolve();
        return onSubmit(values.publicHolidays).catch(noop);
      }}
    >
      {({ values, setFieldValue }) => (
        <>
          {/* @ts-ignore */}
          <PauseDrawerTitle>
            {calendarName}
          </PauseDrawerTitle>
          <PauseDrawerContent compact>
            <div
              css={(t) => ({
                paddingBottom: t.spacing(5),
              })}
            >
              <div css={(theme) => ({
                margin: theme.spacing(2, 3),
                display: 'grid',
                gridGap: theme.spacing(1),
                gridAutoFlow: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
              })}
              >
                <PauseTypography
                  variant="caption"
                  color="pause.primary.medium"
                >
                  Edit holiday list for
                  {' '}
                  {moment().format('YYYY')}
                </PauseTypography>
                <PauseTypography
                  variant="caption"
                  color="pause.primary.medium"
                >
                  {values.publicHolidays.length}
                  {' '}
                  holidays
                </PauseTypography>
              </div>
              <PHDatesForm
                values={values}
                setFieldValue={setFieldValue}
                setLocallySavedValues={setLocallySavedValues}
                locallySavedValues={locallySavedValues}
                autoSave={autoSave}
                onEdit={onEdit}
                onDelete={onDelete}
                onAdd={onAdd}
                isEditable
              />
            </div>

          </PauseDrawerContent>
          <PauseDrawerFooter>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <PauseButton
                  variant="outlined"
                  onClick={onBack}
                >
                  Back
                </PauseButton>
              </Grid>
              <Grid item xs={6}>
                <PauseSubmitButton
                  enableForInitialValid
                  disableIfFormNotValid
                >
                  <PauseButton>
                    Create calendar
                  </PauseButton>
                </PauseSubmitButton>
              </Grid>
            </Grid>
          </PauseDrawerFooter>
        </>
      )}
    </Formik>
  );
};
