import React from 'react';
import { get } from 'lodash';
import Head from 'next/head';
import { useLeavePolicy, useLeaveTypeForm } from './store';
import { DeductibleLTTable } from './components/DeductibleLeaveTypeTable';
import { NonDeductibleLTTable } from './components/NonDeductibleLeaveTypeTable';
import {
  LEAVE_POLICY_JOURNEY_CREATE_LEAVE_TYPE_KEY,
  LEAVE_POLICY_JOURNEY_KEY, PUBLIC_HOLIDAYS_EDIT_KEY,
  PUBLIC_HOLIDAYS_JOURNEY_KEY,
  useEnhancedRouter,
} from '../../../router';
import { LeavePolicyForm } from './components/LeaveTypeForm';
import { PublicHolidaysTable } from './components/PublicHolidaysTable';
import { LEAVE_TYPES } from '../../../utils/constants';
import { PauseLoader } from '../../components/PauseLoader';
import { useMe } from '../../api/me';
import { Journey } from '../_common/Journey';
import { PauseJourneyDrawer } from '../../composites/PauseJourneyDrawer';
import { PublicHolidaysJourney2 } from './PublicHolidaysJourney2';
import { usePublicHolidayCalendars } from '../../api/public-holidays';
import { usePeople } from '../../api/people';

const { DEDUCTIBLE, NON_DEDUCTIBLE } = LEAVE_TYPES;

export const LeavePolicy = () => {
  const {
    loading,
    deductibleLeaveTypes,
    nonDeductibleLeaveTypes,
    onEditOrg,
    disableDeductibleLeaveTypes,
  } = useLeavePolicy();

  const { me } = useMe();

  const isAdmin = React.useMemo(() => get(me, 'isAdmin'), [me]);

  const router = useEnhancedRouter();

  const id = router.query?.id;

  const calendars = usePublicHolidayCalendars({ enabled: true });

  const { people } = usePeople();

  return !loading ? (
    <>
      <Head>
        <title>
          Leave Policy | Pause
        </title>
      </Head>
      <div css={(theme) => ({
        margin: '0 auto',
        display: 'grid',
        gridGap: theme.spacing(5),
      })}
      >
        <PublicHolidaysTable {...{
          calendars,
          people,
          isEditable: isAdmin,
        }}
        />
        <DeductibleLTTable
          disabled={disableDeductibleLeaveTypes}
          onToggleDisable={(val) => onEditOrg({ disableDeductibleLeaveTypes: val })}
          isEditable={isAdmin}
          data={deductibleLeaveTypes || []}
          editLeaveType={({ id, type }) => {
            router.push(router
              .getStopHref(LEAVE_POLICY_JOURNEY_KEY, LEAVE_POLICY_JOURNEY_CREATE_LEAVE_TYPE_KEY,
                { type, id }), undefined, { scroll: false });
          }}
          addNewLeaveTypeURL={router
            .getStopHref(LEAVE_POLICY_JOURNEY_KEY, LEAVE_POLICY_JOURNEY_CREATE_LEAVE_TYPE_KEY,
              { type: DEDUCTIBLE })}
        />

        <NonDeductibleLTTable
          isEditable={isAdmin}
          data={nonDeductibleLeaveTypes || []}
          editLeaveType={({ id, type }) => {
            router.push(router
              .getStopHref(LEAVE_POLICY_JOURNEY_KEY, LEAVE_POLICY_JOURNEY_CREATE_LEAVE_TYPE_KEY,
                { type, id }), undefined, { scroll: false });
          }}
          addNewLeaveTypeURL={router
            .getStopHref(LEAVE_POLICY_JOURNEY_KEY, LEAVE_POLICY_JOURNEY_CREATE_LEAVE_TYPE_KEY,
              { type: NON_DEDUCTIBLE })}
        />
        {/* <PublicHolidaysJourney isEditable={isAdmin} /> */}
        <Journey
          journey={PUBLIC_HOLIDAYS_JOURNEY_KEY}
          stop={PUBLIC_HOLIDAYS_EDIT_KEY}
        >
          <PauseJourneyDrawer>
            <PublicHolidaysJourney2 id={id} />
          </PauseJourneyDrawer>
        </Journey>
      </div>
    </>
  ) : <PauseLoader />;
};

export const LeaveTypeForm = ({
  open,
  onClose,
}) => {
  const {
    loading,
    type,
    isEditing,
    currentLeaveType,
    onCreateLeaveType,
    onEditLeaveType,
    onDeleteLeaveType,
  } = useLeaveTypeForm();

  const handleCreateLeaveType = (v) => {
    onCreateLeaveType(v).then(onClose).catch((_) => {});
  };

  const handleEditLeaveType = (v) => {
    onEditLeaveType(v).then(onClose).catch((_) => {});
  };

  const handleDeleteLeaveType = (v) => {
    onDeleteLeaveType(v).then(onClose).catch((_) => {});
  };

  return (
    <LeavePolicyForm
      open={open}
      onClose={onClose}
      loading={loading}
      type={type || ''}
      isEditing={isEditing}
      currentLeaveType={currentLeaveType}
      onCreateLeaveType={handleCreateLeaveType}
      onEditLeaveType={handleEditLeaveType}
      onDeleteLeaveType={handleDeleteLeaveType}
    />
  );
};
