import { pick } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { api } from '../hooks/useAxios';
import { useQueryRecursive } from './_utils';
import { replaceKeysDeep } from '../../utils/replace-keys';

const { get, post, del } = api();
const createNewAllHands = (data) => post({
  url: '/lockeddates',
  data: replaceKeysDeep(pick(data, [
    'startDate',
    'endDate',
    'startType',
    'endType',
    'reason',
    'teamID',
  ]), {
    teamID: 'groupID',
  }),
});
const deleteAllHands = (id) => del({
  url: `/lockeddates/${id}`,
});

export const useAllHands = (args: {
  enabled?: boolean,
} = {}) => {
  const { enabled = false } = args;
  const { removeById, add, data } = useQueryRecursive<any[]>({
    queryKey: 'allHands',
    queryFn: ({ params }) => get({
      url: '/lockeddates/',
      params,
    }),
    apiResponseKey: 'lockedDates',
    enabled,
  });
  const queryClient = useQueryClient();
  const { mutateAsync: onCreateAllHands } = useMutation(createNewAllHands, {
    onSuccess: (response) => {
      add(response);
      queryClient.refetchQueries('otherNotifications');
    },
  });
  const { mutateAsync: onDeleteAllHands } = useMutation(deleteAllHands, {
    onSuccess: (_, id) => {
      removeById({ id });
      queryClient.refetchQueries('otherNotifications');
    },
  });

  return {
    onCreateAllHands,
    onDeleteAllHands,
    allHands: (data || []).map((d) => replaceKeysDeep(d, {
      groupID: 'teamID',
      group: 'team',
    })),
  };
};
