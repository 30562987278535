import { useMutation, useQueryClient } from 'react-query';
import { api } from '../hooks/useAxios';
import { useQueryRecursive } from './_utils';

const { get, post, put } = api();

const createCompOffAPI = (data: {
  numberOfDays: number,
  applicationReason: string,
}) => post({
  url: '/compoff',
  data: {
    noOfDays: data.numberOfDays,
    applicationReason: data.applicationReason,
  },
});

const cancelCompOffAPI = (id: string) => put({
  url: `/compoff/${id}`,
  data: {
    status: 'CANCELED',
  },
});

const approveCompOffAPI = (id: string) => put({
  url: `/compoff/${id}`,
  data: {
    status: 'ACCEPTED',
  },
});

const declineCompOffAPI = (id: string) => put({
  url: `/compoff/${id}`,
  data: {
    status: 'CANCELED',
  },
});

export const useCompOff = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: createCompOff } = useMutation(createCompOffAPI, {
    onSuccess: () => {
      queryClient.refetchQueries('requestNotifications');
      queryClient.invalidateQueries(['person']);
      queryClient.refetchQueries('people');
      queryClient.invalidateQueries('compoffs-original');
    },
  });
  const { mutateAsync: cancelCompOff } = useMutation(cancelCompOffAPI, {
    onSuccess: () => {
      queryClient.refetchQueries('approvalNotifications');
      queryClient.refetchQueries('requestNotifications');
      queryClient.invalidateQueries(['person']);
      queryClient.refetchQueries('people');
      queryClient.invalidateQueries('compoffs-original');
    },
  });
  const { mutateAsync: approveCompOff } = useMutation(approveCompOffAPI, {
    onSuccess: () => {
      queryClient.refetchQueries('approvalNotifications');
      queryClient.refetchQueries('requestNotifications');
      queryClient.invalidateQueries('compoffs-original');
    },
  });
  const { mutateAsync: declineCompOff } = useMutation(declineCompOffAPI, {
    onSuccess: () => {
      queryClient.refetchQueries('approvalNotifications');
      queryClient.refetchQueries('requestNotifications');
      queryClient.invalidateQueries(['person']);
      queryClient.invalidateQueries('people');
      queryClient.invalidateQueries('compoffs-original');
    },
  });

  return {
    createCompOff,
    approveCompOff,
    cancelCompOff,
    declineCompOff,
  };
};

const getCompOffs = ({ params }) => get({
  url: '/compoff',
  params,
});

export const useCompOffs = (props: {
  enabled: boolean,
}) => {
  const { enabled } = props;
  useQueryRecursive({
    queryKey: 'compoffs',
    queryFn: getCompOffs,
    apiResponseKey: 'compOffs',
    enabled,
  });
};
