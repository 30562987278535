import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useMemo, useEffect, useCallback } from 'react';
import { flatten, get, merge } from 'lodash';

const DEFAULT_LIMIT = 100;

const combinePages = ({ data, key }): any[] => flatten(get(data, 'pages', []).map((page) => get(page, key)));

const updateItemInPages = ({ pages: oldPages, item, key }) => oldPages.map((page) => {
  const pageData = get(page, key, []);
  const findItem = pageData.find((pageItem) => get(pageItem, 'id') === get(item, 'id'));
  if (findItem) {
    const findItemIndex = pageData.indexOf(findItem);
    const modifiedPageData = [...pageData.slice(0, findItemIndex),
      item, ...pageData.slice(findItemIndex + 1)];
    return {
      ...page,
      [key]: modifiedPageData,
    };
  }
  return page;
});

const removeItemFromPages = ({ pages: oldPages, id, key }) => oldPages.map((page) => {
  const pageData = get(page, key, []);
  const modifiedPageData = pageData.filter(
    (pageItem) => get(pageItem, 'id') !== id,
  );
  return {
    ...page,
    [key]: modifiedPageData,
  };
});

const addItemToPages = ({ pages: oldPages, item, key }) => {
  const modifiedLastPage = oldPages[oldPages.length - 1];
  const modifiedLastPageData = get(modifiedLastPage, key);
  modifiedLastPageData.push(item);

  return [...oldPages.slice(0, oldPages.length - 1), {
    ...modifiedLastPage,
    [key]: modifiedLastPageData,
  }];
};

type UseAutoInfiniteQuery = <T extends any>(p: {
  queryKey: any,
  queryFn: Function,
  limit?: number,
  queryParams?: any,
  apiResponseKey: string,
  enabled?: boolean,
  onSuccess?: (a: any) => any,
  refetchOnWindowFocus?: boolean | 'always' | undefined
}) => {
  isComplete: boolean,
  data: T | any,
  isLoading: boolean,
  update: Function,
  remove: Function,
  add: Function,
}
export const useAutoInfiniteQuery: UseAutoInfiniteQuery = ({
  queryKey,
  queryFn,
  limit = DEFAULT_LIMIT,
  queryParams = {},
  apiResponseKey,
  enabled = false,
  onSuccess,
  refetchOnWindowFocus = undefined,
}) => {
  const queryClient = useQueryClient();
  const {
    isLoading,
    isStale,
    isFetchingNextPage, hasNextPage, fetchNextPage,
  } = useInfiniteQuery<any>(queryKey, ({ pageParam = 0 }) => queryFn(
    {
      pageParam, limit, queryKey, queryParams,
    },
  ), {
    onSuccess,
    enabled,
    refetchOnWindowFocus,
    getNextPageParam: (lastPage) => {
      if (!lastPage) return undefined;
      if (lastPage.nextOffset < lastPage.previousOffset + limit) {
        return undefined;
      }
      return lastPage.nextOffset;
    },
  });

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage && enabled) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage, isFetchingNextPage, enabled]);

  const update = useCallback((item) => {
    queryClient.setQueryData(queryKey, (oldData: any) => {
      const pages = get(oldData, 'pages', []);
      const newPages = updateItemInPages({ pages, item, key: apiResponseKey });
      return ({
        ...oldData,
        pages: newPages,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiResponseKey, queryKey]);

  const remove = ({ id }) => {
    queryClient.setQueryData(queryKey, (oldData: any) => {
      const pages = get(oldData, 'pages', []);

      const newPages = removeItemFromPages({ pages, id, key: apiResponseKey });
      return ({
        ...oldData,
        pages: newPages,
      });
    });
  };

  const add = (item) => {
    queryClient.setQueryData(queryKey, (oldData: any) => {
      const pages = get(oldData, 'pages', []);
      const newPages = addItemToPages({ pages, item, key: apiResponseKey });
      return ({
        ...oldData,
        pages: newPages,
      });
    });
  };

  // Had to do this because data from the query response wasn't being reactive
  const queryState = queryClient.getQueryState(queryKey);

  const isComplete = useMemo(() => !isLoading && !isStale && !hasNextPage && !isFetchingNextPage,
    [hasNextPage, isFetchingNextPage, isLoading, isStale]);

  const data = useMemo(() => combinePages(
    { data: get(queryState, 'data'), key: apiResponseKey },
  ), [queryState, apiResponseKey]);

  return {
    isComplete,
    data,
    isLoading,
    update,
    remove,
    add,
  };
};

export const noop = () => undefined;

type RecursiveQuery<T> = {
  updateById: (any) => any
  removeById: (any) => any
  add: (any) => any,
  data: T,
  loading: boolean,
}
export const useQueryRecursive = <T>(args: {
  queryKey: string | Array<any>,
  queryFn: (any) => any,
  params?: any,
  limit?: number,
  apiResponseKey: string,
  enabled?: boolean,
  refetchOnWindowFocus?: boolean
}): RecursiveQuery<T> => {
  const {
    queryKey,
    queryFn,
    params = null,
    limit = DEFAULT_LIMIT,
    apiResponseKey,
    enabled = false,
    refetchOnWindowFocus = false,
  } = args;
  const queryClient = useQueryClient();
  const {
    data,
    hasNextPage,
    fetchNextPage,
    isLoading,
    // isLoading,
  } = useInfiniteQuery(`${queryKey}-original`, ({ pageParam = { limit, offset: 0 } }) => queryFn({
    params: merge({}, params, pageParam),
  }),
  {
    getNextPageParam: (lastPage) => {
      if (!lastPage) return undefined;
      if (lastPage.nextOffset < lastPage.previousOffset + limit) {
        return undefined;
      }
      return { ...params, offset: lastPage.nextOffset || 0, limit };
    },
    enabled,
    refetchOnWindowFocus,
  });
  useEffect(() => {
    // get the next page if there is next page
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage]);

  queryClient.setQueryData(queryKey, ({
    isLoading,
    data: data?.pages?.reduce((acc, page) => {
      if (apiResponseKey) {
        return acc.concat(page[apiResponseKey]);
      }
      return acc.concat(page);
    }, []),
  }));

  const updateById = ({ id, ...rest }) => {
    queryClient.setQueryData(`${queryKey}-original`, ({ pages, ...others }) => ({
      ...others,
      pages: pages.map((page) => ({
        ...page,
        [apiResponseKey]: page[apiResponseKey].map((item) => ({
          ...item,
          ...(item.id === id && rest),
        })),
      })),
    }));
  };

  const removeById = ({ id }) => {
    queryClient.setQueryData(`${queryKey}-original`, ({ pages, ...others }) => ({
      ...others,
      pages: pages.map((page) => ({
        ...page,
        [apiResponseKey]: page[apiResponseKey].filter((item) => item.id !== id),
      })),
    }));
  };

  const add = (_data) => {
    queryClient.setQueryData<any>(`${queryKey}-original`, ({ pages, ...others } = { pages: [] as any[] }) => ({
      ...others,
      pages: pages.length ? pages
        .map((page, pageIdx) => (pageIdx === pages.length - 1
          ? ({
            ...page,
            [apiResponseKey]: page[apiResponseKey].concat(_data),
          })
          : page)) : [{ [apiResponseKey]: [_data] }],
    }));
  };

  const flattenedData = data?.pages?.reduce((acc, page) => {
    if (apiResponseKey) {
      return acc.concat(page[apiResponseKey]);
    }
    return acc.concat(page);
  }, []);

  return {
    updateById,
    removeById,
    add,
    data: flattenedData,
    loading: isLoading,
  };
};
