import React from 'react';
import { Form, FieldArray, Field } from 'formik';
import { every } from 'lodash';
import moment from 'moment';
import { PauseFormTextField } from '../../../components/PauseFormField2/PauseFormTextField';
import { PauseTypography } from '../../../components/PauseTypography';
import { PauseFormDatepicker } from '../../../components/PauseFormField2/PauseFormDatepicker';
import { DAY_FORMAT } from '../../../components/PauseCalendar/PauseFullCalendar/constants';
import {
  Check, Plus, Trash2, X,
} from '../../../../assets/icons/feather/components';
import { PauseTextButton } from '../../../components/PauseTextButton';
import { PauseIconButton } from '../../../components/PauseIconButton';
import { PauseInteractiveButton } from '../../../components/PauseInteractiveButton';

export const PHDatesForm = (props: {
  values: {
    publicHolidays: any[]
  },
  setFieldValue: (string, any) => any,
  setLocallySavedValues: (any) => any,
  locallySavedValues: {
    publicHolidays: any[]
  },
  autoSave?: boolean,
  onAdd?: (any) => any,
  onEdit?: (any) => any,
  onDelete?: (any) => any,
  year?: number,
  isEditable: boolean,
}) => {
  const {
    values = { publicHolidays: [] },
    setFieldValue,
    setLocallySavedValues,
    locallySavedValues = {
      publicHolidays: [],
    },
    autoSave = false,
    onAdd = () => null,
    onEdit = () => null,
    onDelete = () => null,
    year = Number(moment().format('YYYY')),
    isEditable,
  } = props;
  const allowAdd = year >= Number(moment().format('YYYY'));
  return (
    <Form>
      <FieldArray
        name="publicHolidays"
        render={({ insert, remove, replace }) => (
          <>
            <div css={(theme) => ({
              background: theme.get('palette.pause.background.light'),
              minHeight: '100%',
              width: '100%',
              position: 'relative',
            })}
            >
              <div css={(theme) => ({
                background: theme.get('palette.pause.background.dark'),
                display: 'grid',
                gridGap: '1px',
                width: '100%',
              })}
              >
                {allowAdd && isEditable && every(values.publicHolidays, (ph) => !ph.isNew) && (
                <div css={(theme) => ({
                  background: theme.get('palette.pause.white.main'),
                  paddingLeft: theme.spacing(3),
                  paddingRight: theme.spacing(3),
                })}
                >
                  <div css={(theme) => ({
                    height: theme.spacing(8),
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                  })}
                  >
                    {/* @ts-ignore */}
                    <PauseTextButton
                      compact
                      absolute
                      onClick={() => {
                        const initPH = {
                          name: '',
                          date: moment(year, 'YYYY'),
                          isNew: true,
                          id: null,
                          isBeingEdited: true,
                        };
                        setFieldValue('publicHolidays', values.publicHolidays.map((ph) => ({
                          ...ph,
                          isBeingEdited: false,
                        })));
                        insert(0, initPH);
                        if (!autoSave) {
                          setLocallySavedValues({
                            publicHolidays: [initPH as any].concat(values.publicHolidays),
                          });
                        }
                      }}
                      size="medium"
                      startIcon={<Plus />}
                    >
                      Add holiday
                    </PauseTextButton>
                  </div>
                </div>
                )}
                {values.publicHolidays.map(
                  (publicHoliday, index) => {
                    // eslint-disable-next-line no-underscore-dangle
                    const onEdit_ = () => {
                      setFieldValue('publicHolidays', locallySavedValues.publicHolidays.map((ph) => ({
                        ...ph,
                        isBeingEdited: false,
                      })));
                      replace(index, {
                        ...values.publicHolidays[index],
                        isBeingEdited: true,
                      });
                      if (!autoSave) {
                        setLocallySavedValues({
                          publicHolidays: locallySavedValues.publicHolidays.map((ph, i) => ({
                            ...ph,
                            ...(i === index && { isBeingEdited: true }),
                          })),
                        });
                      }
                    };
                    return (
                      (publicHoliday.isNew || publicHoliday.isBeingEdited) ? (
                        <div css={(theme) => ({
                          background: theme.get('palette.pause.white.main'),
                          display: 'grid',
                          gridTemplateColumns: '1.5fr 1.25fr 0.5fr',
                          gridGap: theme.spacing(2),
                          padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px ${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
                        })}
                        >
                          <div>
                            <PauseFormTextField
                              fieldProps={{
                                autoFocus: true,
                                label: 'Holiday Name',
                              }}
                              name={`publicHolidays.${index}.name`}
                            />
                          </div>
                          <div>
                            <PauseTypography>
                              {/* TODO - write a formik wrapper for datepicker */}
                              <Field
                                name={`publicHolidays.${index}.date`}
                              >
                                {({ field, form, meta }) => (
                                  <PauseFormDatepicker
                                    fieldProps={{
                                      minDate: moment(year, 'YYYY').startOf('year'),
                                      maxDate: moment(year, 'YYYY').endOf('year'),
                                      format: 'MMM DD, ddd',
                                      label: 'Date',
                                    }}
                                    {...field}
                                    {...form}
                                    {...meta}
                                  />
                                )}
                              </Field>
                            </PauseTypography>
                          </div>
                          <div css={(theme) => ({
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            // justifyContent: 'end',
                            gridGap: theme.spacing(0.5),
                            alignItems: 'center',
                          })}
                          >
                            <PauseInteractiveButton
                              buttonComponent={PauseIconButton}
                              other
                              onClick={async () => {
                                // eslint-disable-next-line max-len
                                const nameAndDateNotEmpty = values.publicHolidays[index].name
                                   && values.publicHolidays[index].date;
                                const editedPH = {
                                  ...values.publicHolidays[index],
                                  isBeingEdited: false,
                                  date: values.publicHolidays[index].date,
                                  name: values.publicHolidays[index].name,
                                };
                                if (nameAndDateNotEmpty && autoSave) {
                                  const { isNew } = values.publicHolidays[index];
                                  if (isNew) {
                                    return onAdd({
                                      name: values.publicHolidays[index]?.name || '',
                                      date: moment(values.publicHolidays[index]?.date || '').format('YYYY-MM-DD'),
                                    }).then(() => {
                                      setFieldValue('publicHolidays', values.publicHolidays.map((ph) => ({
                                        ...ph,
                                        isBeingEdited: false,
                                        isNew: false,
                                      })));
                                    });
                                  }
                                  return onEdit({
                                    id: values.publicHolidays[index]?.id || '',
                                    name: values.publicHolidays[index]?.name || '',
                                    date: moment(values.publicHolidays[index]?.date || '').format('YYYY-MM-DD'),
                                  }).then(() => {
                                    setFieldValue('publicHolidays', values.publicHolidays.map((ph) => ({
                                      ...ph,
                                      isBeingEdited: false,
                                      isNew: false,
                                    })));
                                  });
                                }
                                if (nameAndDateNotEmpty) {
                                  replace(index, {
                                    ...editedPH,
                                    isNew: false,
                                  });
                                  if (!autoSave) {
                                    return setLocallySavedValues({
                                      publicHolidays: locallySavedValues.publicHolidays
                                        .map((ph, i) => ({
                                          ...ph,
                                          ...(i === index && {
                                            ...editedPH,
                                            isNew: false,
                                            isBeingEdited: false,
                                          }),
                                        })),
                                    });
                                  }
                                }
                                return Promise.resolve();
                              }}
                              variant="ghost"
                              color="primary"
                              size="small"
                              icon={<Check />}
                            />
                            <PauseIconButton
                              // @ts-ignore
                              onClick={() => {
                                if (values.publicHolidays[index].isNew) {
                                  remove(index);
                                  if (!autoSave) {
                                    setLocallySavedValues({
                                      publicHolidays: values.publicHolidays
                                        .filter((_, i) => i > 0),
                                    });
                                  }
                                } else {
                                  replace(index, {
                                    ...locallySavedValues.publicHolidays[index],
                                    isBeingEdited: false,
                                  });
                                  if (!autoSave) {
                                    setLocallySavedValues({
                                      publicHolidays: values.publicHolidays.map((ph) => ({
                                        ...ph,
                                        isBeingEdited: false,
                                      })),
                                    });
                                  }
                                }
                              }}
                              variant="ghost"
                              color="primary"
                              size="small"
                              icon={<X />}
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          css={(theme) => ({
                            background: theme.get('palette.pause.white.main'),
                            display: 'grid',
                            gridTemplateColumns: '1.5fr 1.25fr 0.5fr',
                            gridGap: theme.spacing(2),
                            alignItems: 'center',
                            padding: theme.spacing(1.5, 3),
                            cursor: isEditable ? 'pointer' : 'unset',
                            ':hover': {
                              background: theme.get('palette.pause.background.medium'),
                            },
                          })}
                        >
                          {/* eslint-disable-next-line */}
                        <div onClick={!(values.publicHolidays.some((ph) => ph.isNew)) && isEditable
                          ? onEdit_ : () => null}
                        >
                          {/* @ts-ignore */}
                          <PauseTextButton
                            compact
                            css={{
                              textAlign: 'inherit',
                              cursor: isEditable ? 'pointer' : 'unset',
                            }}
                          >
                            <PauseTypography color="pause.primary.dark">
                              {publicHoliday.name}
                            </PauseTypography>
                          </PauseTextButton>
                        </div>
                          <div onClick={!(values.publicHolidays.some((ph) => ph.isNew)) && isEditable
                            ? onEdit_ : () => null}
                          >
                            {/* @ts-ignore */}
                            <PauseTextButton
                              compact
                              css={{
                                textAlign: 'inherit',
                                cursor: isEditable ? 'pointer' : 'unset',
                              }}
                            >
                              <PauseTypography color="pause.primary.dark">
                                {moment(publicHoliday.date, DAY_FORMAT).format('MMM DD, ddd')}
                              </PauseTypography>
                            </PauseTextButton>
                          </div>
                          <div>
                            <div css={(theme) => ({
                              display: 'grid',
                              gridTemplateColumns: '1fr 1fr',
                              justifyContent: 'end',
                              gridGap: theme.spacing(0.5),
                              alignItems: 'center',
                            })}
                            >
                              <div />
                              {isEditable && (
                              <PauseInteractiveButton
                                buttonComponent={PauseIconButton}
                                variant="ghost"
                                color="primary"
                                size="small"
                                onClick={async () => {
                                  if (!(values.publicHolidays.some((ph) => ph.isNew))) {
                                    if (autoSave) {
                                      return onDelete({ id: values.publicHolidays[index]?.id || '' });
                                    }
                                    remove(index);
                                    return setLocallySavedValues({
                                      publicHolidays: values.publicHolidays
                                        .filter((_, i) => i !== index),
                                    });
                                  }
                                  return null;
                                }}
                                icon={<Trash2 />}
                              />
                              )}
                            </div>
                          </div>
                        </div>
                      ));
                  },
                )}
              </div>
            </div>
          </>
        )}
      />
    </Form>
  );
};
