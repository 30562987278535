import { useRouter } from 'next/router';
import { omit, merge, pick } from 'lodash';

export const JOURNEY_KEY = 'journey';
const JOURNEY_STOP_KEY = 'stop';

export const INVITE_JOURNEY_KEY = 'invite';
export const INVITE_JOURNEY_BEGIN_KEY = 'begin';

export const LEAVE_POLICY_JOURNEY_KEY = 'leave-policy';
export const LEAVE_POLICY_JOURNEY_CREATE_LEAVE_TYPE_KEY = 'create';

export const PUBLIC_HOLIDAYS_JOURNEY_KEY = 'public-holidays';
export const PUBLIC_HOLIDAYS_CREATE_KEY = 'create';
export const PUBLIC_HOLIDAYS_EDIT_KEY = 'edit';

export const TEAMS_JOURNEY_KEY = 'teams';
export const TEAMS_JOURNEY_EDIT_TEAM_KEY = 'edit';
export const TEAMS_JOURNEY_CREATE_TEAM_KEY = 'create';
export const TEAMS_JOURNEY_DELETE_TEAM_KEY = 'delete';
export const TEAMS_JOURNEY_CHANGE_TEAM_KEY = 'change';

export const LEAVE_APPLICATION_JOURNEY_KEY = 'leave-application';
export const LEAVE_APPLICATION_JOURNEY_NEW_APPLICATION_KEY = 'new';

export const PROFILE_JOURNEY_KEY = 'profile';
export const PROFILE_JOURNEY_EDIT_PROFILE_KEY = 'edit';

export const SLACK_INTEGRATION_JOURNEY_KEY = 'slack-integration';
export const SLACK_INTEGRATION_JOURNEY_EDIT_KEY = 'edit';

export const NOTIFICATIONS_JOURNEY_KEY = 'notifications';
export const NOTIFICATIONS_JOURNEY_VIEW_KEY = 'view';

export const PROFILE_ALLOWANCE_OVERRIDE_JOURNEY_KEY = 'allowance-overrides';
export const PROFILE_ALLOWANCE_OVERRIDE_JOURNEY_VIEW_KEY = 'view';

export const SLACK_PUBLIC_HOLIDAY_SETTINGS_JOURNEY_KEY = 'slack-ph-settings';
export const SLACK_PUBLIC_HOLIDAY_SETTINGS_JOURNEY_EDIT_KEY = 'edit';

export const COMPOFF_JOURNEY_KEY = 'compoff';
export const COMPOFF_JOURNEY_VIEW_KEY = 'view';

export enum Routes {
  Home = '/',
  Teams = '/teams',
  Settings = '/settings',
  Invite = '/invite',
  Onboarding = '/start',
  FinishSignup = '/finish-signup',
  BulkInvite = '/invite-bulk'
}

export const useEnhancedRouter = () => {
  const router = useRouter();
  const enhancedRouter = {
    ...router,
    endJourney: (args: { as?: any,
      options?: any,
      clearQueryParams?: string[],
      preserveQueryParams?: string[]
    } = {}) => {
      const {
        as = undefined,
        options = null,
        clearQueryParams = [],
        preserveQueryParams = [],
      } = args;
      return router.push(
        {
          pathname: router.pathname,
          query: merge({}, omit(router.query, [JOURNEY_KEY, JOURNEY_STOP_KEY, 'id', 'type', ...clearQueryParams]), pick(router.query, preserveQueryParams)),
        },
        as,
        { shallow: true, ...options },
      );
    },
    getEndJourneyHref: () => ({
      pathname: router.pathname,
      query: omit(router.query, [JOURNEY_KEY, JOURNEY_STOP_KEY]),
    }),
    isStopActive: (journey, stop) => router.query[JOURNEY_KEY] === journey && router.query[JOURNEY_STOP_KEY] === stop,
    isJourneyActive: (journey) => router.query[JOURNEY_KEY] === journey,
    isPathActive: (pathname) => router.pathname === pathname && !router.query[JOURNEY_KEY],
    getCurrentJourney: () => router.query[JOURNEY_KEY],
    navigateToStop: (args: {
      journey?: any,
      stop?: any,
      as?: any,
      options? : any,
      query?: any
    }) => {
      const {
        journey, stop, as, options, query = {},
      } = args;
      if (!journey && !enhancedRouter.getCurrentJourney()) {
        return null;
      }
      return router.push(
        {
          pathname: router.pathname,
          query: {
            ...query,
            [JOURNEY_KEY]: journey || enhancedRouter.getCurrentJourney(),
            [JOURNEY_STOP_KEY]: stop,
          },
        },
        as,
        { shallow: true, ...options },
      );
    },
    getStopHref: (journey, stop, query = {}) => ({
      pathname: router.pathname,
      // This order is very important. Ensure router.query
      // never overrides the current query or journey/stop keys
      query: {
        ...router.query,
        ...query,
        [JOURNEY_KEY]: journey,
        [JOURNEY_STOP_KEY]: stop,
      },
    }),
  };
  return enhancedRouter;
};
