import React, { cloneElement, useRef } from 'react';
import Close from '../../../../assets/icons/feather/components/X';

type Props = {
  selected?: boolean,
  children: React.ReactElement,
  styles: (any?) => ({}),
  onRemove?: (any?) => any,
  [key: string]: any
}

export const PauseAutocompleteSelectedListItem = ({
  selected,
  children,
  styles,
  onRemove,
  ...props
}: Props) => {
  if (!children) { throw new Error('PauseAutocompleteSelectedListItem needs one child to render'); }
  const ref = useRef(null);
  const css = (theme) => ({
    display: 'grid',
    alignItems: 'center',
    ...(onRemove && { gridTemplateColumns: '3fr 1fr' }),
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: theme.spacing(1.5),
    ...styles(theme),
    transition: 'background 0.2s ease-in-out',
  });
  return (
    <div
      ref={ref}
      css={css}
    >
      <div>{cloneElement(children, { ...props })}</div>
      {onRemove && (
      <div css={{ lineHeight: 0, textAlign: 'right' }}>
        <Close
          css={(theme) => ({
            transition: 'all 0.2s ease-in-out',
            width: 16,
            height: 16,
            cursor: 'pointer',
            stroke: theme.palette.pause.primary.medium,
            '&:hover': {
              stroke: theme.palette.pause.primary.dark,
            },
          })}
          onClick={() => onRemove(props)}
        />
      </div>
      )}
    </div>
  );
};
