import React, { ReactNode } from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import { PauseTypography } from '../PauseTypography';

interface CommonProps {
  name: string,
  checked?: boolean,
  onChange?: (a: any, newChecked: any) => void,
  disabled?: boolean,
  [x:string]: any,
}

interface PlainToggleProps extends CommonProps{
  ref?: any
}

const PlainToggle = ({
  checked, onChange, disabled, name, ref, ...props
}: PlainToggleProps) => (
  <Switch
    {...props}
    ref={ref}
    checked={checked}
    onChange={onChange}
    disabled={disabled}
    disableRipple
    css={(theme) => ({
      '&.MuiSwitch-root': {
        width: theme.spacing(3.5),
        height: theme.spacing(2),
        padding: 0,
        cursor: disabled ? 'not-allowed' : 'default',
        verticalAlign: 'middle',
      },
      '& .MuiButtonBase-root': {
        padding: 0,
        color: theme.get('palette.pause.white.main'),
        marginTop: '1px',
        marginLeft: '1px',
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: `translateX(${theme.spacing(1.5)}px)`,
      },
      '& .MuiButtonBase-root:hover': {
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
      '& .MuiSwitch-thumb': {
        width: theme.spacing(1.75),
        height: theme.spacing(1.75),
        boxShadow: 'none',
      },
      '& .MuiSwitch-track': {
        width: theme.spacing(3.5),
        height: theme.spacing(2),
        backgroundColor: theme.get('palette.pause.grey.low'),
        borderRadius: theme.spacing(1),
      },
      '& .MuiButtonBase-root.Mui-checked + .MuiSwitch-track': {
        backgroundColor: theme.get('palette.pause.primary.main'),
        opacity: 1,
      },
      '& .MuiButtonBase-root.Mui-checked.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: theme.get('palette.pause.primary.main'),
        opacity: 0.4,
      },
    })}
  />
);

interface MainProps extends CommonProps {
  label?: string|ReactNode,
  inverted?: boolean
}

export const PauseToggle = React.forwardRef<HTMLButtonElement, MainProps>(({
  label,
  checked,
  disabled,
  onChange,
  inverted,
  ...props
}: MainProps, ref) => {
  if (!label) {
    return (
      <PlainToggle
        ref={ref}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        {...props}
      />
    );
  }
  return (
    <PauseTypography color={checked ? 'pause.primary.dark' : 'pause.primary.medium'}>
      <FormControlLabel
        disabled={disabled}
        css={(theme) => ({
          color: 'inherit',
          display: 'grid',
          alignItems: 'center',
          gridAutoFlow: 'column',
          justifyContent: inverted ? 'space-between' : 'start',
          gridGap: theme.spacing(2),
          direction: inverted ? 'rtl' : 'ltr',
          marginLeft: 0,
          marginRight: 0,
          verticalAlign: 'unset',
          '& .MuiFormControlLabel-label': {
            direction: 'ltr',
          },
          '& .MuiFormControlLabel-label.Mui-disabled': {
            color: theme.get('palette.pause.primary.medium'),
            cursor: 'not-allowed',
          },
        })}
        // @ts-ignore
        control={(disabled && disabledComponent) ? disabledComponent
          : (
            <PlainToggle
              ref={ref}
              checked={checked}
              onChange={!disabled ? onChange : () => null}
              {...props}
            />
          )}
        label={label}
      />
    </PauseTypography>
  );
});
