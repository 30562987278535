import React, { ReactNode, useCallback } from 'react';
import { Autocomplete } from '@material-ui/lab';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTheme } from '@material-ui/core/styles';
import { VariableSizeList } from 'react-window';
import { PauseTypography } from '../PauseTypography';
import { PauseMenuItem } from '../PauseMenuItem';
import { PauseTextField } from '../PauseTextField';
import { ChevronDown } from '../../../assets/icons/feather/components';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLElement, any>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 34 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

interface CustomOptionProps {
  option: any,
  selected: boolean,
  inputValue: string,
  disableHighlighting: boolean,
  renderLabel: React.FunctionComponent<any>
}
const CustomOption = React.memo(({
  option, selected, inputValue, disableHighlighting, renderLabel,
}: CustomOptionProps) => {
  const { label: optionLabel, ...params } = option;
  const Tag = renderLabel;
  return Tag ? (
    <Tag
      option={option}
      state={{
        selected,
        inputValue,
      }}
      disableHighlighting={disableHighlighting}
      className="PauseMenuItem"
    />
  ) : (
    <PauseMenuItem
      {...params}
      disableHighlighting={disableHighlighting}
      className="PauseMenuItem"
      selected={selected}
    >
      {optionLabel}
    </PauseMenuItem>
  );
});

interface CustomGroupProps {
  key: string|number,
  children: ReactNode,
  group: string,
  renderGroupLabel: React.FunctionComponent<any>
}

const CustomGroup = React.memo(({
  children: groupChildren,
  key,
  group,
  renderGroupLabel,
}: CustomGroupProps) => {
  const Tag = renderGroupLabel;
  return (
    <div>
      {Tag
        ? <Tag group={group} key={key} />
        : (
          <div css={(theme) => ({
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            backgroundColor: theme.get('palette.pause.background.light'),
          })}
          >
            <PauseTypography variant="caption" color="pause.primary.medium">
              {group}
            </PauseTypography>
          </div>
        )}
      {groupChildren}
    </div>
  );
});

export const PauseAutocomplete2 = React.memo<any>(({
  label, options, renderLabel, renderGroupLabel,
  virtualise,
  disableCloseOnSelect, onChange,
  disableHighlighting, multiple, value, renderInput, error, renderTags,
  disablePortal = true, ...props
}: AutoCompleteT) => {
  const memoizedRenderLabel = useCallback(renderLabel, []);
  const memoizedRenderGroupLabel = useCallback(renderGroupLabel, [renderGroupLabel]);
  return (
    <div css={(theme) => ({
      '& .MuiAutocomplete-option': {
        padding: 0,
        '> *': {
          width: '100%',
        },
        border: 'none',
      },
      '& .MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '& .MuiAutocomplete-option[data-focus="true"], & .MuiAutocomplete-option[data-focus="true"] > .PauseMenuItem': {
        backgroundColor: theme.get('palette.pause.background.dark'),
      },
      '& .MuiAutocomplete-popupIndicator': {
        marginTop: '4px',
      },
      '& .MuiAutocomplete-listbox': {
        boxSizing: 'border-box',
        '& ul': {
          padding: 0,
          margin: 0,
        },
      },
    })}
    >
      {/* @ts-ignore */}
      <Autocomplete
        disablePortal={disablePortal}
        blurOnSelect
        disableCloseOnSelect={disableCloseOnSelect}
        getOptionDisabled={(option: any) => option.disabled}
        closeIcon={null}
        popupIcon={(
          <div css={() => ({
            display: 'flex',
            alignItems: 'center',
          })}
          >
            <ChevronDown css={(theme) => ({
              fontSize: theme.spacing(2),
            })}
            />
          </div>
        )}
        options={options}
        value={value}
        multiple={multiple}
        getOptionLabel={(option: any) => option.label}
        renderInput={(params) => (renderInput ? renderInput({ ...params, error }) : (
          <PauseTextField
            /* @ts-ignore */
            label={label}
            /* @ts-ignore */
            error={error}
            {...params}
          />
        ))}
        renderOption={(option: any, { selected, inputValue }) => (
          <CustomOption
            option={option}
            selected={selected}
            inputValue={inputValue}
            renderLabel={memoizedRenderLabel}
            disableHighlighting={disableHighlighting}
          />
        )}
        renderGroup={({
          children: groupChildren,
          key,
          group,
        }) => (
          <CustomGroup
            key={key}
            group={group}
            renderGroupLabel={memoizedRenderGroupLabel}
          >
            {groupChildren}
          </CustomGroup>
        )}
        {...(virtualise && {
          disableListWrap: true,
          ListboxComponent,
        })}
        onChange={(_, newValue) => {
          onChange(newValue);
        }}
        renderTags={renderTags}
        {...props}
      />
    </div>
  );
});

interface AutoCompleteT {
  children: ReactNode,
  label: string
  renderLabel: React.FunctionComponent<any>,
  renderGroupLabel: React.FunctionComponent<any>,
  renderTags?: (any) => ReactNode,
  renderInput: (any) => ReactNode,
  onChange: (any) => any,
  virtualise: boolean,
  disableHighlighting: boolean,
  multiple: boolean,
  disableCloseOnSelect: boolean,
  error: boolean | string,
  value: boolean | string | any[],
  options: {
    label: string,
    value: string | number,
    disabled: boolean,
  }[],
  disablePortal?: boolean,
}
