import { useMutation, useQuery, useQueryClient } from 'react-query';
import { omit } from 'lodash';
import { useAPI } from '../hooks/useAxios';
import { usePeople } from './people';
import { useMe } from './me';
import { formatPerson } from './_person-utils';
import { replaceKeysDeep } from '../../utils/replace-keys';

type UsePersonParams = {personID: any, params?: any, enabled?: boolean }
export const usePerson = ({ personID = null, params = null, enabled = true }: UsePersonParams) => {
  const { put, get } = useAPI();

  const queryKey = ['person', { id: personID, ...params }];

  const { mutatePersonInCache: updatePersonInPeopleCache } = usePeople();

  const { me } = useMe();

  // eslint-disable-next-line no-shadow
  const getPersonAPI = (params) => {
    const { queryKey: qk } = params;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [, { id, ...queryParams }] = qk;
    return get({
      url: `/people/${id}`,
      params: {
        ...queryParams,
      },
    });
  };

  const editPersonAPI = (data) => {
    const { id, ...rest } = data;
    return put({
      url: `/people/${id}`,
      data: replaceKeysDeep(omit(rest, ['joiningDate']), { teamIDs: 'groupIDs' }),
    }, (d) => replaceKeysDeep(d, { groupIDs: 'teamIDs' }));
  };

  const queryClient = useQueryClient();

  const { data: person, isLoading } = useQuery<any>(queryKey, (param) => getPersonAPI(param), {
    enabled: !!personID && enabled,
    onSuccess: (personData) => {
      // if params has cycleStartDate, that means we just fetched allowance for some other cycle
      // in that case, there is no need to update person in people cache
      if (params?.cycleStartDate) return;
      updatePersonInPeopleCache(personData);
    },
  });

  const { mutateAsync: editPerson } = useMutation<any>(editPersonAPI, {
    onSuccess: (newData) => {
      queryClient.setQueryData<any>(queryKey, (oldData: any[]) => ({
        ...oldData,
        ...newData,
      }));
      updatePersonInPeopleCache(newData);
      if (newData.id === me.id) {
        queryClient.setQueryData<any>('me', (oldData: any) => ({
          ...oldData,
          ...newData,
        }));
      }
    },
  });

  return {
    person: formatPerson(person),
    loading: isLoading,
    editPerson,
  };
};
