import React, { useState } from 'react';
import { PauseButton } from '../PauseButton';

interface Props {
  buttonComponent?: any | null,
  onClick: Function,
  children?: JSX.Element | string,
  [key: string]: any, // TODO remove this and extend Prop with PauseButtonProps, PauseTextButtonProps and PauseIconButtonProps
}

export const PauseInteractiveButton = ({
  buttonComponent,
  onClick,
  children,
  ...otherProps
}: React.PropsWithChildren<Props>) => {
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    setLoading(true);
    try {
      await onClick();
    } catch (e) {
      // do nothing
    }
    setLoading(false);
  };
  // TODO: remove hacky use of 'any'. Forced to do this because
  //  otherwise TS complains in the usage of <Component />
  const Component: any = buttonComponent || PauseButton;
  return (
    <Component onClick={handleClick} isLoading={loading} {...otherProps}>
      {children}
    </Component>
  );
};

PauseInteractiveButton.defaultProps = {
  buttonComponent: null,
};
