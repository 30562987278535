import React from 'react';
import { SnackbarContent, useSnackbar } from 'notistack';
import { PauseSnackbar } from '../../components/PauseSnackbar';

type Props = {
  message: any,
  id: string | number,
  onButtonClick: (any?) => any,
  [key: string]: any
}
export const SnackbarWithActions = React.forwardRef<any, Props>(({
  message, id, onButtonClick, ...rest
}, ref) => {
  const { closeSnackbar } = useSnackbar();
  const onClose = () => {
    closeSnackbar(id);
    if (onButtonClick) {
      onButtonClick();
    }
  };
  return (
    <SnackbarContent ref={ref}>
      <PauseSnackbar onButtonClick={onClose} {...rest}>{message}</PauseSnackbar>
    </SnackbarContent>
  );
});
