/* eslint-disable no-underscore-dangle */
import React, {
  useEffect, useState,
} from 'react';
import {
  get, pick,
} from 'lodash';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { PauseDrawerContent, PauseDrawerFooter, PauseDrawerTitle } from '../../../components/PauseDrawer';
import { PauseInteractiveButton } from '../../../components/PauseInteractiveButton';
import { PauseRadio } from '../../../components/PauseRadio';
import { PauseTextField } from '../../../components/PauseTextField';
import { PauseAutocomplete2 } from '../../../components/PauseAutocomplete2';
import { PauseLoader } from '../../../components/PauseLoader';
import { NewPublicHolidayDatesForm } from './NewPublicHolidayDatesForm';
import { PauseTypography } from '../../../components/PauseTypography';

// types
export enum NewCalendarState {
  SelectType,
  EditDates,
}
type CalendarOptions = {
  country: string,
  region: string,
  isCustom: boolean,
}

const PHSelectCalendarType = (props: {
  countries?: Array<{label:string, value: string, regions: Array<{label: string, value: string}>}>,
  onNext: (a:CalendarOptions&{name:string}) => Promise<any>,
  initialData: {
    name: string,
    isCustom: boolean,
    country: string,
    region: string,
  }
}) => {
  const {
    onNext = async () => null,
    countries = [],
    initialData,
  } = props;
  const [calendarName, setCalendarName] = useState<string>(initialData.name);
  const [calendarOptions, setCalendarOptions] = useState<CalendarOptions>({
    country: initialData.country,
    region: initialData.region,
    isCustom: initialData.isCustom,
  });
  const regions = countries.find((c) => c.value === calendarOptions.country)?.regions || [];
  const countryValue = countries.find((c) => c.value === calendarOptions.country);
  const regionValue = (countries.find((c) => c.value === calendarOptions.country) || {} as any)
    .regions?.find((r) => r.value === calendarOptions.region) || calendarOptions.country;

  const enableBtn = !calendarOptions.isCustom
    ? (calendarName && calendarOptions.country)
    : (calendarName);

  const getLocationName = (regionCode:string): string => {
    if (!regionCode) return '';
    const countryCode = regionCode.split('-')[0];
    const country = countries.find((c) => c.value === countryCode);
    const region = country?.regions?.find((r) => r.value === regionCode);
    if (country) {
      return country?.value === region?.value ? country?.label : `${country?.label} - ${region?.label}`;
    }
    return '';
  };

  useEffect(() => {
    if (!calendarOptions.isCustom) setCalendarName(getLocationName(calendarOptions.region));
    if (calendarOptions.isCustom) setCalendarName(initialData.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarOptions.region, calendarOptions.isCustom]);

  useEffect(() => {
    if (!calendarOptions.isCustom) setCalendarName(getLocationName(calendarOptions.region));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* @ts-ignore */}
      <PauseDrawerTitle>
        Create new calendar
      </PauseDrawerTitle>
      <PauseDrawerContent>
        <>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <PauseTypography
                variant="subtitle"
                color="pause.primary.medium"
              >
                Choose a calendar type
              </PauseTypography>
            </Grid>
            <Grid item xs={12}>
              {/* @ts-ignore */}
              <PauseRadio
                label="Calendar based on geography"
                checked={calendarOptions.isCustom === false}
                onChange={() => {
                  setCalendarOptions((prev) => ({
                    ...prev,
                    isCustom: false,
                  }));
                }}
              />
              {!calendarOptions.isCustom && (
              <Grid
                container
                spacing={2}
                css={(theme) => ({
                  margin: theme.spacing(1, 2),
                })}
              >
                <Grid item xs={12}>
                  <PauseAutocomplete2
                    label="Select country"
                    value={countryValue}
                    onChange={(newValue) => {
                      if (!newValue) return;
                      setCalendarOptions((prev) => ({
                        ...prev,
                        country: newValue?.value,
                        region: newValue?.value,
                      }));
                    }}
                    options={countries}
                    css={{
                      maxWidth: '80%',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PauseAutocomplete2
                    label="Select region"
                    value={regionValue}
                    onChange={(newValue) => {
                      if (!newValue) return;
                      setCalendarOptions((prev) => ({
                        ...prev,
                        region: newValue?.value,
                      }));
                    }}
                    options={regions}
                    css={{
                      maxWidth: '80%',
                    }}
                  />
                </Grid>
              </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              {/* @ts-ignore */}
              <PauseRadio
                label="Custom calendar"
                checked={calendarOptions.isCustom === true}
                onChange={() => setCalendarOptions((prev) => ({
                  ...prev,
                  isCustom: true,
                }))}
              />
              {calendarOptions.isCustom && (
              <Grid
                container
                spacing={2}
                css={(theme) => ({
                  margin: theme.spacing(1, 2),
                })}
              >
                <Grid item xs={12}>
                  <PauseTextField
                    autoFocus
                    label="Name of the calendar"
                    value={calendarName}
                    onChange={(e) => {
                      setCalendarName(e?.target?.value);
                    }}
                  />
                </Grid>
              </Grid>
              )}
            </Grid>
          </Grid>
        </>
      </PauseDrawerContent>
      <PauseDrawerFooter>
        <PauseInteractiveButton
          disabled={!enableBtn}
          onClick={() => onNext({
            ...calendarOptions,
            name: calendarName,
          })}
        >
          Next
        </PauseInteractiveButton>

      </PauseDrawerFooter>
    </>
  );
};

const PHAddDates = (props: {
  onNext: (a:any) => Promise<any>,
  initialHolidays: any[],
  onBack: () => any,
  calendarName: string,
}) => {
  const {
    onNext = async () => null,
    initialHolidays = [],
    onBack = () => null,
    calendarName,
  } = props;
  return (
    <>
      <NewPublicHolidayDatesForm
        initialData={initialHolidays
          .map((h) => ({
            ...h,
            id: h.uuid,
          }))}
        calendarName={calendarName}
        onBack={onBack}
        onSubmit={(values) => onNext({
          name: calendarName,
          holidays: values,
        })}
      />
    </>
  );
};

export const PHNewCalendarForms = (props:{
  countries: {
    data: any[],
    loading: boolean,
  },
  holidaysForSelectedRegion: {
    data: any[],
    loading: boolean,
  }
  onChangeRegion: (string) => any,
  onCreateNewCalendar: (args: {
    name: string,
    region: string | null,
    isCustom: boolean,
    holidays: Array<{
      id: string | null,
      name: string,
      date: string,
    }>,
  }) => Promise<any>
}) => {
  const {
    countries = { data: [], loading: false },
    onChangeRegion = () => null,
    holidaysForSelectedRegion = { data: [], loading: false },
    onCreateNewCalendar = () => Promise.resolve(null),
  } = props;
  const [state, setState] = useState<NewCalendarState>(NewCalendarState.SelectType);
  const [calendarFormData, setCalendarFormData] = useState<{
    name: string,
    country: string,
    region: string,
    isCustom: boolean,
    holidays: any[],
    people: string[]
  }>({
    name: '',
    country: '',
    region: '',
    holidays: [],
    isCustom: false,
    people: [],
  });

  // after countries loads, this ensures calendar form data is updated.
  // this is required because we pass this initial calendar form data
  // to the 'select calendar type' screen
  useEffect(() => {
    if (countries.loading) return;
    setCalendarFormData((prev) => ({
      ...prev,
      country: get(countries?.data, '0.value', ''),
      region: get(countries?.data, '0.regions.0.value', ''),
    }));
  }, [countries.loading]);

  useEffect(() => {
    if (holidaysForSelectedRegion.loading) {
      setCalendarFormData((prev) => ({
        ...prev,
        holidays: [],
      }));
      return;
    }
    setCalendarFormData((prev) => ({
      ...prev,
      holidays: calendarFormData.isCustom ? [] : (holidaysForSelectedRegion?.data || []),
    }));
  }, [calendarFormData.isCustom, holidaysForSelectedRegion.data]);

  const goToSelectDates = (data: CalendarOptions & { name: string }) => {
    const {
      isCustom, name, region, country,
    } = data;
    setCalendarFormData((prev) => ({
      ...prev,
      name,
      region,
      isCustom,
      country,
    }));
    onChangeRegion(region);
    return null;
  };

  const goToSelectMembers = (data: {
    name: string,
    holidays: any[] }) => {
    const { name, holidays } = data;
    const holidays_ = holidays.map((h) => ({
      ...pick(h, ['id', 'name']),
      date: moment(h.date).format('YYYY-MM-DD'),
    }));
    const formData = {
      ...pick(calendarFormData, ['isCustom']),
      name,
      holidays: holidays_,
      region: calendarFormData.isCustom ? null : calendarFormData.region,
    };
    setCalendarFormData((prev) => ({
      ...prev,
      name,
      holidays,
    }));
    return onCreateNewCalendar(formData);
  };

  const goToNext = async (data) => {
    if (state === NewCalendarState.SelectType) {
      goToSelectDates(data);
    }
    if (state === NewCalendarState.EditDates) {
      return goToSelectMembers(data);
    }
    setState(state + 1);
    return null;
  };

  const goBack = async () => {
    setState(state - 1);
  };

  if (state === NewCalendarState.SelectType) {
    return countries?.loading ? <PauseLoader /> : (
      <PHSelectCalendarType
        key={Math.random()}
        initialData={{
          name: calendarFormData.name,
          country: calendarFormData.country,
          region: calendarFormData.region,
          isCustom: calendarFormData.isCustom,
        }}
        countries={countries?.data || []}
        onNext={goToNext}
      />
    );
  }

  if (state === NewCalendarState.EditDates) {
    return (
      <>
        <PHAddDates
          calendarName={calendarFormData.name}
          initialHolidays={calendarFormData.holidays}
          onNext={goToNext}
          onBack={goBack}
        />
      </>
    );
  }

  return <></>;
};
