import * as React from 'react';

function SvgTree(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.5 20c1.347-5.19.34-10.904-1.5-15.14m0-.001c1.5-1.465 6 .976 6 4.395m-6-4.395c-2-1.465-6.5 1.464-6.5 5.86m6.5-5.86c1.5-2.93 5-4.395 9-.488m-9 .488c-1.5-2.93-7-3.415-9 .978m9-.978c-1.5 1.955-2.5 5.37-2.5 7.813M10 21.5c1.412-.5 2.825-.5 3.295-.5.471 0 2.354 0 3.705.5"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgTree;
