import React, {
  useContext, useEffect, useState,
} from 'react';
import { extendMoment } from 'moment-range';
import * as Moment from 'moment';
import { range } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import { PauseTypography } from '../PauseTypography';
import { DAY_FORMAT, LEAVE_MARKERS } from '../PauseCalendar/PauseFullCalendar/constants';
import { getDate } from '../PauseCalendar/PauseListCalendar/utils';
import { ChevronLeft, ChevronRight } from '../../../assets/icons/feather/components';

// const SELECTED_DATE_RADIUS = 1.5;

const { START_TYPES, END_TYPES } = LEAVE_MARKERS;

type DatePickerProps = {
  startDate: Moment.Moment,
  startType: string,
  endDate: Moment.Moment,
  endType: string,
  dateType: string,
  tempEndDate: Moment.Moment | null,
  setTempEndDate: (any?) => any,
  currentDate: Moment.Moment,
  setCurrentDate: (any?) => any,
  mode: string,
  onChange: (any?) => any,
}

const moment = extendMoment(Moment);
const DatepickerDatesContext = React.createContext<DatePickerProps | null>(null);

const useSelectedDates = (dateToCheck) => {
  const {
    startDate, endDate, startType, endType, mode,
  } = useContext(DatepickerDatesContext as React.Context<DatePickerProps>);

  if (moment.range([startDate, endDate]).contains(dateToCheck)) {
    if (startDate.isSame(dateToCheck)) {
      return {
        isFirstHalfSelected: startType === START_TYPES[0],
        isSecondHalfSelected: mode === 'range' ? true : endType === END_TYPES[1],
      };
    }
    if (endDate.isSame(dateToCheck)) {
      return {
        isFirstHalfSelected: true,
        isSecondHalfSelected: endType === END_TYPES[1],
      };
    }
    return {
      isFirstHalfSelected: true,
      isSecondHalfSelected: true,
    };
  }
  return {
    isFirstHalfSelected: false,
    isSecondHalfSelected: false,
  };
};

const useHighlightedDates = (dateToCheck) => {
  const {
    startDate, tempEndDate, mode,
  } = useContext(DatepickerDatesContext as React.Context<DatePickerProps>);

  const [isDateHighlighted, setIsDateHighlighted] = useState(false);

  useEffect(() => {
    if (!tempEndDate || mode !== 'range') {
      setIsDateHighlighted(false);
    } else if (moment.range([startDate, tempEndDate]).contains(dateToCheck)) {
      setIsDateHighlighted(true);
    } else setIsDateHighlighted(false);
  }, [mode, dateToCheck, startDate, tempEndDate]);

  return {
    isDateHighlighted,
  };
};

const smartOnChange = ({
  dateType,
  selectedDate,
  endDate,
  mode,
}: {
    dateType: string,
    selectedDate: Moment.Moment,
    endDate: Moment.Moment,
    mode: string,
}) => {
  if (mode === 'single') {
    return {
      startDate: selectedDate.format(DAY_FORMAT),
      endDate: selectedDate.format(DAY_FORMAT),
      // dateType: 'start',
    };
  }
  if (dateType === 'start') {
    return {
      startDate: selectedDate.format(DAY_FORMAT),
      endDate: selectedDate.isAfter(endDate)
        ? selectedDate.clone().add(1, 'day').format(DAY_FORMAT) : endDate.format(DAY_FORMAT),
      // startType,
      // endType,
      // dateType: 'end',
    };
  }
  return {
    endDate: selectedDate.format(DAY_FORMAT),
    // startType,
    // endType,
    // dateType: 'start',
  };
};

const DatepickerWeekdaysHeader = () => (
  <div css={() => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
  })}
  >
    {moment.weekdays().map((weekday) => weekday.slice(0, 1)).map((weekday) => (
      <div
        css={(theme) => ({
          textAlign: 'center',
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
        })}
        key={weekday}
      >
        <PauseTypography
          variant="number"
          color="pause.primary.light"
        >
          {weekday}
        </PauseTypography>
      </div>
    ))}
  </div>
);

const DatepickerMonthSelectorHeader = () => {
  const {
    currentDate, setCurrentDate,
  } = useContext(DatepickerDatesContext as React.Context<DatePickerProps>);
  return (
    <div css={(theme) => ({
      display: 'grid',
      alignItems: 'center',
      gridAutoFlow: 'column',
      justifyContent: 'space-between',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginBottom: theme.spacing(2),
    })}
    >
      <PauseTypography
        css={(theme) => ({
          width: '5.5rem',
          color: theme.get('palette.pause.primary.medium'),
          textTransform: 'uppercase',
        })}
        variant="caption"
      >
        {currentDate.format('MMM YYYY')}

      </PauseTypography>
      <div css={(theme) => ({
        display: 'grid',
        gridAutoFlow: 'column',
        gridGap: theme.spacing(2),
      })}
      >
        <IconButton
          size="small"
          onClick={() => {
            setCurrentDate(
              currentDate
                .clone().subtract(1, 'month'),
            );
          }}
        >
          <ChevronLeft css={(theme) => ({
            color: theme.get('palette.pause.primary.main'),
            width: theme.spacing(3),
            height: theme.spacing(3),
          })}
          />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => setCurrentDate(
            currentDate
              .clone().add(1, 'month'),
          )}
        >
          <ChevronRight css={(theme) => ({
            color: theme.get('palette.pause.primary.main'),
            width: theme.spacing(3),
            height: theme.spacing(3),
          })}
          />
        </IconButton>
      </div>
    </div>
  );
};

const DatepickerHalfDay = ({
  selected,
  index,
}: {
  selected: boolean,
  index: number
}) => (
  <div
    key={`datepickerhalfday-${index}`}
    css={(theme) => ({
      height: '100%',
      cursor: 'pointer',
      backgroundColor: selected ? theme.get('palette.pause.primary.light') : 'transparent',
      // borderLeft: startsHere && `2px solid ${theme.get('palette.pause.primary.dark')}`,
      // borderRight: endsHere && `2px solid ${theme.get('palette.pause.primary.dark')}`,
      // ...(startsHere && {
      //   // borderTopLeftRadius: theme.spacing(SELECTED_DATE_RADIUS),
      //   // borderBottomLeftRadius: theme.spacing(SELECTED_DATE_RADIUS),
      //   // TODO - dont remove
      //   boxShadow: `inset 2px 0 0 0 ${theme.get('palette.pause.primary.dark')}`,
      // }),
      // ...(endsHere && {
      //   // borderTopRightRadius: theme.spacing(SELECTED_DATE_RADIUS),
      //   // borderBottomRightRadius: theme.spacing(SELECTED_DATE_RADIUS),
      //   // TODO - dont remove
      //   boxShadow: `inset -2px 0 0 0 ${theme.get('palette.pause.primary.dark')}`,
      // }),
      pointerEvents: 'none',
    })}
  />
);

type DatePickerDayProps = {
  date: Moment.Moment,
  isNonWorkingDay: boolean,
  isDisabled: boolean
}

const DatepickerDay = React.memo<DatePickerDayProps>(({
  date, isNonWorkingDay, isDisabled,
}) => {
  const {
    endDate,
    onChange,
    dateType,
    mode,
    // tempEndDate,
    setTempEndDate,
  } = useContext(DatepickerDatesContext as React.Context<DatePickerProps>);
  //
  // const isFirstSelectedDay = date.isSame(startDate);
  // const isLastSelectedDay = date.isSame(endDate);
  // const isLastHighlightedDay = date.isSame(tempEndDate);

  const { isFirstHalfSelected, isSecondHalfSelected } = useSelectedDates(date);
  const { isDateHighlighted } = useHighlightedDates(date);

  return (
  // eslint-disable-next-line max-len
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      onMouseOver={() => {
        if (dateType !== 'start') setTempEndDate(date);
      }}
      onClick={() => {
        onChange(smartOnChange({
          endDate,
          dateType,
          mode,
          selectedDate: date,
        }));
      }}
      css={(theme) => ({
        width: '100%',
        position: 'relative',
        cursor: 'pointer',
        ':before': {
          content: '""',
          paddingBottom: '100%',
          zIndex: -1,
          display: 'inline-block',
          verticalAlign: 'top',
        },
        // ...(isFirstSelectedDay && {
        //   borderTopLeftRadius: theme.spacing(SELECTED_DATE_RADIUS),
        //   borderBottomLeftRadius: theme.spacing(SELECTED_DATE_RADIUS),
        // }),
        // ...((isLastHighlightedDay || isLastSelectedDay) && !isFirstSelectedDay && {
        //   borderTopLeftRadius: 0,
        //   borderBottomLeftRadius: 0,
        //   borderTopRightRadius: theme.spacing(SELECTED_DATE_RADIUS),
        //   borderBottomRightRadius: theme.spacing(SELECTED_DATE_RADIUS),
        // }),
        ...(isDateHighlighted && {
          backgroundColor: theme.get('palette.pause.background.dark'),
        }),
        '&:hover': {
          // TODO - dont remove
          // eslint-disable-next-line max-len
          // boxShadow: `${leftBorder} ${theme.get('palette.pause.primary.dark')}, ${rightBorder} ${theme.get('palette.pause.primary.dark')}`,
          // ...(dateType === 'start' && {
          // }),
          backgroundColor: theme.get('palette.pause.background.dark'),
        },
        ...(isNonWorkingDay && {
          pointerEvents: 'none',
        }),
        ...(isDisabled && {
          pointerEvents: 'none',
          opacity: 0.3,
        }),
      })}
    >
      <div css={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        ...(isNonWorkingDay && ({
          opacity: 0.3,
        })),
      }}
      >
        <DatepickerHalfDay
          index={0}
          selected={isFirstHalfSelected}
        />
        <DatepickerHalfDay
          index={1}
          selected={isSecondHalfSelected}
        />
      </div>

      <div css={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: 'none',
      }}
      >
        <PauseTypography
          variant="number"
          color={isNonWorkingDay ? 'pause.primary.light' : 'pause.primary.main'}
        >
          {moment(date).date()}
        </PauseTypography>
      </div>
    </div>
  );
});

const DatepickerMonth = React.memo<{ date: Moment.Moment } & Pick<PauseDatepickerProps, 'nonWorkingDays'>>(({
  date,
  nonWorkingDays,
}) => {
  const startingWeekday = date.startOf('month').weekday();
  const startDay = date.clone().startOf('month');
  const endDay = date.clone().endOf('month');
  const daysOfTheMonth = range(0, endDay.diff(startDay, 'days') + 1);
  const {
    startDate,
    dateType,
    setTempEndDate,
  } = useContext(DatepickerDatesContext as React.Context<DatePickerProps>);

  return (
    <div
      onMouseLeave={() => {
        setTempEndDate(null);
      }}
      css={() => ({
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        // gridTemplateRows: 'repeat(6, 1fr)',
      })}
    >
      {range(startingWeekday).map(() => <div />)}
      {daysOfTheMonth.map((dayOfTheMonth) => {
        const thisDate = date.clone().add(dayOfTheMonth, 'days');
        return (
          <DatepickerDay
            key={dayOfTheMonth}
            date={thisDate}
            isNonWorkingDay={nonWorkingDays.includes(thisDate.weekday())}
            isDisabled={dateType === 'end' && thisDate.isBefore(startDate.clone().add(1, 'days'))}
          />

        );
      })}
    </div>
  );
});

const Datepicker = React.memo<Pick<PauseDatepickerProps, 'dateToShow' | 'nonWorkingDays'>>(({
  dateToShow,
  nonWorkingDays,
}) => (
  <div css={() => ({
    minWidth: '250px',
  })}
  >
    <DatepickerMonthSelectorHeader />
    <DatepickerWeekdaysHeader />

    <DatepickerMonth
      nonWorkingDays={nonWorkingDays}
      date={dateToShow}
    />

  </div>
));

// type FromDatePicker = Pick<DatePickerProps, 'startDate' | 'startType' | 'endDate' | 'endType' | 'onChange' | 'mode'>

type PauseDatepickerProps = Pick<DatePickerProps, 'startDate' | 'startType' | 'endDate' | 'endType' | 'onChange' | 'mode'> & {
  dateToShow: Moment.Moment,
  nonWorkingDays: Array<number>,
  dateTypeToChange: string,
}

export const PauseDatepicker = React.memo<PauseDatepickerProps>(({
  dateToShow: CURRENT_DATE = moment(),
  nonWorkingDays = [0, 6],
  startDate: START_DATE,
  startType: START_TYPE,
  endDate: END_DATE,
  endType: END_TYPE,
  onChange,
  mode: MODE = 'range', // or 'single'
  dateTypeToChange: DATE_TYPE = 'start',
}) => {
  const [currentDate, setCurrentDate] = useState(CURRENT_DATE);
  const [startDate, setStartDate] = useState(getDate(START_DATE));
  const [startType, setStartType] = useState(START_TYPE);
  const [endDate, setEndDate] = useState(MODE === 'range' ? getDate(END_DATE) : getDate(START_DATE));
  const [tempEndDate, setTempEndDate] = useState(null);
  const [endType, setEndType] = useState(END_TYPE);
  const [dateType, setDateType] = useState(DATE_TYPE);
  const [mode, setMode] = useState(MODE);

  useEffect(() => {
    setCurrentDate(getDate(CURRENT_DATE));
  }, [CURRENT_DATE]);
  useEffect(() => {
    setStartDate(getDate(START_DATE));
  }, [START_DATE]);
  useEffect(() => {
    setStartType(START_TYPE);
  }, [START_TYPE]);
  useEffect(() => {
    setEndDate(MODE === 'range' ? getDate(END_DATE) : getDate(START_DATE));
  }, [START_DATE, END_DATE, MODE]);
  useEffect(() => {
    setEndType(END_TYPE);
  }, [END_TYPE]);
  useEffect(() => {
    setDateType(DATE_TYPE);
  }, [DATE_TYPE]);

  useEffect(() => {
    setMode(MODE);
  }, [MODE]);

  useEffect(() => {
    if (dateType === 'start') {
      setTempEndDate(null);
    }
  }, [dateType]);

  return (
    <DatepickerDatesContext.Provider value={{
      startDate,
      startType,
      endDate,
      endType,
      dateType,
      tempEndDate,
      setTempEndDate,
      currentDate,
      setCurrentDate,
      mode,
      onChange,
    }}
    >
      <Datepicker dateToShow={currentDate} nonWorkingDays={nonWorkingDays} />
    </DatepickerDatesContext.Provider>
  );
});
