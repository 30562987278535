import { omit } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { useMemo } from 'react';
import { useAPI } from '../hooks/useAxios';
import { expressiveColors } from '../components/PauseTheme/colors';
import { formatPerson } from './_person-utils';

import { useAutoInfiniteQuery } from './_utils';
import { triggerDownload } from '../../utils/downloader';

export const usePeople = ({ enabled = false } = {}) => {
  const { put, get, post } = useAPI();

  const queryClient = useQueryClient();

  const getPeopleAPI = ({ pageParam = 0, limit }): Promise<any> => get({
    url: '/people',
    params: {
      offset: pageParam,
      limit,
      includeArchived: true,
    },
  }, (d) => d);

  const editPersonAPI = (data): Promise<any> => put({
    url: `/people/${data.id}`,
    data: {
      ...omit(data, ['id', 'joiningDate']),
      ...(data.teamIDs ? { groupIDs: data.teamIDs } : {}),
    },
  });

  const archivePersonAPI = (data): Promise<any> => put({
    url: `/people/${data.personID}/archive`,
    data: omit(data, ['personID']),
  }, (d) => d || true);

  const createPersonAPI = (data) => post({
    url: '/people',
    data,
  });

  const resendInvitationAPI = (id) => put({
    url: `/people/${id}/invitation`,
  }, (d) => d || true);

  const exportLeavesRecord = (params: {
    id: string,
    startDate: string,
    endDate: string,
    format?: 'csv',
    nameOfPerson?: string,
  }) => {
    const {
      id,
      startDate,
      endDate,
      format = 'csv',
      nameOfPerson,
    } = params;
    return get({
      url: `/people/${id}/export/leavesRecord`,
      params: {
        startDate,
        endDate,
        format,
      },
    }).then((res) => triggerDownload({
      fileName: `${nameOfPerson || id}.csv`,
      fileContents: res,
    }));
  };

  const {
    isComplete, isLoading, data: peopleData, update, add,
  } = useAutoInfiniteQuery({
    queryKey: 'people',
    queryFn: getPeopleAPI,
    apiResponseKey: 'people',
    enabled,
    queryParams: {},
  });

  const { mutateAsync: editPerson } = useMutation(editPersonAPI, {
    onSuccess: (newData) => {
      update(newData);
      const me: any = queryClient.getQueryData('me');
      if (newData.id === me?.id) {
        queryClient.setQueryData<any>('me', (oldData: any) => ({
          ...oldData,
          ...newData,
        }));
      }
    },
  });

  const { mutateAsync: editPersonOptimistic } = useMutation(editPersonAPI, {
    onMutate: (newData) => {
      update(newData);
      // const me = queryClient.getQueryData<any>('me');
      // if (newData.id === me?.id) {
      //   queryClient.setQueryData<any>('me', (oldData: any) => ({
      //     ...oldData,
      //     ...newData,
      //   }));
      // }
    },
    onSettled: (newData, error) => {
      if (error) return Promise.resolve();
      const me = queryClient.getQueryData<any>('me');
      if (newData.id === me?.id) {
        queryClient.setQueryData<any>('me', (oldData: any) => ({
          ...oldData,
          ...newData,
        }));
      }
    },
  });

  const archivePersonInPersonQueryData = ({ id, leavingDate }) => ({ pages, pageParams }) => {
    const newPages = pages.map((page) => ({
      ...page,
      people: page.people.map((person) => ({
        ...person,
        isArchived: person.id === id ? true : person.isArchived,
        leavingDate: person.id === id ? leavingDate : person.leavingDate,
      })),
    }));
    return {
      pages: newPages,
      pageParams,
    };
  };

  const { mutateAsync: archivePerson } = useMutation(archivePersonAPI, {
    onSuccess: (_, data) => {
      queryClient.setQueryData<any>('people', archivePersonInPersonQueryData({
        ...data,
        id: data.personID,
      }));
    },
  });

  const { mutateAsync: createPerson } = useMutation(createPersonAPI, {
    onSuccess: (newPerson) => {
      add(newPerson);
    },
  });

  const { mutateAsync: resendInvitation } = useMutation(resendInvitationAPI);

  const removeArchived = (p) => !p.isArchived;
  const memoizedPeopleData = useMemo(() => peopleData, [peopleData]);
  const people = useMemo(() => memoizedPeopleData.filter(removeArchived).sort(
    (a, b) => (a.createdAt > b.createdAt ? 1 : -1),
  ).map((d, idx: number) => ({
    ...d,
    color: expressiveColors[idx % expressiveColors.length],
  })).map(formatPerson), [memoizedPeopleData]);

  const archivedPeople = useMemo(() => peopleData.filter((x) => !removeArchived(x)).sort(
    (a, b) => (a.createdAt > b.createdAt ? 1 : -1),
  ).map((d, idx: number) => ({
    ...d,
    color: expressiveColors[idx % expressiveColors.length],
  })).map(formatPerson), [peopleData]);

  const { mutateAsync: onExportLeavesRecord } = useMutation(exportLeavesRecord);

  return {
    editPerson,
    archivePerson,
    people,
    archivedPeople: archivedPeople || [],
    loading: isLoading,
    isComplete,
    mutatePersonInCache: update,
    createPerson,
    resendInvitation,
    editPersonOptimistic,
    exportLeavesRecord: onExportLeavesRecord,
  };
};
