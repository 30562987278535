import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { PauseTypography } from '../PauseTypography';

export const PauseTag = ({ children, color, textColor }) => (
  <PauseTypography
    variant="caption"
    dontWrapText
    color={`pause.${textColor}`}
    css={(theme) => ({
      display: 'inline',
      background: get(theme, `palette.pause.${color}`),
      padding: `${theme.spacing(0)}px ${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
      borderRadius: theme.spacing(0.5),
      lineHeight: 1,
    })}
  >
    {children}
  </PauseTypography>
);

PauseTag.propTypes = {
  children: PropTypes.string.isRequired,
  color: PropTypes.string,
  textColor: PropTypes.string,
};

PauseTag.defaultProps = {
  color: 'primary.light',
  textColor: 'white.main',
};
