import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@material-ui/core';

export const PauseTable = ({ children, ...props }) => (
  <Table {...props}>
    {children}
  </Table>
);

PauseTable.propTypes = {
  children: PropTypes.node.isRequired,
};

export const PauseTableContainer = ({ children, ...props }) => (
  <div
    css={(theme) => ({
      padding: 0,
      borderWidth: theme.spacing(0.25),
      borderRadius: theme.spacing(1),
      borderStyle: 'solid',
      borderColor: theme.get('palette.pause.background.dark'),
      borderCollapse: 'separate !important',
      overflowX: 'auto',
      backgroundColor: theme.get('palette.pause.white.main'),
      overflowY: 'hidden',
    })}
    {...props}
  >
    {children}
  </div>
);

PauseTableContainer.propTypes = {
  children: PropTypes.instanceOf(PauseTable).isRequired,
};
