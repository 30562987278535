import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useAPI } from '../../hooks/useAxios';

type UseIndividualLeave = (a: {
  id: string | null
}) => {
  leave: any,
  loading: boolean,
  error: string | null
}

export const useIndividualLeave: UseIndividualLeave = ({ id }) => {
  const [error, setError] = useState<string | null>(null);
  const { get } = useAPI();
  // eslint-disable-next-line no-shadow
  const getLeaveById = (leaveID) => async (): Promise<any> => {
    if (leaveID) {
      return get({
        url: `/leaves/${leaveID}`,
      });
    }
    return Promise.resolve(null);
  };

  const {
    data: leave,
    isLoading,
    isError,
    error: leaveError,
  } = useQuery(['leave', id], getLeaveById(id), {
    enabled: !!id,
  });

  useEffect(() => {
    if (isError) {
      setError(leaveError as string);
    }
  }, [leaveError, isError]);

  return {
    leave,
    error,
    loading: isLoading,
  };
};
