import { CheckmarkFilled24 } from '@carbon/icons-react';
import React from 'react';
import { SlackPHDaysInAdvanceOptions } from '../src/api/settings';

// TODO : remove this when v2 is completed
export const DEFAULT_SNACKBAR_OPTIONS = {
  autoHideDuration: 10000,
  variant: 'info',
  anchorOrigin: {
    horizontal: 'center',
    vertical: 'bottom',
  },
  iconVariant: {
    success: <div style={{ marginRight: '8px', marginTop: '4px' }}><CheckmarkFilled24 /></div>,
    error: '',
    warning: '',
    info: '',
  },
  maxSnack: 3,
};

export const SLACK_SCOPES = {
  bot: 'chat:write,users:read,users:read.email,commands,channels:read,channels:join,groups:read',
  summary: 'incoming-webhook',
  user: 'users.profile:write',
};

export const DAY_TYPES = {
  startOfDay: 'START_OF_DAY',
  endOfDay: 'END_OF_DAY',
  afternoon: 'START_OF_SECOND_HALF',
  lunchtime: 'END_OF_FIRST_HALF',
};

export const DAY_TYPE_INFORMAL = {
  [DAY_TYPES.startOfDay]: 'first half',
  [DAY_TYPES.endOfDay]: 'full day',
  [DAY_TYPES.afternoon]: 'second half',
  [DAY_TYPES.lunchtime]: 'first half',
};

export const LEAVE_TYPES = {
  ANNUAL: 'annual',
  DEDUCTIBLE: 'deductible',
  NON_DEDUCTIBLE: 'non_deductible',
};
export const LEAVE_STATUSES = {
  APPROVED: 'ACCEPTED',
  DECLINED: 'REJECTED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
  CANCELLED: 'CANCELED',
};

export const HELP_LINKS = {
  deductibleLeaveTypes: 'http://support.getpause.com/en/articles/4866748-how-do-deductible-leave-types-work',
  nonDeductibleLeaveTypes: 'http://support.getpause.com/en/articles/4867257-how-do-non-deductible-leave-types-work',
};

export const SLACK_PH_FREQUENCIES = [
  { label: '1 week', value: SlackPHDaysInAdvanceOptions.OneWeek },
  { label: '2 weeks', value: SlackPHDaysInAdvanceOptions.TwoWeeks },
  { label: '3 weeks', value: SlackPHDaysInAdvanceOptions.ThreeWeeks },
  { label: '4 weeks', value: SlackPHDaysInAdvanceOptions.FourWeeks },
];

export const GOOGLE_SCOPES = {
  calendar: 'https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.readonly',
};

export const GOOGLE_AUTH_URL = 'https://accounts.google.com/o/oauth2/v2/auth';
