import { omit, get as _get, merge } from 'lodash';
import { useQueryClient, useQuery, useMutation } from 'react-query';
import { useAPI } from '../hooks/useAxios';
import { SpecialSettingsOptions } from '../integrated-components/settings/components/SpecialSettings';
import { useNotifier } from '../integrated-components/snackbar/store';
import { replaceKeysDeep } from '../../utils/replace-keys';

// eslint-disable-next-line no-shadow
export enum SlackPHDaysInAdvanceOptions {
  OneWeek = 'ONE_WEEK',
  TwoWeeks = 'TWO_WEEK',
  ThreeWeeks = 'THREE_WEEK',
  FourWeeks = 'FOUR_WEEK'
}

// eslint-disable-next-line no-shadow
export enum TeamCoverage {
  EntireOrg = 'ENTIRE_ORG',
  SpecificTeam = 'SPECIFIC_TEAM'
}

// eslint-disable-next-line no-shadow
export enum NotificationTiming {
  OnTheDay = 'ON_THE_DAY',
  OneDayBefore = 'ONE_DAY_BEFORE',
  TwoDaysBefore = 'TWO_DAYS_BEFORE',
  OneWeekBefore = 'ONE_WEEK_BEFORE'
}

type IntegrationsAPIResponse = {
  slack: {
    summaries: any[],
    bot: { installed: boolean, supportsBotSummaries: boolean,
      supportsPrivateChannelNotifications: boolean },
    user: {
      installed: boolean,
      birthday: {
        teamIDs: string[] | null,
        teamCoverage: TeamCoverage,
        notificationTiming: NotificationTiming
      } | null,
      workAnniversary: {
        teamIDs: string[] | null,
        teamCoverage: TeamCoverage,
        notificationTiming: NotificationTiming
      } | null,
    },
    botSummaries: {
      daily: any[],
      weekly: any[],
    },
    publicHoliday: {
      slackChannelID: string,
      notificationTime: string,
      notificationDayOfWeek: string,
      numberOfDaysInAdvance: SlackPHDaysInAdvanceOptions
    } | null,
    birthday: {
      slackChannelID: string,
      slackChannelName: string,
      notificationTime: string,
    } | null,
    workAnniversary: {
      slackChannelID: string,
      slackChannelName: string,
      notificationTime: string,
    } | null,
  },
  google: {
    user: {
      calendar: {
        email: string,
        createLeaveEvent: boolean,
        autoDeclineEvents: {
          status: boolean,
          message: string
        } | null,
        autoDeclineEventsObject: {
          onLeave: { status: boolean, message: string } | null,
          onPublicHoliday: { status: boolean, message: string } | null
        }
      } | null
    },
  }
}

type Summary = {
  channelName?: string,
  slackChannelID?: string,
  coverage: 'SPECIFIC_TEAM' | 'ENTIRE_ORG',
  id: string,
  notificationTime: string,
  teamID: string | null | undefined,
  type: 'daily' | 'weekly',
  deprecated: boolean,
}
type IntegrationsData = {
  slack: {
    supportsBotSummaries: boolean,
    supportsPrivateChannels: boolean,
    summaries: Summary[],
    bot: {
      installed: boolean,
    },
    user: {
      installed: boolean,
      birthday: {
        teamIDs: string[] | null,
        teamCoverage: TeamCoverage,
        notificationTiming: NotificationTiming
      } | null,
      workAnniversary: {
        teamIDs: string[] | null,
        teamCoverage: TeamCoverage,
        notificationTiming: NotificationTiming
      } | null,
    },
    publicHolidays: {
      channel: string,
      time: string,
      dayOfWeek: string,
      daysInAdvance: SlackPHDaysInAdvanceOptions
    } | null,
    birthdays: {
      channel: string,
      time: string
    } | null,
    workAnniversary: {
      channel: string,
      time: string,
    } | null,
  },
  google: {
    email: string,
    createLeaveEvent: boolean,
    autoDeclineEventsObject: {
      onLeave: {
        status: boolean,
        message: string
      },
      onPublicHoliday: {
        status: boolean,
        message: string
      }
    } | null
  } | null
}

const formatOptionsForPublicHolidays = (options: SpecialSettingsOptions): {
  slackChannelID: string,
  notificationTime: string,
  notificationDayOfWeek: string,
  numberOfDaysInAdvance: SlackPHDaysInAdvanceOptions
} => ({
  slackChannelID: options.channel,
  notificationTime: options.time,
  notificationDayOfWeek: options.dayOfWeek,
  numberOfDaysInAdvance: options.daysInAdvance,
});

const formatOptionsForBirthdaysOrWorkAnniv = (options: {
  channel: string,
}): {
  slackChannelID: string,
} => ({
  slackChannelID: options.channel,
});

const getSlackAPIURL = ({ type, isOld = false }: {
  type: 'daily' | 'weekly',
  isOld?: boolean
}) => {
  if (isOld) return '/integrations/slack/summaries';
  if (type === 'daily') return '/integrations/slack/botsummaries/daily';
  if (type === 'weekly') return '/integrations/slack/botsummaries/weekly';
  return '';
};

type UseSettings = {
  channels?: boolean
}
export const useSettings = ({
  channels,
}: UseSettings = {
  channels: false,
}) => {
  const {
    get, put, post, del,
  } = useAPI();
  const queryClient = useQueryClient();
  const { enqueueNotification } = useNotifier();

  const getIntegrationsAPI = (): Promise<IntegrationsAPIResponse> => get({
    url: '/integrations',
  }, (d) => replaceKeysDeep(d, { groupID: 'teamID', groupIDs: 'teamIDs' }));

  const slackDisconnectAPI = () => del({
    url: '/integrations/slack/bot',
  });

  const postCodeToFinishIntegrationAPI = (data: {
    code: string,
    redirectURL: string,
    state?: string
  }): Promise<any> => post({
    url: '/integrations/slack',
    data: {
      code: data.code,
      redirectURI: data.redirectURL,
      ...(data.state && { state: data.state }),
    },
  });

  const deleteSlackIntegrationAPI = ({ id, type, deprecated = false }: {
    id: string,
    type: 'daily' | 'weekly',
    deprecated?: boolean
  }): Promise<any> => del({
    url: `${getSlackAPIURL({ type, isOld: deprecated })}/${id}`,
  });

  const editSlackIntegrationAPI = (data: Summary): Promise<any> => put({
    url: `${getSlackAPIURL({ type: data.type, isOld: data.deprecated })}/${data.id}`,
    data: {
      ...omit(data, ['id', 'teamID']),
      groupID: data?.teamID,
    },
  });

  const createSlackIntegrationAPI = (data: Omit<Summary, 'id'>): Promise<any> => post({
    url: getSlackAPIURL({ type: data.type }),
    data: {
      ...omit(data, ['type', 'deprecated', 'channelName', 'teamID']),
      groupID: data?.teamID,
    },
  });

  const getCalendarFeedLinkAPI = ({ type = 'org', id }) => {
    const formattedType = {
      org: 'orgs',
      team: 'groups',
      person: 'people',
      calendar: 'publicholidaycalendars',
    }[type];
    return get({
      url: formattedType === 'orgs' ? '/orgs/calendarfeed' : `${formattedType}/${id}/calendarfeed`,
    });
  };

  const deletePersonSlackIntegrationAPI = (): Promise<any> => del({
    url: '/integrations/slack/users',
  });

  const { mutateAsync: postCodeToFinishIntegration } = useMutation(postCodeToFinishIntegrationAPI, {
    onSuccess: () => {
      queryClient.refetchQueries('integrations');
    },
  });

  const { mutateAsync: createSlackIntegration } = useMutation(createSlackIntegrationAPI, {
    onSuccess: (newIntegration, { type }) => {
      queryClient.setQueryData<any>('integrations', (oldData: IntegrationsAPIResponse) => merge(oldData, {
        slack: {
          botSummaries: {
            [type]: (_get(oldData, `slack.botSummaries.${type}`, []) as any).concat([replaceKeysDeep(newIntegration, {
              groupID: 'teamID',
            })]),
          },
        },
      }));
    },
  });

  const { mutateAsync: editSlackIntegration } = useMutation(editSlackIntegrationAPI, {
    onSuccess: (editedIntegration, { type, deprecated }) => {
      queryClient.setQueryData<any>('integrations', (oldData: IntegrationsAPIResponse) => {
        if (deprecated) {
          return merge(oldData, {
            slack: {
              summaries: (_get(oldData, 'slack.summaries', []) as any).map((d) => ({
                ...d,
                ...((d.id === editedIntegration.id) && editedIntegration),
              })),
            },
          });
        }
        return merge(oldData, {
          slack: {
            botSummaries: {
              [type]: (_get(oldData, `slack.botSummaries.${type}`, []) as any).map((d) => ({
                ...d,
                ...((d.id === editedIntegration.id) && replaceKeysDeep(editedIntegration, {
                  groupID: 'teamID',
                })),
              })),
            },
          },
        });
      });
    },
  });

  const { mutateAsync: deleteSlackIntegration } = useMutation(deleteSlackIntegrationAPI, {
    onSuccess: (_, { id, type, deprecated }) => {
      queryClient.setQueryData<any>('integrations', (oldData: IntegrationsAPIResponse) => {
        let newSummaries = null;
        if (deprecated) {
          newSummaries = (_get(oldData, 'slack.summaries', []) as any).filter((d) => d.id !== id);
          return {
            ...oldData,
            slack: {
              ...oldData.slack,
              summaries: newSummaries,
            },
          };
        }
        newSummaries = (_get(oldData, `slack.botSummaries.${type}`, []) as any).filter((d) => d.id !== id);
        return {
          ...oldData,
          slack: {
            ...oldData.slack,
            botSummaries: {
              ...oldData.slack.botSummaries,
              [type]: newSummaries,
            },
          },
        };
      });
    },
  });

  const { mutateAsync: getCalendarFeedLink } = useMutation(getCalendarFeedLinkAPI);

  const { mutateAsync: deletePersonSlackIntegration } = useMutation(
    deletePersonSlackIntegrationAPI, {
      onSuccess: () => queryClient.refetchQueries('integrations'),
    },
  );

  const exportDataAPI = ({
    startDate,
    endDate,
    format = 'csv',
  }: {
   startDate: string,
   endDate: string,
   format?: 'csv'
 }): Promise<any> => get({
    url: '/orgs/export',
    params: {
      startDate,
      endDate,
      format,
    },
  });

  const { mutateAsync: exportData } = useMutation(exportDataAPI);

  const exportLeavesRecordAPI = ({
    startDate,
    endDate,
    format = 'csv',
    includeArchived = true,
  }: {
    startDate: string,
    endDate: string,
    format?: 'csv',
    includeArchived?: boolean,
  }): Promise<any> => get({
    url: '/orgs/export/record',
    params: {
      startDate,
      endDate,
      format,
      includeArchived,
    },
  });

  const { mutateAsync: exportLeavesRecord } = useMutation(exportLeavesRecordAPI);

  const {
    data: integrationsRaw,
    isLoading: loadingIntegrations,
  } = useQuery<IntegrationsAPIResponse>('integrations', getIntegrationsAPI);

  const mergeSummaries = (integrations: IntegrationsAPIResponse | undefined): Summary[] => {
    const oldDailySummaries: Summary[] = _get(integrations, 'slack.summaries', []).map((a) => ({
      ...a,
      type: 'daily',
      deprecated: true,
    }));
    const newDailySummaries: Summary[] = _get(integrations, 'slack.botSummaries.daily', []).map((a) => ({
      ...a,
      type: 'daily',
    }));
    const newWeeklySummaries: Summary[] = _get(integrations, 'slack.botSummaries.weekly', []).map((a) => ({
      ...a,
      type: 'weekly',
    }));
    return oldDailySummaries.concat(newDailySummaries, newWeeklySummaries);
  };

  const integrations: IntegrationsData = {
    slack: {
      bot: {
        installed: integrationsRaw?.slack?.bot?.installed || false,
      },
      supportsBotSummaries: integrationsRaw?.slack?.bot?.supportsBotSummaries || false,
      supportsPrivateChannels: integrationsRaw?.slack?.bot?.supportsPrivateChannelNotifications
              || false,
      user: {
        installed: integrationsRaw?.slack?.user?.installed || false,
        birthday: integrationsRaw?.slack?.user?.birthday || null,
        workAnniversary: integrationsRaw?.slack?.user?.workAnniversary || null,
      },
      summaries: mergeSummaries(integrationsRaw),
      publicHolidays: !integrationsRaw?.slack?.publicHoliday
        ? null
        : {
          channel: integrationsRaw?.slack?.publicHoliday?.slackChannelID,
          time: integrationsRaw?.slack?.publicHoliday?.notificationTime,
          dayOfWeek: integrationsRaw?.slack?.publicHoliday?.notificationDayOfWeek,
          daysInAdvance: integrationsRaw?.slack?.publicHoliday?.numberOfDaysInAdvance,
        },
      birthdays: integrationsRaw?.slack?.birthday
        ? {
          channel: integrationsRaw?.slack?.birthday?.slackChannelID,
          time: integrationsRaw?.slack?.birthday?.notificationTime,
        } : null,
      workAnniversary: integrationsRaw?.slack?.workAnniversary
        ? {
          channel: integrationsRaw?.slack?.workAnniversary?.slackChannelID,
          time: integrationsRaw?.slack?.workAnniversary?.notificationTime,
        } : null,
    },
    google: !integrationsRaw?.google?.user?.calendar ? null : {
      email: integrationsRaw?.google?.user?.calendar?.email || '',
      createLeaveEvent: integrationsRaw?.google?.user?.calendar?.createLeaveEvent || false,
      autoDeclineEventsObject: !integrationsRaw?.google?.user?.calendar?.autoDeclineEventsObject ? null : {
        onLeave: {
          status: integrationsRaw?.google?.user?.calendar?.autoDeclineEventsObject?.onLeave?.status || false,
          message: integrationsRaw?.google?.user?.calendar?.autoDeclineEventsObject?.onLeave?.message || '',
        },
        onPublicHoliday: {
          status: integrationsRaw?.google?.user?.calendar?.autoDeclineEventsObject?.onPublicHoliday?.status || false,
          message: integrationsRaw?.google?.user?.calendar?.autoDeclineEventsObject?.onPublicHoliday?.message || '',
        },
      },
    },
  };

  const getSlackChannelsAPI = () => get({
    url: '/integrations/slack/channels',
  }, (d) => _get(d, 'channels', []).map((c) => ({
    ...c,
    isVisibleToUser: c.isVisibleToPrincipal,
  })));

  const { data: slackChannels, isLoading: loadingSlackChannels } = useQuery<any[]>('slackChannels', getSlackChannelsAPI, {
    enabled: !loadingIntegrations && _get(integrations, 'slack.supportsBotSummaries') && channels,
    placeholderData: [],
    refetchOnWindowFocus: 'always',
  });

  const toggleSpecialSettingsAPI = (settings: {
    type: 'birthdays' | 'publicHolidays' | 'workAnniversary',
    enabled: boolean,
    isCreating?: boolean,
    options?: Partial<SpecialSettingsOptions> & { channel: string }
  }) => {
    const url = {
      birthdays: '/integrations/slack/birthdays',
      publicHolidays: '/integrations/slack/publicholidays',
      workAnniversary: '/integrations/slack/workanniversaries',
    }[settings.type];

    if (!url) throw new Error('Unknown type in settings.type of toggleSpecialSettingsAPI');

    if (!settings.enabled) {
      return del({
        url,
      });
    }

    if (settings.isCreating) {
      return post({
        url,
      });
    }

    if (['birthdays', 'workAnniversary'].includes(settings.type)) {
      return put({
        url,
        data: replaceKeysDeep(formatOptionsForBirthdaysOrWorkAnniv({
          channel: settings.options?.channel || '',
        }), {
          teamIDs: 'groupIDs',
        }),
      });
    }

    if (settings.type === 'publicHolidays') {
      return put({
        url,
        data: formatOptionsForPublicHolidays(settings.options as SpecialSettingsOptions),
      });
    }

    return Promise.resolve(true);
  };

  const { mutateAsync: toggleSpecialSettings } = useMutation(
    toggleSpecialSettingsAPI, {
      onMutate: async (newSettings: any) => {
        // Snapshot the previous value
        const previousSettings = queryClient.getQueryData('integrations');
        // Optimistically update to the new value
        queryClient.setQueryData('integrations', (old: any) => {
          const obj = {
            slack: {
              ...(newSettings.type === 'birthdays' && {
                birthday: !newSettings.enabled ? null : {
                  slackChannelID: newSettings.options?.channel,
                  notificationTime: '09:00:00',
                },
              }),
              ...(newSettings.type === 'workAnniversary' && {
                workAnniversary: !newSettings.enabled ? null : {
                  slackChannelID: newSettings.options?.channel,
                  notificationTime: '09:00:00',
                },
              }),
              ...(newSettings.type === 'publicHolidays' && {
                publicHoliday: !newSettings.enabled
                  ? null
                // @ts-ignore
                  : formatOptionsForPublicHolidays(newSettings.options),
              }),
            },
          };
          const data = merge({}, old, obj);
          return data;
        });

        // Return a context object with the snapshotted value
        return { previousSettings };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (_err, _newSettings, context) => {
        // @ts-ignore
        queryClient.setQueryData('integrations', context.previousSettings);
        enqueueNotification((_err as any)?.response?.data?.message || (_err as any)?.message);
      },
      // // Always refetch after error or success:
      // onSettled: () => {
      //   queryClient.invalidateQueries('integrations');
      // },
      onSuccess: () => {
        queryClient.invalidateQueries('integrations');
      },
    },
  );

  const togglePersonalSpecialSettingsAPI = (settings: {
    type: 'birthday' | 'workAnniversary',
    enabled: boolean,
    isCreating?: boolean,
    options?: {
      teamIDs: string[],
      notificationTiming: NotificationTiming,
      teamCoverage: TeamCoverage.SpecificTeam
    } | {
      teamIDs: null,
      notificationTiming: NotificationTiming,
      teamCoverage: TeamCoverage.EntireOrg
    }
  }) => {
    const url: string = {
      birthday: '/integrations/slack/users/birthday',
      workAnniversary: '/integrations/slack/users/workanniversaries',
    }[settings.type];

    if (!url) throw new Error('Unknown type in settings.type of togglePersonalSpecialSettingsAPI');

    if (!settings.enabled) {
      return del({
        url,
      });
    }

    if (settings.isCreating) {
      return post({
        url,
      });
    }

    if (['birthday', 'workAnniversary'].includes(settings.type)) {
      return put({
        url,
        data: replaceKeysDeep(settings.options, {
          teamIDs: 'groupIDs',
        }),
      });
    }

    return Promise.resolve(true);
  };

  const { mutateAsync: togglePersonalSpecialSettings } = useMutation(
    togglePersonalSpecialSettingsAPI, {
      onMutate: (settings) => {
        const previousSettings = queryClient.getQueryData('integrations');
        const defaultOptions = {
          teamIDs: null,
          teamCoverage: TeamCoverage.EntireOrg,
          notificationTiming: NotificationTiming.OneWeekBefore,
        };
        const newIntegrations = merge({}, previousSettings, {
          slack: {
            user: {
              // eslint-disable-next-line no-nested-ternary
              [settings.type]: settings.isCreating ? defaultOptions : settings.options || null,
            },
          },
        });
        queryClient.setQueryData('integrations', () => newIntegrations);
        return { previousSettings };
      },
      onError: (_err, _variables, context) => {
        // @ts-ignore
        queryClient.setQueryData('integrations', (context || {}).previousSettings);
        enqueueNotification((_err as any)?.response?.data?.message || (_err as any)?.message);
      },
      onSettled: () => {
        queryClient.invalidateQueries('integrations');
      },
    },
  );

  const { mutateAsync: onSlackDisconnect } = useMutation(slackDisconnectAPI, {
    onSuccess: () => {
      queryClient.invalidateQueries('integrations');
    },
  });

  return {
    integrations,
    loading: loadingIntegrations || loadingSlackChannels,
    slackChannels,
    postCodeToFinishIntegration,
    createSlackIntegration,
    editSlackIntegration,
    deleteSlackIntegration,
    getCalendarFeedLink,
    deletePersonSlackIntegration,
    exportData,
    exportLeavesRecord,
    toggleSpecialSettings,
    togglePersonalSpecialSettings,
    onSlackDisconnect,
  };
};

const getGoogleIntegrations = (a: IntegrationsData['google']) => (a ? ({
  createLeaveEvent: a?.createLeaveEvent || false,
  autoDeclineEventsObject: a?.autoDeclineEventsObject ? ({
    onLeave: {
      status: a?.autoDeclineEventsObject?.onLeave?.status || false,
      message: a?.autoDeclineEventsObject?.onLeave?.message || '',
    },
    onPublicHoliday: {
      status: a?.autoDeclineEventsObject?.onPublicHoliday?.status || false,
      message: a?.autoDeclineEventsObject?.onPublicHoliday?.message || '',
    },
  }) : null,
}) : null);

type UseGoogleCalIntegration = {
  integration: {
    createLeaveEvent: boolean,
    autoDeclineEventsObject: {
      onLeave: {
        status: boolean,
        message: string
      } | null,
      onPublicHoliday: {
        status: boolean,
        message: string
      } | null,
    } | null
  } | null,
  removeIntegration: () => any,
  finishGoogleIntegration: (d: {
    code: string,
    redirectURI: string
  }) => any,
  toggleSetLeaveEvent: (a: boolean) => any,
  toggleAutoDeclineEvents: (a: { status: boolean, type: 'leave' | 'publicHoliday' }) => any,
  editOOOMessage: (a: { msg: string, type: 'leave' | 'publicHoliday' }) => any
}
export const useGoogleCalendarIntegration = (): UseGoogleCalIntegration => {
  const { integrations, loading } = useSettings();
  const googleIntegrations = !loading ? getGoogleIntegrations(integrations?.google as any) : null;
  const queryClient = useQueryClient();
  const {
    put, post, del,
  } = useAPI();
  const calendarIntegrationURL = '/integrations/google/calendar';
  const editIntegrationDetailsAPI = (data) => put({
    url: calendarIntegrationURL,
    data,
  });
  const removeIntegrationAPI = () => del({
    url: calendarIntegrationURL,
  });

  const toggleSetLeaveEventAPI = (a: boolean) => editIntegrationDetailsAPI({
    autoDeclineEventsObject: googleIntegrations?.autoDeclineEventsObject || null,
    autoDeclineEvents: googleIntegrations?.autoDeclineEventsObject?.onLeave,
    createLeaveEvent: a,
  });
  // const toggleSetLeaveEventAPI = (a: boolean) => Promise.resolve(true);

  const toggleAutoDeclineEventAPI: UseGoogleCalIntegration['toggleAutoDeclineEvents'] = ({ status, type }) => editIntegrationDetailsAPI({
    createLeaveEvent: googleIntegrations?.createLeaveEvent || false,
    autoDeclineEvents: googleIntegrations?.autoDeclineEventsObject?.onLeave,
    autoDeclineEventsObject: {
      ...googleIntegrations?.autoDeclineEventsObject,
      ...(type === 'leave' && {
        onLeave: {
          status,
          message: '',
        },
      }),
      ...(type === 'publicHoliday' && {
        onPublicHoliday: {
          status,
          message: '',
        },
      }),
    },
  });

  const editOOOMessageAPI: UseGoogleCalIntegration['editOOOMessage'] = ({ msg, type }) => editIntegrationDetailsAPI({
    createLeaveEvent: googleIntegrations?.createLeaveEvent || false,
    autoDeclineEvents: googleIntegrations?.autoDeclineEventsObject?.onLeave,
    autoDeclineEventsObject: {
      ...googleIntegrations?.autoDeclineEventsObject,
      ...(type === 'leave' && { onLeave: { status: true, message: msg } }),
      ...(type === 'publicHoliday' && { onPublicHoliday: { status: true, message: msg } }),
    },
  });

  const finishGoogleIntegrationAPI = (data: {
    code: string,
    redirectURI: string
  }) => post({
    url: '/integrations/google/auth',
    data: {
      ...data,
      type: 'PERSONAL_CALENDAR',
    },
  });

  const { mutateAsync: finishGoogleIntegration } = useMutation(finishGoogleIntegrationAPI, {
    onSuccess: () => {
      queryClient.refetchQueries('integrations');
    },
  });

  const { mutateAsync: removeIntegration } = useMutation(removeIntegrationAPI, {
    onSuccess: () => {
      queryClient.refetchQueries('integrations');
    },
  });

  const { mutateAsync: toggleSetLeaveEvent } = useMutation(toggleSetLeaveEventAPI, {
    onMutate: (enabled) => {
      const previousState = queryClient.getQueryData('integrations');
      const updatedChunk = merge({}, previousState, {
        google: {
          user: {
            calendar: {
              createLeaveEvent: enabled,
            },
          },
        },
      });
      queryClient.setQueryData<any>('integrations', (oldData: IntegrationsAPIResponse) => merge({}, oldData, updatedChunk));
      return { previousState };
    },
    onError: (_err, _data, context) => {
      queryClient.setQueryData('integrations', (context as any)?.previousState);
    },
    onSettled: () => {
      queryClient.invalidateQueries('integrations');
    },
  });

  const { mutateAsync: toggleAutoDeclineEvents } = useMutation(toggleAutoDeclineEventAPI, {
    onMutate: (enabled) => {
      const previousState = queryClient.getQueryData('integrations');
      const updatedChunk = merge({}, previousState, {
        google: {
          user: {
            calendar: {
              autoDeclineEvents: enabled ? {
                status: true,
                message: '',
              } : {
                status: false,
                message: '',
              },
            },
          },
        },
      });
      queryClient.setQueryData<any>('integrations', (oldData: IntegrationsAPIResponse) => merge({}, oldData, updatedChunk));
      return { previousState };
    },
    onError: (_err, _data, context) => {
      queryClient.setQueryData('integrations', (context as any)?.previousState);
    },
    onSettled: () => {
      queryClient.invalidateQueries('integrations');
    },
  });

  const { mutateAsync: editOOOMessage } = useMutation(editOOOMessageAPI, {
    // onMutate: (message) => {
    //   const previousState = queryClient.getQueryData('integrations');
    //   const updatedChunk = merge({}, previousState, {
    //     google: {
    //       user: {
    //         calendar: {
    //           autoDeclineEvents: {
    //             status: true,
    //             message,
    //           },
    //         },
    //       },
    //     },
    //   });
    //   queryClient.setQueryData<any>('integrations', (oldData: IntegrationsAPIResponse) => merge({}, oldData, updatedChunk));
    //   return { previousState };
    // },
    // onError: (_err, _data, context) => {
    //   queryClient.setQueryData('integrations', (context as any)?.previousState);
    // },
    onSuccess: () => {
      queryClient.invalidateQueries('integrations');
    },
  });

  return {
    integration: googleIntegrations,
    finishGoogleIntegration,
    toggleSetLeaveEvent,
    removeIntegration,
    toggleAutoDeclineEvents,
    editOOOMessage,
  };
};

export const useGoogleIntegrationData = () => {
  const { integrations, loading } = useSettings();
  const googleIntegrations = !loading ? getGoogleIntegrations(integrations?.google as any) : null;
  return { integration: googleIntegrations };
};
