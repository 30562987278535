import { useMutation, useQuery, useQueryClient } from 'react-query';
import moment from 'moment';
import { omit, unionBy } from 'lodash';
import { api } from '../hooks/useAxios';
import { useQueryRecursive } from './_utils';
import { PublicHolidays } from '../types/publicHolidays';
import { usePeople } from './people';

const {
  get, post, put, del,
} = api();
const getCountriesList = () => get({
  url: '/publicholidaycalendars/holidayapi/listregions',
}, (d) => (d?.countries || []).map((country) => ({
  label: country.name,
  value: country.code,
  regions: [{ label: 'All regions', value: country.code }].concat(country.subdivisions.map((region) => ({
    value: region.code,
    label: region.name,
  }))),
})));
const getHolidaysForCountry = async (args: {
  regionCode: string | null,
  year: string,
}) => {
  const { regionCode, year } = args;
  if (!regionCode || !year) return [];
  const holidaysForCurrentYear = await get({
    url: '/publicholidaycalendars/holidayapi/listholidays',
    params: {
      region: regionCode,
      year,
    },
  }, (d) => d.holidays || []);
  const holidaysForNextYear = await get({
    url: '/publicholidaycalendars/holidayapi/listholidays',
    params: {
      region: regionCode,
      year: Number(year) + 1,
    },
  }, (d) => d.holidays || []);
  const combinedHolidays = [].concat(holidaysForCurrentYear, holidaysForNextYear).reduce((acc: any[], curr: any) => {
    if ([year, `${Number(year) + 1}`].includes(moment(curr.date).format('YYYY'))) {
      return acc.concat({
        name: curr.name,
        date: curr.date,
        uuid: curr.uuid,
      });
    }
    return acc;
  }, []);
  return combinedHolidays;
};
const createNewCalendar = (data: {
  name: string,
  regionCode: string | null,
  holidays: Array<{
    holidayID: string | null,
    name: string,
    date: string,
  }>,
  assignToUsers: string[],
  emoji: string | null,
}) => post({
  url: '/publicholidaycalendars',
  data,
});
const getPublicHolidays = ({ params }) => get({
  url: '/publicholidaycalendars',
  params,
});
const getPublicHolidayCalendarById = (id: string) => get({
  url: `/publicholidaycalendars/${id}`,
});
const editPublicHolidayCalendar = (data) => put({
  url: `/publicholidaycalendars/${data.id}`,
  data,
});
const deletePublicHoliday = (id) => del({
  url: `/publicholidays/${id}`,
});
const editPublicHoliday = ({ id, ...rest }) => put({
  url: `/publicholidays/${id}`,
  data: rest,
});
const addPublicHoliday = (data) => post({
  url: '/publicholidays',
  data,
});

export const usePublicHolidayCalendars = (args: {
  enabled?: boolean
}) => {
  const { enabled = false } = args;
  const { data, loading } = useQueryRecursive<any[]>({
    queryKey: 'publicHolidayCalendars',
    queryFn: getPublicHolidays,
    apiResponseKey: 'calendars',
    enabled,
  });
  return { data: data || [], loading };
};

export const usePublicHolidayCalendarsMutation = () => {
  const { add, removeById, updateById } = useQueryRecursive({
    queryKey: 'publicHolidayCalendars',
    queryFn: getPublicHolidays,
    apiResponseKey: 'calendars',
    enabled: false,
  });

  const queryClient = useQueryClient();

  const { mutateAsync: onCreatePHCalendar } = useMutation(createNewCalendar, {
    onSuccess: (response) => {
      add(response);
      // queryClient.invalidateQueries('people');
    },
  });

  const { mutateAsync: onEditPHCalendar } = useMutation(editPublicHolidayCalendar, {
    onSuccess: (response) => {
      updateById(response);
    },
  });

  const { mutateAsync: onDeletePHCalendar } = useMutation((id) => del({
    url: `/publicholidaycalendars/${id}`,
  }), {
    onSuccess: (_, id) => {
      removeById({ id });
      queryClient.invalidateQueries('people');
      queryClient.refetchQueries('publicHolidaysForCalendar-original');
    },
  });

  const { mutateAsync: onEditCalendarNameEmoji } = useMutation((data: {
    id: string,
    name: string,
    emoji: string | null,
  }) => editPublicHolidayCalendar(data), {
    onSuccess: (_, data) => {
      updateById(data);
      queryClient.setQueryData<any>(['publicHolidays', data?.id], (oldData) => ({
        ...oldData,
        name: data.name,
        emoji: data.emoji,
      }));
    },
  });

  return {
    onCreatePHCalendar,
    onEditPHCalendar,
    onDeletePHCalendar,
    onEditCalendarNameEmoji,
  };
};

export const usePublicHolidaysByCalendarID = (args: {
  calendarID: string,
  enabled?: boolean,
}) => {
  const {
    enabled = false,
    calendarID,
  } = args;

  const { data, isLoading: loading } = useQuery<PublicHolidays>(
    ['publicHolidays', calendarID],
    () => getPublicHolidayCalendarById(calendarID),
    { enabled: enabled || !!calendarID },
  );

  return { data, loading };
};

export const usePublicHolidaysByCalendarIDMutations = (params: {
  calendarID?: string,
}) => {
  const {
    calendarID = '',
  } = params;

  const queryClient = useQueryClient();

  const { mutateAsync: onEditHoliday } = useMutation(editPublicHoliday, {
    onSuccess: (response) => {
      queryClient.refetchQueries('publicHolidaysForCalendar-original');
      queryClient.setQueryData<any>(['publicHolidays', response?.calendarID], (oldData: PublicHolidays) => {
        const updated = {
          ...oldData,
          holidays: unionBy([response], oldData?.holidays, 'id'),
        };
        return updated;
      });
    },
  });
  const { mutateAsync: onAddHoliday } = useMutation(addPublicHoliday, {
    onSuccess: (response) => {
      queryClient.refetchQueries('publicHolidayCalendars-original');
      queryClient.refetchQueries('publicHolidaysForCalendar-original');
      queryClient.setQueryData<any>(['publicHolidays', response?.calendarID], (oldData: PublicHolidays) => ({
        ...oldData,
        holidays: oldData.holidays.concat(({
          ...response,
          id: response?.id || `${Math.random()}`,
        })),
      }));
    },
  });
  const { mutateAsync: onDeleteHoliday } = useMutation<any, any, { id: string }>(
    ({ id }) => deletePublicHoliday(id), {
      onSuccess: (_, { id }) => {
        queryClient.refetchQueries('publicHolidayCalendars-original');
        queryClient.refetchQueries('publicHolidaysForCalendar-original');
        queryClient.setQueryData<any>(['publicHolidays', calendarID], (oldData: PublicHolidays) => ({
          ...oldData,
          holidays: (oldData.holidays || []).filter((d) => d.id !== id),
        }));
      },
    },
  );

  const { people, editPerson } = usePeople();
  const { mutateAsync: onRemoveMemberFromCalendar } = useMutation<any, any, {
    calendarID: string,
    personID: string,
  }>((data) => {
    const person = (people || []).find((p) => p.id === data?.personID);
    return editPerson({
      ...omit(person, ['allowance']),
      applicablePublicHolidayCalendars: [],
    });
  }, {
    onSuccess: (_, { personID }) => {
      queryClient.setQueryData<any>(['publicHolidays', calendarID], (oldData) => ({
        ...oldData,
        assignedToPeople: (oldData?.assignedToPeople || []).filter((id) => id !== personID),
      }));
      queryClient.refetchQueries('publicHolidayCalendars-original');
      queryClient.refetchQueries('publicHolidaysForCalendar-original');
    },
  });

  const { mutateAsync: onAddMemberToCalendar } = useMutation<any, any, {
    calendarID: string,
    personID: string,
  }>((data) => {
    const person = (people || []).find((p) => p.id === data?.personID);
    return editPerson({
      ...omit(person, ['allowances']),
      applicablePublicHolidayCalendars: [calendarID],
    });
  }, {
    onSuccess: (_, { personID }) => {
      queryClient.setQueryData<any>(['publicHolidays', calendarID], (oldData) => ({
        ...oldData,
        assignedToPeople: (oldData?.assignedToPeople || []).concat(personID),
      }));
      queryClient.refetchQueries('publicHolidayCalendars-original');
      queryClient.refetchQueries('publicHolidaysForCalendar-original');
    },
  });

  const { mutateAsync: onAddMembersInBulk } = useMutation<any, any, {
    calendarID: string,
  }>(() => {
    const peopleToAdd = (people || []).reduce((acc, curr) => {
      if (!curr.applicablePublicHolidayCalendars.length) return acc.concat(curr.id);
      if (curr.applicablePublicHolidayCalendars.includes(calendarID)) return acc.concat(curr.id);
      return acc;
    }, []);
    return put({
      url: `/publicholidaycalendars/${calendarID}/assignedToPeople`,
      data: {
        people: peopleToAdd,
      },
    });
  }, {
    onSuccess: (response) => {
      queryClient.setQueryData<any>(['publicHolidays', calendarID], (oldData) => ({
        ...oldData,
        assignedToPeople: (oldData?.assignedToPeople || [])
          .concat((response?.assignedToPeople || [])),
      }));
      queryClient.invalidateQueries('person');
      queryClient.refetchQueries('publicHolidayCalendars-original');
      queryClient.refetchQueries('publicHolidaysForCalendar-original');
      queryClient.refetchQueries('people');
    },
  });

  return {
    onEditHoliday,
    onDeleteHoliday,
    onAddHoliday,
    onRemoveMemberFromCalendar,
    onAddMemberToCalendar,
    onAddMembersInBulk,
  };
};

export const usePublicHolidaysForCalendar = (args: {
  enabled: boolean,
}) => {
  const {
    enabled = false,
  } = args;
  useQueryRecursive({
    queryKey: 'publicHolidaysForCalendar',
    queryFn: ({ params }) => get({
      url: '/publicholidays',
      params,
    }),
    enabled,
    apiResponseKey: 'publicHolidays',
  });
};

export const useCountries = (args: {
  enabled: boolean,
}) => {
  const { enabled } = args;
  const { data, isLoading } = useQuery<any[]>('countries', getCountriesList, {
    enabled,
  });
  return {
    data: data || [],
    loading: isLoading,
  };
};

export const useHolidaysForRegion = (args: {
  enabled: boolean,
  regionID: string
}) => {
  const { enabled, regionID } = args;
  const { data, isLoading } = useQuery<any[]>(['holidaysForRegion', regionID], () => getHolidaysForCountry({
    regionCode: regionID,
    year: moment().format('YYYY'),
  }), {
    enabled,
  });
  return {
    data: data || [],
    loading: isLoading,
  };
};
