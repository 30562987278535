import moment from 'moment';
import React from 'react';
import { DAY_FORMAT } from '../PauseCalendar/PauseFullCalendar/constants';
import { PauseIconButton } from '../PauseIconButton';
import { PauseTypography } from '../PauseTypography';
import { PauseStatefulChipRadioGroup } from '../PauseStatefulChip';
import { Trash2 } from '../../../assets/icons/feather/components';

export const PauseDateHandlerContents = ({ label, children }: {
  label?: string, children: React.ReactNode}) => (
    <>
      {label && (
      <div>
        <PauseTypography variant="caption" color="pause.primary.medium">{label}</PauseTypography>
      </div>
      )}
      {children}
    </>
);

type Props = {
  date: moment.Moment,
  dayTypeValue: DayTypeValue,
  dayTypeOptions: Array<DayTypeValue>,
  onClick: (any?) => any,
  dayTypeOnChange: (any?) => any,
  showCloseButton?: boolean,
  onClickCloseButton?: () => any,
  label: string

}
type DayTypeValue = {
  actualValue: {
    endType: string,
    startType?: string
  },
  value: string,
  label: string
}

export const PauseStartOrEndDateHandler = ({
  date, dayTypeOptions, dayTypeValue, onClick, dayTypeOnChange,
  showCloseButton, onClickCloseButton,
  label,
}: Props) => (
  <div
    css={(theme) => ({
      cursor: 'pointer',
      position: 'relative',
      borderRadius: theme.spacing(1),
      '&:hover, &:focus': {
        backgroundColor: theme.get('palette.pause.background.medium'),
      },

    })}
  >
    <div
      css={{
        position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 0,
      }}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    />
    <div
      role="presentation"
      css={(theme) => ({
        display: 'grid',
        gridGap: theme.spacing(0),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(0.75),
        paddingBottom: theme.spacing(0.75),
      })}

    >

      <PauseDateHandlerContents label={label}>

        <div css={(theme) => ({
          display: 'grid',
          gridAutoFlow: 'column',
          gridGap: theme.spacing(1),
          alignItems: 'center',
          justifyContent: 'space-between',
        })}
        >
          <PauseTypography variant="button" color="pause.primary.main">{moment(date, DAY_FORMAT).format('MMM DD')}</PauseTypography>

          <div css={() => ({ zIndex: 5 })}>
            <PauseStatefulChipRadioGroup
              options={dayTypeOptions}
              value={dayTypeValue.value}
              onChange={dayTypeOnChange}
            />
          </div>

          <div css={() => ({
            zIndex: 5,
            opacity: showCloseButton ? 1 : 0,
          })}
          >
            <PauseIconButton
              onClick={onClickCloseButton}
              size="small"
              color="primary"
              variant="ghost"
              icon={<Trash2 />}
            />
          </div>

        </div>
      </PauseDateHandlerContents>

    </div>
  </div>
);
