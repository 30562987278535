import moment from 'moment';
import { get } from 'lodash';
import { LeaveState, LeaveTemporalPosition, Viewer } from './leave-form-state';

type AnyObject = {[key: string]: any }
type LeaveWithStartDate = { startDate: string} & AnyObject | undefined
export const getLeaveTemporalPositionFromLeave = (
  leave: LeaveWithStartDate,
): LeaveTemporalPosition => {
  const startDate = get(leave, 'startDate');
  if (moment().isBefore(startDate)) return LeaveTemporalPosition.Future;
  return LeaveTemporalPosition.Past;
};

type LeaveObj = {
  applicant: {
    id: string
  },
  approver: {
    id: string
  }
} & AnyObject | undefined
type Me = { id: string }
export const getViewerFromLeave = ({ leave, me, currentApprover }: {
  me: Me,
  leave: LeaveObj,
  currentApprover: any
}): Viewer => {
  const applicantID = get(leave, 'applicant.id', '');
  const meID = get(me, 'id', '');
  const isAdmin = get(me, 'isAdmin');
  const currentApproverID = get(currentApprover, 'id');

  /* precedence matters
  * Approver
  * SelfAdmin
  * Admin
  * Self
  * Others
  * */

  /*
  * Viewer is decided by:
  * - who am i? (me)
  * - who is the current approver of the leave's applicant (currentApprover)
  * - is leave new or already existing (includes pending, approved, deleted or declined leaves)
  * */

  if (leave) {
    if (currentApproverID === meID) return Viewer.Approver;

    if (isAdmin && (applicantID === meID)) return Viewer.SelfAdmin;

    if (isAdmin) return Viewer.Admin;

    if (applicantID === meID) return Viewer.Self;

    return Viewer.Others;
  }

  // if there is no leave data, that means it's a new leave
  // in this case, the viewer is either an approver, an admin or self
  if (!leave) {
    if (meID === currentApproverID) return Viewer.Approver;

    if (isAdmin) return Viewer.SelfAdmin;

    return Viewer.Self;
  }
  return Viewer.Others;
};

type LeaveWithState = { status: string } & AnyObject | undefined
export const getLeaveStateFromLeave = (leave: LeaveWithState): LeaveState => {
  if (!leave) return LeaveState.New;
  const status = get(leave, 'status', undefined);
  if (!status) return LeaveState.New;
  switch (status) {
    case 'ACCEPTED':
      return LeaveState.Approved;
    case 'PENDING':
      return LeaveState.Pending;
    case 'REJECTED':
      return LeaveState.Declined;
    case 'CANCELED':
      return LeaveState.Deleted;
    default:
      return LeaveState.New;
  }
};

export const getLeaveActionerString = ({
  leave,
  currentApprover,
  me,
}): string => {
  const leaveState = getLeaveStateFromLeave(leave);
  const actionerName = get(leave, 'actioner.fullName', '');
  switch (leaveState) {
    case LeaveState.Deleted:
      return `Deleted by: ${actionerName}`;
    case LeaveState.Declined:
      return `Declined by: ${actionerName}`;
    case LeaveState.Approved:
      if (get(leave, 'applicant.id') === get(leave, 'actioner.id')) {
        return 'Self-approved';
      }
      return `Approved by: ${actionerName}`;

    case LeaveState.Pending:
      return `Approver: ${get(currentApprover, 'fullName')}`;
    case LeaveState.New:
      if (get(currentApprover, 'id') === get(me, 'id')) {
        return 'Auto-approved';
      }
      return `Approver: ${get(currentApprover, 'fullName')}`;
    default:
      return `Approver: ${get(currentApprover, 'fullName')}`;
  }
};
