import { usePeople } from '../api/people';
import { AppStateEnum, useAppState } from '../api/app-state';
import { useMe } from '../api/me';
import { useOrg } from '../api/org';
import { useAuth } from '../api/auth';
import { useQuickStartGuide } from '../api/quick-start-checklist';
import { usePublicHolidayCalendars, usePublicHolidaysForCalendar } from '../api/public-holidays';
import { useAllHands } from '../api/allhands';

export const useInitApp = () => {
  const { appState } = useAppState();
  useAuth();
  useOrg({ enabled: appState !== AppStateEnum.notAuthenticated });
  useMe({ enabled: appState !== AppStateEnum.notAuthenticated });
  usePeople({ enabled: [AppStateEnum.ready, AppStateEnum.isFirstLogin].includes(appState as AppStateEnum) });
  useQuickStartGuide();
  usePublicHolidaysForCalendar({
    enabled: [AppStateEnum.ready, AppStateEnum.isFirstLogin].includes(appState as AppStateEnum),
  });
  usePublicHolidayCalendars({ enabled: [AppStateEnum.ready, AppStateEnum.isFirstLogin].includes(appState as AppStateEnum) });
  useAllHands({
    enabled: [AppStateEnum.ready, AppStateEnum.isFirstLogin].includes(appState as AppStateEnum),
  });
};
