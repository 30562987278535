import moment from 'moment';
import * as Yup from 'yup';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { PauseForm } from '../../../components/PauseForm';
import { DAY_FORMAT } from '../../../components/PauseCalendar/PauseFullCalendar/constants';
import {
  PauseDrawer, PauseDrawerContent, PauseDrawerFooter, PauseDrawerTitle,
} from '../../../components/PauseDrawer';
import { PauseTypography } from '../../../components/PauseTypography';
import { LeaveApplicationFooter, LeaveFormContents } from './LeaveFormContents';
import {
  getLeaveStateFromLeave, getLeaveTemporalPositionFromLeave, getViewerFromLeave,
} from '../utils';
import {
  getLeaveActions, LeaveAction,
} from '../leave-form-state';
import {
  PauseTab, PauseTabPanel, PauseTabs, PauseTabsList, useTabs,
} from '../../../components/PauseTabs';
import { CompOffFooter, CompOffForm } from './CompOffForm';
import { AllHandsFooter, AllHandsForm } from './AllHands';
import { noop } from '../../../api/_utils';

export enum LeaveFormIntermediateState {
  ConfirmDelete = 'ConfirmDelete',
  ConfirmDecline = 'ConfirmDecline'
}

export enum Tabs {
  Leave = 'Leave',
  CompOff = 'CompOff',
  AllHands = 'AllHands'
}

export enum CompOffFormState {
  New='New',
  Readonly='Readonly',
  ApproveOrDecline='ApproveOrDecline',
  WaitingForDecline='WaitingForDecline',
  Deletable='Deletable'
}

export enum AllHandsState {
  New = 'New',
  Readonly = 'Readonly',
  Deletable = 'Deletable'
}

interface LeaveFormProps extends React.PropsWithChildren<any> {
  open: boolean,
  onClose: () => any,
  drawerTitle?: string,
  drawerProps?: any,
  me: any,
  selectablePeople: any[],
  lockedDates: any[],
  loading: boolean,
  currentLeave: any | null | undefined,
  onCreateLeave: any,
  onApproveLeave: any,
  onRejectLeave: any,
  onDeleteLeave: any,
  approver: any,
  setSelectedPerson: () => any,
  disableDeductibleLeaveTypes?: boolean,
  currentApprover: any,
  allowanceList?: any[],
  setLeaveStartDate?: (a: string | moment.Moment) => any,
  selectedDate?: string,
  staticTab?: Tabs | null,
  currentCompOff?: {
    id: string,
    applicant: any,
    applicationReason: string,
    numberOfDays: number,
    status: string,
    actioner: any
  },
  onCreateCompOff: (data: {
    numberOfDays: number,
    applicationReason: string,
  }) => Promise<any>,
  onApproveCompOff: (id: string) => Promise<any>,
  onDeclineCompOff: (id: string) => Promise<any>,
  onCancelCompOff: (id: string) => Promise<any>,
  currentAllHands: {
    id: string,
    state: string,
    reason: string,
    team: {
      id: string,
      name: string,
    },
    startDate: any,
    endDate: any,
    startType: any,
    endType: any
  },
  onCreateAllHands: (data: {
    reason: string | null,
    teamID: string,
    startDate: string,
    endDate: string,
    startType: string,
    endType: string,
  }) => any,
  onDeleteAllHands: (id: string) => any
}

export const LeaveForm = ({
  open,
  onClose = () => null,
  drawerTitle = 'Apply for',
  drawerProps = {},
  me,
  selectablePeople,
  lockedDates,
  loading,
  currentLeave,
  onCreateLeave,
  onApproveLeave,
  onRejectLeave,
  onDeleteLeave,
  approver,
  setSelectedPerson,
  disableDeductibleLeaveTypes = false,
  currentApprover,
  allowanceList = [],
  setLeaveStartDate = () => null,
  selectedDate = undefined,
  staticTab = null,
  currentCompOff,
  onCreateCompOff = () => Promise.resolve(null),
  onApproveCompOff = () => Promise.resolve(null),
  onDeclineCompOff = () => Promise.resolve(null),
  onCancelCompOff = () => Promise.resolve(null),
  onCreateAllHands = () => Promise.resolve(null),
  onDeleteAllHands = () => Promise.resolve(null),
  currentAllHands,
}: LeaveFormProps) => {
  const { value: activeTab, onChange: onChangeTab } = useTabs(
    { initActiveTab: staticTab || Tabs.Leave },
  );

  const [formIntermediateState, setFormIntermediateState] = React.useState<
          LeaveFormIntermediateState | null>(null);

  const changeFormIntermediateState = (
    a: LeaveFormIntermediateState,
  ) => setFormIntermediateState(a);

  const viewer = getViewerFromLeave({
    leave: currentLeave,
    currentApprover,
    me,
  });
  const leaveState = getLeaveStateFromLeave(currentLeave);
  const leaveTemporalPosition = getLeaveTemporalPositionFromLeave(currentLeave);

  const leaveFormAction = getLeaveActions({
    leaveState,
    leaveTemporalPosition,
    viewer,
  });
  const currentCompOff_ = currentCompOff || {
    applicant: me,
    numberOfDays: null,
    applicationReason: '',
  };
  const currentLeave_ = currentLeave || ({
    applicant: me,
    leaveType: null,
    startDate: moment(selectedDate).format(DAY_FORMAT),
    endDate: moment(selectedDate).format(DAY_FORMAT),
    startType: 'START_OF_DAY',
    endType: 'END_OF_DAY',
    applicationReason: '',
  });
  const currentAllHands_ = currentAllHands || {
    team: null,
    startDate: moment(selectedDate).format(DAY_FORMAT),
    endDate: moment(selectedDate).format(DAY_FORMAT),
    startType: 'START_OF_DAY',
    endType: 'END_OF_DAY',
    reason: '',
  };

  const formInitialValue = useMemo(() => {
    switch (activeTab) {
      case Tabs.Leave:
        return currentLeave_;
      case Tabs.AllHands:
        return currentAllHands_;
      case Tabs.CompOff:
        return currentCompOff_;
      default:
        return currentLeave_;
    }
  }, [activeTab, currentLeave, currentCompOff]);

  const validationSchema = useMemo<any>(() => {
    switch (activeTab) {
      case Tabs.Leave:
        return {
          applicant: Yup.object().required('Need a person to apply the leave for').nullable(),
          startDate: Yup.string().required('Required').nullable(),
          endDate: Yup.string().required('Required').nullable(),
          leaveType: Yup.object().required('Required').nullable(),
          startType: Yup.string().required('Required').nullable(),
          endType: Yup.string().required('Required').nullable(),
        };
      case Tabs.CompOff:
        return {
          numberOfDays: Yup.mixed()
            .test('c', 'Must be a number', (v:any) => !Number.isNaN(Number(v)))
            .test('a', 'Must be greater than 0', (v: any) => v > 0)
            .test('b', 'Must be in multiples of 0.25', (v:any) => v % 0.25 === 0)
            .required('Required'),
          applicationReason: Yup.string().required('Reason is required'),
        };
      case Tabs.AllHands:
        return {
          team: Yup.mixed().required('Select a team').nullable(),
          startDate: Yup.string().required('Required').nullable(),
          endDate: Yup.string().required('Required').nullable(),
          startType: Yup.string().required('Required').nullable(),
          endType: Yup.string().required('Required').nullable(),
          reason: Yup.string().nullable(),
        };
      default:
        return {
          applicant: Yup.object().required('Need a person to apply the leave for').nullable(),
          startDate: Yup.string().required('Required').nullable(),
          endDate: Yup.string().required('Required').nullable(),
          leaveType: Yup.object().required('Required').nullable(),
          startType: Yup.string().required('Required').nullable(),
          endType: Yup.string().required('Required').nullable(),
        };
    }
  }, [activeTab]);

  const getCompOffState = () => {
    if (!currentCompOff) return CompOffFormState.New;
    // all code below this means current comp off is not null/empty
    // and this is an existing compoff being viewed
    const isPending = currentCompOff?.status === 'PENDING';
    const isDeleted = currentCompOff?.status === 'CANCELED';
    const isAdmin = me?.isAdmin;
    const isApprover = (me?.roles?.approver?.teams || [])
      .includes(currentCompOff_.applicant?.teamID);
    if (isApprover || isAdmin) {
      // eslint-disable-next-line no-nested-ternary
      return (isPending) ? CompOffFormState.ApproveOrDecline
        : isDeleted ? CompOffFormState.Readonly : CompOffFormState.Deletable;
    }
    return isPending ? CompOffFormState.Deletable : CompOffFormState.Readonly;
  };
  const [compOffState, setCompOffState] = useState<CompOffFormState>(getCompOffState());

  const allHandsState = useMemo(() => {
    if (!currentAllHands) return AllHandsState.New;
    if (me?.isAdmin) return AllHandsState.Deletable;
    const isApprover = (me?.roles?.approver?.teams || []).includes(currentAllHands_.team.id);
    if (isApprover) return AllHandsState.Deletable;
    return AllHandsState.Readonly;
  }, [me, currentAllHands]);

  useEffect(() => {
    if (compOffState === CompOffFormState.WaitingForDecline) return;
    setCompOffState(getCompOffState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompOff]);

  const drawerWidth = useMemo(() => {
    if (loading) return '320px';
    if (currentLeave) {
      return leaveFormAction === LeaveAction.Submit ? '480px' : '320px';
    } if (currentCompOff) {
      return compOffState === CompOffFormState.New ? '480px' : '320px';
    } if (currentAllHands) {
      return allHandsState === AllHandsState.New ? '480px' : '320px';
    }
    return '480px';
  }, [loading, compOffState, currentCompOff, leaveFormAction, currentLeave]);

  return (
    /* @ts-ignore */
    <PauseForm
      enableReinitialize
      initialValues={formInitialValue}
      validationSchema={Yup.object().shape(validationSchema)}
      onSubmit={(values) => {
        if (activeTab === Tabs.Leave) {
          const dateFormat = 'YYYY-MM-DD';
          const payload = {
            applicantID: get(values, 'applicant.id'),
            leaveTypeID: get(values, 'leaveType.id'),
            applicationReason: values.applicationReason,
            startDate: moment(values.startDate).format(dateFormat),
            endDate: moment(values.endDate).format(dateFormat),
            startType: values.startType,
            endType: values.endType,
          };

          if (leaveFormAction === LeaveAction.Submit) {
            return onCreateLeave(payload).then(onClose).catch(noop);
          }

          if (leaveFormAction === LeaveAction.ApproveReject) {
            // approve new leave
            return formIntermediateState === LeaveFormIntermediateState.ConfirmDecline
              ? onRejectLeave({
                ...payload,
                rejectionReason: values.rejectionReason,
              }).then(onClose).catch(noop)
              : onApproveLeave(payload).then(onClose).catch(noop);
          }

          if (leaveFormAction === LeaveAction.ApproveDelete) {
            return formIntermediateState === LeaveFormIntermediateState.ConfirmDelete
              ? onDeleteLeave().then(onClose).catch(noop)
              : onApproveLeave(payload).then(onClose).catch(noop);
          }

          if (leaveFormAction === LeaveAction.Delete) {
            return onDeleteLeave().then(onClose).catch(noop);
          }
        }

        if (activeTab === Tabs.CompOff) {
          const payload = {
            numberOfDays: values?.numberOfDays,
            applicationReason: values?.applicationReason,
          };
          if (compOffState === CompOffFormState.New) {
            return onCreateCompOff(payload).then(onClose).catch(noop);
          }
          if (compOffState === CompOffFormState.Deletable) {
            return onCancelCompOff(currentCompOff?.id || '').then(onClose).catch(noop);
          }
          if (compOffState === CompOffFormState.ApproveOrDecline) {
            return onApproveCompOff(currentCompOff?.id || '').then(onClose).catch(noop);
          }
          if (compOffState === CompOffFormState.WaitingForDecline) {
            return onDeclineCompOff(currentCompOff?.id || '').then(onClose).catch(noop);
          }
        }

        if (activeTab === Tabs.AllHands) {
          const payload = {
            teamID: values.team.id,
            startDate: moment(values?.startDate).format(DAY_FORMAT),
            endDate: moment(values?.endDate).format(DAY_FORMAT),
            startType: values?.startType,
            endType: values?.endType,
            reason: values?.reason || null,
          };

          if (allHandsState === AllHandsState.New) {
            return onCreateAllHands(payload).then(onClose).catch(noop);
          }

          if (allHandsState === AllHandsState.Deletable) {
            return onDeleteAllHands(currentAllHands.id).then(onClose).catch(noop);
          }
        }

        return Promise.resolve();
      }}
    >
      {/* @ts-ignore */}
      <PauseDrawer
        width={drawerWidth}
        open={open}
        onClose={onClose}
        {...drawerProps}
      >
        {/* @ts-ignore */}
        <PauseDrawerTitle>
          <PauseTypography variant="title">
            {drawerTitle}
          </PauseTypography>
        </PauseDrawerTitle>
        {!loading && (
        <PauseDrawerContent compact>
          <PauseTabs size="medium" value={activeTab} onChange={onChangeTab}>
            {staticTab ? null : (
              <PauseTabsList>
                <PauseTab label="Time-off" value={Tabs.Leave} />
                <PauseTab label="Comp-off" value={Tabs.CompOff} />
                {me?.roles?.approver?.teams?.length || me?.isAdmin ? <PauseTab label="All Hands" value={Tabs.AllHands} /> : null}
              </PauseTabsList>
            )}
            <PauseTabPanel value={Tabs.Leave}>
              <LeaveFormContents
                disableDeductibleLeaveTypes={disableDeductibleLeaveTypes}
                setSelectedPerson={setSelectedPerson}
                approver={approver}
                me={me}
                people={selectablePeople || []}
                formIntermediateState={formIntermediateState}
                leaveState={leaveState}
                currentLeave={currentLeave}
                allowanceList={allowanceList}
                setLeaveStartDate={setLeaveStartDate}
              />
            </PauseTabPanel>
            <PauseTabPanel value={Tabs.CompOff}>
              <CompOffForm
                me={me}
                approver={approver}
                state={compOffState}
                compOffState={currentCompOff?.status || ''}
                actioner={currentCompOff?.actioner || null}
              />
            </PauseTabPanel>
            <PauseTabPanel value={Tabs.AllHands}>
              <AllHandsForm
                state={allHandsState}
              />
            </PauseTabPanel>
          </PauseTabs>
        </PauseDrawerContent>
        )}

        <PauseDrawerFooter>
          {!loading && (activeTab === Tabs.Leave) && (
          <LeaveApplicationFooter
            disableDeductibleLeaveTypes={disableDeductibleLeaveTypes}
            lockedDates={lockedDates} // array <{ date: string, id: string }>
            {...(currentLeave && { currentLeave })}
            formIntermediateState={formIntermediateState}
            changeFormIntermediateState={changeFormIntermediateState}
            onClose={onClose}
            viewer={viewer}
            leaveState={leaveState}
            me={me}
          />
          )}
          {!loading && (activeTab === Tabs.CompOff) && (
            <CompOffFooter
              onClose={onClose}
              state={compOffState}
              onChangeCompOffState={setCompOffState}
            />
          )}
          {!loading && (activeTab === Tabs.AllHands) && (
          <AllHandsFooter
            onClose={onClose}
            state={allHandsState}
          />
          )}
        </PauseDrawerFooter>

      </PauseDrawer>
    </PauseForm>
  );
};
