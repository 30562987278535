import React from 'react';
import { Grid } from '@material-ui/core';
import { get } from 'lodash';
import { Info } from '../../../assets/icons/feather/components';

export const NotificationAvatar = ({ children }:{children: React.ReactNode}) => (
  <Grid item xs={2} css={{ cursor: 'pointer' }}>
    {children}
  </Grid>
);

export const NotificationContent = ({ children }: { children: React.ReactNode }) => (
  <Grid item xs={10} css={{ cursor: 'pointer' }}>
    {children}
  </Grid>
);

const notificationCSS = ({ isUnread = false }: { isUnread: boolean }) => (theme) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
  cursor: 'pointer',
  borderBottom: `1px solid ${theme.palette.pause.background.dark}`,
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    background: theme.palette.pause.background.medium,
  },
  ...(isUnread && {
    background: theme.palette.pause.background.dark,
    '&:hover': {
      background: theme.palette.pause.background.dark,
    },
  }),
  '&.selected': {
    background: theme.palette.pause.background.medium,
    ':hover': {
      background: theme.palette.pause.background.medium,
    },
  },
});
export const Notification = ({
  children, selected, isUnread, ...otherProps
}: {
    children: React.ReactNode,
    selected?: boolean,
    isUnread?: boolean,
    [key: string]: any
}) => (
  <div
    css={notificationCSS({ isUnread: isUnread || false })}
    className={selected ? 'selected' : ''}
    {...otherProps}
  >
    <Grid
      container
      spacing={2}
    >
      {children}
    </Grid>
  </div>
);

export const NotificationActions = ({ children }:{children: React.ReactNode}) => (
  <Grid item xs={12} css={(theme) => ({ marginTop: theme.spacing(2), cursor: 'pointer' })}>
    {children}
  </Grid>
);

export const NotificationMeta = ({ children }: { children: React.ReactNode }) => (
  <div
    css={(theme) => ({
      marginTop: theme.spacing(0.5),
      color: theme.palette.pause.primary.medium,
      '> div': {
        marginTop: theme.spacing(0.05),
        '> div': {
          display: 'inline', // we're doing this because <PauseTypography>, being a <p> tag, is a block element.
          // But the design wants it to be inline.
        },
      },
    })}
  >
    { children }
  </div>
);

export const NotificationAvatarIcon = ({
  color,
  icon: Icon = Info,
}: {
  color: string,
  icon: any
}) => (
  <div
    css={(theme) => ({
      width: theme.spacing(5),
      height: theme.spacing(5),
      borderRadius: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: color !== 'primary' ? get(theme, `palette.pause.${color}.light`) : get(theme, 'palette.pause.background.dark'),
    })}
  >
    {/* @ts-ignore */}
    <Icon css={(theme) => ({
      color: color !== 'primary' ? get(theme, `palette.pause.${color}.dark`) : get(theme, 'palette.pause.primary.medium'),
      width: theme.spacing(3),
      height: theme.spacing(3),
    })}
    />
  </div>
);
