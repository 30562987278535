import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import Head from 'next/head';

export const PauseLoader = ({ size, fullPage, showPageTitle }) => (
  <div css={(theme) => ({
    width: fullPage ? '100vw' : '100%',
    height: fullPage ? '100vh' : '100%',
    minHeight: theme.spacing(20),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })}
  >
    {showPageTitle && (
    <Head>
      <title>Pause | Loading...</title>
    </Head>
    )}
    <CircularProgress
      css={(theme) => ({
        color: theme.get('palette.pause.primary.dark'),
      })}
      size={size}
    />
  </div>
);

PauseLoader.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullPage: PropTypes.bool,
  showPageTitle: PropTypes.bool,
};

PauseLoader.defaultProps = {
  size: 40,
  fullPage: false,
  showPageTitle: true,
};
