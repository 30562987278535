import { usePeople } from '../api/people';
import fromEntries from '../../utils/fromEntries';

export const useApproverForPerson = (person: { approverID: string } | null): {
  id: string,
  fullName: string
} | null => {
  const { people } = usePeople();
  const peopleObject = fromEntries((people || []).map((p) => [p.id, p]));
  if (!person || !person?.approverID) return null;
  const approver = peopleObject[person.approverID as string];
  return approver;
};
