import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { PauseInteractiveButton } from '../PauseInteractiveButton';
import { PauseTextButton } from '../PauseTextButton';
import { PauseTypography } from '../PauseTypography';

type Props = {
  open: boolean,
  title: React.ReactNode,
  description: React.ReactNode,
  onCancel: (any?) => any,
  onConfirm: (any?) => any,
  confirmButtonText?: string,
  cancelButtonText?: string,
}

export const PauseConfirmModal = ({
  open = false,
  onCancel,
  onConfirm,
  title,
  description,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
}: Props) => (
  <Dialog
    open={open}
    onClose={onCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle
      css={(theme) => ({
        padding: theme.spacing(2, 4, 1, 3),
      })}
      id="alert-dialog-title"
    >
      <PauseTypography variant="title" color="pause.primary.dark">{title}</PauseTypography>
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        <PauseTypography variant="body1" color="pause.primary.medium">
          {description}
        </PauseTypography>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <div css={(theme) => ({
        display: 'grid',
        gridAutoFlow: 'column',
        gridGap: theme.spacing(2),
        justifyItems: 'end',
        padding: theme.spacing(0, 2, 1, 2),
      })}
      >
        {/* @ts-ignore */}
        <PauseTextButton onClick={onCancel} color="primary">
          {cancelButtonText}
        </PauseTextButton>
        <PauseInteractiveButton onClick={onConfirm} color="primary" autoFocus>
          {confirmButtonText}
        </PauseInteractiveButton>
      </div>

    </DialogActions>
  </Dialog>
);
