import React, {
  ReactNode, useCallback, useMemo,
} from 'react';
import {
  FieldValidator, useField,
} from 'formik';
import { FormControl, FormHelperText } from '@material-ui/core';
import { PauseTypography } from '../PauseTypography';

interface FormFieldInternalProps extends RenderFunctionProps{
  disabled: boolean,
  error: string|undefined,
  render: (a: RenderFunctionProps) => ReactNode,
  showErrorMessage: boolean|undefined,
  showError: boolean|undefined,
}

const FormFieldInternal = React.memo(({
  disabled,
  render,
  error,
  field,
  state,
  helpers,
  showError,
  showErrorMessage,
}: FormFieldInternalProps) => (
  <FormControl
    fullWidth
    component="div"
    css={() => ({
      '&:last-child': {
        marginBottom: 0,
      },
    })}
    error={showError && Boolean(error)}
    disabled={disabled}
  >
    {render({
      field,
      state,
      helpers,
    })}
    {showErrorMessage && showError && error && error.length ? (
      <FormHelperText css={(theme) => ({
        minHeight: theme.spacing(0),
      })}
      >
        <PauseTypography variant="body2">
          {error}
        </PauseTypography>

      </FormHelperText>
    ) : null}
  </FormControl>
));

interface AnyObject {[x:string]: any}

interface RenderFunctionProps {
  field: AnyObject,
  state: {
    error: string|boolean|null|undefined,
    disabled: boolean
  },
  helpers: AnyObject
}

interface Props {
  showErrorMessage: boolean,
  name: string,
  showErrorOnlyOnTouched: boolean, disabled: boolean,
  validate: FieldValidator|undefined,
  // eslint-disable-next-line react/no-unused-prop-types
  fast: boolean,
  render: (a: RenderFunctionProps) => ReactNode,
  // We only need this to invalidate the render memo.
  fieldProps: AnyObject
}

const typedMemo: <T>(c: T) => T = React.memo;

export const PauseFormField2: any = typedMemo(({
  showErrorMessage, name,
  showErrorOnlyOnTouched = true,
  disabled,
  validate,
  render,
  fieldProps,
}: Props) => {
  const [field, meta, helpers] = useField({ name, validate });

  const memoizedFieldProps = useMemo(() => fieldProps, [fieldProps]);

  const { error, touched } = meta;

  const memoizedRender = useCallback(render, [memoizedFieldProps]);

  const showError = (!showErrorOnlyOnTouched || touched) && Boolean(error);

  const memoizedOnChange = useCallback(field.onChange, []);
  const memoizedOnBlur = useCallback(field.onBlur, []);
  const memoizedField = useMemo(() => ({
    name: field.name,
    value: field.value,
    onChange: memoizedOnChange,
    onBlur: memoizedOnBlur,
  }), [field.name, field.value, memoizedOnChange, memoizedOnBlur]);

  const memoizedState = useMemo(() => ({
    error: showError ? error : null,
    disabled,
  }), [error, showError, disabled]);

  const memoizedSetValue = useCallback(helpers.setValue, []);
  const memoizedHelpers = useMemo(() => ({ setValue: memoizedSetValue }), [memoizedSetValue]);

  return (
    <FormFieldInternal
      field={memoizedField}
      state={memoizedState}
      helpers={memoizedHelpers}
      error={error}
      showError={showError}
      showErrorMessage={showErrorMessage}
      disabled={disabled}
      render={memoizedRender}
    />
  );
});
