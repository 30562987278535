import React from 'react';
import { QueryClient, QueryClientProvider, useQueryClient } from 'react-query';
import { get, merge } from 'lodash';
import { useNotifier } from '../integrated-components/snackbar/store';
import { useAuth } from './auth';

export const mainQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      staleTime: Infinity,
      cacheTime: Infinity,
      queryFn: () => null,
    },
  },
});

const Notifier = () => {
  const queryClient = useQueryClient();
  const { enqueueNotification } = useNotifier();
  const { logout } = useAuth();
  const defaultOptions = queryClient.getDefaultOptions();
  const onError = (error) => {
    // const status = get(error, 'response.status');
    // if (!status) {
    //   return;
    // }
    const isAbortedRequest = (error?.toString() || '').includes('Request aborted');
    if (isAbortedRequest) return error;
    const url = get(error, 'response.config.url');
    const status = get(error, 'response.status');
    const method = get(error, 'response.config.method');
    const message = get(error, 'response.data.message', 'Sorry, something went wrong. Please try refreshing the page.');
    if (url !== '/orgs' && status === 401) {
      logout();
      return error;
    }
    if (!(url === '/orgs' && method === 'get')) enqueueNotification(message);
    return error;
  };
  queryClient.setDefaultOptions(merge(defaultOptions, {
    queries: {
      onError,
    },
    mutations: {
      onError,
    },
  }));
  return <></>;
};

export const PauseQueryClientProvider = ({ children }) => (
  <QueryClientProvider client={mainQueryClient}>
    <Notifier />
    {children}
  </QueryClientProvider>
);
