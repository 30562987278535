import { useState, useEffect } from 'react';
import { get } from 'lodash';
import { useEnhancedRouter } from '../../../router';
import { useNotifier } from '../snackbar/store';
import { LEAVE_TYPES } from '../../../utils/constants';
import { LeaveType, LeaveTypeWithAllowance, useLeaveTypes } from '../../api/leave-types';
import { useOrg } from '../../api/org';
import { noop } from '../../api/_utils';
import { ifArrayThenString } from '../../../utils/query-to-string';

export const DEFAULT_LEAVE_TYPE_DESCRIPTION_MSG = 'Edit this leave type to provide a helpful description';

const { ANNUAL, DEDUCTIBLE } = LEAVE_TYPES;

const filterDeductibleLeaves = (leave) => [ANNUAL, DEDUCTIBLE].includes(leave.type);

export const useLeavePolicy = () => {
  const {
    leaveTypes,
    loading: loadingLeaveTypes,
  } = useLeaveTypes();
  const { org, editOrg } = useOrg();
  const [loading, setLoading] = useState(true);
  const [deductibleLeaveTypes, setDeductibleLeaveTypes] = useState<LeaveTypeWithAllowance[]>([]);
  const [nonDeductibleLeaveTypes, setNonDeductibleLeaveTypes] = useState<LeaveType[]>([]);
  const { enqueueNotification } = useNotifier();

  useEffect(() => {
    if (loadingLeaveTypes || !leaveTypes) return;
    setDeductibleLeaveTypes(
      (leaveTypes || []).filter(filterDeductibleLeaves) as LeaveTypeWithAllowance[],
    );
    setNonDeductibleLeaveTypes(
      (leaveTypes || []).filter((a) => !filterDeductibleLeaves(a)) as LeaveType[],
    );
    setLoading(false);
  }, [loadingLeaveTypes, leaveTypes]);

  const onEditOrg = ({ disableDeductibleLeaveTypes }: {
    disableDeductibleLeaveTypes: boolean,
  }): Promise<any> => editOrg({
    ...org,
    disableDeductibleLeaveTypes,
  }).then(() => {
    enqueueNotification(`${disableDeductibleLeaveTypes ? 'Disabled' : 'Enabled'} deductible leave types`);
  }).catch(noop);

  return {
    deductibleLeaveTypes,
    nonDeductibleLeaveTypes,
    loading,
    disableDeductibleLeaveTypes: !loading && get<boolean>(org, 'disableDeductibleLeaveTypes', false),
    onEditOrg,
  };
};

export const useLeaveTypeForm = () => {
  const [loading, setLoading] = useState(true);
  const { query } = useEnhancedRouter();
  const type = ifArrayThenString(get(query, 'type'));
  const leaveTypeID = get(query, 'id');
  const isEditing = !!leaveTypeID;
  const {
    leaveTypes, loading: loadingLeaveTypes,
    createLeaveType,
    editLeaveType,
    deleteLeaveType,
  } = useLeaveTypes();
  const [currentLeaveType, setCurrentLeaveType] = useState<LeaveType | LeaveTypeWithAllowance | null>(null);
  // const dispatch = useDispatch();
  const { enqueueNotification } = useNotifier();

  const onCreateLeaveType = async (data) => createLeaveType(data).then(() => {
    enqueueNotification('Leave type created!');
  }).catch(noop);

  const onEditLeaveType = async (data) => editLeaveType(data).then(() => {
    enqueueNotification('Leave type edited!');
  }).catch(noop);

  const onDeleteLeaveType = async (id) => deleteLeaveType(id).then(() => {
    enqueueNotification('Leave type deleted!');
  }).catch(noop);

  useEffect(() => {
    if (loadingLeaveTypes) return;
    setCurrentLeaveType((leaveTypes || []).find((l) => l.id === leaveTypeID) || null);
    setLoading(false);
  }, [loadingLeaveTypes, leaveTypeID, leaveTypes]);

  return {
    loading,
    type,
    isEditing,
    currentLeaveType,
    onCreateLeaveType,
    onEditLeaveType,
    onDeleteLeaveType,
  };
};
