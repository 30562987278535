export const CALENDAR_BORDER_WIDTH = 0.25;
export const MONTH_FORMAT = 'YYYY-MM';
export const DAY_FORMAT = 'YYYY-MM-DD';

export const LEAVE_MARKERS = {
  START_TYPES: ['START_OF_DAY', 'START_OF_SECOND_HALF'],
  END_TYPES: ['END_OF_FIRST_HALF', 'END_OF_DAY'],
};

export const FIXED_DAY_HEIGHT = 168;
export const CALENDAR_DAY_PADDING = 1;
export const CALENDAR_LARGE_DAY_PADDING = 2;
export const MAX_LEAVES_TO_SHOW = 4;
export const NON_WORKING_DAY_BACKGROUND = 'palette.pause.background.medium';
export const CALENDAR_LARGE_DAY_TOP_OFFSET = 26;
