/* eslint no-underscore-dangle: 0 */ // --> OFF
import * as Moment from 'moment';
import { chain, get } from 'lodash';
import { extendMoment } from 'moment-range';
import { DAY_FORMAT, LEAVE_MARKERS } from '../PauseFullCalendar/constants';
import { Leaves } from '../PauseFullCalendar';

const { START_TYPES, END_TYPES } = LEAVE_MARKERS;

const moment = extendMoment(Moment);

export const getDate = (dateString: Moment.Moment) => moment(dateString, DAY_FORMAT);

const getEarliestStartDateForPerson = (
  leavesForAPerson: Array<any>,
) => leavesForAPerson?.reduce((acc, leave) => {
  if (!acc) {
    return leave._startDate;
  }

  return moment.min(acc, leave._startDate);
}, null);

// split and get leaves only between the dates
// group by person
export const getFormattedLeavesForListCalendar = (
  {
    startDate,
    endDate,
    leaves,
  }: {
  startDate: Moment.Moment,
  endDate: Moment.Moment,
  leaves: Array<Leaves>
},
) => chain(leaves)
  .filter((leave) => {
    const calendarDatesRange = moment.range([
      getDate(startDate), getDate(endDate),
    ]);

    const leaveDatesRange = moment.range([
      getDate(leave.startDate), getDate(leave.endDate),
    ]);

    return calendarDatesRange.overlaps(leaveDatesRange);
  })
  .map((leave) => ({
    ...leave,
    _startDate: moment.max(getDate(leave.startDate), getDate(startDate)),
    _endDate: moment.min(getDate(leave.endDate), getDate(endDate)),
    _startType: moment(getDate(startDate)).isAfter(getDate(leave.startDate))
      ? START_TYPES[0] : leave.startType,
    _endType: moment(getDate(endDate)).isBefore(getDate(leave.endDate))
      ? END_TYPES[1] : leave.endType,
  }))
  .groupBy((leave) => get(leave, 'applicant.id'))
  .mapValues()
  .map((leavesForAPerson) => leavesForAPerson)
  .sortBy((leavesForAPerson) => getEarliestStartDateForPerson(leavesForAPerson).unix())
  .map((leavesForAPerson) => ({
    leaves: leavesForAPerson,
    person: get(leavesForAPerson[0], 'applicant'),
  }))
  .value();
